export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1점",
  ["exchange_5m"]: "5점",
  ["exchange_15m"]: "15점",
  ["exchange_30m"]: "30점",
  ["exchange_60m"]: "60점",
  ["exchange_more"]: "더",
  ["exchange_4h"]: "4 시간",
  ["exchange_day"]: "낮",
  ["exchange_month"]: "달",
  ["exchange_week"]: "주",
  ["exchange_year"]: "년도",
  ["tabbar.home"]: "첫 장",
  ["tabbar.trade"]: "인용 부호",
  ["tabbar.hosting"]: "재무 관리",
  ["tabbar.exchange"]: "거래",
  ["tabbar.account"]: "자산",
  ["common.confirm"]: "확인하다",
  ["index.number"]: "균형",
  ["index.Total revenue"]: "총 수익",
  ["index.Trading"]: "AI 전문가 고문 시작",
  ["index.intelligentive trading"]: "지금 AI 지능형 양적 거래를 시작하세요",
  ["host.detail"]: "세부",
  ["host.todayProfit"]: "오늘의 수입",
  ["host.totalProfit"]: "예상 수입",
  ["host.Order quantity"]: "주문량",
  ["host.Strategic"]: "전략 활동 제품",
  ["host.escrow"]: "에스크로 금액",
  ["host.max"]: "최고",
  ["host.determine"]: "확신하는",
  ["host.balance"]: "사용 가능한 잔액",
  ["host.Deposit"]: "예금 자금",
  ["host.quota"]: "한계",
  ["host.years"]: "총 연간 비율",
  ["host.expect"]: "예상 이익",
  ["host.profit"]: "유바오",
  ["host.lockup"]: "스테이킹 마이닝",
  ["host.liquidated"]: "조기 상환 페널티",
  ["host.redeem"]: "구원",
  ["host.isitredeemed"]: "조기상환 페널티가 있는데 상환인가요?",
  ["host.consignmentOrder"]: "위탁 주문",
  ["host.purchase"]: "사다",
  ["exchange.bond"]: "여유 마진",
  ["exchange.optionNo"]: "문제",
  ["exchange.endTime"]: "이 문제의 종료",
  ["exchange.Forecast"]: "예측 기간",
  ["enter the amount"]: "금액을 입력해주세요",
  ["Insufficient wallet balance"]: "구매할 지갑 잔액이 부족합니다.",
  ["minimum purchase amount is"]: "최소 구매 금액은",
  ["Purchase successful"]: "성공적인 구매",
  ["exchange.orderConifrm"]: "주문 확인",
  ["exchange.balance"]: "사용 가능한 잔액",
  ["exchange.custormAmount"]: "맞춤 금액",
  ["exchange.input"]: "들어 오세요",
  ["exchange.confirm"]: "확신하는",
  ["exchange.minute"]: "분",
  ["exchange.curentrust"]: "현재 위임장",
  ["exchange.hisentrust"]: "역사적 위원회",
  ["exchange.noneEntrust"]: "아직 권한 없음",
  ["exchange.wait"]: "개봉을 기다리다",
  ["exchange.opened"]: "열려 있는",
  ["exchange.rescinded"]: "취소됨",
  ["exchange.type"]: "유형",
  ["exchange.finashTime"]: "마감 시간",
  ["exchange.openingQuantity"]: "오픈 포지션 수",
  ["exchange.awards"]: "수상 횟수",
  ["exchange.charge"]: "포지션 오픈 수수료",
  ["exchange.openPrice"]: "개점 가격",
  ["exchange.close"]: "종가",
  ["exchange.prediction"]: "포지션 방향",
  ["exchange.pump"]: "펌핑",
  ["hello"]: "안녕하십니까",
  ["welcome"]: "에 오신 것을 환영합니다",
  ["change success"]: "성공적으로 수정됨",
  ["set success"]: "성공적으로 설정",
  ["confirm"]: "확신하는",
  ["confirm1"]: "확신하는",
  ["confirm2"]: "확신하는",
  ["cancel"]: "취소",
  ["copySuccess"]: "성공적으로 복사",
  ["please enter"]: "들어 오세요",
  ["no data"]: "데이터 없음",
  ["all"]: "모두",
  ["processing"]: "처리",
  ["credited"]: "크레딧",
  ["fail"]: "불합격",
  ["timeout failed"]: "시간 초과 실패",
  ["amount"]: "양",
  ["state"]: "상태",
  ["time"]: "시각",
  ["pwd_password"]: "비밀번호",
  ["pwd_Enter password"]: "비밀번호를 입력 해주세요",
  ["pwd_old"]: "기존 비밀번호",
  ["pwd_new"]: "새 비밀번호",
  ["pwd_new again"]: "비밀번호 확인",
  ["pwd_Enter old password"]: "이전 비밀번호를 입력하세요",
  ["pwd_Enter new password"]: "새 비밀번호를 입력하세요",
  ["pwd_Enter new password again"]: "새 비밀번호를 다시 입력하세요",
  ["pwd_set"]: "암호를 설정하세요",
  ["pwd_not empty"]: "비밀번호는 비워둘 수 없습니다.",
  ["pwd_length"]: "비밀번호 길이는 6자 이상이어야 합니다.",
  ["pwd_old not empty"]: "이전 비밀번호는 비워둘 수 없습니다.",
  ["pwd_new not empty"]: "새 비밀번호는 비워둘 수 없습니다.",
  ["pwd_inconsistent twice"]: "두 비밀번호가 일치하지 않습니다",
  ["home_msg"]: "기술은 그것을 개발하는 사람들의 공통된 영혼입니다.",
  ["home_recharge"]: "빠른 입금",
  ["home_exchange"]: "옵션 거래",
  ["home_mining"]: "스테이킹 마이닝",
  ["home_service"]: "온라인 서비스",
  ["home_increaseList"]: "승자 목록",
  ["home_turnover"]: "용량",
  ["home_currencyName"]: "이름",
  ["home_newPrice"]: "최신 가격",
  ["home_vol24"]: "24시간 거래량",
  ["home_upDown"]: "견적 변경",
  ["home_warrant"]: "주류 통화",
  ["trade_tab1"]: "선택 과목",
  ["trade_tab2"]: "Coin-M Futures",
  ["trade_tab3"]: "Exchange",
  ["trade_tab4"]: "계약 거래",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "두 번째 계약",
  ["trade.empty"]: "데이터 없음",
  ["edit_choice"]: "편집자의 선택",
  ["add_optional"]: "옵션에 추가",
  ["save"]: "저장",
  ["select_all"]: "모두 선택",
  ["delete"]: "삭제",
  ["please_choose"]: "선택해주세요",
  ["sidebar_share"]: "나는 공유하고 싶다",
  ["sidebar_help"]: "지원 센터",
  ["sidebar_service"]: "온라인 서비스",
  ["exchange_selCurrency"]: "통화 선택",
  ["exchange_searchText"]: "검색어를 입력해주세요",
  ["exchange_rule"]: "규칙",
  ["exchange_bond"]: "여유 마진",
  ["exchange_optionNo"]: "문제",
  ["exchange_endTime"]: "이 문제의 종료",
  ["exchange_forecast"]: "예측 기간",
  ["exchange_minute"]: "분",
  ["exchange_curentrust"]: "현재 위임장",
  ["exchange_noneEntrust"]: "아직 권한 없음",
  ["exchange_bullish"]: "강세",
  ["exchange_bearish"]: "약세",
  ["exchange_orderConifrm"]: "주문 확인",
  ["exchange_balance"]: "사용 가능한 잔액",
  ["exchange_custormAmount"]: "맞춤 금액",
  ["host_profit"]: "유바오",
  ["host_lockup"]: "스테이킹 마이닝",
  ["host_consignmentOrder"]: "위탁 주문",
  ["host_purchase"]: "사다",
  ["mining_purchase"]: "사다",
  ["host_todayProfit"]: "오늘의 수입",
  ["host_totalProfit"]: "예상 수입",
  ["host_limit"]: "투자 한도",
  ["host_income"]: "소득",
  ["host_funds in custody"]: "구금된 자금",
  ["host_entrusted order"]: "위탁 주문",
  ["host_expected earnings for today"]: "오늘의 예상 수입",
  ["host_cumulative income"]: "누적 소득",
  ["host_order in commission"]: "커미션에 도착한 주문",
  ["host_day"]: "하늘",
  ["host_single limit"]: "단일 제한",
  ["host_daily rate of return"]: "일일 수익률",
  ["host_cycle"]: "주기",
  ["host_processing"]: "처리",
  ["host_completed"]: "완전한",
  ["host.redeemed"]: "구속",
  ["host_histotyProfit"]: "역사적 수입",
  ["host_illustrate"]: "설명하다",
  ["host_order details"]: "주문 세부 정보",
  ["host_single benefit"]: "단일 혜택",
  ["host_single day income"]: "하루 수입",
  ["host_total revenue"]: "총 수익",
  ["host_earnings Details"]: "수입 세부정보",
  ["host_paid"]: "유급의",
  ["host_pay miner fees"]: "광부 수수료 지불",
  ["host_user output"]: "사용자 출력",
  ["host_address"]: "주소",
  ["host_quantity"]: "수량",
  ["host_time"]: "시각",
  ["host_account Balance"]: "계정 잔액",
  ["account_total assets equivalent"]: "총 자산 등가물",
  ["account_deposit"]: "보증금",
  ["account_withdraw"]: "철회하다",
  ["account_exchange"]: "교환",
  ["account_funding Account"]: "자금 계좌",
  ["account_hide small coins"]: "작은 동전 숨기기",
  ["account_available"]: "사용 가능",
  ["account_processing"]: "처리",
  ["account_folded"]: "접힌",
  ["recharge"]: "재충전",
  ["recharge_currency"]: "재충전 통화",
  ["recharge_scan code transfer"]: "스캔 코드 전송 및 충전",
  ["recharge_amount"]: "충전량",
  ["recharge_upload"]: "결제 세부정보 스크린샷 업로드",
  ["recharge_record"]: "충전 기록",
  ["recharge_enter amount"]: "충전금액을 입력해주세요",
  ["recharge_less zero"]: "충전 금액은 0보다 작을 수 없습니다.",
  ["recharge_upload screenshot"]: "스크린샷을 올려주세요",
  ["recharge_try again later"]: "이미지 업로드 중입니다. 나중에 다시 시도하세요.",
  ["recharge_successful"]: "성공적인 재충전",
  ["recharge_Details"]: "충전 세부 정보",
  ["recharge_currency2"]: "통화",
  ["recharge_address"]: "충전 주소",
  ["recharge_order number"]: "주문 번호",
  ["loading"]: "로드 중…",
  ["investment amount"]: "투자 금액",
  ["expire date"]: "만료일",
  ["order number"]: "주문 번호",
  ["start date"]: "시작일",
  ["end date"]: "종료일",
  ["cash back"]: "캐시백",
  ["order"]: "주문하다",
  ["swap"]: "교환",
  ["swap_currency exchange"]: "환전소",
  ["swap_please enter exchange amount"]: "교환금액을 입력해주세요",
  ["swap_exchange quantity"]: "교환 수량",
  ["swap_up to"]: "까지",
  ["swap_redeem all"]: "모두 사용",
  ["swap_exchange rate"]: "환율",
  ["swap_available"]: "사용 가능",
  ["withdraw"]: "철회하다",
  ["withdrawal_currency"]: "출금 통화",
  ["withdrawal_Amount_Available"]: "사용 가능한 금액",
  ["withdrawal_Please_enter_amount"]: "출금금액을 입력해주세요",
  ["withdrawal_remove_all"]: "모두 제거",
  ["withdrawal_address"]: "출금 주소",
  ["withdrawal_Please_enter_address"]: "지갑주소를 입력해주세요",
  ["withdrawal_password"]: "출금 비밀번호",
  ["withdrawal_Please_enter_password"]: "출금 비밀번호를 입력해주세요",
  ["withdrawal_handling_fee"]: "수수료",
  ["withdrawal_Reminder_text"]: "주의사항 : 출금시 수수료 일부가 부과되며, 출금 후 실시간으로 계좌에 입금되오니, 문의사항은 고객센터로 연락주시기 바랍니다.",
  ["withdrawal_under_review"]: "검토 중",
  ["withdrawal_success"]: "출금 성공",
  ["withdrawal_examination_passed"]: "시험 통과",
  ["withdrawal_paying_on_behalf"]: "대신 지불",
  ["withdrawal_payment_overtime"]: "초과 근무 수당",
  ["withdrawal_record"]: "출금 기록",
  ["enter the bet amount"]: "배팅금액을 입력해주세요",
  ["minimum bet amount"]: "최소 베팅 금액은",
  ["bet up to"]: "최대 베팅 금액은",
  ["number is being updated"]: "문제를 업데이트하고 있습니다. 나중에 다시 시도해 주세요.",
  ["checkout success"]: "성공적으로 주문",
  ["exchange amount cannot be blank"]: "교환 금액은 비워둘 수 없습니다.",
  ["exchange amount must be greater than 0"]: "교환 금액은 0보다 커야 합니다.",
  ["successfully redeemed"]: "당신은 이미 환전 성공 제출하였으니 인내심을 가지고 기다리십시오",
  ["enter the withdrawal amount"]: "출금금액을 입력해주세요",
  ["amount cannot be less than 0"]: "출금 금액은 0보다 작을 수 없습니다.",
  ["enter the withdrawal address"]: "출금주소를 입력해주세요",
  ["enter the withdrawal password"]: "출금 비밀번호를 입력해주세요",
  ["successful application"]: "성공적인 신청",
  ["set the password first"]: "비밀번호를 먼저 설정해주세요",
  ["Withdrawal details"]: "출금내역",
  ["Withdrawal quantity"]: "출금 금액",
  ["Withdrawal address"]: "출금 주소",
  ["share_invite"]: "친구 초대",
  ["share_link"]: "링크 공유",
  ["share"]: "공유하다",
  ["add_success"]: "성공적으로 추가됨",
  ["mining.question1"]: "스테이킹 마이닝이란? 수입은 어디서 나오나요?",
  ["mining.answer1"]: "스테이킹 마이닝은 사용자가 보상을 받기 위해 신속하게 온체인을 약속할 수 있도록 회사에서 만든 도구 제품입니다. 블록체인 네트워크에 디지털 자산을 스테이킹하고 POS(Proof of Stake, 즉 지분증명) 메커니즘을 기반으로 보상을 얻습니다. 이 메커니즘에서 사용자는 디지털 자산을 노드에 위탁하고 노드는 블록체인에서 블록을 생성하고 트랜잭션을 패키징하는 등의 권한을 행사하고 보상을 받습니다. 사용자는 잠긴 위치의 수에 비례하여 노드에서 얻은 보상을 공유합니다.",
  ["mining.question2"]: "참조 연율화란 무엇입니까? 계산하는 방법?",
  ["mining.answer2"]: "참조 연간 수익률은 귀하가 구독한 제품의 참조 연간 수익률이며, 참조용으로만 체인의 실제 수입에 따라 동적으로 업데이트됩니다. 참조 연율화 = 온체인 수익/온체인 락업 수량/온체인 락업 시간*365*100%.",
  ["mining.question3"]: "기대수익률이란? 계산하는 방법?",
  ["mining.answer3"]: "예상 수익은 구독 수량 및 연간 제품 참조를 기반으로 계산된 일일 예상 수익이며 참조용일 뿐입니다. 일일 예상 수익 = 구독 수 * 연간 기준 참조 / 365",
  ["mining.question4"]: "이자는 언제부터 발생하나요?",
  ["mining.answer4"]: "T일 구독의 경우 T+1일 00:00부터 이자가 계산됩니다.",
  ["mining.question5"]: "혜택은 언제 분배되나요?",
  ["mining.answer5"]: "T일 청약, T+1일부터 이자가 발생하여 만기 후 원금과 함께 수익이 분배됩니다.",
  ["mining.question6"]: "기간 제품을 조기에 사용할 수 있습니까? 어떤 영향을 미치나요?",
  ["mining.answer6"]: "주문은 언제든지 상환할 수 있지만 기간 상품을 미리 상환하는 경우 이미 주문에서 얻은 수입의 일부를 잃게 되며 발행된 수입은 상환 시점의 원금에서 공제됩니다.",
  ["mining.question7"]: "제품 기간이 만료된 후 수동으로 교환해야 합니까?",
  ["mining.answer7"]: "기간 제품이 만료된 후 자동 상환.",
  ["mining.question8"]: "1. 어떻게 가입하나요?",
  ["mining.answer8"]: "비파괴 및 무담보 유동성 채굴에 참여하려면 ETH 채굴 수수료를 지불해야 하며 ETH 지갑 주소는 한 번만 요청하면 되며 성공 후 채굴 허가가 자동으로 열립니다.",
  ["mining.question9"]: "2. 어떻게 돈을 인출합니까?",
  ["mining.answer9"]: "매일 생성되는 코인을 USDT로 전환한 후 출금을 시작할 수 있습니다. USDT 출금은 귀하가 노드에 추가한 지갑 주소로 자동 발송되며, 다른 주소는 지원되지 않습니다.",
  ["mining.question10"]: "3. 소득은 어떻게 계산하나요?",
  ["mining.answer10"]: "성공적으로 가입하면 스마트 계약은 노드를 통해 주소 계산을 시작하고 수입 계산을 시작합니다.",
  ["show_more"]: "더보기",
  ["promble"]: "일반적인 문제",
  ["what_yeb"]: "유바오란?",
  ["what_yeb_answer"]: "Yubibao는 유휴 디지털 자산이 있는 사용자와 돈을 빌려야 하는 사용자를 돕기 위해 회사에서 만든 제품입니다. 즉석 입출금, 시간당 이자계산, 금리 커스터마이징 지원 등의 특징을 가지고 있습니다. 사용자가 디지털 자산을 Yubibao로 전송한 후 시스템은 사용자가 설정한 대출 금리에 따라 매시간 대출 성공 여부를 판단합니다. 획득할 수 없으며 다음 시간을 기다려야 합니다. 1시간 일치하는 결과입니다. 엄격한 위험 관리 시스템에 의존하는 Yubibao는 사용자 거래의 보안을 완전히 보장합니다.",
  ["what_lixi"]: "이자는 어떻게 계산됩니까?",
  ["what_lixi_answer"]: "이자(일) = 대출 금액 * 시장 대출 연간 환산 / 365/24 * 85%",
  ["rule_yeb_title1"]: "유바오란?",
  ["rule_yeb_cont1"]: "Yubibao는 유휴 디지털 자산이 있는 사용자와 돈을 빌려야 하는 사용자를 돕기 위해 회사에서 만든 제품입니다. 즉석 입출금, 시간당 이자계산, 금리 커스터마이징 지원 등의 특징을 가지고 있습니다. 사용자가 디지털 자산을 Yubibao로 전송한 후 시스템은 사용자가 설정한 대출 금리에 따라 매시간 대출 성공 여부를 판단합니다. 획득할 수 없으며 다음 시간을 기다려야 합니다. 1시간 일치하는 결과입니다. 엄격한 위험 관리 시스템에 의존하는 Yubibao는 사용자 거래의 보안을 완전히 보장합니다.",
  ["rule_yeb_note1"]: "*참고: 과거 수익률은 미래 수익률을 나타내지 않습니다. 우리는 원금과 이자를 화폐, 물리적 물건, 지분 또는 기타 형태의 재산 반환으로 일정 기간 내에 상환할 것을 약속하지 않습니다.",
  ["rule_yeb_title2"]: "제품 장점",
  ["rule_yeb_til2"]: "건장한",
  ["rule_yeb_cont2"]: "엄격한 위험 관리 시스템에 의존하는 Yu'ebao는 사용자 자산의 안전을 완전히 보장하고 사용자가 안심하고 이익을 누릴 수 있도록 합니다.",
  ["rule_yeb_title3"]: "이자 수익 자산에 대한 투자 지침",
  ["rule_yeb_cont3"]: "사용자가 자산을 Yubibao로 전송한 후 구매한 디지털 자산은 레버리지 거래 사용자에게 대여됩니다.",
  ["rule_yeb_title4"]: "손익 계산서",
  ["rule_yeb_til4"]: "소득 규칙",
  ["rule_yeb_cont4"]: "경매가 성공하면 전날의 수입을 합산하여 익일 02:00에 정산합니다.",
  ["rule_yeb_title5"]: "소득 구성 설명",
  ["rule_yeb_cont5"]: "사용자의 대출 이자의 15%는 위험 예금으로 사용되며 85%는 대출 사용자에게 할당됩니다. 즉, 대출 사용자가 얻을 수 있는 이자는 다음과 같습니다. 85%",
  ["rule_yeb_title6"]: "거래 설명",
  ["rule_yeb_cont61"]: "언제든지 구독 지원, 구독 후 한 시간에 입찰 시작",
  ["rule_yeb_cont62"]: '금리 매칭 규칙: 시장은 차입 수요에 따라 매시간 "최소 대출 연율"에 따라 낮은 가격에서 높은 값으로 입찰하고 경매의 가장 높은 값은 "현재 대출 연율"로 사용됩니다.',
  ["rule_yeb_cont63"]: "최소 대출 연율 < 현재 대출 연율 인 경우 현재 대출 연율을 사용하여 성공적으로 대출합니다.",
  ["rule_yeb_cont64"]: "최소 대출 연율 > 현재 대출 연율일 경우 대출은 실패하고 이자는 없습니다.",
  ["rule_yeb_cont65"]: "최소 대출 연율 = 현재 대출 연율 인 경우 부분적으로 대출되거나 대출이 실패 할 수 있습니다.",
  ["rule_yeb_title7"]: "상환 규칙",
  ["rule_yeb_til7"]: "만기 시 자동상환",
  ["rule_wk_title1"]: "스테이킹 마이닝이란?",
  ["rule_wk_cont1"]: "스테이킹 마이닝은 사용자가 보상을 받기 위해 신속하게 온체인을 약속할 수 있도록 회사에서 만든 도구 제품입니다. 블록체인 네트워크에 디지털 자산을 스테이킹하고 POS(Proof of Stake, 즉 지분증명) 메커니즘을 기반으로 보상을 얻습니다. 이 메커니즘에서 사용자는 디지털 자산을 노드에 위탁하고 노드는 블록체인에서 블록을 생성하고 트랜잭션을 패키징하는 등의 권한을 행사하고 보상을 받습니다. 사용자는 잠긴 위치의 수에 비례하여 노드에서 얻은 보상을 공유합니다.",
  ["rule_wk_note1"]: "*참고: 과거 수익률은 미래 수익률을 나타내지 않습니다. 우리는 원금과 이자를 화폐, 물리적 물건, 지분 또는 기타 형태의 재산 반환으로 일정 기간 내에 상환할 것을 약속하지 않습니다.",
  ["rule_wk_title2"]: "제품 장점",
  ["rule_wk_til2"]: "건장한",
  ["rule_wk_cont2"]: "스테이킹 마이닝은 다양한 상품 기간으로 제3자로부터 비교적 안정적인 보상을 받을 수 있습니다.",
  ["rule_wk_title3"]: "이자 수익 자산에 대한 투자 지침",
  ["rule_wk_cont3"]: "스테이킹 마이닝은 블록체인 네트워크의 운영을 지원하고 그에 상응하는 보상을 받기 위해 블록체인에 디지털 자산을 약속하는 것입니다.",
  ["rule_wk_title4"]: "손익 계산서",
  ["rule_wk_cont4"]: "T일에 청약이 성공하면 T+1일 00시에 이자가 시작되며, 이자는 02:00에 정산됩니다.",
  ["rule_wk_title5"]: "거래 설명",
  ["rule_wk_til5"]: "구매 규칙",
  ["rule_wk_cont5"]: "언제든지 구독을 지원하세요.",
  ["rule_wk_title6"]: "상환 규칙",
  ["rule_wk_cont61"]: "상시 상환 지원, 프로젝트별로 상환 대기 시간이 다릅니다.",
  ["rule_wk_cont62"]: "기간이 만료되지 않은 경우에는 선불로 상환하고 지불한 이자의 일부를 차감합니다.",
  ["rule_wk_cont63"]: "사용이 지원되지 않음",
  ["rule_wk_title7"]: "위험 경고",
  ["rule_wk_cont7"]: "일반 상품의 조기 상환의 경우, 시스템은 주문에서 이미 얻은 수입의 일부를 공제합니다.",
  ["rule_fzywk_title1"]: "유동성 채굴",
  ["rule_fzywk_cont1"]: "유동성 마이닝은 사용자가 디지털 자산을 관리하고 장기적이고 효율적인 수익을 낼 수 있도록 지원하기 위해 회사에서 만든 자산 관리 기능입니다. 사용자가 클릭(마이너 수수료 지불)하고 일정량의 마이너 수수료를 지불하면 즉시 수익이 발생합니다. 사용자는 한 번만 지불하면 영구적인 효과를 얻을 수 있으며, 이후에는 다시 클릭하여 지불할 필요가 없습니다. 소득 비율에 따라 다릅니다. 사용자의 지갑 잔고에서 잔고가 높을수록 소득 비율이 높아집니다.",
  ["rule_fzywk_note"]: "참고: (비대부) 수익내역은 6시간마다 하루 4회 발송되며, 자세한 수익내역은 적립내역을 통해 확인하실 수 있습니다.",
  ["rule_fzywk_note1"]: "참고: (본 상품은 현재 할당량이 제한된 복지 배수 상품입니다. 향후 디지털 자산 평가가 사용자에게 추가될 예정이며, 본 상품은 자격을 갖춘 사용자에게만 공개되므로 선착순으로 제공됩니다.)",
  ["rule_fzywk_title2"]: "제품 장점",
  ["rule_fzywk_til2"]: "강력한 이점",
  ["rule_fzywk_cont2"]: "자금을 빌려주지 않고도 장기적인 혜택을 얻을 수 있고, 사용자 자산의 안전을 보장하고, 손실 위험 없이 혜택을 누릴 수 있습니다.",
  ["rule_fzywk_title3"]: "손익 계산서",
  ["rule_fzywk_til3"]: "소득 규칙",
  ["rule_fzywk_cont3"]: "결제 승인 후 즉시 적용되며, 매일 정해진 시간 내에 수익이 분배되며, 사용자의 수익 주기는 6시간이며, 24시간 이내에 총 4배의 수익을 얻을 수 있습니다.",
  ["rule_fzywk_til3_1"]: "기어 수입 비율",
  ["rule_fzywk_tab1"]: "기어",
  ["rule_fzywk_tab2"]: "수량",
  ["rule_fzywk_tab3"]: "수익률",
  ["rule_fzywk_tab4"]: "수익 단위",
  ["unlimited"]: "제한 없는",
  ["verified"]: "확인됨",
  ["verified_tips"]: "안전한 계정 경험을 보장하기 위해 개인 식별 정보를 묶으십시오.",
  ["verified_name"]: "이름",
  ["verified_input_name"]: "이름을 입력하세요.",
  ["verified_card"]: "면허 번호",
  ["verified_input_card"]: "주민등록번호를 입력해주세요",
  ["photo_front"]: "신분증 사진",
  ["clearly_visible"]: "(문서가 잘 보이는지 확인하십시오)",
  ["front_photo"]: "신분증 사진 앞면 업로드",
  ["reverse_photo"]: "신분증 사진 뒷면 업로드",
  ["standard"]: "기준",
  ["missing"]: "잃어버린",
  ["vague"]: "희미한",
  ["strong flash"]: "강한 플래시",
  ["confirm_submit"]: "확인 및 제출",
  ["need to change"]: "바인딩을 변경해야 하는 경우",
  ["Contact Customer Service"]: "고객 서비스에 문의",
  ["authentication succeeded"]: "인증 성공",
  ["please_information"]: "정보를 완성해주세요",
  ["only_symbol"]: "현재 거래 쌍을 살펴보십시오.",
  ["options"]: "옵션",
  ["occupy"]: "점령하다",
  ["bb_buy"]: "구입",
  ["bb_sell"]: "팔다",
  ["bb_count"]: "수량",
  ["bb_current_best_price"]: "현재 최저가로 거래하세요",
  ["bb_turnover"]: "회전율",
  ["bb_Limit order"]: "지정가 주문",
  ["bb_market order"]: "시장 주문",
  ["bb_successful operation"]: "성공적인 작업",
  ["bb_operation failed"]: "작업 실패",
  ["bb_Hide other trading pairs"]: "다른 거래 쌍 숨기기",
  ["bb_price"]: "가격",
  ["bb_volume"]: "용량",
  ["bb_default"]: "기본",
  ["bb_buy order"]: "구매 주문",
  ["bb_sell order"]: "매도 주문",
  ["bb_commission price"]: "수수료 가격",
  ["bb_commission amount"]: "수수료 금액",
  ["bb_Cancellation"]: "해제",
  ["bb_total turnover"]: "총 매출액",
  ["bb_Average transaction price"]: "평균 거래 가격",
  ["bb_Undo succeeded"]: "실행 취소 성공",
  ["bb_Undo failed"]: "실행취소 실패",
  ["bb_Deal done"]: "거래 완료",
  ["bb_Revoked"]: "취소됨",
  ["bb_depth map"]: "깊이 맵",
  ["bb_actual price"]: "실제 가격",
  ["bb_to buy order"]: "구매 주문",
  ["bb_to sell order"]: "매도 주문",
  ["bb_direction"]: "방향",
  ["bb_clinch"]: "거래를하다",
  ["user_login"]: "사용자 로그인",
  ["password_placeholder"]: "비밀번호를 입력 해주세요",
  ["code_placeholder"]: "인증 코드를 입력하십시오",
  ["login"]: "로그인",
  ["no account"]: "계정이 없으신가요?",
  ["to register"]: "등록하다",
  ["Forgot password"]: "비밀번호 분실?",
  ["user register"]: "사용자 등록",
  ["username"]: "사용자 이름",
  ["repassword_placeholder"]: "비밀번호를 다시 입력해주세요",
  ["register"]: "등록하다",
  ["have account"]: "이미 계정이 있습니까?",
  ["to login"]: "로그인으로 이동",
  ["address_placeholder"]: "지갑주소를 입력해주세요",
  ["password does not match"]: "두 비밀번호가 일치하지 않습니다",
  ["username_msg"]: "사용자 이름을 입력하십시오",
  ["ua_msg"]: "사용자 이름/지갑 주소를 입력하십시오",
  ["register_failed"]: "등록 실패",
  ["login_failed"]: "로그인 실패",
  ["invalid_address"]: "주소가 잘못되었습니다. 지갑을 열고 승인하세요.",
  ["exchange_hisentrust"]: "역사적 위원회",
  ["sidebar_bindPassword"]: "펀드 비밀번호 설정",
  ["sidebar_changePassword"]: "펀드 비밀번호 수정",
  ["sidebar_setLoginPassword"]: "로그인 비밀번호 설정",
  ["sidebar_changeLoginPassword"]: "로그인 비밀번호 수정",
  ["add_address"]: "바인드 주소",
  ["paste"]: "반죽",
  ["No amount available"]: "사용 가능한 금액 없음",
  ["Please commission amount"]: "수수료 금액을 입력하세요.",
  ["country"]: "시민권 국가",
  ["verified_input_country"]: "국적을 입력하세요",
  ["Please select a chain"]: "체인을 선택해 주세요",
  ["login_out"]: "로그아웃",
  ["login_out_sure"]: "로그아웃하시겠습니까?",
  ["login_out_success"]: "성공적으로 종료",
  ["login_out_faild"]: "종료하지 못했습니다.",
  ["login_out_cancel"]: "취소 종료",
  ["real_name_withdrawal"]: "출금 전 실명확인을 최대한 빨리 완료해주세요",
  ["going_long"]: "오래 가다",
  ["open_empty"]: "열려 있는",
  ["can_open_empty"]: "열 수 있는",
  ["cash_deposit"]: "여유",
  ["fund_transfer"]: "자금 이체",
  ["from"]: "~에서",
  ["arrive"]: "도착하다",
  ["fund_account"]: "자금 계좌",
  ["transaction_account"]: "거래 계좌",
  ["transfer_all"]: "모두 전송",
  ["up_to"]: "까지",
  ["contract_account"]: "계약 계정",
  ["spot_account"]: "현물 계정",
  ["transfer_amount"]: "이체금액을 입력해주세요",
  ["max_transfer"]: "최대 전송",
  ["current_position"]: "현재 위치",
  ["minimum_transfer"]: "최소 이체 금액은",
  ["sell"]: "팔다",
  ["buy"]: "구입",
  ["null"]: "없는",
  ["income"]: "소득",
  ["yield_rate"]: "수익률",
  ["open_interest"]: "미결제약정",
  ["position_collateral_assets"]: "포지션 담보 자산",
  ["average_opening_price"]: "평균 시가",
  ["expected_strong_parity"]: "강력한 패리티 예상",
  ["guaranteed_asset_ratio"]: "보장 자산 비율",
  ["close_position"]: "포지션을 닫다",
  ["adjustment_margin"]: "조정 여백",
  ["stop_profit"]: "이익을 취하다",
  ["stop_loss"]: "손절하다",
  ["profit_loss"]: "이익을 얻고 손절매",
  ["many"]: "많은",
  ["cancel_bulk"]: "일괄 취소",
  ["trigger_price"]: "방아쇠 가격",
  ["mark"]: "표시",
  ["order_price"]: "주문 가격",
  ["total_entrustment"]: "총 위탁 금액",
  ["latest_price"]: "최근 거래 가격",
  ["profit_price"]: "이익 창출 트리거 가격",
  ["loss_price"]: "손절매 트리거 가격",
  ["profit_order"]: "이익 주문 받기",
  ["loss_order"]: "손절매 주문",
  ["profit_tips_1"]: "시장 가격",
  ["profit_tips_2"]: "거래가 완료되면 이익실현 주문이 발생하며 거래 후 이익이 발생할 것으로 예상됩니다.",
  ["profit_tips_3"]: "손절매 주문이 발동되면 거래 후 예상되는 손실",
  ["order_count"]: "수량",
  ["market_price"]: "시장 가격",
  ["limit_price"]: "가격 제한",
  ["margin_call"]: "마진 콜",
  ["margin_reduction"]: "마진 감소",
  ["sustainable"]: "지속 가능한",
  ["increase_most"]: "기껏해야 증가",
  ["reduce_most"]: "최대한 줄여라",
  ["after_price"]: "추가 후 청산 가격",
  ["add_lever"]: "추가된 레버리지",
  ["sub_price"]: "청산가격 인하",
  ["sub_lever"]: "레버리지 감소",
  ["commission_detail"]: "수수료 세부정보",
  ["to_trade"]: "무역",
  ["order_status"]: "주문 상태",
  ["delegate_type"]: "대리인 유형",
  ["volume"]: "용량",
  ["delegate_value"]: "대리인 값",
  ["commission_time"]: "커미션 시간",
  ["currency_least_purchase"]: "최소 구매 필요",
  ["quick_close"]: "빠른 닫기",
  ["closing_price"]: "종가",
  ["completely_sold"]: "완전 매진",
  ["not_contract_transactions"]: "현재 통화는 계약 거래를 지원하지 않습니다.",
  ["coinbase_h_l_hot"]: "인기 통화",
  ["coinbase_h_market"]: "통화 시세",
  ["coinbase_h_coin"]: "통화",
  ["coinbase_h_vol"]: "거래량",
  ["coinbase_h_price"]: "가격",
  ["coinbase_h_quote"]: "견적 변경",
  ["coinbase_h_more"]: "더보기",
  ["coinbase_h_pt"]: "사용자가 신뢰할 수 있는 암호화폐 거래 플랫폼",
  ["coinbase_h_tit1"]: "사용자 보안 자산 펀드(SAFU)",
  ["coinbase_h_conn1"]: "모든 거래 수수료의 10%를 안전 자산 펀드에 저장하여 사용자 자금을 부분적으로 보호합니다.",
  ["coinbase_h_tit2"]: "개인화된 액세스 제어",
  ["coinbase_h_conn2"]: "고급 액세스 제어는 개인 계정 장치 및 주소에 대한 액세스를 제한하여 사용자가 안심할 수 있도록 합니다.",
  ["coinbase_h_tit3"]: "고급 데이터 암호화",
  ["coinbase_h_conn3"]: "개인 거래 데이터는 End-to-End 암호화를 통해 안전하게 보호되며, 개인 정보는 본인만이 접근할 수 있습니다.",
  ["coinbase_hq_title"]: "관련 질문",
  ["coinbase_hq_tit1"]: "1. 데피란?",
  ["coinbase_hq_conn1"]: "DeFi의 정식 명칭은 탈중앙화 금융(Decentralized Finance)입니다.오픈 파이낸스, 거의 모든디파이프로젝트는 모두 이더리움 블록체인에서 수행됩니다. Ethereum은 분산 응용 프로그램을 위한 글로벌 오픈 소스 플랫폼입니다. 이더리움에서는 디지털 자산을 관리하는 코드를 작성하고 지리적 제한 없이 프로그램을 실행할 수 있습니다.",
  ["coinbase_hq_tit2"]: "2. 탈중앙화 금융의 장점은 무엇입니까?",
  ["coinbase_hq_conn2"]: "분산 금융은 블록체인의 핵심 원칙을 활용하여 금융 보안 및 투명성을 개선하고 유동성 및 성장 기회를 확보하며 통합되고 표준화된 경제 시스템을 지원합니다. 고도로 프로그래밍 가능한 스마트 계약은 새로운 금융 상품 및 디지털 자산 생성을 자동화하고 지원합니다. 블록체인 분산 아키텍처 전반에 걸친 변조 방지 데이터 오케스트레이션은 보안 및 감사 가능성을 향상시킵니다.",
  ["coinbase_hq_tit3"]: "3. Defi는 트랜잭션을 어떻게 실행합니까?",
  ["coinbase_hq_conn3"]: 'Defi는 현재 무기한 계약, 배송 계약 및 알루미늄 양적 거래를 지원합니다. 무기한 계약은 일종의 "혁신적인" 선물입니다. 계약인 전통적 계약은 만료일이 있지만 무기한 계약은 인도일이 없고 영구적으로 보유할 수 있으므로 무기한 계약이라고 합니다. 수동 양적 거래는 기존 컴퓨터 기술을 사용하여 단기간에 많은 양의 데이터를 분석한 후 미리 작성된 거래 모델을 통해 자동으로 거래하여 거래 효율성을 높이는 것을 말합니다. 이 컴퓨터는 주요 거래소에서 스프레드를 거래하는 데 사용됩니다.',
  ["coinbase_hq_tit4"]: "4. 거래를 위해 코인베이스를 선택하는 이유는 무엇입니까?",
  ["coinbase_hq_conn4"]: '고도로 규제된 환경에서 운영되는 Coinbase는 고객 데이터를 보호하기 위해 추가 조치를 취하며 Coinbase의 자체 데이터 과학자 및 엔지니어도 이 데이터에 무료로 액세스할 수 없습니다. Coinbase 프로덕션 서버에서 실행되는 모든 코드는 프로덕션에 들어가기 전에 여러 그룹의 코드 검토를 거쳐야 합니다. "우리 플랫폼에 저장된 디지털 자산은 고객의 것이기 때문에 핵심 원칙 중 하나는 보안 우선입니다.',
  ["coinbase_hq_tit5"]: "5. Coinbase와의 파트너십에 대한 Pangea Capital Management의 비전.",
  ["coinbase_hq_conn5"]: "2019 팬 콘티넨탈 캐피탈 매니지먼트. 코인베이스 화폐 시장 진출을 위해 2억 3천만 달러 자금 조달, 블록체인 측면에서 미국 최대 코인베이스 거래소인 코인베이스와의 전략적 파트너십 구축에 앞장서고, 코인베이스와 협력하여 Defi 분산 금융에 대한 심층 연구 수행 독립적인 블록체인 기술과 분산 애플리케이션을 구축합니다. 코인베이스 통화의 분산 교환을 수행합니다. 계약 감사 기관",
  ["coinbase_ho_tit1"]: "계약 감사",
  ["coinbase_ho_tit2"]: "현물/레버리지/계약",
  ["coinbase_ho_tit3"]: "스테이킹 마이닝, DeFi 등",
  ["coinbase_ho_tit4"]: "거래를 시작하려고 합니다",
  ["coinbase_ho_tit5"]: "탈중앙화 거래소 플랫폼",
  ["coinbase_ho_tit6"]: "지금 거래",
  ["coinbase_ho_tit7"]: "파트너",
  ["coinbase_ho_tit8"]: "지갑을 연결",
  ["coinbase_ho_tit9"]: "귀하의 브라우저는 지갑 확장을 지원하지 않습니다",
  ["coinbase_hs_tit1"]: "서비스 약관",
  ["coinbase_hs_tit2"]: "백지",
  ["coinbase_hs_tit3"]: "이메일 인증",
  ["coinbase_he_tit1"]: "이메일 바인딩",
  ["coinbase_he_tit2"]: "우편",
  ["coinbase_he_tit3"]: "확인 코드",
  ["coinbase_he_tit4"]: "인증 코드 보내기",
  ["coinbase_he_tit5"]: "이메일을 입력해주세요",
  ["coinbase_he_tit6"]: "재전송",
  ["tokpiedex_he_tit3"]: "확인 코드",
  ["opening time"]: "개장 시간",
  ["view_desc"]: "설명 보기",
  ["host_non-collateralized mining"]: "데피 마이닝",
  ["air_activity"]: "에어드롭 활동",
  ["total_output"]: "총 생산량",
  ["valid_node"]: "유효한 노드",
  ["participant"]: "참가자",
  ["user_benefit"]: "사용자 혜택",
  ["participate_mining"]: "채굴 참여",
  ["take_over"]: "인수하다",
  ["divide"]: "나누다",
  ["balance_reaches"]: "지갑 잔액이 도달하면",
  ["you_get"]: "당신은 얻을 것이다",
  ["mining_pool"]: "마이닝 풀의 보상",
  ["valid_time"]: "유효한 시간 받기:",
  ["air_activity_help_1"]: "스마트 계약은 유익한 방식으로 계약을 전파, 확인 또는 실행하도록 설계된 컴퓨터 프로토콜입니다. 스마트 계약은 제3자 없이 추적 가능하고 되돌릴 수 없는 신뢰할 수 있는 거래를 허용합니다. 스마트 계약의 개념은 1995년 Nick Szabo에 의해 처음 제안되었습니다. 스마트 계약의 목적은 기존 계약보다 우수한 보안 방법을 제공하여 계약과 관련된 기타 거래 비용을 줄이는 것입니다. 스마트 계약은 사람의 개입 없이 완전히 자율적으로 실행되며 공정하고 정당합니다.",
  ["air_activity_help_2"]: "에어드롭은 이더리움 스마트 계약을 통해 자율적으로 실행되며 모든 사용자가 에어드롭에 참여할 수 있는 것은 아닙니다. 스마트 컨트랙트에 대한 에어드랍 작업을 받으면 작업 조건만 완료하면 보상을 받을 수 있습니다.",
  ["air_activity_help_3"]: "에어드랍 미션의 기준이 다르고, 획득하는 보상도 다릅니다.",
  ["submit_success"]: "성공적으로 제출됨",
  ["verified_wait"]: "정보 검토 중",
  ["air_activity_msg_1"]: "참여해주셔서 감사합니다. ETH 보상이 귀하의 계정 잔액으로 분배되었습니다.",
  ["promotion_center"]: "홍보센터",
  ["invite_friende"]: "친구를 초대하여 함께 코인을 받으세요",
  ["invite_friends_commission"]: "친구 초대, 즉시 충전 및 커미션 받기",
  ["promotion_rule"]: "프로모션 규칙 보기",
  ["my_promotion"]: "내 승진",
  ["total_promotions"]: "총 프로모션 수",
  ["commission_amount"]: "수수료 금액",
  ["generation_p"]: "세대",
  ["second_generation_p"]: "2세대 수",
  ["three_generations_p"]: "3세대",
  ["invitation_code_y"]: "당신의 초대 코드",
  ["sponsored_link"]: "스폰서 링크",
  ["click_share"]: "공유하려면 클릭",
  ["commission_details"]: "수수료 세부정보",
  ["generation"]: "세대",
  ["second_generation"]: "2세대",
  ["three_generations"]: "3세대",
  ["user_ID"]: "사용자 ID",
  ["rebate_amount"]: "리베이트 금액",
  ["registration_time"]: "등록 시간",
  ["QRcode_register"]: "QR 코드를 스캔하여 등록",
  ["click_save"]: "저장을 클릭",
  ["share_amount"]: "양",
  ["invitation_code"]: "초대 코드(선택사항)",
  ["option_buy_up"]: "사다",
  ["option_buy_short"]: "공매도",
  ["option_countdown"]: "카운트다운",
  ["option_purchase_price"]: "구매 가격",
  ["option_profitability"]: "수익성",
  ["option_minimum_amount"]: "최소 금액",
  ["option_profit_loss"]: "예상 손익",
  ["option_latest_price"]: "최신 가격",
  ["option_now_price"]: "현재 가격",
  ["option_profit_loss_1"]: "이익과 손실",
  ["option_profit_loss_2"]: "최소 구매 금액",
  ["option_profit_loss_3"]: "최종 가격은 시스템 정산에 따릅니다.",
  ["host_single_limit_1"]: "몫",
  ["record_tip"]: "참고",
  ["record_tip_1"]: "친애하는 고객님, 사용 가능한 구매가 소진되었습니다. 고객 서비스에 문의하십시오.",
  ["available_time"]: "이용 가능 시간",
  ["about_us"]: "우리에 대해",
  ["home_tip_agree"]: "등록함으로써 동의합니다",
  ["home_tip_terms"]: "이용약관",
  ["home_tip_agreement"]: "개인 정보 정책",
  ["home_tip_score"]: "신용 점수",
  ["home_tip_service_email"]: "공식 고객 서비스 이메일",
  ["home_tip_and"]: "그리고",
  ["quick_label_1"]: "기한 정산 완료",
  ["quick_label_2"]: "현재 가격",
  ["quick_label_3"]: "주기",
  ["quick_label_5"]: "수량",
  ["quick_label_6"]: "가격",
  ["quick_label_7"]: "예상 이익",
  ["coinbase2_buy_tip"]: "구매를 원하시면 고객센터로 연락주세요",
  ["upload_file_error"]: "파일 크기가 1.5M를 초과하여 업로드할 수 없습니다. 업로드할 사진을 변경하십시오.",
  ["tokpiedex_home_recharge"]: "빠른 입금",
  ["tokpiedex_trade_tab6"]: "두 번째 계약",
  ["defi_host_non-collateralized mining"]: "데피 마이닝",
  ["defi_host_lockup"]: "스테이킹 마이닝",
  ["defi_participate_mining"]: "채굴 참여",
  ["defi_host_non-collateralized"]: "데피 마이닝",
  ["defi_h_pt"]: "사용자가 신뢰할 수 있는 암호화폐 거래 플랫폼",
  ["defi_hq_tit4"]: "4. 거래를 위해 코인베이스를 선택하는 이유는 무엇입니까?",
  ["defi_hq_tit5"]: "5. Coinsaving와의 파트너십에 대한 Pangea Capital Management의 비전.",
  ["defi_hq_conn4"]: '고도로 규제된 환경에서 운영되는 Coinsaving는 고객 데이터를 보호하기 위해 추가 조치를 취하며 Coinsaving의 자체 데이터 과학자 및 엔지니어도 이 데이터에 무료로 액세스할 수 없습니다. Coinsaving 프로덕션 서버에서 실행되는 모든 코드는 프로덕션에 들어가기 전에 여러 그룹의 코드 검토를 거쳐야 합니다. "우리 플랫폼에 저장된 디지털 자산은 고객의 것이기 때문에 핵심 원칙 중 하나는 보안 우선입니다.',
  ["defi_hq_conn5"]: "2019 팬 콘티넨탈 캐피탈 매니지먼트. 코인베이스 화폐 시장 진출을 위해 2억 3천만 달러 자금 조달, 블록체인 측면에서 미국 최대 코인베이스 거래소인 코인베이스와의 전략적 파트너십 구축에 앞장서고, 코인베이스와 협력하여 Defi 분산 금융에 대한 심층 연구 수행 독립적인 블록체인 기술과 분산 애플리케이션을 구축합니다. 코인베이스 통화의 분산 교환을 수행합니다. 계약 감사 기관",
  ["rachange_link_huobi"]: "Huobi 공식 충전 채널",
  ["rachange_link_binance"]: "바이낸스 공식 입금 채널",
  ["rachange_link_coinbase"]: "Coinbase 공식 충전 채널",
  ["rachange_link_crypto"]: "크립토 공식 충전 채널",
  ["recharge_hash"]: "트랜잭션 ID",
  ["recharge_hash_placeholder"]: "트랜잭션 ID를 입력하세요.",
  ["symbol_closed"]: "닫은",
  ["email_placeholder"]: "이메일을 입력해주세요",
  ["email_title"]: "우편",
  ["email_code"]: "확인 코드",
  ["email register"]: "이메일 등록",
  ["email login"]: "이메일 로그인",
  ["email_code_placeholder"]: "이메일 인증 코드를 입력하세요",
  ["use_email_change"]: "이메일을 사용하여 수정",
  ["use_password_change"]: "원래 암호를 사용하여 수정",
  ["tip_change"]: "참고: 사서함이 바인딩되지 않았거나 사서함이 분실된 경우",
  ["forgot_password"]: "비밀번호를 잊으 셨나요",
  ["i_know"]: "내가 참조",
  ["regulatory_permission"]: "금융 라이센스",
  ["regulatory_conn_1"]: "PLATFORM_TITLE Group은 호주 증권 투자 위원회(규정 번호: 001296201) 및 캐나다 금융 시장 행위 당국(규정 번호: M19578081)을 포함하여 전 세계 여러 곳에서 권위 있는 금융 기관의 엄격한 감독을 받습니다. PLATFORM_TITLE는 호주 증권 및 투자 위원회와 캐나다 금융 시장 행위 당국의 엄격한 금융 규정을 완전히 준수합니다. 규제 기관의 감독하에 PLATFORM_TITLE는 모든 거래의 절대적인 투명성을 보장하며 세계에서 가장 신뢰할 수 있는 금융 파생 상품 거래자 중 하나입니다.",
  ["regulatory_conn_2"]: "Versign은 PLATFORM_TITLE Capital Group의 글로벌 네트워크 보안 파트너입니다.게이트웨이는 업계 최고 표준인 최대 256비트의 고급 암호화 기술을 채택하여 고객의 온라인 보안과 대규모 온라인 거래의 정상적인 수행을 보장하여 고객이 안전하고 안정적으로 PLATFORM_TITLE 공식 사이트에서 거래 처리 입출금, 고객 정보 수정 및 기타 절차 Verisign은 매일 수억 건의 온라인 거래의 원활한 진행을 보장할 수 있는 서비스를 제공하는 우수한 기본 서비스 제공업체입니다. Verisign은 세계 최대의 독립 SS7 네트워크를 갖춘 모든 .com, net, .cc 및 .tv 도메인 이름에 대한 권위 있는 디렉토리 제공업체입니다. 매일 Verisign은 전 세계적으로 15억 개 이상의 보안 로그를 모니터링하고 500,000개 이상의 웹 서버를 보호합니다.",
  ["regulatory_conn_3"]: "Trading Central의 찬사를 받고 수상 경력에 빛나는 분석 방법론은 입증된 기술 지표를 활용합니다. 일중 거래, 스윙 거래 또는 장기 투자 등 Trading Central은 투자자에게 다양한 투자 스타일의 요구 사항을 충족하는 차트 및 수학적 분석을 제공할 수 있습니다.",
  ["regulatory_conn_4"]: "전 세계 30개국 이상에서 100개 이상의 주요 글로벌 금융 기관이 Trading Central이 제공하는 투자 조사 및 기술 분석을 사용하기로 선택했습니다.",
  ["regulatory_conn_5"]: "TRADING CENTRAL은 또한 Investorside, European IRP 및 Asia IRP의 3개 독립 연구 제공자(IRP) 협회의 공인 회원입니다. TRADING CENTRAL은 투자자 이익에 부합하고 투자 은행 이해 상충이 없는 독립적인 금융 연구를 제공합니다.",
  ["username_placeholder"]: "사용자 이름/지갑 주소/이메일",
  ["air_title"]: "마이닝 풀 활동 보상",
  ["air_content"]: "마이닝풀 활동보상번호 : 951 ERC-20 스마트 서약 컨트랙트, 지금 참여하시면 받을 수 있습니다",
  ["pulling_text"]: "새로고침하려면 아래로 당기세요...",
  ["loosing_text"]: "새로고침하려면 손을 뗍니다...",
  ["pledge_amount"]: "스마트 파이낸싱 금액",
  ["deposit_amount"]: "필요한 보증금",
  ["tokpiedex_home_increaseList"]: "승자 목록",
  ["tokpiedex_home_turnover"]: "용량",
  ["tokpiedex_trade_tab1"]: "선택 과목",
  ["tokpiedex_home_service"]: "온라인 서비스",
  ["cwg_host_non-collateralized mining"]: "데피 마이닝",
  ["help_loan"]: "도움 대출",
  ["loan_tip"]: "플랫폼 검토 후 플랫폼에서 대출을 신청할 수 있습니다!",
  ["loan_amount"]: "예상 대출 금액",
  ["repayment_cycle"]: "상환주기",
  ["daily_rate"]: "일일 요금",
  ["repayment"]: "반환",
  ["interest"]: "관심",
  ["repayment_principal_maturity"]: "1회 상환 예정",
  ["lending_institution"]: "대출 기관",
  ["credit_loan"]: "신용대출 (사진이 선명하게 보이는지 확인해주세요)",
  ["upload_passport_photo"]: "여권 사진 업로드",
  ["approval"]: "승인",
  ["approval_failed"]: "승인 실패",
  ["reps"]: "반환",
  ["overdue"]: "연체",
  ["loan_money"]: "대출 금액",
  ["repayment_date"]: "상환일",
  ["mechanism"]: "대출 기관",
  ["loan_record"]: "대출 기록",
  ["primary_certification"]: "기본 인증",
  ["advanced_certification"]: "고급 인증",
  ["certificate_type"]: "인증서 유형",
  ["example"]: "예",
  ["passport"]: "여권",
  ["id_card"]: "신분증",
  ["driver_license"]: "운전 면허증",
  ["show_passport_photo"]: "여권 사진",
  ["show_front_photo"]: "여권 사진",
  ["show_reverse_photo"]: "여권 사진",
  ["minimum_borrowing"]: "최소 차입금",
  ["copy_link"]: "복사",
  ["to_be_confirmed"]: "확인",
  ["notify"]: "알리다",
  ["account_frozen"]: "계정이 동결되었습니다. 고객 서비스에 문의하십시오.",
  ["mining_pool_rewards"]: "마이닝 풀 보상",
  ["output"]: "산출",
  ["required"]: "재충전 필요",
  ["reward"]: "상",
  ["popup_miniing_title"]: "마이닝 풀 보상 신청",
  ["popup_miniing_conn1"]: "ERC-20 스마트 계약",
  ["popup_miniing_conn2"]: "ERC-20 스마트 컨트랙트 마이닝 풀은 다음 ETH 공유 이벤트를 시작하려고 합니다. 가입하려면 지금 신청하십시오. 이벤트가 시작된 후 유동성 공급자는 여러 보상을 공유할 수 있습니다.",
  ["popup_miniing_btn"]: "수상 신청",
  ["my_account"]: "내 계정",
  ["mining_status"]: "상태",
  ["wallet_balance"]: "지갑 잔액",
  ["activity"]: "활동",
  ["additional_rewards"]: "추가 보상",
  ["pledge"]: "약속",
  ["loan_swiper1"]: "귀하의 재정 문제는 저희가 해결하겠다고 약속한 것입니다",
  ["loan_swiper2"]: "담보물 및 담보물이 필요하지 않으며 최대 3 시간 만에 대출!",
  ["now_loan"]: "지금 대출",
  ["loan_tip1"]: "Onchain, Coinbase, MetaMask, Math Wallet 또는 BitKeep 디지털 지갑을 사용하고 18-60 세 사이라면 신청할 수 있습니다.",
  ["loan_tip2"]: "USDT(달러) 개인 대출로 연이자가 매우 낮은",
  ["loan_tip3"]: "%까지 대출이 가능합니다.",
  ["loan_tip4"]: "긴급 비용, 주택 수리, 비즈니스 자본 또는 다른 부채 상환을 위한 대출이 필요한지 여부에 관계없이 대부분의 상황에서 개인 대출 서비스가 도움을 줄 수 있습니다.",
  ["max_loan_amount"]: "최대 대출 금액",
  ["rate_as_low_to"]: "이자율 최저",
  ["max_period"]: "최대로 분할 가능",
  ["open_an_account"]: "계정 개설",
  ["loan_record"]: "대출 기록",
  ["personal_data"]: "개인 정보",
  ["go"]: "시작",
  ["complete"]: "완료",
  ["not_filled_in"]: "작성되지 않음",
  ["job_info"]: "직업 정보",
  ["beneficiary_info"]: "수혜자 정보",
  ["low_exchange_rate"]: "낮은 환율",
  ["quick_arrival"]: "빠른 도착",
  ["mortgage_free"]: "담보물 필요 없음",
  ["user_loan"]: "사용자 대출",
  ["address"]: "주소",
  ["successful_loan_amount"]: "성공한 대출 금액",
  ["company_qualification"]: "회사 자격",
  ["view"]: "보기",
  ["common_questions"]: "자주 묻는 질문",
  ["loan_question1"]: "사기로부터 스스로를 보호하는 방법!",
  ["loan_answer1"]: "가장 많은 소비자 사기 유형은 사칭 사기로, 그 중 가장 일반적인 것은 사전 지불 사기입니다. 사기꾼은 주로 대출 또는 신용카드를 약속하며 경계심 없는 소비자들을 다양한 사전 지불로 유인합니다. 일반적인 사전 지불 이유로는 보증금, 세금, 보증금, 비밀 유지비, 회원비, 플랫폼 수수료, 자료비, 작업비, 해동비, 관리비, 수수료 등이 있습니다. 조언: 대출 자금이나 신용카드가 계좌로 입금되기 전까지 공식 대출 회사나 은행은 사전 지불을 요청하지 않습니다. 주의: 어떤 대출이나 신용카드에 미리 지불하라는 요구가 있으면 사기일 확률이 높습니다!",
  ["loan_question2"]: "개인 대출이란 무엇인가요?",
  ["loan_answer2"]: "개인 대출은 대부분의 목적으로 돈을 빌릴 수 있는 대출을 가리키며, 일반적으로 고정 기간, 고정 이자율 및 매월 상환 계획이 있습니다.",
  ["loan_question3"]: "개인 대출 신청 프로세스 단계",
  ["loan_answer3"]: "대출 신청자는 대출 신청 프로세스를 완료하기 위해 두 단계만 거쳐야 합니다. 1: 신청하려는 대출 금액을 선택하고 개인 정보를 등록합니다. 2: 디지털 지갑 인증 서명을 통해 대출을 바인딩하고 지갑을 검토 및 확인합니다. 회사 검토 단계: 1: 대출 신청자 정보를 종합 분석하고 점수를 부여합니다. 2: 대출 신청자의 신용 및 상환 능력을 검토합니다. 3: 대출 금액을 증가 또는 감소시키는지 여부를 검토합니다. 4: 계약 서명 및 대출 지급. 5: 대출 및 이자 상환.",
  ["loan_question4"]: "신청을 제출한 후 개인 대출을 받는 데 얼마나 걸립니까?",
  ["loan_answer4"]: "대부분의 사용자는 2시간 이내에 검토를 완료하며, 검토가 승인되면 서명한 디지털 지갑에서 1시간 이내에 USDT(일반적으로 신청서 제출부터 대출 수령까지 3시간 미만)를 수령합니다.",
  ["loan_question5"]: "개인 대출 자격이 있나요?",
  ["loan_answer5"]: "개인 대출 자격을 얻으려면 18세에서 60세 사이의 건강한 성인이고 자신의 디지털 지갑이 있는 경우 신청할 수 있습니다. 대출 신청은 제출된 정보, 신용 점수 및 상환 능력을 포함한 여러 요인을 기반으로 평가됩니다. 최저 이자율은 3%이며 첫 신청자이고 신용 점수가 정상이며 신용 기록이 좋다면 잠재적으로 대출이 가능할 수 있습니다.",
  ["loan_question6"]: "대출 성명서!",
  ["loan_answer6"]: "대부분의 사용자는 2시간 이내에 검토를 완료하며, 검토가 승인되면 서명한 디지털 지갑에서 1시간 이내에 USDT 대출(일반적으로 신청서 제출부터 대출 수령까지 3시간 미만)를 수령할 수 있습니다.",
  ["loan_question7"]: "대출을 받기 전에 요금을 지불해야 하나요?",
  ["loan_answer7"]: "사전 지불은 필요하지 않습니다. (공식 대출 회사나 은행에서 대출 자금을 수령하기 전에 요금을 지불해야 하는 경우는 없습니다. 고금리 대출 기관 중 일부는 대출 원금에서 관련 요금을 차감할 수 있습니다. 대출 기관에 선납해야 하는 요금이 있으면 확실히 사기일 가능성이 높습니다!)",
  ["loan_question8"]: "왜 우리는 달러 대신 USDT(디지털 통화)를 빌릴까요?",
  ["loan_answer8"]: "1: USDT의 가치는 달러와 동일하며 1 USDT의 가치는 1 달러와 동일합니다. 2: 우리 회사는 글로벌 시장을 대상으로 하기 때문에 디지털 통화가 보편적이며 효율적이며 신속하며 편리하며 실시간으로 자금을 송금할 수 있습니다. 거의 모든 디지털 통화 플랫폼에서 USDT를 해당 국가 통화로 교환하고 바인딩된 은행 계좌로 전송할 수 있습니다. 3: 국제 개인 현금 대출 채널은 일반적으로 매우 드물며, 승인이 어렵습니다. 다양한 국가의 법률, 현금 대출, 세금, 통화 가치 상승 및 하락, 각국 은행의 규정 등과 관련이 있습니다. 4: 디지털 통화는 전 세계적으로 널리 사용되며 많은 국가에서 상점, 호텔을 비롯한 여러 분야에서 직접 디지털 통화를 사용할 수 있습니다. 5: 디지털 통화의 안전성은 이미 매우 안정적이며 은행 시스템을 뛰어넘는 경우도 있습니다.",
  ["loan_question9"]: "어떻게 상환하나요?",
  ["loan_answer9"]: "우리와 체결한 개인 대출 계약에 따라 서명한 디지턑 지갑에서 상환 날짜에 필요한 금액을 자동으로 차감합니다. 상환 날짜에 상환할 금액만 미리 준비해 두면 됩니다.",
  ["loan_question10"]: "회사에서 판매 대행을 할 수 있나요?",
  ["loan_answer10"]: "1: 네, 글로벌 사용자를 대상으로 하기 때문에 계속해서 아르바이트 판매원을 고용하고 있습니다. 2: 아르바이트 판매원은 기본급 및 기타 혜택이 없으며 권리가 바로 직접 제안하는 고객의 대출 금액에서 2% 수수료만 받게 됩니다.",
  ["our_advantages"]: "우리의 장점",
  ["high_quota"]: "높은 한도",
  ["big_company"]: "대기업",
  ["without_guarantee"]: "보증물 없음",
  ["fast_arrival"]: "빠른 도착",
  ["convenient_and_fast"]: "편리하고 빠름",
  ["lend_money"]: "빌리다",
  ["verify_amount"]: "금액 확인",
  ["verify_money"]: "금액 확인",
  ["wallet_balance"]: "지갑 잔고",
  ["need_to_add_amount"]: "추가 금액 필요",
  ["loan_term_month"]: "대출 기간(월)",
  ["loan_amount_and_details"]: "대출 금액 및 상세 정보",
  ["total_interest_rate"]: "총 이자율",
  ["monthly_interest"]: "월별 이자",
  ["total_interest"]: "총 이자",
  ["loan_start_and_end_dates"]: "대출 시작 및 종료 날짜",
  ["date_of_first_renovation"]: "첫 번째 리모델링 날짜",
  ["loan_term"]: "대출 기간",
  ["loan_agree"]: "동의함을 확인하고",
  ["loan_agreement"]: "《대출 및 보증 계약》",
  ["loan_confirm"]: "동의 및 확인",
  ["verify_title"]: "정보 입력",
  ["enter_name"]: "이름을 입력하세요",
  ["enter_phone"]: "전화번호를 입력하세요",
  ["enter_age"]: "나이를 입력하세요",
  ["select_gender"]: "성별 선택",
  ["enter_email"]: "이메일을 입력하세요",
  ["enter_current_address"]: "현재 주소를 입력하세요",
  ["option_marital_status"]: "결혼 여부 선택",
  ["option_education_attainment"]: "교육 수준 선택",
  ["front_of_documents"]: "신분증 앞면",
  ["back_of_documents"]: "신분증 뒷면",
  ["confirm_personal_infomation"]: "개인 정보 확인",
  ["work_infomation"]: "직장 정보",
  ["option_type_of_job"]: "직종 선택",
  ["option_total_working_years"]: "총 근무 연수 선택",
  ["option_monthly_income"]: "월 수입 선택",
  ["monthly_household_income"]: "가정 내 월 수입",
  ["confirm_work_information"]: "직장 정보 확인",
  ["beneficiary_information"]: "수혜자 정보",
  ["beneficiary_address_trc20"]: "수혜자 주소(TRC20)",
  ["confirm_beneficiary_information"]: "수혜자 정보 확인",
  ["confirm"]: "확인",
  ["cancel"]: "취소",
  "loan_title": "대출",
  ["message_center"]: "메시지 센터",
  "activity-top-title": "ETH 에어드랍 보상",
"activity-top-big-title": "100만 개의 ETH 에어드랍 보상이 옵니다",
"activity-top-tip": "당신의 DeFi 수익 프로젝트의 마이닝 풀 노드 페이지에서 간단한 작업을 완료하여 거대한 에어드랍 보상을 나눠가세요.",
"activity-end": "활동 종료",
"activity-join": "활동 참여",
"activity-v3-provide": "이 서비스는 {net} 에서 제공합니다",
  "activity-v3-sub": "활동에 참여하고 {awardNumber} {currencyType} 보상을 받으세요!",
  "activity-v3-support-title": "지원되는 네트워크",
  "activity-v3-all-support": "모든 네트워크",
  "activity-v3-token": "관련 토큰",
  "activity-v3-read": "읽고 동의합니다",
  "activity-v3-statement": "위험 고지",
  "activity-v3-no-remind": "다시 표시하지 않기",
  "activity-v3-btn": "{awardNumber} {currencyType} 에어드롭 활동에 참여하기",
  "safety-title": "안전 확인",
  "safety-tip": "계정의 보안을 위해 이 작업에는 슬라이더 검증이 필요합니다.",
  "slider-text": "오른쪽으로 밀기",
  "slider-success": "성공",
  'grade': '등급'
}
export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1 points",
  ["exchange_5m"]: "5 points",
  ["exchange_15m"]: "15 points",
  ["exchange_30m"]: "30 points",
  ["exchange_60m"]: "60 points",
  ["exchange_more"]: "Suite",
  ["exchange_4h"]: "4 heures",
  ["exchange_day"]: "journée",
  ["exchange_month"]: "lune",
  ["exchange_week"]: "la semaine",
  ["exchange_year"]: "an",
  ["tabbar.home"]: "page de garde",
  ["tabbar.trade"]: "Devis",
  ["tabbar.hosting"]: "direction financière",
  ["tabbar.exchange"]: "Commerce",
  ["tabbar.account"]: "des atouts",
  ["common.confirm"]: "confirmer",
  ["index.number"]: "solde",
  ["index.Total revenue"]: "Revenu total",
  ["index.Trading"]: "Conseiller expert en intelligence artificielle",
  ["index.intelligentive trading"]: "Commencez dès maintenant le trading quantitatif intelligent",
  ["host.detail"]: "Détails",
  ["host.todayProfit"]: "Gains aujourd'hui",
  ["host.totalProfit"]: "Bénéfices attendus",
  ["host.Order quantity"]: "quantité de commande",
  ["host.Strategic"]: "Activités stratégiques Produits",
  ["host.escrow"]: "Montant d'entiercement",
  ["host.max"]: "maximum",
  ["host.determine"]: "Bien sûr",
  ["host.balance"]: "Solde disponible",
  ["host.Deposit"]: "fonds de dépôt",
  ["host.quota"]: "limite",
  ["host.years"]: "taux total annualisé",
  ["host.expect"]: "bénéfice attendu",
  ["host.profit"]: "Yu'e Bao",
  ["host.lockup"]: "Jalonnement minier",
  ["host.liquidated"]: "Pénalité de remboursement anticipé",
  ["host.redeem"]: "rachat",
  ["host.isitredeemed"]: "Il y a une pénalité pour un remboursement anticipé, est-ce un remboursement ?",
  ["host.consignmentOrder"]: "commande confiée",
  ["host.purchase"]: "acheter",
  ["exchange.bond"]: "marge libre",
  ["exchange.optionNo"]: "Publier",
  ["exchange.endTime"]: "Clôture de ce sujet",
  ["exchange.Forecast"]: "période de prévision",
  ["enter the amount"]: "Veuillez entrer le montant",
  ["Insufficient wallet balance"]: "Solde de portefeuille insuffisant pour acheter",
  ["minimum purchase amount is"]: "Le montant minimum d'achat est",
  ["Purchase successful"]: "achat réussi",
  ["exchange.orderConifrm"]: "Confirmation de commande",
  ["exchange.balance"]: "Solde disponible",
  ["exchange.custormAmount"]: "montant personnalisé",
  ["exchange.input"]: "Entrez s'il vous plait",
  ["exchange.confirm"]: "Bien sûr",
  ["exchange.minute"]: "Minute",
  ["exchange.curentrust"]: "mandat actuel",
  ["exchange.hisentrust"]: "commission historique",
  ["exchange.noneEntrust"]: "Pas encore de mandat",
  ["exchange.wait"]: "attendre l'ouverture",
  ["exchange.opened"]: "ouvert",
  ["exchange.rescinded"]: "Révoqué",
  ["exchange.type"]: "taper",
  ["exchange.finashTime"]: "heure de fermeture",
  ["exchange.openingQuantity"]: "Nombre de postes ouverts",
  ["exchange.awards"]: "Nombre de récompenses",
  ["exchange.charge"]: "Frais d'ouverture de poste",
  ["exchange.openPrice"]: "prix d'ouverture",
  ["exchange.close"]: "Le dernier prix",
  ["exchange.prediction"]: "Orientation des positions",
  ["exchange.pump"]: "pompage",
  ["hello"]: "Bonjour",
  ["welcome"]: "Bienvenue à",
  ["change success"]: "Modifié avec succès",
  ["set success"]: "réglé avec succès",
  ["confirm"]: "Bien sûr",
  ["confirm1"]: "Bien sûr",
  ["confirm2"]: "Bien sûr",
  ["cancel"]: "Annuler",
  ["copySuccess"]: "Copier avec succès",
  ["please enter"]: "Entrez s'il vous plait",
  ["no data"]: "Pas de données",
  ["all"]: "tout",
  ["processing"]: "Traitement",
  ["credited"]: "crédité",
  ["fail"]: "échouer",
  ["timeout failed"]: "échec du délai d'attente",
  ["amount"]: "montant",
  ["state"]: "Etat",
  ["time"]: "temps",
  ["pwd_password"]: "le mot de passe",
  ["pwd_Enter password"]: "Veuillez entrer le mot de passe",
  ["pwd_old"]: "ancien mot de passe",
  ["pwd_new"]: "nouveau mot de passe",
  ["pwd_new again"]: "Confirmez le mot de passe",
  ["pwd_Enter old password"]: "Veuillez saisir l'ancien mot de passe",
  ["pwd_Enter new password"]: "Veuillez entrer un nouveau mot de passe",
  ["pwd_Enter new password again"]: "Veuillez saisir à nouveau le nouveau mot de passe",
  ["pwd_set"]: "définir le mot de passe",
  ["pwd_not empty"]: "le mot de passe ne peut pas être vide",
  ["pwd_length"]: "La longueur du mot de passe ne peut pas être inférieure à 6 caractères",
  ["pwd_old not empty"]: "L'ancien mot de passe ne peut pas être vide",
  ["pwd_new not empty"]: "Le nouveau mot de passe ne peut pas être vide",
  ["pwd_inconsistent twice"]: "les deux mots de passe ne correspondent pas",
  ["home_msg"]: "La technologie est l'âme commune de ceux qui la développent",
  ["home_recharge"]: "Dépôt rapide",
  ["home_exchange"]: "Négociation d'options",
  ["home_mining"]: "Jalonnement minier",
  ["home_service"]: "un service en ligne",
  ["home_increaseList"]: "Liste des gagnants",
  ["home_turnover"]: "le volume",
  ["home_currencyName"]: "Nom",
  ["home_newPrice"]: "Dernier prix",
  ["home_vol24"]: "Volume de transactions sur 24 heures",
  ["home_upDown"]: "Changement de devis",
  ["home_warrant"]: "monnaie courante",
  ["trade_tab1"]: "optionnel",
  ["trade_tab2"]: "Coin-m Futures",
  ["trade_tab3"]: "Échange",
  ["trade_tab4"]: "transaction de contrat",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "deuxième contrat",
  ["trade.empty"]: "Pas de données",
  ["edit_choice"]: "Le choix des éditeurs",
  ["add_optional"]: "Ajouter à facultatif",
  ["save"]: "enregistrer",
  ["select_all"]: "tout sélectionner",
  ["delete"]: "effacer",
  ["please_choose"]: "Choisissez s'il vous plaît",
  ["sidebar_share"]: "Je veux partager",
  ["sidebar_help"]: "Centre d'aide",
  ["sidebar_service"]: "un service en ligne",
  ["exchange_selCurrency"]: "Sélectionnez la devise",
  ["exchange_searchText"]: "Veuillez entrer le mot-clé de recherche",
  ["exchange_rule"]: "régner",
  ["exchange_bond"]: "marge libre",
  ["exchange_optionNo"]: "Publier",
  ["exchange_endTime"]: "Clôture de ce sujet",
  ["exchange_forecast"]: "période de prévision",
  ["exchange_minute"]: "Minute",
  ["exchange_curentrust"]: "mandat actuel",
  ["exchange_noneEntrust"]: "Pas encore de mandat",
  ["exchange_bullish"]: "haussier",
  ["exchange_bearish"]: "baissier",
  ["exchange_orderConifrm"]: "Confirmation de commande",
  ["exchange_balance"]: "Solde disponible",
  ["exchange_custormAmount"]: "montant personnalisé",
  ["host_profit"]: "Yu'e Bao",
  ["host_lockup"]: "Jalonnement minier",
  ["host_consignmentOrder"]: "commande confiée",
  ["host_purchase"]: "acheter",
  ["mining_purchase"]: "acheter",
  ["host_todayProfit"]: "Gains aujourd'hui",
  ["host_totalProfit"]: "Bénéfices attendus",
  ["host_limit"]: "limite d'investissement",
  ["host_income"]: "le revenu",
  ["host_funds in custody"]: "fonds en dépôt",
  ["host_entrusted order"]: "commande confiée",
  ["host_expected earnings for today"]: "Revenus attendus pour aujourd'hui",
  ["host_cumulative income"]: "revenu cumulé",
  ["host_order in commission"]: "Commandes qui arrivent à la commission",
  ["host_day"]: "ciel",
  ["host_single limit"]: "limite unique",
  ["host_daily rate of return"]: "taux de rendement journalier",
  ["host_cycle"]: "cycle",
  ["host_processing"]: "En traitement",
  ["host_completed"]: "complété",
  ["host.redeemed"]: "racheté",
  ["host_histotyProfit"]: "gains historiques",
  ["host_illustrate"]: "illustrer",
  ["host_order details"]: "détails de la commande",
  ["host_single benefit"]: "prestation unique",
  ["host_single day income"]: "revenu d'une journée",
  ["host_total revenue"]: "Revenu total",
  ["host_earnings Details"]: "Détails des gains",
  ["host_paid"]: "Payé",
  ["host_pay miner fees"]: "Payer les frais de mineur",
  ["host_user output"]: "sortie utilisateur",
  ["host_address"]: "adresse",
  ["host_quantity"]: "quantité",
  ["host_time"]: "temps",
  ["host_account Balance"]: "Solde du compte",
  ["account_total assets equivalent"]: "Total actif équivalent",
  ["account_deposit"]: "verser",
  ["account_withdraw"]: "Se désister",
  ["account_exchange"]: "échanger",
  ["account_funding Account"]: "Compte de financement",
  ["account_hide small coins"]: "Cacher les petites pièces",
  ["account_available"]: "disponible",
  ["account_processing"]: "Traitement",
  ["account_folded"]: "plié",
  ["recharge"]: "recharger",
  ["recharge_currency"]: "recharger la monnaie",
  ["recharge_scan code transfer"]: "Scan code transfert et recharge",
  ["recharge_amount"]: "Montant de la recharge",
  ["recharge_upload"]: "Télécharger une capture d'écran des détails de paiement",
  ["recharge_record"]: "Recharger l'enregistrement",
  ["recharge_enter amount"]: "Veuillez entrer le montant de la recharge",
  ["recharge_less zero"]: "Le montant de la recharge ne peut pas être inférieur à 0",
  ["recharge_upload screenshot"]: "Veuillez télécharger la capture d'écran",
  ["recharge_try again later"]: "Téléchargement de l'image, réessayez plus tard",
  ["recharge_successful"]: "recharge réussie",
  ["recharge_Details"]: "Détails de la recharge",
  ["recharge_currency2"]: "devise",
  ["recharge_address"]: "adresse de recharge",
  ["recharge_order number"]: "numéro de commande",
  ["loading"]: "Chargement…",
  ["investment amount"]: "coût d'investissement",
  ["expire date"]: "Date d'expiration",
  ["order number"]: "numéro de commande",
  ["start date"]: "date de début",
  ["end date"]: "date de fin",
  ["cash back"]: "Remise en argent",
  ["order"]: "Ordre",
  ["swap"]: "échanger",
  ["swap_currency exchange"]: "Échange de devises",
  ["swap_please enter exchange amount"]: "Veuillez entrer le montant de l'échange",
  ["swap_exchange quantity"]: "Quantité d'échange",
  ["swap_up to"]: "jusqu'à",
  ["swap_redeem all"]: "Racheter tout",
  ["swap_exchange rate"]: "taux de change",
  ["swap_available"]: "Disponible",
  ["withdraw"]: "se désister",
  ["withdrawal_currency"]: "Devise de retrait",
  ["withdrawal_Amount_Available"]: "Montant disponible",
  ["withdrawal_Please_enter_amount"]: "Veuillez entrer le montant du retrait",
  ["withdrawal_remove_all"]: "Enlever tout",
  ["withdrawal_address"]: "Adresse de retrait",
  ["withdrawal_Please_enter_address"]: "Veuillez entrer l'adresse du portefeuille",
  ["withdrawal_password"]: "Mot de passe de retrait",
  ["withdrawal_Please_enter_password"]: "Veuillez entrer le mot de passe de retrait",
  ["withdrawal_handling_fee"]: "frais de gestion",
  ["withdrawal_Reminder_text"]: "Rappel : Une partie des frais de traitement sera facturée pour le retrait, et elle sera créditée sur le compte en temps réel après le retrait. Si vous avez des questions, veuillez contacter le service client.",
  ["withdrawal_under_review"]: "à l'étude",
  ["withdrawal_success"]: "Succès du retrait",
  ["withdrawal_examination_passed"]: "examen réussi",
  ["withdrawal_paying_on_behalf"]: "Payer au nom",
  ["withdrawal_payment_overtime"]: "Paiement des heures supplémentaires",
  ["withdrawal_record"]: "Registre des retraits",
  ["enter the bet amount"]: "Veuillez entrer le montant du pari",
  ["minimum bet amount"]: "Le montant minimum de la mise est de",
  ["bet up to"]: "Le montant maximum de la mise est de",
  ["number is being updated"]: "Problème de mise à jour, veuillez réessayer plus tard",
  ["checkout success"]: "commandé avec succès",
  ["exchange amount cannot be blank"]: "Le montant de l'échange ne peut pas être vide",
  ["exchange amount must be greater than 0"]: "Le montant de l'échange doit être supérieur à 0",
  ["successfully redeemed"]: "Vous avez soumis votre échange avec succès. Veuillez patienter.",
  ["enter the withdrawal amount"]: "Veuillez entrer le montant du retrait",
  ["amount cannot be less than 0"]: "Le montant du retrait ne peut être inférieur à 0",
  ["enter the withdrawal address"]: "Veuillez entrer l'adresse de retrait",
  ["enter the withdrawal password"]: "Veuillez entrer le mot de passe de retrait",
  ["successful application"]: "Candidature réussie",
  ["set the password first"]: "Veuillez d'abord définir un mot de passe",
  ["Withdrawal details"]: "Détails du retrait",
  ["Withdrawal quantity"]: "Montant de retrait",
  ["Withdrawal address"]: "Adresse de retrait",
  ["share_invite"]: "Invitez des amis à utiliser",
  ["share_link"]: "lien de partage",
  ["share"]: "partager",
  ["add_success"]: "Ajouté avec succès",
  ["mining.question1"]: "Qu'est-ce que le jalonnement minier ? D'où viennent les revenus ?",
  ["mining.answer1"]: "Le Staking Mining est un produit outil créé par la société pour aider les utilisateurs à s'engager rapidement en chaîne pour obtenir des récompenses. En jalonnant des actifs numériques sur le réseau blockchain, et en obtenant des récompenses basées sur le mécanisme POS (Proof of Stake, c'est-à-dire preuve de participation). Dans ce mécanisme, les utilisateurs confient des actifs numériques aux nœuds, et les nœuds exercent les droits de produire des blocs, de regrouper des transactions, etc. sur la blockchain et de recevoir des récompenses. Les utilisateurs partagent les récompenses obtenues par les nœuds proportionnellement en fonction du nombre de positions verrouillées.",
  ["mining.question2"]: "Qu'est-ce que l'annualisation de référence ? Comment calculer?",
  ["mining.answer2"]: "Le taux annualisé de référence est le taux de rendement annualisé de référence du produit auquel vous avez souscrit, qui sera mis à jour dynamiquement en fonction des gains réels sur la chaîne, à titre indicatif uniquement. Annualisation de référence = revenus en chaîne/quantité de blocage en chaîne/temps de blocage en chaîne*365*100 %.",
  ["mining.question3"]: "Quel est le retour attendu ? Comment calculer?",
  ["mining.answer3"]: "Les revenus attendus sont les revenus quotidiens attendus calculés en fonction de la quantité de votre abonnement et de la référence du produit annualisés, et sont uniquement à titre de référence. Rendement attendu journalier = nombre d'abonnements * référence annualisé / 365.",
  ["mining.question4"]: "Quand les intérêts commencent-ils à courir ?",
  ["mining.answer4"]: "Pour une souscription au jour T, les intérêts seront calculés à partir de 00h00 le jour T+1.",
  ["mining.question5"]: "Quand les bénéfices seront-ils distribués ?",
  ["mining.answer5"]: "Souscription le jour T, les intérêts commenceront à être générés le jour T+1 et les revenus seront distribués avec le principal après l'échéance.",
  ["mining.question6"]: "Puis-je racheter des produits à terme plus tôt ? Quel est l'impact ?",
  ["mining.answer6"]: "Vous pouvez racheter votre commande à tout moment, mais si vous rachetez le produit à terme à l'avance, vous perdrez une partie du revenu déjà obtenu de la commande, et le revenu émis sera déduit de votre capital au moment du rachat.",
  ["mining.question7"]: "Dois-je racheter manuellement après l'expiration du produit à terme ?",
  ["mining.answer7"]: "Remboursement automatique après l'expiration du produit à terme.",
  ["mining.question8"]: "1. Comment puis-je m'inscrire ?",
  ["mining.answer8"]: "Pour participer à l'extraction de liquidités non destructives et non sécurisées, vous devez payer des frais de mineur ETH.Une adresse de portefeuille ETH ne doit être réclamée qu'une seule fois, et l'autorisation d'extraction sera automatiquement ouverte après le succès.",
  ["mining.question9"]: "2. Comment retirer de l'argent ?",
  ["mining.answer9"]: "Vous pouvez convertir vos pièces générées quotidiennement en USDT, puis initier un retrait. Les retraits USDT seront automatiquement envoyés à l'adresse de portefeuille que vous avez ajoutée au nœud, les autres adresses ne sont pas prises en charge.",
  ["mining.question10"]: "3. Comment calculer le revenu ?",
  ["mining.answer10"]: "Lorsque vous vous inscrivez avec succès, le contrat intelligent commence à calculer votre adresse via le nœud et commence à calculer le revenu.",
  ["show_more"]: "voir plus",
  ["promble"]: "Problème commun",
  ["what_yeb"]: "Qu'est-ce que le Yu'e Bao ?",
  ["what_yeb_answer"]: "Yubibao est un produit créé par la société pour aider les utilisateurs qui ont des actifs numériques inactifs et les utilisateurs qui ont besoin d'emprunter de l'argent. Il présente les caractéristiques de dépôt et de retrait instantanés, de calcul des intérêts horaires et de prise en charge de la personnalisation des taux d'intérêt. Une fois que l'utilisateur a transféré les actifs numériques à Yubibao, le système détermine si le prêt est réussi à chaque heure en fonction du taux de prêt défini par l'utilisateur. Une fois le prêt réussi, l'intérêt pour l'heure peut être obtenu, sinon l'intérêt ne peut pas être obtenu, et vous devez attendre la prochaine fois. Résultats correspondants d'une heure. S'appuyant sur un système strict de contrôle des risques, Yubibao garantit pleinement la sécurité des transactions des utilisateurs.",
  ["what_lixi"]: "Comment les intérêts sont-ils calculés ?",
  ["what_lixi_answer"]: "Intérêt (par jour) = montant du prêt * annualisation du prêt du marché / 365/24 * 85 %",
  ["rule_yeb_title1"]: "Qu'est-ce que le Yu'e Bao ?",
  ["rule_yeb_cont1"]: "Yubibao est un produit créé par la société pour aider les utilisateurs qui ont des actifs numériques inactifs et les utilisateurs qui ont besoin d'emprunter de l'argent. Il présente les caractéristiques de dépôt et de retrait instantanés, de calcul des intérêts horaires et de prise en charge de la personnalisation des taux d'intérêt. Une fois que l'utilisateur a transféré les actifs numériques à Yubibao, le système détermine si le prêt est réussi à chaque heure en fonction du taux de prêt défini par l'utilisateur. Une fois le prêt réussi, l'intérêt pour l'heure peut être obtenu, sinon l'intérêt ne peut pas être obtenu, et vous devez attendre la prochaine fois. Résultats correspondants d'une heure. S'appuyant sur un système strict de contrôle des risques, Yubibao garantit pleinement la sécurité des transactions des utilisateurs.",
  ["rule_yeb_note1"]: "*Veuillez noter : les rendements historiques ne sont pas indicatifs des rendements futurs. Nous ne promettons pas de rembourser le principal et les intérêts en devises, objets physiques, actions ou toute autre forme de retour de propriété dans un certain délai.",
  ["rule_yeb_title2"]: "Avantages du produit",
  ["rule_yeb_til2"]: "Robuste",
  ["rule_yeb_cont2"]: "S'appuyant sur un système strict de contrôle des risques, Yu'ebao garantit pleinement la sécurité des actifs des utilisateurs et leur permet de profiter des bénéfices en toute sérénité.",
  ["rule_yeb_title3"]: "Instructions pour investir dans des actifs productifs d'intérêts",
  ["rule_yeb_cont3"]: "Une fois que les utilisateurs auront transféré leurs actifs à Yubibao, les actifs numériques achetés seront prêtés aux utilisateurs du trading à effet de levier.",
  ["rule_yeb_title4"]: "releve de revenue",
  ["rule_yeb_til4"]: "règle de revenu",
  ["rule_yeb_cont4"]: "Si l'enchère réussit, les revenus de la veille seront composés et réglés à 02h00 le lendemain.",
  ["rule_yeb_title5"]: "Description de la composition du revenu",
  ["rule_yeb_cont5"]: "15 % des intérêts de prêt de l'utilisateur seront utilisés comme dépôt de risque et 85 % seront attribués à l'utilisateur prêteur, c'est-à-dire que l'intérêt que l'utilisateur prêteur peut obtenir est de : Principal du prêt * Prêt annualisé actuel / 365 / 24 * 85%",
  ["rule_yeb_title6"]: "description de la transaction",
  ["rule_yeb_cont61"]: "Soutenez l'abonnement à tout moment, commencez à enchérir l'heure suivant l'abonnement",
  ["rule_yeb_cont62"]: "Règles d'appariement des taux d'intérêt : le marché enchérit toutes les heures en fonction de la demande d'emprunt, selon \"l'annualisation minimale du prêt\" de bas en haut, et la valeur la plus élevée de l'enchère sera utilisée comme \"l'annualisation actuelle du prêt\"",
  ["rule_yeb_cont63"]: "Si l'annualisation minimale du prêt < l'annualisation actuelle du prêt, l'annualisation actuelle du prêt sera utilisée pour prêter avec succès",
  ["rule_yeb_cont64"]: "Si l'annualisation minimale du prêt > l'annualisation actuelle du prêt, le prêt échoue et il n'y a pas d'intérêt",
  ["rule_yeb_cont65"]: "Si l'annualisation minimale du prêt = l'annualisation actuelle du prêt, il peut être partiellement prêté ou le prêt peut échouer",
  ["rule_yeb_title7"]: "règles de rachat",
  ["rule_yeb_til7"]: "Remboursement automatique à l'échéance",
  ["rule_wk_title1"]: "Qu'est-ce que le jalonnement minier ?",
  ["rule_wk_cont1"]: "Le Staking Mining est un produit outil créé par la société pour aider les utilisateurs à s'engager rapidement en chaîne pour obtenir des récompenses. En jalonnant des actifs numériques sur le réseau blockchain, et en obtenant des récompenses basées sur le mécanisme POS (Proof of Stake, c'est-à-dire preuve de participation). Dans ce mécanisme, les utilisateurs confient des actifs numériques aux nœuds, et les nœuds exercent les droits de produire des blocs, de regrouper des transactions, etc. sur la blockchain et de recevoir des récompenses. Les utilisateurs partagent les récompenses obtenues par les nœuds proportionnellement en fonction du nombre de positions verrouillées.",
  ["rule_wk_note1"]: "*Veuillez noter : les rendements historiques ne sont pas indicatifs des rendements futurs. Nous ne promettons pas de rembourser le principal et les intérêts en devises, objets physiques, actions ou toute autre forme de retour de propriété dans un certain délai.",
  ["rule_wk_title2"]: "Avantages du produit",
  ["rule_wk_til2"]: "Robuste",
  ["rule_wk_cont2"]: "Le jalonnement minier peut obtenir des récompenses relativement stables de la part de tiers, avec différentes périodes de produit.",
  ["rule_wk_title3"]: "Instructions pour investir dans des actifs productifs d'intérêts",
  ["rule_wk_cont3"]: "Le jalonnement minier consiste à mettre en gage vos actifs numériques sur la blockchain pour soutenir le fonctionnement du réseau blockchain et obtenir les récompenses correspondantes.",
  ["rule_wk_title4"]: "releve de revenue",
  ["rule_wk_cont4"]: "Après une souscription réussie le jour T, les intérêts commencent à 00h00 le jour T+1 et les intérêts sont réglés à 02h00.",
  ["rule_wk_title5"]: "description de la transaction",
  ["rule_wk_til5"]: "règle d'achat",
  ["rule_wk_cont5"]: "Soutenir l'abonnement à tout moment.",
  ["rule_wk_title6"]: "règles de rachat",
  ["rule_wk_cont61"]: "La prise en charge du rachat à tout moment, et le temps d'attente pour le rachat des différents projets est différent.",
  ["rule_wk_cont62"]: "Tant que le terme n'est pas expiré, il sera remboursé par anticipation et une partie des intérêts payés sera déduite.",
  ["rule_wk_cont63"]: "Remboursement non pris en charge",
  ["rule_wk_title7"]: "avertissement de risque",
  ["rule_wk_cont7"]: "Pour le rachat anticipé de produits réguliers, le système déduira une partie des revenus déjà obtenus de la commande.",
  ["rule_fzywk_title1"]: "Extraction de liquidité",
  ["rule_fzywk_cont1"]: "L'extraction de liquidités est une fonction de gestion de patrimoine créée par la société pour aider les utilisateurs à gérer les actifs numériques et à générer des rendements à long terme et efficaces. L'utilisateur clique (paye les frais de mineur) et paie un certain frais de mineur pour générer des revenus immédiatement. L'utilisateur n'a besoin de payer qu'une seule fois pour un effet permanent, et il n'est pas nécessaire de cliquer pour payer à nouveau à l'avenir. Le ratio de revenu dépend sur le solde du portefeuille de l'utilisateur. Plus le solde est élevé, plus le ratio de revenu est élevé.",
  ["rule_fzywk_note"]: "Remarque : (hors prêt) les détails des gains sont envoyés 4 fois par jour toutes les 6 heures. Vous pouvez afficher les gains détaillés via Détails des gains.",
  ["rule_fzywk_note1"]: "Remarque : (Ce produit est un produit de drainage de bien-être avec un quota limité à l'heure actuelle. À l'avenir, l'évaluation des actifs numériques sera ajoutée aux utilisateurs, et ce produit ne sera ouvert qu'aux utilisateurs qualifiés, il est donc premier arrivé, premier servi).",
  ["rule_fzywk_title2"]: "Avantages du produit",
  ["rule_fzywk_til2"]: "Avantages robustes",
  ["rule_fzywk_cont2"]: "Des avantages à long terme peuvent être obtenus sans prêter de fonds, en garantissant la sécurité des actifs des utilisateurs et en profitant des avantages sans risquer de pertes.",
  ["rule_fzywk_title3"]: "releve de revenue",
  ["rule_fzywk_til3"]: "règle de revenu",
  ["rule_fzywk_cont3"]: "Il prendra effet immédiatement après la réussite de l'autorisation de paiement et le revenu sera distribué chaque jour dans un délai fixe.Le cycle de revenu de l'utilisateur est de 6 heures et un total de 4 fois le revenu peut être obtenu dans les 24 heures.",
  ["rule_fzywk_til3_1"]: "Rapport de revenu d'engrenage",
  ["rule_fzywk_tab1"]: "équipement",
  ["rule_fzywk_tab2"]: "quantité",
  ["rule_fzywk_tab3"]: "taux de retour",
  ["rule_fzywk_tab4"]: "unité de revenu",
  ["unlimited"]: "illimité",
  ["verified"]: "Vérifié",
  ["verified_tips"]: "Afin d'assurer une expérience de compte sécurisée, veuillez lier vos informations personnellement identifiables",
  ["verified_name"]: "Nom",
  ["verified_input_name"]: "Veuillez saisir votre nom",
  ["verified_card"]: "numéro de licence",
  ["verified_input_card"]: "Veuillez entrer le numéro d'identification",
  ["photo_front"]: "Photo d'identité",
  ["clearly_visible"]: "(Veuillez vous assurer que les documents sont clairement visibles)",
  ["front_photo"]: "Télécharger le recto de la photo d'identité",
  ["reverse_photo"]: "Téléchargez le verso de votre photo d'identité",
  ["standard"]: "la norme",
  ["missing"]: "disparu",
  ["vague"]: "Vague",
  ["strong flash"]: "flash fort",
  ["confirm_submit"]: "Confirmer et soumettre",
  ["need to change"]: "Si vous avez besoin de changer la reliure, veuillez",
  ["Contact Customer Service"]: "Contactez le service à la clientèle",
  ["authentication succeeded"]: "Authentification réussie",
  ["please_information"]: "Merci de compléter les informations",
  ["only_symbol"]: "Regardez simplement la paire de trading actuelle",
  ["options"]: "options",
  ["occupy"]: "Occuper",
  ["bb_buy"]: "acheter",
  ["bb_sell"]: "vendre",
  ["bb_count"]: "quantité",
  ["bb_current_best_price"]: "Négociez au meilleur prix actuel",
  ["bb_turnover"]: "Chiffre d'affaires",
  ["bb_Limit order"]: "Ordre à cours limité",
  ["bb_market order"]: "ordre du marché",
  ["bb_successful operation"]: "Opération réussie",
  ["bb_operation failed"]: "l'opération a échoué",
  ["bb_Hide other trading pairs"]: "Masquer les autres paires de trading",
  ["bb_price"]: "le prix",
  ["bb_volume"]: "le volume",
  ["bb_default"]: "défaut",
  ["bb_buy order"]: "ordre d'achat",
  ["bb_sell order"]: "ordre de vente",
  ["bb_commission price"]: "prix des commissions",
  ["bb_commission amount"]: "montant des commissions",
  ["bb_Cancellation"]: "Annulation",
  ["bb_total turnover"]: "changement total",
  ["bb_Average transaction price"]: "Prix moyen des transactions",
  ["bb_Undo succeeded"]: "L'annulation a réussi",
  ["bb_Undo failed"]: "Échec de l'annulation",
  ["bb_Deal done"]: "Affaire conclue",
  ["bb_Revoked"]: "Révoqué",
  ["bb_depth map"]: "carte de profondeur",
  ["bb_actual price"]: "prix actuel",
  ["bb_to buy order"]: "ordre d'achat",
  ["bb_to sell order"]: "ordre de vente",
  ["bb_direction"]: "direction",
  ["bb_clinch"]: "faire une affaire",
  ["user_login"]: "Utilisateur en ligne",
  ["password_placeholder"]: "Veuillez entrer le mot de passe",
  ["code_placeholder"]: "veuillez entrer le code de vérification",
  ["login"]: "Connexion",
  ["no account"]: "Vous n'avez pas de compte ?",
  ["to register"]: "enregistrer",
  ["Forgot password"]: "mot de passe oublié?",
  ["user register"]: "Enregistrement de l'utilisateur",
  ["username"]: "Nom d'utilisateur",
  ["repassword_placeholder"]: "Veuillez saisir à nouveau le mot de passe",
  ["register"]: "S'inscrire",
  ["have account"]: "Vous avez déjà un compte?",
  ["to login"]: "aller à la connexion",
  ["address_placeholder"]: "Veuillez entrer l'adresse du portefeuille",
  ["password does not match"]: "les deux mots de passe ne correspondent pas",
  ["username_msg"]: "veuillez entrer le nom d'utilisateur",
  ["ua_msg"]: "Veuillez entrer le nom d'utilisateur/l'adresse du portefeuille",
  ["register_failed"]: "échec de l'enregistrement",
  ["login_failed"]: "Échec de la connexion",
  ["invalid_address"]: "Adresse invalide, veuillez ouvrir et autoriser dans le portefeuille",
  ["exchange_hisentrust"]: "commission historique",
  ["sidebar_bindPassword"]: "Configurer un mot de passe de fonds",
  ["sidebar_changePassword"]: "Modifier le mot de passe du fonds",
  ["sidebar_setLoginPassword"]: "Définition du mot de passe de connexion",
  ["sidebar_changeLoginPassword"]: "Modifier le mot de passe de connexion",
  ["add_address"]: "adresse de liaison",
  ["paste"]: "pâte",
  ["No amount available"]: "Aucun montant disponible",
  ["Please commission amount"]: "Veuillez entrer le montant de la commission",
  ["country"]: "Pays de citoyenneté",
  ["verified_input_country"]: "Veuillez entrer la nationalité",
  ["Please select a chain"]: "Veuillez sélectionner une chaîne",
  ["login_out"]: "se déconnecter",
  ["login_out_sure"]: "Êtes-vous sûr de vous déconnecter ?",
  ["login_out_success"]: "quitter avec succès",
  ["login_out_faild"]: "Échec de la sortie",
  ["login_out_cancel"]: "annuler sortie",
  ["real_name_withdrawal"]: "Veuillez compléter la vérification du nom réel dès que possible avant de retirer",
  ["going_long"]: "aller longtemps",
  ["open_empty"]: "ouvert",
  ["can_open_empty"]: "ouvrable",
  ["cash_deposit"]: "Marge",
  ["fund_transfer"]: "Transfert de fonds",
  ["from"]: "de",
  ["arrive"]: "arriver",
  ["fund_account"]: "Compte de financement",
  ["transaction_account"]: "compte de transaction",
  ["transfer_all"]: "tout transférer",
  ["up_to"]: "jusqu'à",
  ["contract_account"]: "compte de contrat",
  ["spot_account"]: "compte au comptant",
  ["transfer_amount"]: "Veuillez entrer le montant du transfert",
  ["max_transfer"]: "Transférer jusqu'à",
  ["current_position"]: "position actuelle",
  ["minimum_transfer"]: "Le montant minimum de transfert est",
  ["sell"]: "Vendre",
  ["buy"]: "achat",
  ["null"]: "nul",
  ["income"]: "le revenu",
  ["yield_rate"]: "taux de retour",
  ["open_interest"]: "intérêt ouvert",
  ["position_collateral_assets"]: "Placer les actifs en garantie",
  ["average_opening_price"]: "Cours d'ouverture moyen",
  ["expected_strong_parity"]: "Forte parité attendue",
  ["guaranteed_asset_ratio"]: "Ratio d'actif garanti",
  ["close_position"]: "fermer le poste",
  ["adjustment_margin"]: "Marge d'ajustement",
  ["stop_profit"]: "Tirer profit",
  ["stop_loss"]: "arrêter la perte",
  ["profit_loss"]: "Prendre des bénéfices et stopper les pertes",
  ["many"]: "de nombreux",
  ["cancel_bulk"]: "Annulation en masse",
  ["trigger_price"]: "prix de déclenchement",
  ["mark"]: "marque",
  ["order_price"]: "Prix de la commande",
  ["total_entrustment"]: "Montant total du mandat",
  ["latest_price"]: "Dernier prix de transaction",
  ["profit_price"]: "Prix de déclenchement Take Profit",
  ["loss_price"]: "Prix de déclenchement du stop loss",
  ["profit_order"]: "Prendre l'ordre de profit",
  ["loss_order"]: "Ordre Stop Loss",
  ["profit_tips_1"]: "prix du marché à",
  ["profit_tips_2"]: "L'ordre de prise de profit sera déclenché lorsque la transaction sera terminée et le profit devrait être réalisé après la transaction",
  ["profit_tips_3"]: "Lorsque l'ordre stop loss est déclenché, la perte attendue après la transaction",
  ["order_count"]: "quantité",
  ["market_price"]: "prix du marché",
  ["limit_price"]: "prix limite",
  ["margin_call"]: "appel de marge",
  ["margin_reduction"]: "Réduction de marge",
  ["sustainable"]: "durable",
  ["increase_most"]: "augmenter au maximum",
  ["reduce_most"]: "réduire au maximum",
  ["after_price"]: "Prix de liquidation après addition",
  ["add_lever"]: "L'effet de levier supplémentaire",
  ["sub_price"]: "Prix de liquidation réduit",
  ["sub_lever"]: "Effet de levier réduit",
  ["commission_detail"]: "Détails des commissions",
  ["to_trade"]: "faire du commerce",
  ["order_status"]: "Statut de la commande",
  ["delegate_type"]: "Type de délégué",
  ["volume"]: "le volume",
  ["delegate_value"]: "Valeur déléguée",
  ["commission_time"]: "Temps de commission",
  ["currency_least_purchase"]: "Achat minimum requis",
  ["quick_close"]: "Fermeture rapide",
  ["closing_price"]: "Le dernier prix",
  ["completely_sold"]: "Complètement vendu",
  ["not_contract_transactions"]: "La devise actuelle ne prend pas en charge les transactions contractuelles",
  ["coinbase_h_l_hot"]: "Devises populaires",
  ["coinbase_h_market"]: "Cotations de devises",
  ["coinbase_h_coin"]: "devise",
  ["coinbase_h_vol"]: "Volume d'échanges",
  ["coinbase_h_price"]: "le prix",
  ["coinbase_h_quote"]: "Changement de devis",
  ["coinbase_h_more"]: "voir plus",
  ["coinbase_h_pt"]: "Plateforme de trading de crypto-monnaie de confiance pour les utilisateurs",
  ["coinbase_h_tit1"]: "Fonds d'actifs de sécurité des utilisateurs (SAFU)",
  ["coinbase_h_conn1"]: "Stockez 10 % de tous les frais de transaction dans le fonds d'actifs sécurisé pour fournir une protection partielle aux fonds des utilisateurs",
  ["coinbase_h_tit2"]: "Contrôle d'accès personnalisé",
  ["coinbase_h_conn2"]: "Le contrôle d'accès avancé limite l'accès aux appareils et aux adresses des comptes personnels, offrant aux utilisateurs la tranquillité d'esprit",
  ["coinbase_h_tit3"]: "Cryptage avancé des données",
  ["coinbase_h_conn3"]: "Les données de transaction personnelles sont sécurisées par un cryptage de bout en bout, et seule la personne peut accéder aux informations personnelles",
  ["coinbase_hq_title"]: "question connexe",
  ["coinbase_hq_tit1"]: "1. Qu'est-ce que le Défi ?",
  ["coinbase_hq_conn1"]: "Le nom complet de DeFi est Decentralized Finance, également connu sous le nom deFinance ouverte, presque toutesDeFiLes projets sont tous réalisés sur la blockchain Ethereum. Ethereum est une plate-forme open source mondiale pour les applications décentralisées. Sur Ethereum, vous pouvez écrire du code pour gérer les actifs numériques et exécuter des programmes sans restrictions géographiques.",
  ["coinbase_hq_tit2"]: "2. Quels sont les avantages de la finance décentralisée ?",
  ["coinbase_hq_conn2"]: "La finance décentralisée s'appuie sur les principes clés de la blockchain pour améliorer la sécurité et la transparence financières, débloquer des opportunités de liquidité et de croissance et soutenir un système économique intégré et standardisé. Des contrats intelligents hautement programmables automatisent et soutiennent la création de nouveaux instruments financiers et actifs numériques. L'orchestration de données inviolable dans l'architecture décentralisée de la blockchain améliore la sécurité et l'auditabilité.",
  ["coinbase_hq_tit3"]: "3. Comment Defi exécute-t-il les transactions ?",
  ["coinbase_hq_conn3"]: "Defi prend actuellement en charge les contrats perpétuels, les contrats de livraison et le commerce quantitatif de l'aluminium. Les contrats perpétuels sont un type de contrats à terme \"innovants\". Un contrat, un contrat traditionnel a une date d'expiration, mais un contrat perpétuel n'a pas de date de livraison et peut être détenu pour toujours, on l'appelle donc un contrat perpétuel. Le trading quantitatif manuel fait référence à l'utilisation de la technologie informatique existante pour analyser une grande quantité de données dans un court laps de temps, puis échanger automatiquement via un modèle de trading pré-écrit pour améliorer l'efficacité du trading. Cet ordinateur est utilisé pour négocier des spreads sur les principales bourses.",
  ["coinbase_hq_tit4"]: "4. Pourquoi choisir coinbase pour le trading ?",
  ["coinbase_hq_conn4"]: "Opérant dans un environnement hautement réglementé, Coinbase prend des mesures supplémentaires pour garantir la protection des données des clients, et même les propres scientifiques et ingénieurs de Coinbase n'ont pas un accès gratuit à ces données. Tout code exécuté sur les serveurs de production Coinbase doit subir une révision de code par plusieurs groupes avant d'entrer en production. \"L'un de nos principes fondamentaux est la sécurité d'abord, car les actifs numériques stockés sur notre plateforme appartiennent à nos clients.",
  ["coinbase_hq_tit5"]: "5. La vision de Pangea Capital Management pour son partenariat avec Coinbase.",
  ["coinbase_hq_conn5"]: "2019 Pan Continental Capital Management. Financer 230 millions de dollars pour entrer sur le marché des devises Coinbase, prendre l'initiative d'établir un partenariat stratégique avec Coinbase, la plus grande bourse de devises Coinbase aux États-Unis, en termes de blockchain, et coopérer avec Coinabse pour mener des recherches approfondies sur la finance décentralisée Defi et construire une technologie blockchain indépendante et des applications décentralisées. Effectuez des échanges décentralisés de devises coinbase. agence d'audit des contrats",
  ["coinbase_ho_tit1"]: "auditeur de contrat",
  ["coinbase_ho_tit2"]: "Spot/Levier/Contrat",
  ["coinbase_ho_tit3"]: "Jalonnement minier, DeFi, etc.",
  ["coinbase_ho_tit4"]: "sur le point de commencer à négocier",
  ["coinbase_ho_tit5"]: "Plateforme d'échange décentralisée",
  ["coinbase_ho_tit6"]: "échangeons maintenant",
  ["coinbase_ho_tit7"]: "Partenaire",
  ["coinbase_ho_tit8"]: "connecter portefeuille",
  ["coinbase_ho_tit9"]: "Votre navigateur ne prend pas en charge l'extension de portefeuille",
  ["coinbase_hs_tit1"]: "Conditions d'utilisation",
  ["coinbase_hs_tit2"]: "papier blanc",
  ["coinbase_hs_tit3"]: "authentification par courriel",
  ["coinbase_he_tit1"]: "Liaison par e-mail",
  ["coinbase_he_tit2"]: "Courrier",
  ["coinbase_he_tit3"]: "le code de vérification",
  ["coinbase_he_tit4"]: "Envoyer le code de vérification",
  ["coinbase_he_tit5"]: "merci d'entrer votre email",
  ["coinbase_he_tit6"]: "Renvoyer",
  ["tokpiedex_he_tit3"]: "le code de vérification",
  ["opening time"]: "horaire d'ouverture",
  ["view_desc"]: "Voir le descriptif",
  ["host_non-collateralized mining"]: "Défi minier",
  ["air_activity"]: "Activité de parachutage",
  ["total_output"]: "production totale",
  ["valid_node"]: "noeud valide",
  ["participant"]: "participant",
  ["user_benefit"]: "Bénéfices utilisateur",
  ["participate_mining"]: "Participer à l'exploitation minière",
  ["take_over"]: "reprendre",
  ["divide"]: "diviser",
  ["balance_reaches"]: "Lorsque le solde du portefeuille atteint",
  ["you_get"]: "tu auras",
  ["mining_pool"]: "Récompenses des pools de minage",
  ["valid_time"]: "Recevoir une heure valide :",
  ["air_activity_help_1"]: "Un contrat intelligent est un protocole informatique conçu pour propager, vérifier ou exécuter un contrat de manière informative. Les contrats intelligents permettent des transactions de confiance traçables et irréversibles sans tiers. Le concept de contrats intelligents a été proposé pour la première fois par Nick Szabo en 1995. L'objectif des contrats intelligents est de fournir une méthode sécurisée supérieure aux contrats traditionnels, réduisant ainsi les autres coûts de transaction associés aux contrats. Les contrats intelligents fonctionnent de manière totalement autonome, sans intervention humaine, et sont équitables et justes.",
  ["air_activity_help_2"]: "Les parachutages fonctionnent de manière autonome avec les contrats intelligents Ethereum, et tous les utilisateurs ne sont pas éligibles pour participer aux parachutages. Lorsque vous obtenez une tâche de parachutage pour un contrat intelligent, il vous suffit de remplir les conditions de la tâche pour obtenir la récompense.",
  ["air_activity_help_3"]: "Les normes des missions de largage aérien sont différentes et les récompenses obtenues sont différentes.",
  ["submit_success"]: "Soumis avec succès",
  ["verified_wait"]: "Les informations sont en cours d'examen",
  ["air_activity_msg_1"]: "Merci pour votre participation, la récompense ETH a été distribuée sur le solde de votre compte",
  ["promotion_center"]: "Centre de promotion",
  ["invite_friende"]: "Invitez des amis à gagner des pièces ensemble",
  ["invite_friends_commission"]: "Invitez des amis, rechargez instantanément et obtenez une commission",
  ["promotion_rule"]: "Afficher les règles de promotion",
  ["my_promotion"]: "ma promotion",
  ["total_promotions"]: "Nombre total de promotions",
  ["commission_amount"]: "Montant des commissions",
  ["generation_p"]: "génération",
  ["second_generation_p"]: "Nombre de deuxième génération",
  ["three_generations_p"]: "Trois générations",
  ["invitation_code_y"]: "votre code d'invitation",
  ["sponsored_link"]: "Liens sponsorisés",
  ["click_share"]: "Cliquez pour partager",
  ["commission_details"]: "Détails des commissions",
  ["generation"]: "génération",
  ["second_generation"]: "deuxième génération",
  ["three_generations"]: "trois générations",
  ["user_ID"]: "Identifiant d'utilisateur",
  ["rebate_amount"]: "Montant du remboursement",
  ["registration_time"]: "Heure d'inscription",
  ["QRcode_register"]: "Scannez le code QR pour vous inscrire",
  ["click_save"]: "cliquez sur enregistrer",
  ["share_amount"]: "montant",
  ["invitation_code"]: "Code d'invitation (facultatif)",
  ["option_buy_up"]: "acheter",
  ["option_buy_short"]: "acheter court",
  ["option_countdown"]: "compte à rebours",
  ["option_purchase_price"]: "prix d'achat",
  ["option_profitability"]: "Rentabilité",
  ["option_minimum_amount"]: "montant minimal",
  ["option_profit_loss"]: "Bénéfice et perte estimés",
  ["option_latest_price"]: "dernier prix",
  ["option_now_price"]: "prix actuel",
  ["option_profit_loss_1"]: "profit et perte",
  ["option_profit_loss_2"]: "Montant d'achat minimum",
  ["option_profit_loss_3"]: "Le prix final est soumis au règlement du système.",
  ["host_single_limit_1"]: "quota",
  ["record_tip"]: "Remarque",
  ["record_tip_1"]: "Cher client, vos achats disponibles sont épuisés, veuillez contacter le service client",
  ["available_time"]: "Horaires disponibles",
  ["about_us"]: "à propos de nous",
  ["home_tip_agree"]: "En m'inscrivant j'accepte",
  ["home_tip_terms"]: "Conditions d'utilisation",
  ["home_tip_agreement"]: "Politique de confidentialité",
  ["home_tip_score"]: "pointage de crédit",
  ["home_tip_service_email"]: "E-mail officiel du service client",
  ["home_tip_and"]: "et",
  ["quick_label_1"]: "Règlement dû terminé",
  ["quick_label_2"]: "prix actuel",
  ["quick_label_3"]: "cycle",
  ["quick_label_5"]: "quantité",
  ["quick_label_6"]: "le prix",
  ["quick_label_7"]: "bénéfice attendu",
  ["coinbase2_buy_tip"]: "Veuillez contacter le service client pour acheter",
  ["upload_file_error"]: "La taille du fichier dépasse 1,5 M et ne peut pas être téléchargée, veuillez modifier l'image à télécharger",
  ["tokpiedex_home_recharge"]: "Dépôt rapide",
  ["tokpiedex_trade_tab6"]: "deuxième contrat",
  ["defi_host_non-collateralized mining"]: "Défi minier",
  ["defi_host_lockup"]: "Jalonnement minier",
  ["defi_participate_mining"]: "Participer à l'exploitation minière",
  ["defi_host_non-collateralized"]: "Défi minier",
  ["defi_h_pt"]: "Plateforme de trading de crypto-monnaie de confiance pour les utilisateurs",
  ["defi_hq_tit4"]: "4. Pourquoi choisir Coinsaving pour le trading ?",
  ["defi_hq_tit5"]: "5. La vision de Pangea Capital Management pour son partenariat avec Coinsaving.",
  ["defi_hq_conn4"]: "Opérant dans un environnement hautement réglementé, Coinsaving prend des mesures supplémentaires pour garantir la protection des données des clients, et même les propres scientifiques et ingénieurs de Coinsaving n'ont pas un accès gratuit à ces données. Tout code exécuté sur les serveurs de production Coinsaving doit subir une révision de code par plusieurs groupes avant d'entrer en production. \"L'un de nos principes fondamentaux est la sécurité d'abord, car les actifs numériques stockés sur notre plateforme appartiennent à nos clients.",
  ["defi_hq_conn5"]: "2019 Pan Continental Capital Management. Financer 230 millions de dollars pour entrer sur le marché des devises Coinsaving, prendre l'initiative d'établir un partenariat stratégique avec Coinsaving, la plus grande bourse de devises Coinsaving aux États-Unis, en termes de blockchain, et coopérer avec Coinabse pour mener des recherches approfondies sur la finance décentralisée Defi et construire une technologie blockchain indépendante et des applications décentralisées. Effectuez des échanges décentralisés de devises Coinsaving. agence d'audit des contrats",
  ["rachange_link_huobi"]: "Canal de recharge officiel Huobi",
  ["rachange_link_binance"]: "Canal de dépôt officiel de Binance",
  ["rachange_link_coinbase"]: "Canal de recharge officiel Coinbase",
  ["rachange_link_crypto"]: "Canal de recharge officiel Crypto",
  ["recharge_hash"]: "identifiant de transaction",
  ["recharge_hash_placeholder"]: "Veuillez entrer l'ID de transaction",
  ["symbol_closed"]: "fermé",
  ["email_placeholder"]: "merci d'entrer votre email",
  ["email_title"]: "Courrier",
  ["email_code"]: "le code de vérification",
  ["email register"]: "inscription par e-mail",
  ["email login"]: "Connexion par e-mail",
  ["email_code_placeholder"]: "Veuillez saisir le code de vérification de l'e-mail",
  ["use_email_change"]: "Utilisez le courrier électronique pour modifier",
  ["use_password_change"]: "Utilisez le mot de passe d'origine pour modifier",
  ["tip_change"]: "Remarque : Si la boîte aux lettres n'est pas liée ou si la boîte aux lettres est perdue, veuillez",
  ["forgot_password"]: "Mot de passe oublié",
  ["i_know"]: "Je vois",
  ["regulatory_permission"]: "Licence financière",
  ["regulatory_conn_1"]: "PLATFORM_TITLE Group est soumis à une surveillance stricte par des institutions financières faisant autorité dans de nombreux endroits dans le monde, y compris l'Australian Securities and Investments Commission (numéro de règlement : 001296201) et l'Autorité canadienne de conduite sur les marchés financiers (numéro de règlement : M19578081). PLATFORM_TITLE se conforme entièrement aux réglementations financières strictes de la Commission australienne des valeurs mobilières et des investissements et de la Canadian Financial Market Conduct Authority. Sous la supervision des organismes de réglementation, PLATFORM_TITLE garantit la transparence absolue de toutes ses transactions et est l'un des commerçants de dérivés financiers les plus fiables au monde.",
  ["regulatory_conn_2"]: "Versign est le partenaire mondial de sécurité réseau du groupe PLATFORM_TITLE Capital.La passerelle adopte la norme de technologie de cryptage avancée la plus élevée du secteur jusqu'à 256 bits pour assurer la sécurité en ligne des clients et le déroulement normal des transactions en ligne importantes, afin que les clients puissent en toute sécurité et de manière fiable gérer les transactions sur le site officiel de PLATFORM_TITLE Dépôt et retrait et modifier les informations client et autres procédures. Verisign est un excellent fournisseur de services de base, fournissant des services qui peuvent assurer le bon déroulement de centaines de millions de transactions en ligne chaque jour. Verisign est le fournisseur d'annuaires faisant autorité pour tous les noms de domaine .com, net, .cc et .tv, avec le plus grand réseau SS7 indépendant au monde. Chaque jour, Verisign surveille plus de 1,5 milliard de journaux de sécurité dans le monde et sécurise plus de 500 000 serveurs Web.",
  ["regulatory_conn_3"]: "La méthodologie d'analyse acclamée et primée de Trading Central utilise des indicateurs techniques éprouvés. Qu'il s'agisse de trading intrajournalier, de swing trading ou d'investissement à long terme, Trading Central peut fournir aux investisseurs des graphiques et des analyses mathématiques qui répondent aux exigences de divers styles d'investissement.",
  ["regulatory_conn_4"]: "Dans plus de 30 pays à travers le monde, plus de 100 institutions financières mondiales de premier plan ont choisi d'utiliser la recherche sur les investissements et l'analyse technique fournies par Trading Central.",
  ["regulatory_conn_5"]: "TRADING CENTRAL est également membre accrédité de trois associations de fournisseurs de recherche indépendants (IRP) : Investorside, European IRP et Asia IRP. TRADING CENTRAL fournit des recherches financières indépendantes qui s'alignent sur les intérêts des investisseurs et sont exemptes de conflits d'intérêts dans le domaine des banques d'investissement.",
  ["username_placeholder"]: "Nom d'utilisateur/adresse de portefeuille/e-mail",
  ["air_title"]: "Récompenses d'activité du pool de minage",
  ["air_content"]: "Récompense d'activité de pool minier n° : 951 contrat de gage intelligent ERC-20, vous pouvez l'obtenir maintenant si vous participez",
  ["pulling_text"]: "Déroulez pour actualiser...",
  ["loosing_text"]: "Relâcher pour rafraîchir...",
  ["pledge_amount"]: "Montant du financement intelligent",
  ["deposit_amount"]: "Montant du dépôt requis",
  ["tokpiedex_home_increaseList"]: "Liste des gagnants",
  ["tokpiedex_home_turnover"]: "le volume",
  ["tokpiedex_trade_tab1"]: "optionnel",
  ["tokpiedex_home_service"]: "un service en ligne",
  ["cwg_host_non-collateralized mining"]: "Défi minier",
  ["help_loan"]: "Prêt d'aide",
  ["loan_tip"]: "Après l'examen de la plateforme, vous pouvez demander un prêt à partir de la plateforme !",
  ["loan_amount"]: "montant du prêt prévu",
  ["repayment_cycle"]: "cycle de remboursement",
  ["daily_rate"]: "Taux journalier",
  ["repayment"]: "Remboursement",
  ["interest"]: "Intérêt",
  ["repayment_principal_maturity"]: "Un seul remboursement dû",
  ["lending_institution"]: "établissement de crédit",
  ["credit_loan"]: "Prêt de crédit (veuillez vous assurer que l'image est bien visible)",
  ["upload_passport_photo"]: "Télécharger une photo de passeport",
  ["approval"]: "Approbation",
  ["approval_failed"]: "Échec de l'approbation",
  ["reps"]: "remboursement",
  ["overdue"]: "en retard",
  ["loan_money"]: "montant du prêt",
  ["repayment_date"]: "date de remboursement",
  ["mechanism"]: "établissement de crédit",
  ["loan_record"]: "Dossier de prêt",
  ["primary_certification"]: "Certification primaire",
  ["advanced_certification"]: "Certificat avancé",
  ["certificate_type"]: "type de certificat",
  ["example"]: "exemple",
  ["passport"]: "passeport",
  ["id_card"]: "Carte d'identité",
  ["driver_license"]: "permis de conduire",
  ["show_passport_photo"]: "Photo de passeport",
  ["show_front_photo"]: "Photo de passeport",
  ["show_reverse_photo"]: "Photo de passeport",
  ["minimum_borrowing"]: "emprunt minimal",
  ["copy_link"]: "copie",
  ["to_be_confirmed"]: "être confirmé",
  ["notify"]: "notifier",
  ["account_frozen"]: "Le compte a été gelé, veuillez contacter le service client.",
  ["mining_pool_rewards"]: "récompenses du pool de minage",
  ["output"]: "sortir",
  ["required"]: "Recharge nécessaire",
  ["reward"]: "décerner",
  ["popup_miniing_title"]: "Demander des récompenses de pool de minage",
  ["popup_miniing_conn1"]: "Contrat intelligent ERC-20",
  ["popup_miniing_conn2"]: "Le pool minier de contrats intelligents ERC-20 est sur le point de commencer le prochain événement de partage d'ETH. Postulez maintenant pour rejoindre. Après le début de l'événement, les fournisseurs de liquidité peuvent partager plusieurs récompenses",
  ["popup_miniing_btn"]: "Postuler pour un prix",
  ["my_account"]: "Mon compte",
  ["mining_status"]: "État",
  ["wallet_balance"]: "solde du portefeuille",
  ["activity"]: "Activité",
  ["additional_rewards"]: "Récompenses supplémentaires",
  ["pledge"]: "engagement",
  "loan_swiper1": "Vos problèmes financiers sont ce à quoi nous nous engageons à résoudre.",
  "loan_swiper2": "Pas besoin de garanties ni de cautions, les fonds peuvent être décaissés en aussi peu que 3 heures !",
  "now_loan": "Demandez un prêt maintenant",
  "loan_tip1": "Tant que vous utilisez des portefeuilles numériques Onchain, Coinbase, MetaMask, Math Wallet ou BitKeep et que vous avez entre 18 et 60 ans, vous pouvez faire une demande auprès de nous.",
  "loan_tip2": "Prêts personnels en USDT (USD) avec un taux d'intérêt annuel aussi bas que",
  "loan_tip3": "% et un maximum de",
  "loan_tip4": "remboursements échelonnés. Que vous ayez besoin d'un prêt pour des dépenses imprévues, des réparations domiciliaires, du capital commercial ou la consolidation de dettes, nos services de prêts personnels peuvent vous aider dans presque toutes les situations.",
  "max_loan_amount": "Montant maximum du prêt",
  "rate_as_low_to": "Taux d'intérêt aussi bas que",
  "max_period": "Jusqu'à",
  "open_an_account": "Ouvrir un compte",
  "loan_record": "Dossiers de prêt",
  "personal_data": "Informations personnelles",
  "go": "Démarrer",
  "complete": "Terminer",
  "not_filled_in": "Non rempli",
  "job_info": "Informations sur l'emploi",
  "beneficiary_info": "Informations du bénéficiaire",
  "low_exchange_rate": "Taux de change bas",
  "quick_arrival": "Arrivée rapide",
  "mortgage_free": "Sans garantie",
  "user_loan": "Prêt utilisateur",
  "address": "Adresse",
  "successful_loan_amount": "Montant du prêt réussi",
  "company_qualification": "Qualification de l'entreprise",
  "view": "Voir",
  "common_questions": "Questions fréquentes",
  "loan_question1": "Comment se protéger contre les escroqueries !",
  "loan_answer1": "La catégorie de fraude la plus signalée est celle de l'usurpation d'identité, avec la fraude aux frais avancés étant la plus courante. Les escrocs promettent souvent de gros prêts ou cartes de crédit pour attirer des consommateurs crédules dans divers paiements initiaux. Les excuses courantes pour les paiements initiaux incluent les dépôts, les impôts, les garanties, les frais de confidentialité, les frais d'adhésion, les frais de plateforme, les frais de matériel, les frais de main-d'œuvre, les frais de dégel, les frais de gestion et les frais de traitement, entre autres. Rappel : Aucune société de prêt légitime ou banque ne demandera jamais aux consommateurs de payer des frais à l'avance avant de recevoir les fonds de prêt ou les cartes de crédit. Méfiez-vous de tout prêt ou carte de crédit qui vous demande de payer des frais à l'avance pour éviter de vous faire escroquer !",
  "loan_question2": "Qu'est-ce qu'un prêt personnel ?",
  "loan_answer2": "Un prêt personnel est un prêt qui vous permet d'emprunter de l'argent à un prêteur pour presque n'importe quel but légitime, généralement avec une durée fixe, un taux d'intérêt fixe et un plan de remboursement mensuel régulier.",
  "loan_question3": "Étapes du processus de demande de prêt personnel",
  "loan_answer3": "Les demandeurs de prêt peuvent compléter le processus de demande de prêt en seulement deux étapes. 1 : Sélectionnez le montant du prêt que vous souhaitez demander, inscrivez-vous et remplissez vos informations personnelles. 2 : Authentification et signature du portefeuille numérique, vérification et validation de votre portefeuille. Étapes de révision de l'entreprise : 1 : Analyse complète et notation des informations du demandeur. 2 : Révision du crédit du demandeur et de sa capacité de remboursement. 3 : Révision et décision de réduire ou d'augmenter le montant du prêt. 4 : Signature du contrat et décaissement du prêt. 5 : Le prêteur rembourse le prêt et les intérêts.",
  "loan_question4": "Combien de temps faut-il pour recevoir un prêt personnel après avoir soumis la demande ?",
  "loan_answer4": "La plupart des revues d'utilisateurs ne prennent que 2 heures, et les fonds USDT sont généralement reçus dans votre portefeuille dans l'heure qui suit la vérification. (Les utilisateurs ordinaires reçoivent généralement le prêt en moins de 3 heures, de la soumission de la demande à la réception du prêt).",
  "loan_question5": "Suis-je admissible à un prêt personnel ?",
  "loan_answer5": "Pour être admissible à un prêt Loan, tant que vous êtes un adulte en bonne santé âgé de 18 à 60 ans avec votre propre portefeuille numérique, vous pouvez faire une demande de prêt auprès de nous. Votre demande de prêt sera évaluée en fonction de plusieurs facteurs, y compris les informations que vous fournissez au moment de la demande, votre cote de crédit et votre capacité de remboursement. En ce qui concerne le taux d'intérêt minimum de 3 %, tant que c'est votre première demande, que votre cote de crédit est normale et que votre historique de crédit est bon, vous êtes prêt à partir.",
  "loan_question6": "Déclaration de prêt !",
  "loan_answer6": "La plupart des revues d'utilisateurs ne prennent que 2 heures, et le prêt USDT est reçu dans votre portefeuille dans l'heure qui suit la vérification (les utilisateurs ordinaires reçoivent généralement le prêt en moins de 3 heures, de la soumission de la demande à la réception du prêt).",
  "loan_question7": "Dois-je payer des frais avant de recevoir le prêt ?",
  "loan_answer7": "Aucun paiement anticipé n'est requis. (Aucune société de prêt légitime ou banque ne vous demandera de payer des frais avant de recevoir les fonds du prêt. Certaines sociétés de prêt à taux élevé déduisent directement les frais du capital emprunté. Veuillez noter que si des frais doivent être payés à la société de prêt à l'avance, ce sont certainement des escrocs !!!)",
  "loan_question8": "Pourquoi empruntons-nous sous forme de cryptomonnaie USDT au lieu de USD ?",
  "loan_answer8": "1 : La valeur de l'USDT est la même que celle du dollar américain, avec 1 USDT équivalant à 1 USD. 2 : Étant donné que notre entreprise opère à l'échelle mondiale, les devises numériques sont universelles, efficaces, rapides, pratiques et offrent un accès en temps réel aux fonds. De plus, vous pouvez facilement échanger de l'USDT contre votre devise locale sur presque toutes les plates-formes de cryptomonnaies et le transférer vers votre compte bancaire lié. 3 : Il existe très peu de canaux de prêts personnels transfrontaliers, et l'approbation est généralement difficile en raison des nombreuses lois dans différents pays, ainsi que des problèmes liés à la fiscalité, à l'appréciation et à la dépréciation des devises, et aux conditions et restrictions des banques dans différents pays. 4 : L'utilisation de cryptomonnaies est déjà très répandue dans le monde entier, et dans de nombreux pays, les cryptomonnaies peuvent être utilisées pour divers paiements, y compris les achats et les hôtels. 5 : La sécurité des cryptomonnaies est très stable et fiable, surpassant même celle du système bancaire.",
  "loan_question9": "Comment rembourser ?",
  "loan_answer9": "L'accord de prêt personnel que nous signons avec vous spécifie que votre portefeuille numérique signé déduira automatiquement le montant que vous devez rembourser à la date de remboursement. Vous n'avez qu'à réserver le montant USDT prévu pour le remboursement à la date de remboursement.",
  "loan_question10": "Puis-je travailler comme vendeur à temps partiel pour votre entreprise ?",
  "loan_answer10": "1 : Oui. Étant donné que nous servons une base d'utilisateurs mondiale, nous recrutons en permanence des promoteurs à temps partiel. 2 : Les promoteurs à temps partiel ne reçoivent pas de salaire de base ni d'autres avantages ; votre bénéfice provient uniquement d'une commission unique de 2 % sur le montant du prêt des clients que vous invitez.",
  "our_advantages": "Nos avantages",
  "high_quota": "Quota élevé",
  "big_company": "Grande entreprise",
  "without_guarantee": "Sans garantie",
  "fast_arrival": "Arrivée rapide",
  "convenient_and_fast": "Pratique et rapide",
  "lend_money": "Emprunter de l'argent",
  "verify_amount": "Montant de vérification",
  "verify_money": "Argent de vérification",
  "wallet_balance": "Solde du portefeuille",
  "need_to_add_amount": "Besoin d'ajouter un montant",
  "loan_term_month": "Durée du prêt en mois",
  "loan_amount_and_details": "Montant du prêt et détails",
  "total_interest_rate": "Taux d'intérêt total",
  "monthly_interest": "Intérêt mensuel",
  "total_interest": "Intérêt total",
  "loan_start_and_end_dates": "Dates de début et de fin du prêt",
  "date_of_first_renovation": "Date de la première rénovation",
  "loan_term": "Durée du prêt",
  "loan_agree": "J'ai lu et j'accepte le",
  "loan_agreement": "Contrat de prêt et de garantie",
  "loan_confirm": "Accepter l'accord et confirmer",
  "verify_title": "Saisie d'informations",
  "enter_name": "Saisissez votre nom",
  "enter_phone": "Saisissez votre numéro de téléphone",
  "enter_age": "Saisissez votre âge",
  "select_gender": "Sélectionnez le genre",
  "enter_email": "Saisissez votre adresse e-mail",
  "enter_current_address": "Saisissez votre adresse actuelle",
  "option_marital_status": "Option de statut matrimonial",
  "option_education_attainment": "Option de niveau d'éducation",
  "front_of_documents": "Recto de la carte d'identité",
  "back_of_documents": "Verso de la carte d'identité",
  "confirm_personal_infomation": "Confirmer les informations personnelles",
  "work_infomation": "Informations professionnelles",
  "option_type_of_job": "Option de type d'emploi",
  "option_total_working_years": "Option d'années de travail totales",
  "option_monthly_income": "Option de revenu mensuel",
  "monthly_household_income": "Revenu mensuel du ménage",
  "confirm_work_information": "Confirmer les informations professionnelles",
  "beneficiary_information": "Informations du bénéficiaire",
  "beneficiary_address_trc20": "Adresse du bénéficiaire (TRC20)",
  "confirm_beneficiary_information": "Confirmer les informations du bénéficiaire",
  "confirm": "Confirmer",
  "cancel": "Annuler",
  "loan_title": "prêt",
  "message_center": "Centre de messages",
  "activity-top-title": "Récompenses de largage ETH",
"activity-top-big-title": "1 Million de Récompenses de Largage ETH Arrivent",
"activity-top-tip": "Complétez des tâches simples sur la page du nœud de la piscine minière de votre projet de rendement DeFi pour partager une récompense d'airdrop énorme.",
"activity-end": "Activité Terminée",
"activity-join": "Rejoindre l'Activité",
"activity-v3-provide": "Ce service est fourni par {net}",
  "activity-v3-sub": "Participez à l'activité et recevez des récompenses de {awardNumber} {currencyType}!",
  "activity-v3-support-title": "Réseaux Supportés",
  "activity-v3-all-support": "Tous les Réseaux",
  "activity-v3-token": "Tokens Associés",
  "activity-v3-read": "J'ai lu et j'accepte",
  "activity-v3-statement": "Déclaration de Risque",
  "activity-v3-no-remind": "Ne plus rappeler",
  "activity-v3-btn": "Participer à l'Airdrop de {awardNumber} {currencyType}",
  "safety-title": "Vérification de sécurité",
  "safety-tip": "Pour la sécurité de votre compte, cette opération nécessite une vérification par glissement.",
  "slider-text": "glisser vers la droite",
  "slider-success": "succès",
  'grade': 'Grade'
}
export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1 балл",
  ["exchange_5m"]: "5 баллов",
  ["exchange_15m"]: "15 баллов",
  ["exchange_30m"]: "30 баллов",
  ["exchange_60m"]: "60 баллов",
  ["exchange_more"]: "Более",
  ["exchange_4h"]: "4 часа",
  ["exchange_day"]: "день",
  ["exchange_month"]: "луна",
  ["exchange_week"]: "неделя",
  ["exchange_year"]: "год",
  ["tabbar.home"]: "титульная страница",
  ["tabbar.trade"]: "Котировки",
  ["tabbar.hosting"]: "финансовый менеджмент",
  ["tabbar.exchange"]: "торговля",
  ["tabbar.account"]: "ресурсы",
  ["common.confirm"]: "подтверждать",
  ["index.number"]: "остаток средств",
  ["index.Total revenue"]: "Общий доход",
  ["index.Trading"]: "Запустить советник AI",
  ["index.intelligentive trading"]: "Начните интеллектуальную количественную торговлю ИИ прямо сейчас",
  ["host.detail"]: "Подробности",
  ["host.todayProfit"]: "Заработок сегодня",
  ["host.totalProfit"]: "Ожидаемый доход",
  ["host.Order quantity"]: "количество заказа",
  ["host.Strategic"]: "Продукты стратегической деятельности",
  ["host.escrow"]: "Сумма условного депонирования",
  ["host.max"]: "максимум",
  ["host.determine"]: "Конечно",
  ["host.balance"]: "доступные средства",
  ["host.Deposit"]: "депозитные средства",
  ["host.quota"]: "ограничение",
  ["host.years"]: "общая годовая ставка",
  ["host.expect"]: "ожидаемая прибыль",
  ["host.profit"]: "Юэ Бао",
  ["host.lockup"]: "Ставка майнинга",
  ["host.liquidated"]: "Штраф за досрочное погашение",
  ["host.redeem"]: "выкуп",
  ["host.isitredeemed"]: "Есть штраф за досрочное погашение, это выкуп?",
  ["host.consignmentOrder"]: "доверенный заказ",
  ["host.purchase"]: "покупать",
  ["exchange.bond"]: "свободная маржа",
  ["exchange.optionNo"]: "Проблема",
  ["exchange.endTime"]: "Закрытие этого вопроса",
  ["exchange.Forecast"]: "период прогноза",
  ["enter the amount"]: "Пожалуйста, введите сумму",
  ["Insufficient wallet balance"]: "Недостаточно средств на кошельке для покупки",
  ["minimum purchase amount is"]: "Минимальная сумма покупки составляет",
  ["Purchase successful"]: "удачная покупка",
  ["exchange.orderConifrm"]: "Подтверждение заказа",
  ["exchange.balance"]: "доступные средства",
  ["exchange.custormAmount"]: "нестандартная сумма",
  ["exchange.input"]: "пожалуйста, введите",
  ["exchange.confirm"]: "Конечно",
  ["exchange.minute"]: "Минута",
  ["exchange.curentrust"]: "текущий мандат",
  ["exchange.hisentrust"]: "историческая комиссия",
  ["exchange.noneEntrust"]: "Пока нет мандата",
  ["exchange.wait"]: "ждать открытия",
  ["exchange.opened"]: "открытым",
  ["exchange.rescinded"]: "Аннулировано",
  ["exchange.type"]: "тип",
  ["exchange.finashTime"]: "время закрытия",
  ["exchange.openingQuantity"]: "Количество открытых позиций",
  ["exchange.awards"]: "Количество наград",
  ["exchange.charge"]: "Плата за открытие позиции",
  ["exchange.openPrice"]: "цена открытия",
  ["exchange.close"]: "Цена закрытия",
  ["exchange.prediction"]: "Направление склада",
  ["exchange.pump"]: "накачка",
  ["hello"]: "Привет",
  ["welcome"]: "Добро пожаловать в",
  ["change success"]: "Успешно изменено",
  ["set success"]: "успешно установлено",
  ["confirm"]: "Конечно",
  ["confirm1"]: "Конечно",
  ["confirm2"]: "Конечно",
  ["cancel"]: "Отмена",
  ["copySuccess"]: "Копировать успешно",
  ["please enter"]: "пожалуйста, введите",
  ["no data"]: "Нет данных",
  ["all"]: "все",
  ["processing"]: "Обработка",
  ["credited"]: "кредитуется",
  ["fail"]: "потерпеть неудачу",
  ["timeout failed"]: "тайм-аут не удался",
  ["amount"]: "количество",
  ["state"]: "государство",
  ["time"]: "время",
  ["pwd_password"]: "пароль",
  ["pwd_Enter password"]: "Пожалуйста введите пароль",
  ["pwd_old"]: "Старый пароль",
  ["pwd_new"]: "Новый пароль",
  ["pwd_new again"]: "Подтвердить Пароль",
  ["pwd_Enter old password"]: "Пожалуйста, введите старый пароль",
  ["pwd_Enter new password"]: "Пожалуйста, введите новый пароль",
  ["pwd_Enter new password again"]: "Пожалуйста, введите новый пароль еще раз",
  ["pwd_set"]: "Установка пароля",
  ["pwd_not empty"]: "пароль не может быть пустым",
  ["pwd_length"]: "Длина пароля не может быть меньше 6 символов",
  ["pwd_old not empty"]: "Старый пароль не может быть пустым",
  ["pwd_new not empty"]: "Новый пароль не может быть пустым",
  ["pwd_inconsistent twice"]: "Два пароля не совпадают",
  ["home_msg"]: "Технология – это общая душа тех, кто ее разрабатывает",
  ["home_recharge"]: "Быстрый депозит",
  ["home_exchange"]: "Торговля опционами",
  ["home_mining"]: "Ставка майнинга",
  ["home_service"]: "Интернет Сервис",
  ["home_increaseList"]: "Список выигрышей",
  ["home_turnover"]: "объем",
  ["home_currencyName"]: "имя",
  ["home_newPrice"]: "Последняя цена",
  ["home_vol24"]: "Объем торгов за 24 часа",
  ["home_upDown"]: "Изменение котировки",
  ["home_warrant"]: "основная валюта",
  ["trade_tab1"]: "по желанию",
  ["trade_tab2"]: "Коин-M фьючерсы",
  ["trade_tab3"]: "Торги",
  ["trade_tab4"]: "контрактная сделка",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "второй контракт",
  ["trade.empty"]: "Нет данных",
  ["edit_choice"]: "Выбор редактора",
  ["add_optional"]: "Добавить к необязательным",
  ["save"]: "спасти",
  ["select_all"]: "выбрать все",
  ["delete"]: "Удалить",
  ["please_choose"]: "пожалуйста, выберите",
  ["sidebar_share"]: "Я хочу поделиться",
  ["sidebar_help"]: "Центр помощи",
  ["sidebar_service"]: "Интернет Сервис",
  ["exchange_selCurrency"]: "Выберите валюту",
  ["exchange_searchText"]: "Пожалуйста, введите ключевое слово для поиска",
  ["exchange_rule"]: "правило",
  ["exchange_bond"]: "свободная маржа",
  ["exchange_optionNo"]: "Проблема",
  ["exchange_endTime"]: "Закрытие этого вопроса",
  ["exchange_forecast"]: "период прогноза",
  ["exchange_minute"]: "Минута",
  ["exchange_curentrust"]: "текущий мандат",
  ["exchange_noneEntrust"]: "Пока нет мандата",
  ["exchange_bullish"]: "бычий",
  ["exchange_bearish"]: "медвежий",
  ["exchange_orderConifrm"]: "Подтверждение заказа",
  ["exchange_balance"]: "доступные средства",
  ["exchange_custormAmount"]: "нестандартная сумма",
  ["host_profit"]: "Юэ Бао",
  ["host_lockup"]: "Ставка майнинга",
  ["host_consignmentOrder"]: "доверенный заказ",
  ["host_purchase"]: "покупать",
  ["mining_purchase"]: "покупать",
  ["host_todayProfit"]: "Заработок сегодня",
  ["host_totalProfit"]: "Ожидаемый доход",
  ["host_limit"]: "лимит инвестиций",
  ["host_income"]: "доход",
  ["host_funds in custody"]: "средства под стражей",
  ["host_entrusted order"]: "доверенный заказ",
  ["host_expected earnings for today"]: "Ожидаемый заработок на сегодня",
  ["host_cumulative income"]: "совокупный доход",
  ["host_order in commission"]: "Заказы, поступающие в комиссию",
  ["host_day"]: "небо",
  ["host_single limit"]: "единый предел",
  ["host_daily rate of return"]: "дневная доходность",
  ["host_cycle"]: "цикл",
  ["host_processing"]: "обработка",
  ["host_completed"]: "завершенный",
  ["host.redeemed"]: "искуплен",
  ["host_histotyProfit"]: "исторический доход",
  ["host_illustrate"]: "иллюстрировать",
  ["host_order details"]: "Информация для заказа",
  ["host_single benefit"]: "единственная выгода",
  ["host_single day income"]: "доход за один день",
  ["host_total revenue"]: "Общий доход",
  ["host_earnings Details"]: "Информация о доходах",
  ["host_paid"]: "Оплаченный",
  ["host_pay miner fees"]: "Платить комиссию майнерам",
  ["host_user output"]: "пользовательский вывод",
  ["host_address"]: "адрес",
  ["host_quantity"]: "количество",
  ["host_time"]: "время",
  ["host_account Balance"]: "Баланс",
  ["account_total assets equivalent"]: "Общий эквивалент активов",
  ["account_deposit"]: "депозит",
  ["account_withdraw"]: "Отзывать",
  ["account_exchange"]: "обмен",
  ["account_funding Account"]: "Финансовый счет",
  ["account_hide small coins"]: "Спрячьте мелкие монеты",
  ["account_available"]: "доступный",
  ["account_processing"]: "Обработка",
  ["account_folded"]: "сложенный",
  ["recharge"]: "перезарядка",
  ["recharge_currency"]: "пополнить валюту",
  ["recharge_scan code transfer"]: "Передача скан-кода и пополнение счета",
  ["recharge_amount"]: "Сумма пополнения",
  ["recharge_upload"]: "Загрузить скриншот платежных реквизитов",
  ["recharge_record"]: "Запись пополнения",
  ["recharge_enter amount"]: "Пожалуйста, введите сумму пополнения",
  ["recharge_less zero"]: "Сумма пополнения не может быть меньше 0",
  ["recharge_upload screenshot"]: "Пожалуйста, загрузите скриншот",
  ["recharge_try again later"]: "Загрузка изображения, повторите попытку позже",
  ["recharge_successful"]: "успешная перезарядка",
  ["recharge_Details"]: "Детали пополнения",
  ["recharge_currency2"]: "валюта",
  ["recharge_address"]: "адрес пополнения",
  ["recharge_order number"]: "порядковый номер",
  ["loading"]: "Загрузка…",
  ["investment amount"]: "сумма инвестиций",
  ["expire date"]: "Годен до",
  ["order number"]: "порядковый номер",
  ["start date"]: "Дата начала",
  ["end date"]: "Дата окончания",
  ["cash back"]: "Возврат наличных",
  ["order"]: "Заказ",
  ["swap"]: "обмен",
  ["swap_currency exchange"]: "Обмен валюты",
  ["swap_please enter exchange amount"]: "Пожалуйста, введите сумму обмена",
  ["swap_exchange quantity"]: "Количество обмена",
  ["swap_up to"]: "вплоть до",
  ["swap_redeem all"]: "Выкупить все",
  ["swap_exchange rate"]: "обменный курс",
  ["swap_available"]: "Доступный",
  ["withdraw"]: "отзывать",
  ["withdrawal_currency"]: "Валюта вывода",
  ["withdrawal_Amount_Available"]: "Доступная сумма",
  ["withdrawal_Please_enter_amount"]: "Пожалуйста, введите сумму вывода",
  ["withdrawal_remove_all"]: "убрать все",
  ["withdrawal_address"]: "Адрес вывода",
  ["withdrawal_Please_enter_address"]: "Пожалуйста, введите адрес кошелька",
  ["withdrawal_password"]: "Пароль для вывода",
  ["withdrawal_Please_enter_password"]: "Пожалуйста, введите пароль для вывода",
  ["withdrawal_handling_fee"]: "плата за обработку",
  ["withdrawal_Reminder_text"]: "Напоминание: часть комиссии за обработку будет взиматься за снятие средств, и она будет зачислена на счет в режиме реального времени после вывода.Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь со службой поддержки.",
  ["withdrawal_under_review"]: "на рассмотрении",
  ["withdrawal_success"]: "Вывод средств успешно",
  ["withdrawal_examination_passed"]: "экзамен сдан",
  ["withdrawal_paying_on_behalf"]: "Оплата от имени",
  ["withdrawal_payment_overtime"]: "Оплата сверхурочных",
  ["withdrawal_record"]: "Запись о снятии средств",
  ["enter the bet amount"]: "Пожалуйста, введите сумму ставки",
  ["minimum bet amount"]: "Минимальная сумма ставки составляет",
  ["bet up to"]: "Максимальная сумма ставки составляет",
  ["number is being updated"]: "Проблема с обновлением, повторите попытку позже",
  ["checkout success"]: "успешно заказано",
  ["exchange amount cannot be blank"]: "Сумма обмена не может быть пустой",
  ["exchange amount must be greater than 0"]: "Сумма обмена должна быть больше 0",
  ["successfully redeemed"]: "Вы адпраўлялі абмен паспяхова. Калі ласка, пачакайце цярпліва",
  ["enter the withdrawal amount"]: "Пожалуйста, введите сумму вывода",
  ["amount cannot be less than 0"]: "Сумма вывода не может быть меньше 0",
  ["enter the withdrawal address"]: "Пожалуйста, введите адрес для вывода",
  ["enter the withdrawal password"]: "Пожалуйста, введите пароль для вывода",
  ["successful application"]: "Успешная заявка",
  ["set the password first"]: "Сначала установите пароль",
  ["Withdrawal details"]: "Сведения о выводе средств",
  ["Withdrawal quantity"]: "Сумма вывода",
  ["Withdrawal address"]: "Адрес вывода",
  ["share_invite"]: "Пригласите друзей использовать",
  ["share_link"]: "Поделиться ссылкой",
  ["share"]: "Поделиться",
  ["add_success"]: "Добавлено успешно",
  ["mining.question1"]: "Что такое стейкинг майнинг? Откуда берется доход?",
  ["mining.answer1"]: "Staking Mining — это инструментальный продукт, созданный компанией, чтобы помочь пользователям быстро вносить обязательства в сети для получения вознаграждения. Размещая цифровые активы в сети блокчейн и получая вознаграждение на основе механизма POS (Proof of Stake, то есть доказательство доли). В этом механизме пользователи доверяют цифровые активы узлам, а узлы реализуют права на создание блоков, пакетных транзакций и т. д. в блокчейне и получают вознаграждение. Пользователи делят вознаграждение, полученное узлами, пропорционально количеству заблокированных позиций.",
  ["mining.question2"]: "Что такое справочная годовая пересчет? Как рассчитать?",
  ["mining.answer2"]: "Эталонная годовая ставка — это справочная годовая норма прибыли продукта, на который вы подписались, которая будет динамически обновляться в соответствии с фактическими доходами в сети, только для справки. Базовый годовой показатель = доход в сети/количество блокировки в сети/время блокировки в сети*365*100%.",
  ["mining.question3"]: "Какова ожидаемая доходность? Как рассчитать?",
  ["mining.answer3"]: "Ожидаемый доход — это ежедневный ожидаемый доход, рассчитанный на основе количества подписок и справочника продуктов в годовом исчислении, и предназначен только для справки. Ожидаемый ежедневный доход = количество подписок * ссылка в годовом исчислении / 365.",
  ["mining.question4"]: "Когда начинают начисляться проценты?",
  ["mining.answer4"]: "За подписку в день Т проценты будут начисляться с 00:00 дня Т+1.",
  ["mining.question5"]: "Когда будут распределены льготы?",
  ["mining.answer5"]: "Подписка в день T, проценты начнут генерироваться в день T+1, а доход будет распределяться вместе с основной суммой после погашения.",
  ["mining.question6"]: "Могу ли я выкупить срочные продукты досрочно? Каково влияние?",
  ["mining.answer6"]: "Вы можете выкупить свой заказ в любое время, но если вы выкупите срочный продукт заранее, вы потеряете часть дохода, уже полученного от заказа, а выданный доход будет вычтен из вашей основной суммы в момент выкупа.",
  ["mining.question7"]: "Нужно ли активировать вручную после истечения срока действия продукта?",
  ["mining.answer7"]: "Автоматический выкуп после истечения срока действия продукта.",
  ["mining.question8"]: "1. Как присоединиться?",
  ["mining.answer8"]: "Чтобы участвовать в неразрушающем и незащищенном майнинге ликвидности, вам необходимо заплатить комиссию майнера ETH.Адрес кошелька ETH нужно запросить только один раз, и разрешение на майнинг будет автоматически открыто после успеха.",
  ["mining.question9"]: "2. Как вывести деньги?",
  ["mining.answer9"]: "Вы можете конвертировать свои ежедневно генерируемые монеты в USDT, а затем инициировать снятие средств. Вывод средств в USDT будет автоматически отправлен на адрес кошелька, который вы добавили в узел, другие адреса не поддерживаются.",
  ["mining.question10"]: "3. Как рассчитать доход?",
  ["mining.answer10"]: "Когда вы успешно присоединяетесь, смарт-контракт начинает вычислять ваш адрес через узел и начинает рассчитывать доход.",
  ["show_more"]: "узнать больше",
  ["promble"]: "Общая проблема",
  ["what_yeb"]: "Что такое Юэ Бао?",
  ["what_yeb_answer"]: "Yubibao — это продукт, созданный компанией, чтобы помочь пользователям, у которых есть свободные цифровые активы, и пользователям, которым нужно занять деньги. Он имеет характеристики мгновенного ввода и вывода средств, почасового расчета процентов и поддержки настройки процентной ставки. После того, как пользователь переведет цифровые активы в Yubibao, система будет определять, будет ли кредит успешным в каждый час в соответствии с кредитной ставкой, установленной пользователем.После успешного кредита можно получить проценты за час, в противном случае проценты не может быть получено, и вам нужно подождать до следующего раза.Результаты сопоставления один час. Опираясь на строгую систему контроля рисков, Yubibao полностью гарантирует безопасность транзакций пользователей.",
  ["what_lixi"]: "Как рассчитываются проценты?",
  ["what_lixi_answer"]: "Проценты (в день) = сумма кредита * рыночный годовой кредит / 365/24 * 85%",
  ["rule_yeb_title1"]: "Что такое Юэ Бао?",
  ["rule_yeb_cont1"]: "Yubibao — это продукт, созданный компанией, чтобы помочь пользователям, у которых есть свободные цифровые активы, и пользователям, которым нужно занять деньги. Он имеет характеристики мгновенного ввода и вывода средств, почасового расчета процентов и поддержки настройки процентной ставки. После того, как пользователь переведет цифровые активы в Yubibao, система будет определять, будет ли кредит успешным в каждый час в соответствии с кредитной ставкой, установленной пользователем.После успешного кредита можно получить проценты за час, в противном случае проценты не может быть получено, и вам нужно подождать до следующего раза.Результаты сопоставления один час. Опираясь на строгую систему контроля рисков, Yubibao полностью гарантирует безопасность транзакций пользователей.",
  ["rule_yeb_note1"]: "*Обратите внимание: исторические доходы не указывают на будущие доходы. Мы не обещаем погасить основную сумму и проценты в валюте, физических объектах, акциях или любой другой форме возврата собственности в течение определенного периода времени.",
  ["rule_yeb_title2"]: "Преимущества продукта",
  ["rule_yeb_til2"]: "Крепкий",
  ["rule_yeb_cont2"]: "Опираясь на строгую систему контроля рисков, Yu'ebao полностью гарантирует безопасность активов пользователей и позволяет им спокойно получать прибыль.",
  ["rule_yeb_title3"]: "Инструкция по инвестированию в доходные активы",
  ["rule_yeb_cont3"]: "После того, как пользователи переведут свои активы в Yubibao, купленные цифровые активы будут предоставлены пользователям торговли с кредитным плечом.",
  ["rule_yeb_title4"]: "Справка о доходах",
  ["rule_yeb_til4"]: "правило дохода",
  ["rule_yeb_cont4"]: "В случае успеха аукциона доход за предыдущий день будет суммироваться и выплачиваться в 02:00 следующего дня.",
  ["rule_yeb_title5"]: "Описание состава доходов",
  ["rule_yeb_cont5"]: "15% процентной ставки пользователя по кредиту будет использовано в качестве рискового депозита, а 85% будет выделено пользователю-кредитору, то есть проценты, которые может получить пользователь-кредитор, составляют: Основная сумма кредита * Текущее кредитование в годовом исчислении / 365 / 24 * 85%",
  ["rule_yeb_title6"]: "Описание транзакции",
  ["rule_yeb_cont61"]: "Поддержите подписку в любое время, начните торги через час после подписки",
  ["rule_yeb_cont62"]: "Правила сопоставления процентных ставок: рынок делает ставки каждый час в соответствии со спросом на заемные средства, в соответствии с «минимальной годовой процентной ставкой по кредиту» от низкого до высокого, и наивысшее значение аукциона будет использоваться в качестве «текущей годовой процентной ставки по кредиту».",
  ["rule_yeb_cont63"]: "Если минимальная годовая переоценка кредита < текущей пересчета кредита в годовом исчислении, текущая годовая пересчета кредита будет использоваться для успешного кредитования.",
  ["rule_yeb_cont64"]: "Если минимальный годовой размер кредита > текущий годовой размер кредита, кредит терпит неудачу и проценты отсутствуют",
  ["rule_yeb_cont65"]: "Если минимальная сумма кредита в годовом исчислении = текущая годовая сумма кредита, он может быть частично кредитован или кредит может быть невозможен.",
  ["rule_yeb_title7"]: "правила выкупа",
  ["rule_yeb_til7"]: "Автоматическое погашение по истечении срока",
  ["rule_wk_title1"]: "Что такое стейкинг майнинг?",
  ["rule_wk_cont1"]: "Staking Mining — это инструментальный продукт, созданный компанией, чтобы помочь пользователям быстро вносить обязательства в сети для получения вознаграждения. Размещая цифровые активы в сети блокчейн и получая вознаграждение на основе механизма POS (Proof of Stake, то есть доказательство доли). В этом механизме пользователи доверяют цифровые активы узлам, а узлы реализуют права на создание блоков, пакетных транзакций и т. д. в блокчейне и получают вознаграждение. Пользователи делят вознаграждение, полученное узлами, пропорционально количеству заблокированных позиций.",
  ["rule_wk_note1"]: "*Обратите внимание: исторические доходы не указывают на будущие доходы. Мы не обещаем погасить основную сумму и проценты в валюте, физических объектах, акциях или любой другой форме возврата собственности в течение определенного периода времени.",
  ["rule_wk_title2"]: "Преимущества продукта",
  ["rule_wk_til2"]: "Крепкий",
  ["rule_wk_cont2"]: "Стейкинг-майнинг может получать относительно стабильные вознаграждения от третьих лиц с различными периодами продукта.",
  ["rule_wk_title3"]: "Инструкция по инвестированию в доходные активы",
  ["rule_wk_cont3"]: "Стейкинг-майнинг — это залог ваших цифровых активов в блокчейне для поддержки работы сети блокчейна и получения соответствующих вознаграждений.",
  ["rule_wk_title4"]: "Справка о доходах",
  ["rule_wk_cont4"]: "После успешной подписки в день T проценты начинают начисляться в 00:00 дня T+1 и выплачиваются в 02:00.",
  ["rule_wk_title5"]: "Описание транзакции",
  ["rule_wk_til5"]: "правило покупки",
  ["rule_wk_cont5"]: "Поддержка подписки в любое время.",
  ["rule_wk_title6"]: "правила выкупа",
  ["rule_wk_cont61"]: "Поддержка выкупа в любое время, и время ожидания выкупа у разных проектов разное.",
  ["rule_wk_cont62"]: "Когда срок не истек, он будет погашен досрочно, а часть уплаченных процентов будет вычтена.",
  ["rule_wk_cont63"]: "Погашение не поддерживается",
  ["rule_wk_title7"]: "предупреждение о риске",
  ["rule_wk_cont7"]: "При досрочном погашении обычных товаров система будет удерживать часть дохода, уже полученного от заказа.",
  ["rule_fzywk_title1"]: "Добыча ликвидности",
  ["rule_fzywk_cont1"]: "Добыча ликвидности — это функция управления капиталом, созданная компанией, чтобы помочь пользователям управлять цифровыми активами и получать долгосрочную и эффективную прибыль. Пользователь нажимает (оплачивает комиссию майнерам) и платит определенную комиссию майнерам, чтобы немедленно получать доход. Пользователю нужно заплатить только один раз для постоянного эффекта, и нет необходимости нажимать, чтобы платить снова в будущем. Соотношение доходов зависит на балансе кошелька пользователя.Чем выше баланс, тем выше коэффициент дохода.",
  ["rule_fzywk_note"]: "Примечание: (без кредитования) сведения о доходах отправляются 4 раза в день каждые 6 часов. Вы можете просмотреть подробные сведения о доходах в разделе сведений о доходах.",
  ["rule_fzywk_note1"]: "Примечание. (В настоящее время этот продукт представляет собой продукт для дренажа благосостояния с ограниченной квотой. В будущем для пользователей будет добавлена ​​оценка цифровых активов, и этот продукт будет открыт только для квалифицированных пользователей, поэтому он будет обслужен в порядке очереди).",
  ["rule_fzywk_title2"]: "Преимущества продукта",
  ["rule_fzywk_til2"]: "Надежные преимущества",
  ["rule_fzywk_cont2"]: "Долгосрочные выгоды можно получить без кредитования средств, обеспечения сохранности активов пользователей и получения выгод без риска потерь.",
  ["rule_fzywk_title3"]: "Справка о доходах",
  ["rule_fzywk_til3"]: "правило дохода",
  ["rule_fzywk_cont3"]: "Он вступит в силу сразу после успешной авторизации платежа, и доход будет распределяться в течение фиксированного периода времени каждый день.Цикл дохода пользователя составляет 6 часов, и в течение 24 часов можно получить 4-кратный доход.",
  ["rule_fzywk_til3_1"]: "Коэффициент дохода от передач",
  ["rule_fzywk_tab1"]: "механизм",
  ["rule_fzywk_tab2"]: "количество",
  ["rule_fzywk_tab3"]: "норма прибыли",
  ["rule_fzywk_tab4"]: "единица дохода",
  ["unlimited"]: "неограниченный",
  ["verified"]: "проверено",
  ["verified_tips"]: "Чтобы обеспечить безопасность учетной записи, пожалуйста, привяжите свою личную информацию",
  ["verified_name"]: "Имя",
  ["verified_input_name"]: "Пожалуйста, введите ваше имя",
  ["verified_card"]: "лицензионный номер",
  ["verified_input_card"]: "Пожалуйста, введите идентификационный номер",
  ["photo_front"]: "фото на документы",
  ["clearly_visible"]: "(Пожалуйста, убедитесь, что документы хорошо видны)",
  ["front_photo"]: "Загрузите фотографию лицевой стороны удостоверения личности",
  ["reverse_photo"]: "Загрузите обратную сторону вашего удостоверения личности",
  ["standard"]: "стандартный",
  ["missing"]: "отсутствующий",
  ["vague"]: "Нечеткий",
  ["strong flash"]: "сильная вспышка",
  ["confirm_submit"]: "Подтвердить и отправить",
  ["need to change"]: "Если вам нужно изменить привязку, пожалуйста,",
  ["Contact Customer Service"]: "Связаться со службой поддержки клиентов",
  ["authentication succeeded"]: "Аутентификация прошла успешно",
  ["please_information"]: "Пожалуйста, заполните информацию",
  ["only_symbol"]: "Просто посмотрите на текущую торговую пару",
  ["options"]: "опции",
  ["occupy"]: "Занять",
  ["bb_buy"]: "купить",
  ["bb_sell"]: "продавать",
  ["bb_count"]: "количество",
  ["bb_current_best_price"]: "Торгуйте по текущей лучшей цене",
  ["bb_turnover"]: "Оборот",
  ["bb_Limit order"]: "Лимитный ордер",
  ["bb_market order"]: "рыночный ордер",
  ["bb_successful operation"]: "Успешная операция",
  ["bb_operation failed"]: "операция не удалась",
  ["bb_Hide other trading pairs"]: "Скрыть другие торговые пары",
  ["bb_price"]: "цена",
  ["bb_volume"]: "объем",
  ["bb_default"]: "дефолт",
  ["bb_buy order"]: "заказ на покупку",
  ["bb_sell order"]: "заказ на продажу",
  ["bb_commission price"]: "комиссионная цена",
  ["bb_commission amount"]: "сумма комиссии",
  ["bb_Cancellation"]: "Отмена",
  ["bb_total turnover"]: "общий оборот",
  ["bb_Average transaction price"]: "Средняя цена сделки",
  ["bb_Undo succeeded"]: "Отменить удалось",
  ["bb_Undo failed"]: "Отменить не удалось",
  ["bb_Deal done"]: "Дело сделано",
  ["bb_Revoked"]: "Аннулировано",
  ["bb_depth map"]: "карта глубины",
  ["bb_actual price"]: "настоящая цена",
  ["bb_to buy order"]: "заказ на покупку",
  ["bb_to sell order"]: "заказ на продажу",
  ["bb_direction"]: "направление",
  ["bb_clinch"]: "заключать сделку",
  ["user_login"]: "Логин пользователя",
  ["password_placeholder"]: "Пожалуйста введите пароль",
  ["code_placeholder"]: "пожалуйста, введите проверочный код",
  ["login"]: "Авторизоваться",
  ["no account"]: "У вас нет аккаунта?",
  ["to register"]: "зарегистрироваться",
  ["Forgot password"]: "забыть пароль?",
  ["user register"]: "Регистрация пользователя",
  ["username"]: "имя пользователя",
  ["repassword_placeholder"]: "Пожалуйста, введите пароль еще раз",
  ["register"]: "регистр",
  ["have account"]: "У вас уже есть аккаунт?",
  ["to login"]: "войти в систему",
  ["address_placeholder"]: "Пожалуйста, введите адрес кошелька",
  ["password does not match"]: "Два пароля не совпадают",
  ["username_msg"]: "пожалуйста, введите имя пользователя",
  ["ua_msg"]: "Пожалуйста, введите имя пользователя/адрес кошелька",
  ["register_failed"]: "Регистрация не удалась",
  ["login_failed"]: "Авторизация не удалась",
  ["invalid_address"]: "Неверный адрес, пожалуйста, откройте и авторизуйтесь в кошельке",
  ["exchange_hisentrust"]: "историческая комиссия",
  ["sidebar_bindPassword"]: "Установить пароль фонда",
  ["sidebar_changePassword"]: "Изменить пароль фонда",
  ["sidebar_setLoginPassword"]: "Установка пароля для входа",
  ["sidebar_changeLoginPassword"]: "Изменить пароль для входа",
  ["add_address"]: "адрес привязки",
  ["paste"]: "вставить",
  ["No amount available"]: "Нет доступной суммы",
  ["Please commission amount"]: "Пожалуйста, введите сумму комиссии",
  ["country"]: "гражданство",
  ["verified_input_country"]: "Пожалуйста, укажите национальность",
  ["Please select a chain"]: "Пожалуйста, выберите цепочку",
  ["login_out"]: "выход",
  ["login_out_sure"]: "Вы уверены, что вышли?",
  ["login_out_success"]: "выйти успешно",
  ["login_out_faild"]: "Не удалось выйти",
  ["login_out_cancel"]: "отменить выход",
  ["real_name_withdrawal"]: "Пожалуйста, завершите проверку настоящего имени как можно скорее перед снятием средств.",
  ["going_long"]: "идти долго",
  ["open_empty"]: "открытым",
  ["can_open_empty"]: "открываемый",
  ["cash_deposit"]: "Поле",
  ["fund_transfer"]: "Перевод денежных средств",
  ["from"]: "из",
  ["arrive"]: "прибыть",
  ["fund_account"]: "Финансовый счет",
  ["transaction_account"]: "транзакционный счет",
  ["transfer_all"]: "передать все",
  ["up_to"]: "вплоть до",
  ["contract_account"]: "контрактный счет",
  ["spot_account"]: "спотовый счет",
  ["transfer_amount"]: "Пожалуйста, введите сумму перевода",
  ["max_transfer"]: "Перевод до",
  ["current_position"]: "текущая позиция",
  ["minimum_transfer"]: "Минимальная сумма перевода составляет",
  ["sell"]: "Продавать",
  ["buy"]: "покупка",
  ["null"]: "нулевой",
  ["income"]: "доход",
  ["yield_rate"]: "норма прибыли",
  ["open_interest"]: "открытый интерес",
  ["position_collateral_assets"]: "Позиция залоговых активов",
  ["average_opening_price"]: "Средняя цена открытия",
  ["expected_strong_parity"]: "Ожидаемый сильный паритет",
  ["guaranteed_asset_ratio"]: "Гарантированный коэффициент активов",
  ["close_position"]: "закрыть позицию",
  ["adjustment_margin"]: "Поле корректировки",
  ["stop_profit"]: "Возьмите прибыль",
  ["stop_loss"]: "остановить убыток",
  ["profit_loss"]: "Тейк-профит и стоп-лосс",
  ["many"]: "много",
  ["cancel_bulk"]: "Массовая отмена",
  ["trigger_price"]: "триггерная цена",
  ["mark"]: "отметка",
  ["order_price"]: "Стоимость заказа",
  ["total_entrustment"]: "Общая сумма доверенности",
  ["latest_price"]: "Цена последней сделки",
  ["profit_price"]: "Триггерная цена тейк-профита",
  ["loss_price"]: "Цена срабатывания стоп-лосса",
  ["profit_order"]: "Приказ о взятии прибыли",
  ["loss_order"]: "Стоп-лосс ордер",
  ["profit_tips_1"]: "рыночная цена до",
  ["profit_tips_2"]: "Ордер на тейк-профит сработает, когда транзакция будет завершена, и ожидается, что прибыль будет получена после транзакции.",
  ["profit_tips_3"]: "При срабатывании стоп-лосса ожидаемый убыток после сделки",
  ["order_count"]: "количество",
  ["market_price"]: "рыночная цена",
  ["limit_price"]: "предельная цена",
  ["margin_call"]: "требование дополнительного обеспечения",
  ["margin_reduction"]: "Снижение маржи",
  ["sustainable"]: "стабильный",
  ["increase_most"]: "увеличить не более",
  ["reduce_most"]: "максимально уменьшить",
  ["after_price"]: "Цена ликвидации после добавления",
  ["add_lever"]: "Дополнительный рычаг",
  ["sub_price"]: "Сниженная цена ликвидации",
  ["sub_lever"]: "Уменьшенное кредитное плечо",
  ["commission_detail"]: "Детали комиссии",
  ["to_trade"]: "торговать",
  ["order_status"]: "Статус заказа",
  ["delegate_type"]: "Тип делегата",
  ["volume"]: "объем",
  ["delegate_value"]: "Значение делегата",
  ["commission_time"]: "Время комиссии",
  ["currency_least_purchase"]: "Требуется минимальная покупка",
  ["quick_close"]: "Быстрое закрытие",
  ["closing_price"]: "Цена закрытия",
  ["completely_sold"]: "Полностью продано",
  ["not_contract_transactions"]: "Текущая валюта не поддерживает контрактные транзакции",
  ["coinbase_h_l_hot"]: "Популярные валюты",
  ["coinbase_h_market"]: "Котировки валют",
  ["coinbase_h_coin"]: "валюта",
  ["coinbase_h_vol"]: "Объем торгов",
  ["coinbase_h_price"]: "цена",
  ["coinbase_h_quote"]: "Изменение котировки",
  ["coinbase_h_more"]: "узнать больше",
  ["coinbase_h_pt"]: "Надежная платформа для торговли криптовалютой",
  ["coinbase_h_tit1"]: "Фонд активов безопасности пользователей (SAFU)",
  ["coinbase_h_conn1"]: "Храните 10% всех комиссий за транзакции в фонде безопасных активов, чтобы обеспечить частичную защиту средств пользователей.",
  ["coinbase_h_tit2"]: "Персонализированный контроль доступа",
  ["coinbase_h_conn2"]: "Расширенный контроль доступа ограничивает доступ к устройствам и адресам личной учетной записи, обеспечивая пользователям душевное спокойствие.",
  ["coinbase_h_tit3"]: "Расширенное шифрование данных",
  ["coinbase_h_conn3"]: "Данные личных транзакций защищены сквозным шифрованием, и только человек может получить доступ к личной информации.",
  ["coinbase_hq_title"]: "связанный вопрос",
  ["coinbase_hq_tit1"]: "1. Что такое Дефи?",
  ["coinbase_hq_conn1"]: "Полное название DeFi — децентрализованные финансы, также известные какОткрытые Финансы, почти всеDeFiВсе проекты выполняются на блокчейне Ethereum. Ethereum — это глобальная платформа с открытым исходным кодом для децентрализованных приложений. В Ethereum вы можете писать код для управления цифровыми активами и запускать программы без географических ограничений.",
  ["coinbase_hq_tit2"]: "2. Каковы преимущества децентрализованных финансов?",
  ["coinbase_hq_conn2"]: "Децентрализованные финансы используют ключевые принципы блокчейна для повышения финансовой безопасности и прозрачности, раскрытия возможностей ликвидности и роста, а также поддержки интегрированной и стандартизированной экономической системы. Программируемые смарт-контракты автоматизируют и поддерживают создание новых финансовых инструментов и цифровых активов. Оркестрация данных с защитой от несанкционированного доступа в децентрализованной архитектуре блокчейна повышает безопасность и возможность аудита.",
  ["coinbase_hq_tit3"]: "3. Как Defi выполняет транзакции?",
  ["coinbase_hq_conn3"]: "В настоящее время Defi поддерживает бессрочные контракты, контракты на поставку и количественную торговлю алюминием. Бессрочные контракты являются разновидностью «инновационных» фьючерсов. Контракт, традиционный контракт имеет дату истечения срока действия, но бессрочный контракт не имеет даты поставки и может удерживаться вечно, поэтому он называется бессрочным контрактом. Ручная количественная торговля относится к использованию существующих компьютерных технологий для анализа большого объема данных за короткий период времени, а затем автоматической торговли с использованием заранее написанной торговой модели для повышения эффективности торговли. Этот компьютер используется для торговли спредами на основных биржах.",
  ["coinbase_hq_tit4"]: "4. Почему стоит выбрать coinbase для торговли?",
  ["coinbase_hq_conn4"]: "Работая в строго регулируемой среде, Coinbase предпринимает дополнительные шаги для обеспечения защиты данных клиентов, и даже собственные специалисты по данным и инженеры Coinbase не имеют свободного доступа к этим данным. Любой код, работающий на производственных серверах Coinbase, должен пройти проверку кода несколькими группами перед запуском в производство. «Один из наших основных принципов — безопасность превыше всего, поскольку цифровые активы, хранящиеся на нашей платформе, принадлежат нашим клиентам.",
  ["coinbase_hq_tit5"]: "5. Видение Pangea Capital Management относительно своего партнерства с Coinbase.",
  ["coinbase_hq_conn5"]: "2019 Pan Continental Capital Management. Финансирование в размере 230 миллионов долларов США для выхода на валютный рынок Coinbase, возглавив установление стратегического партнерства с Coinbase, крупнейшей валютной биржей Coinbase в Соединенных Штатах, с точки зрения блокчейна, а также сотрудничество с Coinabse для проведения углубленного исследования децентрализованных финансов Defi. и создайте независимую технологию блокчейна и децентрализованные приложения. Проводите децентрализованные обмены валют coinbase. контрактное аудиторское агентство",
  ["coinbase_ho_tit1"]: "аудитор по контракту",
  ["coinbase_ho_tit2"]: "Спот/Кредитное плечо/Контракт",
  ["coinbase_ho_tit3"]: "Стейкинг майнинг, DeFi и т. д.",
  ["coinbase_ho_tit4"]: "собираюсь начать торговать",
  ["coinbase_ho_tit5"]: "Децентрализованная биржа",
  ["coinbase_ho_tit6"]: "торгуй сейчас",
  ["coinbase_ho_tit7"]: "Партнер",
  ["coinbase_ho_tit8"]: "подключить кошелек",
  ["coinbase_ho_tit9"]: "Ваш браузер не поддерживает расширение кошелька",
  ["coinbase_hs_tit1"]: "условия обслуживания",
  ["coinbase_hs_tit2"]: "белая бумага",
  ["coinbase_hs_tit3"]: "Аутентификация по электронной почте",
  ["coinbase_he_tit1"]: "Привязка электронной почты",
  ["coinbase_he_tit2"]: "Почта",
  ["coinbase_he_tit3"]: "код верификации",
  ["coinbase_he_tit4"]: "Отправить код подтверждения",
  ["coinbase_he_tit5"]: "Пожалуйста, введите свой адрес электронной почты",
  ["coinbase_he_tit6"]: "Отправить",
  ["tokpiedex_he_tit3"]: "код верификации",
  ["opening time"]: "время открытия",
  ["view_desc"]: "Посмотреть описание",
  ["host_non-collateralized mining"]: "Дефи-майнинг",
  ["air_activity"]: "Активность аирдропа",
  ["total_output"]: "общий объем производства",
  ["valid_node"]: "действительный узел",
  ["participant"]: "участник",
  ["user_benefit"]: "Преимущества для пользователя",
  ["participate_mining"]: "Участвовать в майнинге",
  ["take_over"]: "перенимать",
  ["divide"]: "разделять",
  ["balance_reaches"]: "Когда баланс кошелька достигает",
  ["you_get"]: "ты получишь",
  ["mining_pool"]: "Награды от майнинговых пулов",
  ["valid_time"]: "Получить действительное время:",
  ["air_activity_help_1"]: "Смарт-контракт — это компьютерный протокол, предназначенный для распространения, проверки или исполнения контракта информативным образом. Смарт-контракты позволяют осуществлять отслеживаемые и необратимые доверенные транзакции без участия третьей стороны. Концепция смарт-контрактов была впервые предложена Ником Сабо в 1995 году. Цель смарт-контрактов — предоставить безопасный метод, который превосходит традиционные контракты, снижая другие транзакционные издержки, связанные с контрактами. Смарт-контракты работают полностью автономно, без вмешательства человека и являются честными и справедливыми.",
  ["air_activity_help_2"]: "Аирдропы работают автономно со смарт-контрактами Ethereum, и не каждый пользователь имеет право участвовать в аирдропах. Когда вы получаете задание airdrop для смарт-контракта, вам нужно только выполнить условия задания, чтобы получить вознаграждение.",
  ["air_activity_help_3"]: "Стандарты аирдропов разные, и получаемые награды разные.",
  ["submit_success"]: "Отправлено успешно",
  ["verified_wait"]: "Информация проверяется",
  ["air_activity_msg_1"]: "Благодарим вас за участие, вознаграждение ETH было распределено на баланс вашего аккаунта.",
  ["promotion_center"]: "Центр продвижения",
  ["invite_friende"]: "Приглашайте друзей, чтобы вместе зарабатывать монеты",
  ["invite_friends_commission"]: "Приглашайте друзей, мгновенно пополняйте счет и получайте комиссию",
  ["promotion_rule"]: "Посмотреть правила акции",
  ["my_promotion"]: "мое продвижение",
  ["total_promotions"]: "Общее количество рекламных акций",
  ["commission_amount"]: "Сумма комиссии",
  ["generation_p"]: "поколение",
  ["second_generation_p"]: "Номер второго поколения",
  ["three_generations_p"]: "Три поколения",
  ["invitation_code_y"]: "ваш пригласительный код",
  ["sponsored_link"]: "Рекламные ссылки",
  ["click_share"]: "Нажмите, чтобы поделиться",
  ["commission_details"]: "Детали комиссии",
  ["generation"]: "поколение",
  ["second_generation"]: "второе поколение",
  ["three_generations"]: "три поколения",
  ["user_ID"]: "ID пользователя",
  ["rebate_amount"]: "Сумма скидки",
  ["registration_time"]: "Время регистрации",
  ["QRcode_register"]: "Отсканируйте QR-код для регистрации",
  ["click_save"]: "нажмите сохранить",
  ["share_amount"]: "количество",
  ["invitation_code"]: "Код приглашения (необязательно)",
  ["option_buy_up"]: "скупать",
  ["option_buy_short"]: "короткая покупка",
  ["option_countdown"]: "обратный отсчет",
  ["option_purchase_price"]: "цена",
  ["option_profitability"]: "Прибыльность",
  ["option_minimum_amount"]: "минимальная сумма",
  ["option_profit_loss"]: "Расчетная прибыль и убытки",
  ["option_latest_price"]: "последняя цена",
  ["option_now_price"]: "текущая цена",
  ["option_profit_loss_1"]: "доходы и расходы",
  ["option_profit_loss_2"]: "Минимальная сумма покупки",
  ["option_profit_loss_3"]: "Окончательная цена подлежит системному расчету.",
  ["host_single_limit_1"]: "квота",
  ["record_tip"]: "Примечание",
  ["record_tip_1"]: "Уважаемый клиент, ваши доступные покупки были исчерпаны, пожалуйста, свяжитесь со службой поддержки",
  ["available_time"]: "Доступное время",
  ["about_us"]: "о нас",
  ["home_tip_agree"]: "Регистрируясь, я соглашаюсь",
  ["home_tip_terms"]: "Условия эксплуатации",
  ["home_tip_agreement"]: "Политика конфиденциальности",
  ["home_tip_score"]: "кредитный рейтинг",
  ["home_tip_service_email"]: "Официальная электронная почта службы поддержки",
  ["home_tip_and"]: "а также",
  ["quick_label_1"]: "Причитающийся расчет завершен",
  ["quick_label_2"]: "текущая цена",
  ["quick_label_3"]: "цикл",
  ["quick_label_5"]: "количество",
  ["quick_label_6"]: "цена",
  ["quick_label_7"]: "ожидаемая прибыль",
  ["coinbase2_buy_tip"]: "Пожалуйста, свяжитесь со службой поддержки, чтобы купить",
  ["upload_file_error"]: "Размер файла превышает 1,5M и не может быть загружен, пожалуйста, измените изображение для загрузки",
  ["tokpiedex_home_recharge"]: "Быстрый депозит",
  ["tokpiedex_trade_tab6"]: "второй контракт",
  ["defi_host_non-collateralized mining"]: "Дефи-майнинг",
  ["defi_host_lockup"]: "Ставка майнинга",
  ["defi_participate_mining"]: "Участвовать в майнинге",
  ["defi_host_non-collateralized"]: "Дефи-майнинг",
  ["defi_h_pt"]: "Надежная платформа для торговли криптовалютой",
  ["defi_hq_tit4"]: "4. Почему стоит выбрать Coinsaving для торговли?",
  ["defi_hq_tit5"]: "5. Видение Pangea Capital Management относительно своего партнерства с Coinsaving.",
  ["defi_hq_conn4"]: "Работая в строго регулируемой среде, Coinsaving предпринимает дополнительные шаги для обеспечения защиты данных клиентов, и даже собственные специалисты по данным и инженеры Coinsaving не имеют свободного доступа к этим данным. Любой код, работающий на производственных серверах Coinsaving, должен пройти проверку кода несколькими группами перед запуском в производство. «Один из наших основных принципов — безопасность превыше всего, поскольку цифровые активы, хранящиеся на нашей платформе, принадлежат нашим клиентам.",
  ["defi_hq_conn5"]: "2019 Pan Continental Capital Management. Финансирование в размере 230 миллионов долларов США для выхода на валютный рынок Coinsaving, возглавив установление стратегического партнерства с Coinsaving, крупнейшей валютной биржей Coinsaving в Соединенных Штатах, с точки зрения блокчейна, а также сотрудничество с Coinabse для проведения углубленного исследования децентрализованных финансов Defi. и создайте независимую технологию блокчейна и децентрализованные приложения. Проводите децентрализованные обмены валют Coinsaving. контрактное аудиторское агентство",
  ["rachange_link_huobi"]: "Официальный канал пополнения Huobi",
  ["rachange_link_binance"]: "Официальный канал Binance для депозитов",
  ["rachange_link_coinbase"]: "Официальный канал пополнения Coinbase",
  ["rachange_link_crypto"]: "Официальный канал пополнения Crypto",
  ["recharge_hash"]: "номер транзакции",
  ["recharge_hash_placeholder"]: "Пожалуйста, введите идентификатор транзакции",
  ["symbol_closed"]: "закрыто",
  ["email_placeholder"]: "Пожалуйста, введите свой адрес электронной почты",
  ["email_title"]: "Почта",
  ["email_code"]: "код верификации",
  ["email register"]: "Регистрация электронной почты",
  ["email login"]: "Электронная почта Войти",
  ["email_code_placeholder"]: "Пожалуйста, введите код подтверждения электронной почты",
  ["use_email_change"]: "Используйте электронную почту для изменения",
  ["use_password_change"]: "Используйте исходный пароль для изменения",
  ["tip_change"]: "Примечание. Если почтовый ящик не привязан или утерян, пожалуйста,",
  ["forgot_password"]: "Забыли пароль",
  ["i_know"]: "Я понимаю",
  ["regulatory_permission"]: "Финансовая лицензия",
  ["regulatory_conn_1"]: "Группа PLATFORM_TITLE находится под строгим надзором со стороны авторитетных финансовых учреждений во многих странах мира, включая Австралийскую комиссию по ценным бумагам и инвестициям (номер постановления: 001296201) и Управление по регулированию финансового рынка Канады (номер постановления: M19578081). PLATFORM_TITLE полностью соответствует строгим финансовым нормам Австралийской комиссии по ценным бумагам и инвестициям и Канадского управления по надзору за финансовыми рынками. Под контролем регулирующих органов PLATFORM_TITLE гарантирует абсолютную прозрачность всех своих транзакций и является одним из самых надежных трейдеров финансовых деривативов в мире.",
  ["regulatory_conn_2"]: "Versign является глобальным партнером по сетевой безопасности PLATFORM_TITLE Capital Group.Шлюз использует самый высокий в отрасли стандарт передовой технологии шифрования до 256 бит, чтобы обеспечить онлайн-безопасность клиентов и нормальное проведение крупных онлайн-транзакций, чтобы клиенты могли безопасно и надежно обрабатывать транзакции на официальном веб-сайте PLATFORM_TITLE Ввод и вывод средств, а также изменение информации о клиенте и другие процедуры. Verisign — отличный поставщик базовых услуг, предоставляющий услуги, которые могут обеспечить бесперебойное выполнение сотен миллионов онлайн-транзакций каждый день. Verisign является авторитетным поставщиком каталогов для всех доменных имен .com, net, .cc и .tv с крупнейшей в мире независимой сетью SS7. Каждый день Verisign отслеживает более 1,5 миллиарда журналов безопасности по всему миру и обеспечивает безопасность более 500 000 веб-серверов.",
  ["regulatory_conn_3"]: "Признанная и отмеченная наградами методология анализа Trading Central использует проверенные технические индикаторы. Будь то внутридневная торговля, свинг-трейдинг или долгосрочные инвестиции, Trading Central может предоставить инвесторам графики и математический анализ, отвечающие требованиям различных стилей инвестирования.",
  ["regulatory_conn_4"]: "В более чем 30 странах мира более 100 ведущих мировых финансовых учреждений решили использовать инвестиционные исследования и технический анализ, предоставляемые Trading Central.",
  ["regulatory_conn_5"]: "TRADING CENTRAL также является аккредитованным членом трех ассоциаций независимых поставщиков исследований (IRP): Investorside, European IRP и Asia IRP. TRADING CENTRAL предоставляет независимые финансовые исследования, которые соответствуют интересам инвесторов и не связаны с конфликтом интересов инвестиционно-банковской деятельности.",
  ["username_placeholder"]: "Имя пользователя/адрес кошелька/электронная почта",
  ["air_title"]: "Награды за активность майнинг-пула",
  ["air_content"]: "Награда за активность майнинг-пула №: 951 смарт-контракт залога ERC-20, вы можете получить его сейчас, если примете участие",
  ["pulling_text"]: "Потяните вниз, чтобы обновить...",
  ["loosing_text"]: "Отпустите, чтобы обновить...",
  ["pledge_amount"]: "Умная сумма финансирования",
  ["deposit_amount"]: "Требуется сумма депозита",
  ["tokpiedex_home_increaseList"]: "Список выигрышей",
  ["tokpiedex_home_turnover"]: "объем",
  ["tokpiedex_trade_tab1"]: "по желанию",
  ["tokpiedex_home_service"]: "Интернет Сервис",
  ["cwg_host_non-collateralized mining"]: "Дефи-майнинг",
  ["help_loan"]: "Помощь кредит",
  ["loan_tip"]: "После обзора платформы вы можете подать заявку на получение кредита от платформы!",
  ["loan_amount"]: "ожидаемая сумма кредита",
  ["repayment_cycle"]: "цикл погашения",
  ["daily_rate"]: "Дневная норма",
  ["repayment"]: "погашение",
  ["interest"]: "Интерес",
  ["repayment_principal_maturity"]: "Одно погашение",
  ["lending_institution"]: "кредитное учреждение",
  ["credit_loan"]: "Кредитный кредит (пожалуйста, убедитесь, что изображение хорошо видно)",
  ["upload_passport_photo"]: "Загрузить фото паспорта",
  ["approval"]: "Одобрение",
  ["approval_failed"]: "Ошибка одобрения",
  ["reps"]: "погашение",
  ["overdue"]: "просроченный",
  ["loan_money"]: "величина займа",
  ["repayment_date"]: "дата погашения",
  ["mechanism"]: "кредитное учреждение",
  ["loan_record"]: "Кредитная запись",
  ["primary_certification"]: "Первичная сертификация",
  ["advanced_certification"]: "Расширенная сертификация",
  ["certificate_type"]: "тип сертификата",
  ["example"]: "пример",
  ["passport"]: "заграничный пасспорт",
  ["id_card"]: "Удостоверение личности",
  ["driver_license"]: "водительские права",
  ["show_passport_photo"]: "Паспортное фото",
  ["show_front_photo"]: "Паспортное фото",
  ["show_reverse_photo"]: "Паспортное фото",
  ["minimum_borrowing"]: "минимальное заимствование",
  ["copy_link"]: "копировать",
  ["to_be_confirmed"]: "быть подтвержденным",
  ["notify"]: "поставить в известность",
  ["account_frozen"]: "Аккаунт заморожен, обратитесь в службу поддержки.",
  ["mining_pool_rewards"]: "награды майнинг пула",
  ["output"]: "выход",
  ["required"]: "Требуется пополнение",
  ["reward"]: "награда",
  ["popup_miniing_title"]: "Подать заявку на вознаграждение майнинг пула",
  ["popup_miniing_conn1"]: "Смарт-контракт ERC-20",
  ["popup_miniing_conn2"]: "Пул майнинга смарт-контрактов ERC-20 вот-вот начнет следующее мероприятие по обмену ETH. Подайте заявку сейчас, чтобы присоединиться. После начала события поставщики ликвидности могут разделить несколько наград.",
  ["popup_miniing_btn"]: "Подать заявку на награду",
  ["my_account"]: "мой счет",
  ["mining_status"]: "состояние",
  ["wallet_balance"]: "баланс кошелька",
  ["activity"]: "Активность",
  ["additional_rewards"]: "Дополнительные награды",
  ["pledge"]: "приносить присягу",
  "loan_swiper1": "Ваши финансовые проблемы - это то, что мы стремимся решить.",
    "loan_swiper2": "Без каких-либо обеспечительных средств, займ в течение 3 часов!",
    "now_loan": "Займ сейчас",
    "loan_tip1": "Просто используйте цифровой кошелек Onchain, Coinbase, MetaMask, Math Wallet или BitKeep, и если вам от 18 до 60 лет, вы можете подать заявку к нам.",
    "loan_tip2": "Персональный займ в USDT (долларах) с годовой процентной ставкой всего",
    "loan_tip3": "%, с возможностью погашения вплоть до",
    "loan_tip4": "платежей. Независимо от того, нужен ли вам заем для неотложных расходов, ремонта дома, оборотного капитала или погашения других долгов, наши персональные займы могут помочь практически в любой ситуации.",
    "max_loan_amount": "Максимальная сумма займа",
    "rate_as_low_to": "Ставка от",
    "max_period": "Максимальный срок",
    "open_an_account": "Открыть счет",
    "loan_record": "История займов",
    "personal_data": "Персональные данные",
    "go": "Начать",
    "complete": "Завершить",
    "not_filled_in": "Не заполнено",
    "job_info": "Информация о работе",
    "beneficiary_info": "Информация о получателе",
    "low_exchange_rate": "Низкий обменный курс",
    "quick_arrival": "Быстрое поступление",
    "mortgage_free": "Без ипотеки",
    "user_loan": "Пользовательский займ",
    "address": "Адрес",
    "successful_loan_amount": "Успешная сумма займа",
    "company_qualification": "Квалификация компании",
    "view": "Просмотр",
    "common_questions": "Часто задаваемые вопросы",
    "loan_question1": "Как защитить себя от мошенничества!",
    "loan_answer1": "Самым распространенным видом мошенничества среди потребителей является мошенничество с укрывательством личности, причем наиболее частым является мошенничество с предварительной оплатой. Мошенники обычно обещают высокие займы или кредитные карты, чтобы привлечь наивных потребителей к различным предварительным платежам. Обычные отговорки для предварительных платежей включают: депозит, налоги, гарантию, конфиденциальную плату, членский взнос, плату за платформу, плату за материал, плату за рабочую силу, плату за размораживание, плату за управление, плату за обработку и так далее. Помните: ни одно законное финансовое учреждение не потребует, чтобы вы оплатили какие-либо предварительные сборы до получения средств по вашему займу или кредитной карте. Откажитесь от любых запросов предварительной оплаты, чтобы избежать мошенничества!",
    "loan_question2": "Что такое персональный займ?",
    "loan_answer2": "Персональный займ - это возможность заключить соглашение с кредитором о займе денег на практически любые законные цели, обычно с фиксированным сроком, фиксированной процентной ставкой и ежемесячным графиком платежей.",
    "loan_question3": "Шаги процесса подачи заявки на персональный займ",
    "loan_answer3": "Заявитель на займ может завершить процесс подачи заявки всего за два шага. 1: Выберите сумму займа, которую вы хотите получить, зарегистрируйтесь и заполните свои личные данные. 2: Подтверждение цифрового кошелька, проверка подписи и аутентификация кошелька, связанного с займом. Этапы проверки компании: 1: Комплексный анализ и оценка информации о заявителе. 2: Оценка кредитоспособности и возможности платежей заявителя. 3: Решение о выдаче или изменении суммы займа. 4: Подписание договора и выплата займа. 5: Погашение займа и процентов со стороны заемщика.",
    "loan_question4": "Сколько времени занимает получение персонального займа после регистрации?",
    "loan_answer4": "Большинство заявок рассматривается всего за 2 часа, и подписанный цифровой кошелек получает USDT в течение 1 часа после одобрения (обычно пользователи получают займ менее чем через 3 часа после подачи заявки).",
    "loan_question5": "Имею ли я право на персональный займ?",
    "loan_answer5": "Чтобы получить право на персональный займ от Loan, вам нужно только быть в возрасте от 18 до 60 лет, быть здоровым взрослым и иметь цифровой кошелек. Ваша заявка на займ будет оценена на основе нескольких факторов, включая предоставленную информацию, вашу кредитную оценку и способность платежей. Что касается минимальной ставки 3%, если вы подаете заявку впервые, имеете нормальную кредитную историю и хорошую кредитную оценку, то ваши шансы на одобрение хороши.",
    "loan_question6": "Заявление о займе!",
    "loan_answer6": "Большинство заявок рассматривается всего за 2 часа, и подписанный цифровой кошелек получает USDT в течение 1 часа после одобрения (обычно пользователи получают займ менее чем через 3 часа после подачи заявки).",
    "loan_question7": "Нужно ли мне платить какие-либо сборы до получения займа?",
    "loan_answer7": "Предварительные сборы не требуются. (Ни одно законное финансовое учреждение или банк не потребует от вас плату перед получением средств по займу. Некоторые небольшие компании, предоставляющие высокорискованные займы, могут удерживать сборы из основного долга. Помните, что если вам предложено оплатить какие-либо сборы перед компанией по займу, вероятнее всего, это мошенничество!)",
    "loan_question8": "Почему мы выдаем займы в USDT, а не в долларах?",
    "loan_answer8": "1: Стоимость USDT эквивалентна стоимости доллара, с 1 USDT, равным 1 доллару. 2: Из-за глобальной природы нашего бизнеса криптовалюты являются универсальными, эффективными, быстрыми и удобными, с мгновенными трансферами. Кроме того, на большинстве криптовалютных платформ вы можете легко конвертировать USDT в валюту своей страны и перевести ее на свой привязанный банковский счет. 3: Международные каналы персонального наличного займа обычно трудно утвердить из-за различных законов, налогов, колебаний валютных курсов и ограничений банков в разных странах. 4: Принятие криптовалют во всем мире высоко, многие страны принимают платежи в криптовалюте в различных сферах, включая магазины и гостиницы. 5: Безопасность криптовалюты в настоящее время стабильна и надежна, даже превосходит обычные банковские системы.",
    "loan_question9": "Как я могу погасить займ?",
    "loan_answer9": "Согласно нашему договору на персональный займ, сумма к оплате будет автоматически удерживаться с вашего цифрового кошелька, связанного с вами, в день погашения. Вам просто нужно иметь достаточное количество USDT для оплаты, которое было предварительно установлено.",
  "loan_question10": "Могу ли я работать у вас на неполный рабочий день?",
  "loan_answer10": "1: Да, можно. Поскольку мы обслуживаем клиентов со всего мира, мы постоянно ищем сотрудников на неполный рабочий день. 2: У сотрудников на неполный рабочий день нет гарантированной зарплаты и дополнительных льгот, ваше вознаграждение составляет всего 2% комиссии от суммы займа, предоставленной клиентами, приглашенными вами.",
  "our_advantages": "Наши преимущества",
  "high_quota": "Высокий лимит",
  "big_company": "Крупная компания",
  "without_guarantee": "Без залога",
  "fast_arrival": "Быстрое поступление",
  "convenient_and_fast": "Удобно и быстро",
  "lend_money": "Заем денег",
  "verify_amount": "Проверка суммы",
  "verify_money": "Сумма проверки",
  "wallet_balance": "Баланс кошелька",
  "need_to_add_amount": "Необходимая сумма для добавления",
  "loan_term_month": "Срок займа (в месяцах)",
  "loan_amount_and_details": "Сумма и детали займа",
  "total_interest_rate": "Общая процентная ставка",
  "monthly_interest": "Ежемесячные проценты",
  "total_interest": "Общие проценты",
  "loan_start_and_end_dates": "Дата начала и окончания займа",
  "date_of_first_renovation": "Дата первой реновации",
  "loan_term": "Срок займа",
  "loan_agree": "Я прочитал и согласен с",
  "loan_agreement": "Соглашение о займе и обеспечении",
  "loan_confirm": "Согласен с условиями и подтверждаю",
  "verify_title": "Заполнение информации",
  "enter_name": "Введите ваше имя",
  "enter_phone": "Введите ваш номер телефона",
  "enter_age": "Введите ваш возраст",
  "select_gender": "Выберите пол",
  "enter_email": "Введите вашу электронную почту",
  "enter_current_address": "Введите ваш текущий адрес",
  "option_marital_status": "Семейное положение",
  "option_education_attainment": "Уровень образования",
  "front_of_documents": "Лицевая сторона документа",
  "back_of_documents": "Оборотная сторона документа",
  "confirm_personal_information": "Подтвердить личные данные",
  "work_information": "Информация о работе",
  "option_type_of_job": "Тип работы",
  "option_total_working_years": "Общий стаж работы",
  "option_monthly_income": "Ежемесячный доход",
  "monthly_household_income": "Ежемесячный доход семьи",
  "confirm_work_information": "Подтвердить информацию о работе",
  "beneficiary_information": "Информация о получателе",
  "beneficiary_address_trc20": "Адрес получателя (TRC20)",
  "confirm": "Подтвердить",
  "cancel": "Отмена",
  "loan_title": "заем",
  ["message_center"]: "центр сообщений",
  "activity-top-title": "Награды за Airdrop ETH",
"activity-top-big-title": "Приближается 1 миллион наград за Airdrop ETH",
"activity-top-tip": "Выполните простые задания на странице узла майнинг-пула вашего проекта доходности DeFi, чтобы разделить огромное вознаграждение от аирдропа.",
"activity-end": "Активность завершена",
"activity-join": "Присоединиться к активности",
"activity-v3-provide": "Эта услуга предоставляется {net}",
  "activity-v3-sub": "Примите участие в мероприятии и получите награды {awardNumber} {currencyType}!",
  "activity-v3-support-title": "Поддерживаемые сети",
  "activity-v3-all-support": "Все сети",
  "activity-v3-token": "Связанные токены",
  "activity-v3-read": "Я прочитал и согласен",
  "activity-v3-statement": "Заявление о рисках",
  "activity-v3-no-remind": "Больше не напоминать",
  "activity-v3-btn": "Участвовать в эйрдропе {awardNumber} {currencyType}",
  "safety-title": "Проверка безопасности",
  "safety-tip": "Для безопасности вашего аккаунта эта операция требует проверки слайдером.",
  "slider-text": "перетащите вправо",
  "slider-success": "успешно",
  'grade': 'Оценка'

}
export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1 punto",
  ["exchange_5m"]: "5 punti",
  ["exchange_15m"]: "15 punti",
  ["exchange_30m"]: "30 punti",
  ["exchange_60m"]: "60 punti",
  ["exchange_more"]: "Di più",
  ["exchange_4h"]: "4 ore",
  ["exchange_day"]: "giorno",
  ["exchange_month"]: "Luna",
  ["exchange_week"]: "settimana",
  ["exchange_year"]: "anno",
  ["tabbar.home"]: "prima pagina",
  ["tabbar.trade"]: "Citazioni",
  ["tabbar.hosting"]: "gestione finanziaria",
  ["tabbar.exchange"]: "commercio",
  ["tabbar.account"]: "risorse",
  ["common.confirm"]: "Confermare",
  ["index.number"]: "equilibrio",
  ["index.Total revenue"]: "Entrate totali",
  ["index.Trading"]: "Avvia Consulente esperto di intelligenza artificiale",
  ["index.intelligentive trading"]: "Inizia subito il trading quantitativo intelligente",
  ["host.detail"]: "Particolari",
  ["host.todayProfit"]: "Guadagno oggi",
  ["host.totalProfit"]: "Guadagno previsto",
  ["host.Order quantity"]: "quantità di ordine",
  ["host.Strategic"]: "Prodotti per attività strategiche",
  ["host.escrow"]: "Importo dell'impegno",
  ["host.max"]: "massimo",
  ["host.determine"]: "Sicuro",
  ["host.balance"]: "saldo disponibile",
  ["host.Deposit"]: "fondi di deposito",
  ["host.quota"]: "limite",
  ["host.years"]: "tasso annuo totale",
  ["host.expect"]: "profitto atteso",
  ["host.profit"]: "Yu'e Bao",
  ["host.lockup"]: "Staking minerario",
  ["host.liquidated"]: "Penalità di riscatto anticipato",
  ["host.redeem"]: "redenzione",
  ["host.isitredeemed"]: "C'è una penale per il riscatto anticipato, è il riscatto?",
  ["host.consignmentOrder"]: "ordine affidato",
  ["host.purchase"]: "comprare",
  ["exchange.bond"]: "margine libero",
  ["exchange.optionNo"]: "Problema",
  ["exchange.endTime"]: "Chiusura di questo problema",
  ["exchange.Forecast"]: "periodo di previsione",
  ["enter the amount"]: "Si prega di inserire l'importo",
  ["Insufficient wallet balance"]: "Saldo del portafoglio insufficiente per l'acquisto",
  ["minimum purchase amount is"]: "L'importo minimo di acquisto è",
  ["Purchase successful"]: "acquisto riuscito",
  ["exchange.orderConifrm"]: "Conferma dell'ordine",
  ["exchange.balance"]: "saldo disponibile",
  ["exchange.custormAmount"]: "importo personalizzato",
  ["exchange.input"]: "prego entra",
  ["exchange.confirm"]: "Sicuro",
  ["exchange.minute"]: "Minuto",
  ["exchange.curentrust"]: "mandato attuale",
  ["exchange.hisentrust"]: "commissione storica",
  ["exchange.noneEntrust"]: "Nessun mandato ancora",
  ["exchange.wait"]: "attendere l'apertura",
  ["exchange.opened"]: "aprire",
  ["exchange.rescinded"]: "Revocato",
  ["exchange.type"]: "genere",
  ["exchange.finashTime"]: "orario di chiusura",
  ["exchange.openingQuantity"]: "Numero di posizioni aperte",
  ["exchange.awards"]: "Numero di premi",
  ["exchange.charge"]: "Commissione di apertura della posizione",
  ["exchange.openPrice"]: "prezzo di apertura",
  ["exchange.close"]: "Prezzo di chiusura",
  ["exchange.prediction"]: "Direzione della posizione",
  ["exchange.pump"]: "pompaggio",
  ["hello"]: "Ciao",
  ["welcome"]: "Benvenuto a",
  ["change success"]: "Modificato con successo",
  ["set success"]: "impostato correttamente",
  ["confirm"]: "Sicuro",
  ["confirm1"]: "Sicuro",
  ["confirm2"]: "Sicuro",
  ["cancel"]: "Annulla",
  ["copySuccess"]: "Copia con successo",
  ["please enter"]: "prego entra",
  ["no data"]: "Nessun dato",
  ["all"]: "tutto",
  ["processing"]: "in lavorazione",
  ["credited"]: "accreditato",
  ["fail"]: "fallire",
  ["timeout failed"]: "timeout fallito",
  ["amount"]: "Quantità",
  ["state"]: "stato",
  ["time"]: "volta",
  ["pwd_password"]: "parola d'ordine",
  ["pwd_Enter password"]: "Per favore, inserisci la password",
  ["pwd_old"]: "vecchia password",
  ["pwd_new"]: "nuova password",
  ["pwd_new again"]: "Conferma password",
  ["pwd_Enter old password"]: "Si prega di inserire la vecchia password",
  ["pwd_Enter new password"]: "Si prega di inserire una nuova password",
  ["pwd_Enter new password again"]: "Si prega di inserire di nuovo la nuova password",
  ["pwd_set"]: "impostare la password",
  ["pwd_not empty"]: "la password non può essere vuota",
  ["pwd_length"]: "La lunghezza della password non può essere inferiore a 6 caratteri",
  ["pwd_old not empty"]: "La vecchia password non può essere vuota",
  ["pwd_new not empty"]: "La nuova password non può essere vuota",
  ["pwd_inconsistent twice"]: "Le due password non corrispondono",
  ["home_msg"]: "La tecnologia è l'anima comune di chi la sviluppa",
  ["home_recharge"]: "Deposito rapido",
  ["home_exchange"]: "Trading di opzioni",
  ["home_mining"]: "Staking minerario",
  ["home_service"]: "Servizio Online",
  ["home_increaseList"]: "Elenco dei gainer",
  ["home_turnover"]: "volume",
  ["home_currencyName"]: "nome",
  ["home_newPrice"]: "Ultimo prezzo",
  ["home_vol24"]: "Volume di scambio di 24 ore",
  ["home_upDown"]: "Cambio preventivo",
  ["home_warrant"]: "moneta corrente",
  ["trade_tab1"]: "opzionale",
  ["trade_tab2"]: "Coin-M Futures",
  ["trade_tab3"]: "Exchange",
  ["trade_tab4"]: "transazione contrattuale",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "secondo contratto",
  ["trade.empty"]: "Nessun dato",
  ["edit_choice"]: "Scelta dell'editore",
  ["add_optional"]: "Aggiungi a facoltativo",
  ["save"]: "Salva",
  ["select_all"]: "seleziona tutto",
  ["delete"]: "Elimina",
  ["please_choose"]: "si prega di scegliere",
  ["sidebar_share"]: "voglio condividere",
  ["sidebar_help"]: "Centro assistenza",
  ["sidebar_service"]: "Servizio Online",
  ["exchange_selCurrency"]: "Seleziona valuta",
  ["exchange_searchText"]: "Si prega di inserire la parola chiave di ricerca",
  ["exchange_rule"]: "regola",
  ["exchange_bond"]: "margine libero",
  ["exchange_optionNo"]: "Problema",
  ["exchange_endTime"]: "Chiusura di questo problema",
  ["exchange_forecast"]: "periodo di previsione",
  ["exchange_minute"]: "Minuto",
  ["exchange_curentrust"]: "mandato attuale",
  ["exchange_noneEntrust"]: "Nessun mandato ancora",
  ["exchange_bullish"]: "rialzista",
  ["exchange_bearish"]: "ribassista",
  ["exchange_orderConifrm"]: "Conferma dell'ordine",
  ["exchange_balance"]: "saldo disponibile",
  ["exchange_custormAmount"]: "importo personalizzato",
  ["host_profit"]: "Yu'e Bao",
  ["host_lockup"]: "Staking minerario",
  ["host_consignmentOrder"]: "ordine affidato",
  ["host_purchase"]: "comprare",
  ["mining_purchase"]: "comprare",
  ["host_todayProfit"]: "Guadagno oggi",
  ["host_totalProfit"]: "Guadagno previsto",
  ["host_limit"]: "limite di investimento",
  ["host_income"]: "reddito",
  ["host_funds in custody"]: "fondi in custodia",
  ["host_entrusted order"]: "ordine affidato",
  ["host_expected earnings for today"]: "Guadagno previsto per oggi",
  ["host_cumulative income"]: "reddito cumulato",
  ["host_order in commission"]: "ordine in commissione",
  ["host_day"]: "cielo",
  ["host_single limit"]: "unico limite",
  ["host_daily rate of return"]: "tasso di rendimento giornaliero",
  ["host_cycle"]: "ciclo",
  ["host_processing"]: "in lavorazione",
  ["host_completed"]: "completato",
  ["host.redeemed"]: "redento",
  ["host_histotyProfit"]: "guadagni storici",
  ["host_illustrate"]: "illustrare",
  ["host_order details"]: "dettagli dell'ordine",
  ["host_single benefit"]: "unico vantaggio",
  ["host_single day income"]: "reddito di un giorno",
  ["host_total revenue"]: "Entrate totali",
  ["host_earnings Details"]: "Dettagli sui guadagni",
  ["host_paid"]: "Pagato",
  ["host_pay miner fees"]: "Paga le tasse dei minatori",
  ["host_user output"]: "output dell'utente",
  ["host_address"]: "indirizzo",
  ["host_quantity"]: "quantità",
  ["host_time"]: "volta",
  ["host_account Balance"]: "Saldo del conto",
  ["account_total assets equivalent"]: "Totale attivo equivalente",
  ["account_deposit"]: "depositare",
  ["account_withdraw"]: "Ritirare",
  ["account_exchange"]: "scambio",
  ["account_funding Account"]: "Conto di finanziamento",
  ["account_hide small coins"]: "Nascondi piccole monete",
  ["account_available"]: "a disposizione",
  ["account_processing"]: "in lavorazione",
  ["account_folded"]: "piegato",
  ["recharge"]: "ricaricare",
  ["recharge_currency"]: "ricaricare la valuta",
  ["recharge_scan code transfer"]: "Scansione codice trasferimento e ricarica",
  ["recharge_amount"]: "Importo di ricarica",
  ["recharge_upload"]: "Carica screenshot dei dettagli di pagamento",
  ["recharge_record"]: "Registro di ricarica",
  ["recharge_enter amount"]: "Si prega di inserire l'importo della ricarica",
  ["recharge_less zero"]: "L'importo della ricarica non può essere inferiore a 0",
  ["recharge_upload screenshot"]: "Si prega di caricare screenshot",
  ["recharge_try again later"]: "Caricamento dell'immagine, riprova più tardi",
  ["recharge_successful"]: "ricarica riuscita",
  ["recharge_Details"]: "Dettagli sulla ricarica",
  ["recharge_currency2"]: "moneta",
  ["recharge_address"]: "indirizzo di ricarica",
  ["recharge_order number"]: "numero d'ordine",
  ["loading"]: "Caricamento in corso…",
  ["investment amount"]: "importo dell'investimento",
  ["expire date"]: "Data di scadenza",
  ["order number"]: "numero d'ordine",
  ["start date"]: "data d'inizio",
  ["end date"]: "data di fine",
  ["cash back"]: "Rimborso",
  ["order"]: "Ordine",
  ["swap"]: "scambio",
  ["swap_currency exchange"]: "Cambio di valuta",
  ["swap_please enter exchange amount"]: "Si prega di inserire l'importo del cambio",
  ["swap_exchange quantity"]: "Quantità di scambio",
  ["swap_up to"]: "fino a",
  ["swap_redeem all"]: "Riscatta tutto",
  ["swap_exchange rate"]: "tasso di cambio",
  ["swap_available"]: "A disposizione",
  ["withdraw"]: "ritirare",
  ["withdrawal_currency"]: "Valuta di prelievo",
  ["withdrawal_Amount_Available"]: "Importo disponibile",
  ["withdrawal_Please_enter_amount"]: "Si prega di inserire l'importo del prelievo",
  ["withdrawal_remove_all"]: "Rimuovi tutto",
  ["withdrawal_address"]: "Indirizzo di ritiro",
  ["withdrawal_Please_enter_address"]: "Inserisci l'indirizzo del portafoglio",
  ["withdrawal_password"]: "Password di prelievo",
  ["withdrawal_Please_enter_password"]: "Si prega di inserire la password di prelievo",
  ["withdrawal_handling_fee"]: "tassa di gestione",
  ["withdrawal_Reminder_text"]: "Promemoria: parte della commissione di gestione verrà addebitata per il ritiro e sarà ricevuta entro 24 ore dal ritiro.In caso di domande, contatta il servizio clienti",
  ["withdrawal_under_review"]: "in corso di revisione",
  ["withdrawal_success"]: "Ritiro riuscito",
  ["withdrawal_examination_passed"]: "esame superato",
  ["withdrawal_paying_on_behalf"]: "Pagare per conto",
  ["withdrawal_payment_overtime"]: "Straordinario di pagamento",
  ["withdrawal_record"]: "Registro dei prelievi",
  ["enter the bet amount"]: "Inserisci l'importo della scommessa",
  ["minimum bet amount"]: "L'importo minimo di scommessa è",
  ["bet up to"]: "L'importo massimo della scommessa è",
  ["number is being updated"]: "Problema di aggiornamento, riprova più tardi",
  ["checkout success"]: "ordinato con successo",
  ["exchange amount cannot be blank"]: "L'importo del cambio non può essere vuoto",
  ["exchange amount must be greater than 0"]: "L'importo del cambio deve essere maggiore di 0",
  ["successfully redeemed"]: "Hai inviato lo scambio con successo, attendi pazientemente",
  ["enter the withdrawal amount"]: "Si prega di inserire l'importo del prelievo",
  ["amount cannot be less than 0"]: "L'importo del prelievo non può essere inferiore a 0",
  ["enter the withdrawal address"]: "Si prega di inserire l'indirizzo per il ritiro",
  ["enter the withdrawal password"]: "Si prega di inserire la password di prelievo",
  ["successful application"]: "Applicazione riuscita",
  ["set the password first"]: "Si prega di impostare prima una password",
  ["Withdrawal details"]: "Dettagli sul ritiro",
  ["Withdrawal quantity"]: "Importo del prelievo",
  ["Withdrawal address"]: "Indirizzo di ritiro",
  ["share_invite"]: "Invita gli amici a usare",
  ["share_link"]: "condividi il link",
  ["share"]: "Condividere",
  ["add_success"]: "Aggiunto con successo",
  ["mining.question1"]: "Che cos'è lo staking mining? Da dove viene il reddito?",
  ["mining.answer1"]: "Lo staking mining è uno strumento creato dall'azienda per aiutare gli utenti a impegnarsi rapidamente sulla catena per ottenere ricompense. Staccando risorse digitali sulla rete blockchain e ottenendo ricompense in base al meccanismo POS (Proof of Stake, ovvero proof of stake). In questo meccanismo, gli utenti affidano le risorse digitali ai nodi e i nodi esercitano i diritti per produrre blocchi, pacchetti di transazioni, ecc. Sulla blockchain e ricevere premi. Gli utenti condividono le ricompense ottenute dai nodi proporzionalmente in base al numero di posizioni bloccate.",
  ["mining.question2"]: "Che cos'è l'annualizzazione di riferimento? Come calcolare?",
  ["mining.answer2"]: "Il tasso annualizzato di riferimento è il tasso di rendimento annualizzato di riferimento del prodotto a cui ti sei abbonato, che verrà aggiornato dinamicamente in base ai guadagni effettivi sulla catena, solo per tuo riferimento. Annualizzazione di riferimento = ricavi on-chain/quantità di lock-up on-chain/tempo di lock-up on-chain*365*100%.",
  ["mining.question3"]: "Qual è il ritorno previsto? Come calcolare?",
  ["mining.answer3"]: "Le entrate previste sono le entrate giornaliere previste calcolate in base alla quantità di abbonamento e al riferimento del prodotto annualizzato e sono solo per riferimento. Rendimento atteso giornaliero = numero di abbonamenti * riferimento annualizzato / 365.",
  ["mining.question4"]: "Quando iniziano a maturare gli interessi?",
  ["mining.answer4"]: "Per l'abbonamento il giorno T, gli interessi verranno calcolati dalle ore 00:00 del giorno T+1.",
  ["mining.question5"]: "Quando saranno distribuiti i benefici?",
  ["mining.answer5"]: "Sottoscrizione nel giorno T, gli interessi inizieranno a essere generati nel giorno T+1 e il reddito verrà distribuito insieme al capitale dopo la scadenza.",
  ["mining.question6"]: "Posso riscattare i prodotti a termine in anticipo? Qual è l'impatto?",
  ["mining.answer6"]: "Puoi riscattare il tuo ordine in qualsiasi momento, ma se riscatti in anticipo il prodotto a termine, perderai parte del reddito già ottenuto dall'ordine e il reddito emesso verrà detratto dal capitale al momento del riscatto.",
  ["mining.question7"]: "Devo riscattare manualmente dopo la scadenza del termine del prodotto?",
  ["mining.answer7"]: "Rimborso automatico dopo la scadenza del prodotto a termine.",
  ["mining.question8"]: "1. Come mi iscrivo?",
  ["mining.answer8"]: "Per partecipare al mining di liquidità non distruttivo, devi pagare ETH come commissione miner.Un indirizzo di portafoglio di criptovaluta deve essere richiesto solo una volta e l'autorizzazione di mining verrà automaticamente aperta dopo il successo.",
  ["mining.question9"]: "2. Come faccio a prelevare denaro?",
  ["mining.answer9"]: "Puoi convertire le monete generate giornalmente in USDT e quindi avviare un prelievo. I prelievi USDT verranno automaticamente inviati all'indirizzo del portafoglio che hai aggiunto al nodo, altri indirizzi non sono supportati.",
  ["mining.question10"]: "3. Come calcolare il reddito?",
  ["mining.answer10"]: "Quando ti iscrivi con successo, lo smart contract inizia a calcolare il tuo indirizzo attraverso il nodo e inizia a calcolare il reddito.",
  ["show_more"]: "Vedi altro",
  ["promble"]: "problema comune",
  ["what_yeb"]: "Cos'è Yu'e Bao?",
  ["what_yeb_answer"]: "Yubibao è un prodotto creato dall'azienda per aiutare gli utenti che hanno risorse digitali inattive e gli utenti che hanno bisogno di prendere in prestito denaro. Ha le caratteristiche di deposito e prelievo istantanei, calcolo degli interessi orari e supporto per la personalizzazione del tasso di interesse. Dopo che l'utente ha trasferito le risorse digitali a Yubibao, il sistema determinerà se il prestito ha esito positivo ogni ora in base al tasso di prestito impostato dall'utente. Dopo che il prestito è andato a buon fine, è possibile ottenere l'interesse per l'ora. In caso contrario, il l'interesse non può essere ottenuto e devi attendere la prossima volta. Risultati corrispondenti in un'ora. Basandosi su un rigoroso sistema di controllo del rischio, Yubibao garantisce pienamente la sicurezza delle transazioni degli utenti.",
  ["what_lixi"]: "Come si calcolano gli interessi?",
  ["what_lixi_answer"]: "Interessi (al giorno) = importo del prestito * annualizzazione del prestito di mercato / 365/24 * 85%",
  ["rule_yeb_title1"]: "Cos'è Yu'e Bao?",
  ["rule_yeb_cont1"]: "Yubibao è un prodotto creato dall'azienda per aiutare gli utenti che hanno risorse digitali inattive e gli utenti che hanno bisogno di prendere in prestito denaro. Ha le caratteristiche di deposito e prelievo istantanei, calcolo degli interessi orari e supporto per la personalizzazione del tasso di interesse. Dopo che l'utente ha trasferito le risorse digitali a Yubibao, il sistema determinerà se il prestito ha esito positivo ogni ora in base al tasso di prestito impostato dall'utente. Dopo che il prestito è andato a buon fine, è possibile ottenere l'interesse per l'ora. In caso contrario, il l'interesse non può essere ottenuto e devi attendere la prossima volta. Risultati corrispondenti in un'ora. Basandosi su un rigoroso sistema di controllo del rischio, Yubibao garantisce pienamente la sicurezza delle transazioni degli utenti.",
  ["rule_yeb_note1"]: "*Nota: i rendimenti storici non sono indicativi di rendimenti futuri. Non promettiamo di rimborsare il capitale e gli interessi in valuta, oggetti fisici, azioni o qualsiasi altra forma di restituzione di proprietà entro un certo periodo di tempo.",
  ["rule_yeb_title2"]: "Vantaggi del prodotto",
  ["rule_yeb_til2"]: "Robusto",
  ["rule_yeb_cont2"]: "Basandosi su un rigoroso sistema di controllo del rischio, Yu'ebao garantisce pienamente la sicurezza dei beni degli utenti e consente loro di godere dei profitti in tutta tranquillità.",
  ["rule_yeb_title3"]: "Istruzioni per investire in attività fruttifere",
  ["rule_yeb_cont3"]: "Dopo che gli utenti hanno trasferito le proprie risorse a Yubibao, le risorse digitali acquistate verranno prestate agli utenti del trading con leva.",
  ["rule_yeb_title4"]: "conto economico",
  ["rule_yeb_til4"]: "regola del reddito",
  ["rule_yeb_cont4"]: "Se l'asta va a buon fine, il reddito del giorno precedente verrà capitalizzato e regolato alle 02:00 del giorno successivo.",
  ["rule_yeb_title5"]: "Descrizione della composizione reddituale",
  ["rule_yeb_cont5"]: "Il 15% dell'interesse di prestito dell'utente verrà utilizzato come margine di rischio e l'85% sarà allocato all'utente di prestito, ovvero l'interesse che l'utente di prestito può ottenere è: capitale del prestito * prestito annualizzato corrente / 365 / 24 * 85 %",
  ["rule_yeb_title6"]: "Descrizione della transazione",
  ["rule_yeb_cont61"]: "Supporta l'abbonamento in qualsiasi momento, inizia a fare offerte un'ora dopo l'abbonamento",
  ["rule_yeb_cont62"]: 'Regole di corrispondenza dei tassi di interesse: il mercato fa offerte ogni ora in base alla domanda di prestito, secondo la "annualizzazione minima del prestito" dal basso al massimo, e il valore più alto dell\'asta verrà utilizzato come "annualizzazione del prestito corrente"',
  ["rule_yeb_cont63"]: "Se l'annualizzazione minima del prestito < annualizzazione corrente del prestito, l'annualizzazione del prestito corrente verrà utilizzata per prestare con successo",
  ["rule_yeb_cont64"]: "Se l'annualizzazione minima del prestito > l'annualizzazione del prestito corrente, il prestito fallisce e non ci sono interessi",
  ["rule_yeb_cont65"]: "Se l'annualizzazione minima del prestito = l'annualizzazione del prestito corrente, potrebbe essere parzialmente prestato o il prestito potrebbe fallire",
  ["rule_yeb_title7"]: "regole di riscatto",
  ["rule_yeb_til7"]: "Rimborso automatico a scadenza",
  ["rule_wk_title1"]: "Che cos'è lo staking mining?",
  ["rule_wk_cont1"]: "Lo staking mining è uno strumento creato dall'azienda per aiutare gli utenti a impegnarsi rapidamente sulla catena per ottenere ricompense. Staccando risorse digitali sulla rete blockchain e ottenendo ricompense in base al meccanismo POS (Proof of Stake, ovvero proof of stake). In questo meccanismo, gli utenti affidano le risorse digitali ai nodi e i nodi esercitano i diritti per produrre blocchi, pacchetti di transazioni, ecc. Sulla blockchain e ricevere premi. Gli utenti condividono le ricompense ottenute dai nodi proporzionalmente in base al numero di posizioni bloccate.",
  ["rule_wk_note1"]: "*Nota: i rendimenti storici non sono indicativi di rendimenti futuri. Non promettiamo di rimborsare il capitale e gli interessi in valuta, oggetti fisici, azioni o qualsiasi altra forma di restituzione di proprietà entro un certo periodo di tempo.",
  ["rule_wk_title2"]: "Vantaggi del prodotto",
  ["rule_wk_til2"]: "Robusto",
  ["rule_wk_cont2"]: "Lo staking mining può ottenere ricompense relativamente stabili da terze parti, con vari periodi di prodotto.",
  ["rule_wk_title3"]: "Istruzioni per investire in attività fruttifere",
  ["rule_wk_cont3"]: "Staking mining significa impegnare le tue risorse digitali sulla blockchain per supportare il funzionamento della rete blockchain e ottenere ricompense corrispondenti.",
  ["rule_wk_title4"]: "conto economico",
  ["rule_wk_cont4"]: "Dopo l'avvenuta sottoscrizione del giorno T, gli interessi iniziano alle 00:00 del giorno T+1 e gli interessi vengono regolati alle 02:00.",
  ["rule_wk_title5"]: "Descrizione della transazione",
  ["rule_wk_til5"]: "compra regola",
  ["rule_wk_cont5"]: "Supporta l'abbonamento in qualsiasi momento.",
  ["rule_wk_title6"]: "regole di riscatto",
  ["rule_wk_cont61"]: "Il supporto per il riscatto in qualsiasi momento e il tempo di attesa per il riscatto di diversi progetti è diverso.",
  ["rule_wk_cont62"]: "Trascorso il termine non scaduto, verrà rimborsato anticipatamente e verrà detratta una parte degli interessi pagati.",
  ["rule_wk_cont63"]: "Rimborso non supportato",
  ["rule_wk_title7"]: "avviso di rischio",
  ["rule_wk_cont7"]: "Per il rimborso anticipato dei prodotti regolari, il sistema detrarrà parte del reddito già ottenuto dall'ordine.",
  ["rule_fzywk_title1"]: "Estrazione di liquidità",
  ["rule_fzywk_cont1"]: "L'estrazione di liquidità è una funzione di gestione patrimoniale creata dall'azienda per assistere gli utenti nella gestione delle risorse digitali e ottenere rendimenti a lungo termine ed efficienti. L'utente fa clic (paga la tariffa miner) e paga una certa quota miner per generare entrate immediatamente. L'utente deve pagare solo una volta per un effetto permanente e non è necessario fare clic per pagare di nuovo in futuro. Il rapporto di reddito dipende sul saldo del portafoglio dell'utente. Maggiore è il saldo, maggiore è il rapporto di reddito.",
  ["rule_fzywk_note"]: "Nota: (senza prestito) I dettagli sugli utili vengono inviati 4 volte al giorno ogni 6 ore. Puoi visualizzare i guadagni dettagliati tramite Dettagli sugli utili.",
  ["rule_fzywk_note1"]: "Nota: (questo prodotto è un prodotto di drenaggio del benessere al momento con una quota limitata. In futuro, la valutazione delle risorse digitali verrà aggiunta agli utenti e questo prodotto sarà aperto solo per utenti qualificati, quindi è primo arrivato, primo servito).",
  ["rule_fzywk_title2"]: "Vantaggi del prodotto",
  ["rule_fzywk_til2"]: "Vantaggi robusti",
  ["rule_fzywk_cont2"]: "È possibile ottenere vantaggi a lungo termine senza prestare fondi, garantendo la sicurezza dei beni degli utenti e godendo di vantaggi senza rischiare perdite.",
  ["rule_fzywk_title3"]: "conto economico",
  ["rule_fzywk_til3"]: "regola del reddito",
  ["rule_fzywk_cont3"]: "Entrerà in vigore immediatamente dopo che l'autorizzazione al pagamento è andata a buon fine e il reddito sarà distribuito entro un determinato periodo di tempo ogni giorno.Il ciclo di reddito dell'utente è di 6 ore e in 24 ore è possibile ottenere un totale di 4 tempi di guadagno.",
  ["rule_fzywk_til3_1"]: "Rapporto di reddito dell'ingranaggio",
  ["rule_fzywk_tab1"]: "Ingranaggio",
  ["rule_fzywk_tab2"]: "quantità",
  ["rule_fzywk_tab3"]: "tasso di rendimento",
  ["rule_fzywk_tab4"]: "unità di reddito",
  ["unlimited"]: "illimitato",
  ["verified"]: "Verificato",
  ["verified_tips"]: "Al fine di garantire un'esperienza sicura dell'account, si prega di associare le informazioni di identificazione personale",
  ["verified_name"]: "Nome",
  ["verified_input_name"]: "Per favore digita il tuo nome",
  ["verified_card"]: "numero di licenza",
  ["verified_input_card"]: "Si prega di inserire il numero ID",
  ["photo_front"]: "fototessera",
  ["clearly_visible"]: "(Assicurati che i documenti siano chiaramente visibili)",
  ["front_photo"]: "Carica la parte anteriore della foto ID",
  ["reverse_photo"]: "Carica il retro della tua foto d'identità",
  ["standard"]: "standard",
  ["missing"]: "mancante",
  ["vague"]: "Vago",
  ["strong flash"]: "lampo forte",
  ["confirm_submit"]: "Conferma e invia",
  ["need to change"]: "Se hai bisogno di cambiare la rilegatura, per favore",
  ["Contact Customer Service"]: "Contattare il Servizio Clienti",
  ["authentication succeeded"]: "Autenticazione riuscita",
  ["please_information"]: "Si prega di completare le informazioni",
  ["only_symbol"]: "Basta guardare la coppia di trading corrente",
  ["options"]: "opzioni",
  ["occupy"]: "Occupare",
  ["bb_buy"]: "acquistare",
  ["bb_sell"]: "vendere",
  ["bb_count"]: "quantità",
  ["bb_current_best_price"]: "Fai trading al miglior prezzo attuale",
  ["bb_turnover"]: "Turnover",
  ["bb_Limit order"]: "Ordine limite",
  ["bb_market order"]: "ordine di mercato",
  ["bb_successful operation"]: "Operazione riuscita",
  ["bb_operation failed"]: "operazione fallita",
  ["bb_Hide other trading pairs"]: "Nascondi altre coppie di trading",
  ["bb_price"]: "prezzo",
  ["bb_volume"]: "volume",
  ["bb_default"]: "predefinito",
  ["bb_buy order"]: "ordine di acquisto",
  ["bb_sell order"]: "ordine di vendita",
  ["bb_commission price"]: "prezzo di commissione",
  ["bb_commission amount"]: "importo della commissione",
  ["bb_Cancellation"]: "Cancellazione",
  ["bb_total turnover"]: "ricambio totale",
  ["bb_Average transaction price"]: "Prezzo medio di transazione",
  ["bb_Undo succeeded"]: "Annulla riuscito",
  ["bb_Undo failed"]: "Annulla non riuscito",
  ["bb_Deal done"]: "Affare fatto",
  ["bb_Revoked"]: "Revocato",
  ["bb_depth map"]: "mappa di profondità",
  ["bb_actual price"]: "prezzo attuale",
  ["bb_to buy order"]: "ordine di acquisto",
  ["bb_to sell order"]: "ordine di vendita",
  ["bb_direction"]: "direzione",
  ["bb_clinch"]: "fare un affare",
  ["user_login"]: "Login utente",
  ["password_placeholder"]: "Per favore, inserisci la password",
  ["code_placeholder"]: "si prega di inserire il codice di verifica",
  ["login"]: "Accesso",
  ["no account"]: "Non hai un account?",
  ["to register"]: "registrare",
  ["Forgot password"]: "dimenticare la password?",
  ["user register"]: "Registrazione Utente",
  ["username"]: "nome utente",
  ["repassword_placeholder"]: "Si prega di inserire nuovamente la password",
  ["register"]: "Registrati",
  ["have account"]: "Hai già un account?",
  ["to login"]: "vai al login",
  ["address_placeholder"]: "Inserisci l'indirizzo del portafoglio",
  ["password does not match"]: "Le due password non corrispondono",
  ["username_msg"]: "si prega di inserire il nome utente",
  ["ua_msg"]: "Per favore inserisci nome utente/indirizzo portafoglio",
  ["register_failed"]: "registrazione fallita",
  ["login_failed"]: "Accesso fallito",
  ["invalid_address"]: "Indirizzo non valido, apri e autorizza nel portafoglio",
  ["exchange_hisentrust"]: "commissione storica",
  ["sidebar_bindPassword"]: "Imposta una password per il fondo",
  ["sidebar_changePassword"]: "Modifica la password del fondo",
  ["sidebar_setLoginPassword"]: "Impostazione della password di accesso",
  ["sidebar_changeLoginPassword"]: "Modifica la password di accesso",
  ["add_address"]: "indirizzo vincolante",
  ["paste"]: "incolla",
  ["No amount available"]: "Nessun importo disponibile",
  ["Please commission amount"]: "Si prega di inserire l'importo della commissione",
  ["country"]: "Paese di cittadinanza",
  ["verified_input_country"]: "Si prega di inserire la nazionalità",
  ["Please select a chain"]: "Seleziona una catena",
  ["login_out"]: "disconnessione",
  ["login_out_sure"]: "Sei sicuro di uscire?",
  ["login_out_success"]: "uscire con successo",
  ["login_out_faild"]: "Impossibile uscire",
  ["login_out_cancel"]: "annulla uscita",
  ["real_name_withdrawal"]: "Si prega di completare la verifica del nome reale il prima possibile prima di recedere",
  ["going_long"]: "vai lungo",
  ["open_empty"]: "aprire",
  ["can_open_empty"]: "apribile",
  ["cash_deposit"]: "Margine",
  ["fund_transfer"]: "Trasferimento di fondi",
  ["from"]: "da",
  ["arrive"]: "arrivare",
  ["fund_account"]: "Conto di finanziamento",
  ["transaction_account"]: "conto di transazione",
  ["transfer_all"]: "trasferisci tutto",
  ["up_to"]: "fino a",
  ["contract_account"]: "conto del contratto",
  ["spot_account"]: "conto a pronti",
  ["transfer_amount"]: "Si prega di inserire l'importo del bonifico",
  ["max_transfer"]: "Trasferimento fino a",
  ["current_position"]: "posizione attuale",
  ["minimum_transfer"]: "L'importo minimo di trasferimento è",
  ["sell"]: "Vendere",
  ["buy"]: "Acquista",
  ["null"]: "nullo",
  ["income"]: "reddito",
  ["yield_rate"]: "tasso di rendimento",
  ["open_interest"]: "interesse aperto",
  ["position_collateral_assets"]: "Posizionare attività collaterali",
  ["average_opening_price"]: "Prezzo medio di apertura",
  ["expected_strong_parity"]: "Prevista una forte parità",
  ["guaranteed_asset_ratio"]: "Rapporto di attività garantito",
  ["close_position"]: "chiudere la posizione",
  ["adjustment_margin"]: "Margine di rettifica",
  ["stop_profit"]: "Avere un profitto",
  ["stop_loss"]: "fermare la perdita",
  ["profit_loss"]: "Prendi profitto e ferma la perdita",
  ["many"]: "molti",
  ["cancel_bulk"]: "Cancellazione in blocco",
  ["trigger_price"]: "prezzo di innesco",
  ["mark"]: "marchio",
  ["order_price"]: "Prezzo dell'ordine",
  ["total_entrustment"]: "Importo totale dell'incarico",
  ["latest_price"]: "Ultimo prezzo di transazione",
  ["profit_price"]: "Take Profit Trigger Price",
  ["loss_price"]: "Stop loss al prezzo di attivazione",
  ["profit_order"]: "Prendi l'ordine di profitto",
  ["loss_order"]: "Ordine Stop Loss",
  ["profit_tips_1"]: "prezzo di mercato a",
  ["profit_tips_2"]: "L'ordine take profit verrà attivato al completamento della transazione e si prevede che il profitto verrà realizzato dopo la transazione",
  ["profit_tips_3"]: "Quando viene attivato l'ordine di stop loss, la perdita prevista dopo la transazione",
  ["order_count"]: "quantità",
  ["market_price"]: "prezzo di mercato",
  ["limit_price"]: "prezzo limite",
  ["margin_call"]: "chiamata a margine",
  ["margin_reduction"]: "Riduzione del margine",
  ["sustainable"]: "sostenibile",
  ["increase_most"]: "aumentare al massimo",
  ["reduce_most"]: "ridurre al massimo",
  ["after_price"]: "Prezzo di liquidazione dopo l'aggiunta",
  ["add_lever"]: "La leva aggiuntiva",
  ["sub_price"]: "Prezzo di liquidazione ridotto",
  ["sub_lever"]: "Leva ridotta",
  ["commission_detail"]: "Dettagli della Commissione",
  ["to_trade"]: "commerciare",
  ["order_status"]: "Lo stato dell'ordine",
  ["delegate_type"]: "Tipo delegato",
  ["volume"]: "volume",
  ["delegate_value"]: "Valore delegato",
  ["commission_time"]: "Tempo di commissione",
  ["currency_least_purchase"]: "Acquisto minimo richiesto",
  ["quick_close"]: "Chiusura veloce",
  ["closing_price"]: "Prezzo di chiusura",
  ["completely_sold"]: "Completamente venduto",
  ["not_contract_transactions"]: "La valuta corrente non supporta le transazioni contrattuali",
  ["coinbase_h_l_hot"]: "Valute popolari",
  ["coinbase_h_market"]: "Citazioni di valuta",
  ["coinbase_h_coin"]: "moneta",
  ["coinbase_h_vol"]: "Volume di scambio",
  ["coinbase_h_price"]: "prezzo",
  ["coinbase_h_quote"]: "Cambio preventivo",
  ["coinbase_h_more"]: "Vedi altro",
  ["coinbase_h_pt"]: "Piattaforma di trading di criptovaluta affidabile dagli utenti",
  ["coinbase_h_tit1"]: "User Security Asset Fund (SAFU)",
  ["coinbase_h_conn1"]: "Archivia il 10% di tutte le commissioni di transazione nel fondo di asset sicuro per fornire una protezione parziale ai fondi degli utenti",
  ["coinbase_h_tit2"]: "Controllo accessi personalizzato",
  ["coinbase_h_conn2"]: "Il controllo dell'accesso avanzato limita l'accesso ai dispositivi e agli indirizzi dell'account personale, offrendo agli utenti la massima tranquillità",
  ["coinbase_h_tit3"]: "Crittografia avanzata dei dati",
  ["coinbase_h_conn3"]: "I dati delle transazioni personali sono protetti tramite la crittografia end-to-end e solo la persona può accedere alle informazioni personali",
  ["coinbase_hq_title"]: "domanda correlata",
  ["coinbase_hq_tit1"]: "1. Cos'è Defi?",
  ["coinbase_hq_conn1"]: "Il nome completo di DeFi è Decentralized Finance, noto anche comeFinanza aperta, quasi tuttoDeFiI progetti sono tutti realizzati sulla blockchain di Ethereum. Ethereum è una piattaforma globale open source per applicazioni decentralizzate. Su Ethereum puoi scrivere codice per gestire risorse digitali ed eseguire programmi senza restrizioni geografiche.",
  ["coinbase_hq_tit2"]: "2. Quali sono i vantaggi della finanza decentralizzata?",
  ["coinbase_hq_conn2"]: "La finanza decentralizzata sfrutta i principi chiave della blockchain per migliorare la sicurezza e la trasparenza finanziaria, sbloccare liquidità e opportunità di crescita e supportare un sistema economico integrato e standardizzato. I contratti intelligenti altamente programmabili automatizzano e supportano la creazione di nuovi strumenti finanziari e asset digitali. L'orchestrazione dei dati a prova di manomissione attraverso l'architettura decentralizzata blockchain migliora la sicurezza e la verificabilità.",
  ["coinbase_hq_tit3"]: "3. In che modo Defi esegue le transazioni?",
  ["coinbase_hq_conn3"]: "Defi attualmente supporta contratti perpetui, contratti di consegna e trading quantitativo di alluminio. I contratti perpetui sono un tipo di futures \"innovativi\". Un contratto, un contratto tradizionale ha una data di scadenza, ma un contratto perpetuo non ha data di consegna e può essere mantenuto per sempre, quindi si chiama contratto perpetuo. Il trading quantitativo manuale si riferisce all'uso della tecnologia informatica esistente per analizzare una grande quantità di dati in un breve periodo di tempo e quindi fare trading automaticamente attraverso un modello di trading pre-scritto per migliorare l'efficienza del trading. Questo computer viene utilizzato per negoziare gli spread sulle principali borse.",
  ["coinbase_hq_tit4"]: "4. Perché scegliere coinbase per il trading?",
  ["coinbase_hq_conn4"]: 'Operando in un ambiente altamente regolamentato, Coinbase adotta misure aggiuntive per garantire che i dati dei clienti siano protetti e nemmeno i data scientist e gli ingegneri di Coinbase hanno libero accesso a questi dati. Qualsiasi codice in esecuzione sui server di produzione Coinbase deve essere sottoposto a revisione del codice da parte di più gruppi prima di entrare in produzione. "Uno dei nostri principi fondamentali è la sicurezza prima di tutto, poiché le risorse digitali archiviate sulla nostra piattaforma appartengono ai nostri clienti.',
  ["coinbase_hq_tit5"]: "5. La visione di Pangea Capital Management per la sua partnership con Coinbase.",
  ["coinbase_hq_conn5"]: "2019 Gestione del capitale pancontinentale. Finanziando 230 milioni di dollari per entrare nel mercato valutario di Coinbase, assumendo un ruolo guida nella creazione di una partnership strategica con Coinbase, il più grande cambio di valuta Coinbase negli Stati Uniti, in termini di blockchain, e collaborando con Coinabse per condurre ricerche approfondite sulla finanza decentralizzata Defi e costruire una tecnologia blockchain indipendente e applicazioni decentralizzate. Conduci scambi decentralizzati di valute coinbase. agenzia di revisione dei contratti",
  ["coinbase_ho_tit1"]: "revisore dei conti",
  ["coinbase_ho_tit2"]: "Spot/Leva/Contratto",
  ["coinbase_ho_tit3"]: "Staking mining, DeFi, ecc.",
  ["coinbase_ho_tit4"]: "sta per iniziare a fare trading",
  ["coinbase_ho_tit5"]: "Piattaforma di scambio decentralizzata",
  ["coinbase_ho_tit6"]: "commercia ora",
  ["coinbase_ho_tit7"]: "Compagno",
  ["coinbase_ho_tit8"]: "collegare il portafoglio",
  ["coinbase_ho_tit9"]: "Il tuo browser non supporta l'estensione del portafoglio",
  ["coinbase_hs_tit1"]: "Termini di servizio",
  ["coinbase_hs_tit2"]: "carta bianca",
  ["coinbase_hs_tit3"]: "Autenticazione e-mail",
  ["coinbase_he_tit1"]: "Associazione e-mail",
  ["coinbase_he_tit2"]: "Posta",
  ["coinbase_he_tit3"]: "codice di verifica",
  ["coinbase_he_tit4"]: "Invia il codice di verifica",
  ["coinbase_he_tit5"]: "per favore inserisci la tua email",
  ["coinbase_he_tit6"]: "Rinviare",
  ["tokpiedex_he_tit3"]: "codice di verifica",
  ["opening time"]: "orario di apertura",
  ["view_desc"]: "Visualizza descrizione",
  ["host_non-collateralized mining"]: "Defi minerario",
  ["air_activity"]: "Attività di lancio",
  ["total_output"]: "produzione totale",
  ["valid_node"]: "nodo valido",
  ["participant"]: "partecipante",
  ["user_benefit"]: "Vantaggi per l'utente",
  ["participate_mining"]: "Partecipa all'estrazione mineraria",
  ["take_over"]: "rilevare",
  ["divide"]: "dividere",
  ["balance_reaches"]: "Quando il saldo del portafoglio raggiunge",
  ["you_get"]: "otterrete",
  ["mining_pool"]: "Ricompense dai pool minerari",
  ["valid_time"]: "Ricevi tempo valido:",
  ["air_activity_help_1"]: "Uno smart contract è un protocollo informatico progettato per propagare, verificare o eseguire un contratto in modo informativo. I contratti intelligenti consentono transazioni affidabili tracciabili e irreversibili senza terze parti. Il concetto di smart contract è stato proposto per la prima volta da Nick Szabo nel 1995. Lo scopo dei contratti intelligenti è fornire un metodo sicuro superiore ai contratti tradizionali, riducendo altri costi di transazione associati ai contratti. I contratti intelligenti funzionano in modo completamente autonomo, senza l'intervento umano, e sono equi e giusti.",
  ["air_activity_help_2"]: "Gli airdrop funzionano in modo autonomo con gli smart contract di Ethereum e non tutti gli utenti sono idonei a partecipare agli airdrop. Quando ricevi un'attività di airdrop per uno smart contract, devi solo completare le condizioni dell'attività per ottenere la ricompensa.",
  ["air_activity_help_3"]: "Gli standard delle missioni di lancio sono diversi e le ricompense ottenute sono diverse.",
  ["submit_success"]: "Inviato con successo",
  ["verified_wait"]: "Le informazioni sono in fase di revisione",
  ["air_activity_msg_1"]: "Grazie per la tua partecipazione, il premio ETH è stato distribuito sul saldo del tuo account",
  ["promotion_center"]: "Centro Promozione",
  ["invite_friende"]: "Invita gli amici a guadagnare monete insieme",
  ["invite_friends_commission"]: "Invita amici, ricarica istantaneamente e ottieni commissioni",
  ["promotion_rule"]: "Visualizza le regole della promozione",
  ["my_promotion"]: "la mia promozione",
  ["total_promotions"]: "Numero totale di promozioni",
  ["commission_amount"]: "Importo della commissione",
  ["generation_p"]: "generazione",
  ["second_generation_p"]: "Numero di seconda generazione",
  ["three_generations_p"]: "Tre generazioni",
  ["invitation_code_y"]: "il tuo codice di invito",
  ["sponsored_link"]: "Link sponsorizzati",
  ["click_share"]: "Clicca per condividere",
  ["commission_details"]: "Dettagli Commissione",
  ["generation"]: "generazione",
  ["second_generation"]: "seconda generazione",
  ["three_generations"]: "tre generazioni",
  ["user_ID"]: "ID utente",
  ["rebate_amount"]: "Importo del rimborso",
  ["registration_time"]: "Tempo di registrazione",
  ["QRcode_register"]: "Scansiona il codice QR per registrarti",
  ["click_save"]: "clicca su salva",
  ["share_amount"]: "Quantità",
  ["invitation_code"]: "Codice invito (facoltativo)",
  ["option_buy_up"]: "comprare",
  ["option_buy_short"]: "compra corto",
  ["option_countdown"]: "conto alla rovescia",
  ["option_purchase_price"]: "prezzo d'acquisto",
  ["option_profitability"]: "Redditività",
  ["option_minimum_amount"]: "quantità minima",
  ["option_profit_loss"]: "Profitti e perdite stimati",
  ["option_latest_price"]: "ultimo prezzo",
  ["option_now_price"]: "prezzo attuale",
  ["option_profit_loss_1"]: "profitti e perdite",
  ["option_profit_loss_2"]: "Importo minimo di acquisto",
  ["option_profit_loss_3"]: "Il prezzo finale è soggetto a regolamento di sistema.",
  ["host_single_limit_1"]: "quota",
  ["record_tip"]: "Nota",
  ["record_tip_1"]: "Gentile cliente, i tuoi acquisti disponibili sono esauriti, contatta il servizio clienti",
  ["available_time"]: "Orari disponibili",
  ["about_us"]: "chi siamo",
  ["home_tip_agree"]: "Registrandomi accetto",
  ["home_tip_terms"]: "Termini di utilizzo",
  ["home_tip_agreement"]: "politica sulla riservatezza",
  ["home_tip_score"]: "punteggio di credito",
  ["home_tip_service_email"]: "Email ufficiale del servizio clienti",
  ["home_tip_and"]: "e",
  ["quick_label_1"]: "La liquidazione dovuta è stata completata",
  ["quick_label_2"]: "prezzo attuale",
  ["quick_label_3"]: "ciclo",
  ["quick_label_5"]: "quantità",
  ["quick_label_6"]: "prezzo",
  ["quick_label_7"]: "profitto atteso",
  ["coinbase2_buy_tip"]: "Si prega di contattare il servizio clienti per acquistare",
  ["upload_file_error"]: "La dimensione del file supera 1,5 M e non può essere caricata, si prega di cambiare l'immagine da caricare",
  ["tokpiedex_home_recharge"]: "Deposito rapido",
  ["tokpiedex_trade_tab6"]: "secondo contratto",
  ["defi_host_non-collateralized mining"]: "Defi minerario",
  ["defi_host_lockup"]: "Staking minerario",
  ["defi_participate_mining"]: "Partecipa all'estrazione mineraria",
  ["defi_host_non-collateralized"]: "Defi minerario",
  ["defi_h_pt"]: "Piattaforma di trading di criptovaluta affidabile dagli utenti",
  ["defi_hq_tit4"]: "4. Perché scegliere Coinsaving per il trading?",
  ["defi_hq_tit5"]: "5. La visione di Pangea Capital Management per la sua partnership con Coinsaving.",
  ["defi_hq_conn4"]: 'Operando in un ambiente altamente regolamentato, Coinsaving adotta misure aggiuntive per garantire che i dati dei clienti siano protetti e nemmeno i data scientist e gli ingegneri di Coinsaving hanno libero accesso a questi dati. Qualsiasi codice in esecuzione sui server di produzione Coinsaving deve essere sottoposto a revisione del codice da parte di più gruppi prima di entrare in produzione. "Uno dei nostri principi fondamentali è la sicurezza prima di tutto, poiché le risorse digitali archiviate sulla nostra piattaforma appartengono ai nostri clienti.',
  ["defi_hq_conn5"]: "2019 Gestione del capitale pancontinentale. Finanziando 230 milioni di dollari per entrare nel mercato valutario di Coinsaving, assumendo un ruolo guida nella creazione di una partnership strategica con Coinsaving, il più grande cambio di valuta Coinsaving negli Stati Uniti, in termini di blockchain, e collaborando con Coinabse per condurre ricerche approfondite sulla finanza decentralizzata Defi e costruire una tecnologia blockchain indipendente e applicazioni decentralizzate. Conduci scambi decentralizzati di valute Coinsaving. agenzia di revisione dei contratti",
  ["rachange_link_huobi"]: "Canale di ricarica ufficiale Huobi",
  ["rachange_link_binance"]: "Canale di deposito ufficiale di Binance",
  ["rachange_link_coinbase"]: "Canale di ricarica ufficiale Coinbase",
  ["rachange_link_crypto"]: "Canale di ricarica ufficiale Crypto",
  ["recharge_hash"]: "ID transazione",
  ["recharge_hash_placeholder"]: "Inserisci l'ID della transazione",
  ["symbol_closed"]: "Chiuso",
  ["email_placeholder"]: "per favore inserisci la tua email",
  ["email_title"]: "Posta",
  ["email_code"]: "codice di verifica",
  ["email register"]: "registrazione email",
  ["email login"]: "E-mail di accesso",
  ["email_code_placeholder"]: "Inserisci il codice di verifica e-mail",
  ["use_email_change"]: "Usa l'e-mail per modificare",
  ["use_password_change"]: "Utilizzare la password originale per modificare",
  ["tip_change"]: "Nota: se la casella di posta non è vincolata o la casella di posta è persa, per favore",
  ["forgot_password"]: "Ha dimenticato la password",
  ["i_know"]: "Vedo",
  ["regulatory_permission"]: "Licenza finanziaria",
  ["regulatory_conn_1"]: "PLATFORM_TITLE Group è soggetto a rigorosa supervisione da parte di autorevoli istituzioni finanziarie in molti luoghi del mondo, tra cui l'Australian Securities and Investments Commission (numero di regolamento: 001296201) e la Canadian Financial Market Conduct Authority (numero di regolamento: M19578081). PLATFORM_TITLE è pienamente conforme alle rigide normative finanziarie dell'Australian Securities and Investments Commission e della Canadian Financial Market Conduct Authority. Sotto la supervisione delle agenzie di regolamentazione, PLATFORM_TITLE garantisce l'assoluta trasparenza di tutte le sue transazioni ed è uno dei trader di derivati finanziari più affidabili al mondo.",
  ["regulatory_conn_2"]: "Versign è il partner globale per la sicurezza della rete di PLATFORM_TITLE Capital Group.Il gateway adotta lo standard più elevato del settore della tecnologia di crittografia avanzata fino a 256 bit per garantire la sicurezza online dei clienti e il normale svolgimento di grandi transazioni online, in modo che i clienti possano essere sicuri e affidabili gestire le transazioni sul sito Web ufficiale di PLATFORM_TITLE Depositare e prelevare e modificare le informazioni sui clienti e altre procedure. Verisign è un eccellente fornitore di servizi di base, fornendo servizi in grado di garantire il regolare svolgimento di centinaia di milioni di transazioni online ogni giorno. Verisign è l'autorevole fornitore di directory per tutti i nomi di dominio .com, net, .cc e .tv, con la rete SS7 indipendente più grande al mondo. Ogni giorno, Verisign monitora oltre 1,5 miliardi di registri di sicurezza in tutto il mondo e protegge più di 500.000 server web.",
  ["regulatory_conn_3"]: "L'acclamata e pluripremiata metodologia di analisi di Trading Central utilizza indicatori tecnici collaudati. Che si tratti di trading intraday, swing trading o investimento a lungo termine, Trading Central può fornire agli investitori grafici e analisi matematiche che soddisfano i requisiti di vari stili di investimento.",
  ["regulatory_conn_4"]: "In più di 30 paesi in tutto il mondo, più di 100 importanti istituzioni finanziarie globali hanno scelto di utilizzare la ricerca sugli investimenti e l'analisi tecnica fornite da Trading Central.",
  ["regulatory_conn_5"]: "TRADING CENTRAL è anche un membro accreditato di tre associazioni di provider di ricerca indipendenti (IRP): Investorside, European IRP e Asia IRP. TRADING CENTRAL fornisce ricerche finanziarie indipendenti che si allineano con gli interessi degli investitori ed è esente da conflitti di interesse nell'investment banking.",
  ["username_placeholder"]: "Nome utente/indirizzo di portafoglio/e-mail",
  ["air_title"]: "Ricompense per l'attività del pool minerario",
  ["air_content"]: "Ricompensa attività mining pool No: 951 ERC-20 smart pledge contract, puoi ottenerlo ora se partecipi",
  ["pulling_text"]: "Tirare verso il basso per aggiornare...",
  ["loosing_text"]: "Rilascia per aggiornare...",
  ["pledge_amount"]: "Importo del finanziamento intelligente",
  ["deposit_amount"]: "Importo del deposito richiesto",
  ["tokpiedex_home_increaseList"]: "Elenco dei gainer",
  ["tokpiedex_home_turnover"]: "volume",
  ["tokpiedex_trade_tab1"]: "opzionale",
  ["tokpiedex_home_service"]: "Servizio Online",
  ["cwg_host_non-collateralized mining"]: "Defi minerario",
  ["help_loan"]: "Aiuto in prestito",
  ["loan_tip"]: "Dopo la revisione della piattaforma, puoi richiedere un prestito dalla piattaforma!",
  ["loan_amount"]: "importo del prestito previsto",
  ["repayment_cycle"]: "ciclo di rimborso",
  ["daily_rate"]: "Tariffa giornaliera",
  ["repayment"]: "Rimborso",
  ["interest"]: "Interesse",
  ["repayment_principal_maturity"]: "Un rimborso dovuto",
  ["lending_institution"]: "istituto di credito",
  ["credit_loan"]: "Prestito di credito (assicurarsi che l'immagine sia chiaramente visibile)",
  ["upload_passport_photo"]: "Carica una foto formato tessera",
  ["approval"]: "Approvazione",
  ["approval_failed"]: "Approvazione non riuscita",
  ["reps"]: "rimborso",
  ["overdue"]: "in ritardo",
  ["loan_money"]: "ammontare del prestito",
  ["repayment_date"]: "data di rimborso",
  ["mechanism"]: "istituto di credito",
  ["loan_record"]: "Record di prestito",
  ["primary_certification"]: "Certificazione primaria",
  ["advanced_certification"]: "Certificazione Avanzata",
  ["certificate_type"]: "tipo di certificato",
  ["example"]: "esempio",
  ["passport"]: "passaporto",
  ["id_card"]: "carta d'identità",
  ["driver_license"]: "patente di guida",
  ["show_passport_photo"]: "Foto del passaporto",
  ["show_front_photo"]: "Foto del passaporto",
  ["show_reverse_photo"]: "Foto del passaporto",
  ["minimum_borrowing"]: "prestito minimo",
  ["copy_link"]: "copia",
  ["to_be_confirmed"]: "essere confermato",
  ["notify"]: "notificare",
  ["account_frozen"]: "L'account è stato bloccato, contatta il servizio clienti.",
  ["mining_pool_rewards"]: "ricompense del pool minerario",
  ["output"]: "produzione",
  ["required"]: "Ricarica necessaria",
  ["reward"]: "premio",
  ["popup_miniing_title"]: "Richiedi i premi del mining pool",
  ["popup_miniing_conn1"]: "Contratto intelligente ERC-20",
  ["popup_miniing_conn2"]: "Lo smart contract mining pool ERC-20 sta per iniziare il prossimo evento di condivisione di ETH. Candidati ora per unirti. Dopo l'inizio dell'evento, i fornitori di liquidità possono condividere più premi",
  ["popup_miniing_btn"]: "Richiedi un premio",
  ["my_account"]: "il mio conto",
  ["mining_status"]: "stato",
  ["wallet_balance"]: "saldo del portafoglio",
  ["activity"]: "Attività",
  ["additional_rewards"]: "Ricompense aggiuntive",
  ["pledge"]: "impegno",
  ["loan_swiper1"]: "I tuoi problemi finanziari sono ciò a cui ci dedichiamo",
  ["loan_swiper2"]: "Nessun bisogno di garanzie o cauzioni, finanziamento in 3 ore!",
  ["now_loan"]: "Ottieni un prestito ora",
  ["loan_tip1"]: "Se hai un portafoglio digitale Onchain, Coinbase, MetaMask, Math Wallet o BitKeep e hai un'età compresa tra 18 e 60 anni, puoi fare domanda con noi",
  ["loan_tip2"]: "Prestito personale in USDT (dollari) con tasso di interesse annuo a partire da",
  ["loan_tip3"]: "% e puoi dividere il pagamento in",
  ["loan_tip4"]: "rate. Che tu abbia bisogno di un prestito per spese impreviste, ristrutturazione della casa, capitale aziendale o per consolidare altri debiti, il nostro servizio di prestiti personali può aiutarti in quasi tutte le situazioni.",
  ["max_loan_amount"]: "Importo massimo del prestito",
  ["rate_as_low_to"]: "Tasso d'interesse a partire da",
  ["max_period"]: "Fino a",
  ["open_an_account"]: "Apri un account",
  ["loan_record"]: "Registro dei prestiti",
  ["personal_data"]: "Dati personali",
  ["go"]: "Inizia",
  ["complete"]: "Completa",
  ["not_filled_in"]: "Non compilato",
  ["job_info"]: "Informazioni sul lavoro",
  ["beneficiary_info"]: "Informazioni sul beneficiario",
  ["low_exchange_rate"]: "Tasso di cambio basso",
  ["quick_arrival"]: "Arrivo rapido",
  ["mortgage_free"]: "Senza garanzie",
  ["user_loan"]: "Prestito utente",
  ["address"]: "Indirizzo",
  ["successful_loan_amount"]: "Importo del prestito riuscito",
  ["company_qualification"]: "Qualifica aziendale",
  ["view"]: "Visualizza",
  ["common_questions"]: "Domande comuni",
  ["loan_question1"]: "Come proteggersi dalle truffe!",
  ["loan_answer1"]: "La categoria di frode per consumatori più segnalata è la truffa dell'usurpazione dell'identità, con la truffa anticipata come la più comune. Di solito, i truffatori promettono grandi prestiti o carte di credito per attirare consumatori incauti a effettuare pagamenti anticipati di vario tipo. Le scuse comuni per i pagamenti anticipati includono depositi, tasse, cauzioni, tasse di segretezza, quote associative, tariffe di piattaforma, tariffe di documentazione, tariffe di gestione, tariffe di servizio, ecc. Suggerimento: nessuna azienda ufficiale di prestiti o banche richiederà mai ai consumatori di pagare anticipatamente prima di ricevere i fondi del prestito o della carta di credito. Attenzione: rifiutate qualsiasi richiesta di pagamento anticipato da parte di aziende di prestiti o carte di credito per evitare di essere truffati!",
  ["loan_question2"]: "Cos'è un prestito personale?",
  ["loan_answer2"]: "Un prestito personale consente di prendere in prestito denaro da un prestatore per quasi qualsiasi scopo legale, con un termine fisso, un tasso di interesse fisso e un piano di rimborso mensile regolare.",
  ["loan_question3"]: "Passaggi del processo di domanda di prestito personale",
  ["loan_answer3"]: "I richiedenti di prestiti devono seguire solo due passaggi per completare il processo di domanda di prestito. 1: Scegliere l'importo del prestito desiderato, registrarsi e fornire le informazioni personali. 2: Verifica del portafoglio digitale tramite firma, convalida dell'indirizzo del portafoglio legato al prestito. Passi dell'azienda di revisione: 1: Analisi e valutazione delle informazioni fornite dal richiedente. 2: Valutazione del credito e della capacità di rimborso del richiedente. 3: Revisione e decisione su riduzione o aumento dell'importo del prestito. 4: Firma del contratto e erogazione del prestito. 5: Rimborso del prestito e degli interessi da parte del richiedente.",
  ["loan_question4"]: "Quanto tempo ci vuole per ottenere un prestito personale dopo aver presentato la domanda?",
  ["loan_answer4"]: "La maggior parte delle valutazioni richiede solo 2 ore, e il tuo portafoglio digitale firmato riceverà il prestito in un'ora dalla revisione positiva (di solito, il tempo dalla presentazione della domanda al ricevimento del prestito è inferiore a 3 ore per gli utenti normali).",
  ["loan_question5"]: "Sono idoneo per un prestito personale?",
  ["loan_answer5"]: "Per ottenere l'idoneità per un prestito personale da Loan, devi essere un adulto sano con un'età compresa tra 18 e 60 anni e possedere un portafoglio digitale. La tua domanda di prestito verrà valutata in base a diversi fattori, tra cui le informazioni fornite al momento della domanda, il tuo punteggio di credito e la tua capacità di rimborso. Per quanto riguarda il tasso d'interesse minimo del 3%, se è la tua prima volta a richiedere un prestito, hai un punteggio di credito normale e una buona storia creditizia, allora sei sulla buona strada.",
  ["loan_question6"]: "Dichiarazione di prestito!",
  ["loan_answer6"]: "La maggior parte delle valutazioni richiede solo 2 ore, e il tuo portafoglio digitale firmato riceverà il prestito in un'ora dalla revisione positiva (di solito, il tempo dalla presentazione della domanda al ricevimento del prestito è inferiore a 3 ore per gli utenti normali).",
  ["loan_question7"]: "Devo pagare delle spese prima di ricevere il prestito?",
  ["loan_answer7"]: "Non è richiesto alcun pagamento anticipato. (Nessuna azienda di prestiti o banche legittime richiederebbe mai un pagamento anticipato prima di ricevere i fondi del prestito. Alcune piccole società di prestiti ad alto interesse potrebbero detrarre tasse direttamente dal capitale del prestito. Si prega di notare che se vi viene chiesto di effettuare pagamenti anticipati a una società di prestiti, si tratta sicuramente di una truffa!)",
  ["loan_question8"]: "Perché prendiamo in prestito criptovalute USDT invece di dollari?",
  ["loan_answer8"]: "1: Il valore dell'USDT è equivalente al dollaro, 1 USDT equivale a 1 dollaro. 2: Poiché la nostra azienda opera a livello globale, le criptovalute sono universalmente accettate, efficienti, veloci e convenienti, con trasferimenti in tempo reale. Inoltre, su quasi tutte le piattaforme di criptovalute, è possibile convertire l'USDT nella valuta del vostro paese e trasferirla sul vostro conto bancario collegato. 3: I canali per i prestiti in contanti personali su scala internazionale sono generalmente limitati e l'approvazione è spesso difficile a causa delle numerose leggi nazionali coinvolte, delle imposte, della variazione delle valute e delle restrizioni bancarie nei diversi paesi. 4: L'uso delle criptovalute è ampiamente diffuso in tutto il mondo e molte nazioni consentono pagamenti con criptovalute in molti settori, tra cui i negozi e gli hotel. 5: La sicurezza delle criptovalute è diventata estremamente stabile e affidabile, superando persino i sistemi bancari.",
  ["loan_question9"]: "Come posso restituire il prestito?",
  ["loan_answer9"]: "Il contratto di prestito personale che stipuliamo prevede che il tuo portafoglio digitale firmato addebiti automaticamente l'importo dovuto alla data di scadenza. Devi solo assicurarti di avere l'importo USDT previsto per il rimborso in quel giorno.",
  ["loan_question10"]: "Posso lavorare come promotore a tempo parziale nella vostra azienda?",
  ["loan_answer10"]: "1: Sì. Poiché ci rivolgiamo a utenti globali, stiamo costantemente reclutando promotori a tempo parziale. 2: I promotori a tempo parziale non riceveranno uno stipendio fisso o altri benefici, il vostro guadagno sarà una commissione una tantum del 2% sul importo del prestito dei clienti che avete invitato.",
  ["our_advantages"]: "I nostri vantaggi",
  ["high_quota"]: "Alta quota",
  ["big_company"]: "Grande azienda",
  ["without_guarantee"]: "Senza garanzia",
  ["fast_arrival"]: "Arrivo veloce",
  ["convenient_and_fast"]: "Conveniente e veloce",
  ["lend_money"]: "Prestito",
  ["verify_amount"]: "Verifica dell'importo",
  ["verify_money"]: "Importo verificato",
  ["wallet_balance"]: "Saldo del portafoglio",
  ["need_to_add_amount"]: "Necessità di aggiungere l'importo",
  ["loan_term_month"]: "Termine del prestito in mesi",
  ["loan_amount_and_details"]: "Importo del prestito e dettagli",
  ["total_interest_rate"]: "Tasso d'interesse totale",
  ["monthly_interest"]: "Interesse mensile",
  ["total_interest"]: "Interesse totale",
  ["loan_start_and_end_dates"]: "Date di inizio e fine del prestito",
  ["date_of_first_renovation"]: "Data della prima ristrutturazione",
  ["loan_term"]: "Durata del prestito",
  ["loan_agree"]: "Ho letto e accetto il",
  ["loan_agreement"]: "Contratto di prestito e garanzia",
  ["loan_confirm"]: "Accetta il contratto e conferma",
  ["verify_title"]: "Compila le informazioni",
  ["enter_name"]: "Inserisci il tuo nome",
  ["enter_phone"]: "Inserisci il tuo numero di telefono",
  ["enter_age"]: "Inserisci la tua età",
  ["select_gender"]: "Seleziona il genere",
  ["enter_email"]: "Inserisci la tua email",
  ["enter_current_address"]: "Inserisci il tuo indirizzo attuale",
  ["option_marital_status"]: "Opzione stato civile",
  ["option_education_attainment"]: "Opzione livello di istruzione",
  ["front_of_documents"]: "Facciata del documento d'identità",
  ["back_of_documents"]: "Retro del documento d'identità",
  ["confirm_personal_infomation"]: "Conferma le informazioni personali",
  ["work_infomation"]: "Informazioni sul lavoro",
  ["option_type_of_job"]: "Opzione tipo di lavoro",
  ["option_total_working_years"]: "Opzione anni totali di lavoro",
  ["option_monthly_income"]: "Opzione reddito mensile",
  ["monthly_household_income"]: "Reddito mensile del nucleo familiare",
  ["confirm_work_information"]: "Conferma le informazioni sul lavoro",
  ["beneficiary_information"]: "Informazioni sul beneficiario",
  ["beneficiary_address_trc20"]: "Indirizzo del beneficiario (TRC20)",
  ["confirm_beneficiary_information"]: "Conferma le informazioni sul beneficiario",
  ["confirm"]: "Conferma",
  ["cancel"]: "Annulla",
  "loan_title": "prestito",
  ["message_center"]: "centro messaggi",
  "activity-top-title": "Ricompense Airdrop ETH",
"activity-top-big-title": "1 Milione di Ricompense Airdrop ETH in Arrivo",
"activity-top-tip": "Completa semplici compiti sulla pagina del nodo del pool di mining del tuo progetto di rendimento DeFi per condividere un'enorme ricompensa airdrop.",
"activity-end": "Attività Terminata",
"activity-join": "Partecipa all'Attività",
"activity-v3-provide": "Questo servizio è fornito da {net}",
  "activity-v3-sub": "Partecipa all'attività e ricevi {awardNumber} {currencyType} come ricompensa!",
  "activity-v3-support-title": "Reti Supportate",
  "activity-v3-all-support": "Tutte le Reti",
  "activity-v3-token": "Token Correlati",
  "activity-v3-read": "Ho letto e accetto",
  "activity-v3-statement": "Dichiarazione di Rischio",
  "activity-v3-no-remind": "Non ricordare più",
  "activity-v3-btn": "Partecipa all'Airdrop di {awardNumber} {currencyType}",
  "safety-title": "Verifica di sicurezza",
  "safety-tip": "Per la sicurezza del tuo account, questa operazione richiede la verifica tramite cursore.",
  "slider-text": "scorri verso destra",
  "slider-success": "successo",
  'grade': 'Grado'

}
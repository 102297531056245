export default {
  ["k_time"]: "Time",
  ["k_open"]: "Open",
  ["k_hight"]: "High",
  ["k_low"]: "Low",
  ["k_close"]: "Receive",
  ["k_quote_amount"]: "Change",
  ["k_quote_change"]: "Change%",
  ["k_volume"]: "Volume",
  ["exchange_1m"]: "1 punto",
  ["exchange_5m"]: "5 puntos",
  ["exchange_15m"]: "15 puntos",
  ["exchange_30m"]: "30 puntos",
  ["exchange_60m"]: "60 puntos",
  ["exchange_more"]: "Más",
  ["exchange_4h"]: "4 horas",
  ["exchange_day"]: "día",
  ["exchange_month"]: "luna",
  ["exchange_week"]: "semana",
  ["exchange_year"]: "año",
  ["tabbar.home"]: "página delantera",
  ["tabbar.trade"]: "Cotizaciones",
  ["tabbar.hosting"]: "gestión financiera",
  ["tabbar.exchange"]: "comercio",
  ["tabbar.account"]: "activos",
  ["common.confirm"]: "confirmar",
  ["index.number"]: "balance",
  ["index.Total revenue"]: "Los ingresos totales",
  ["index.Trading"]: "Iniciar asesor experto en IA",
  ["index.intelligentive trading"]: "Inicie el comercio cuantitativo inteligente de ai ahora",
  ["host.detail"]: "Detalles",
  ["host.todayProfit"]: "Ganancias hoy",
  ["host.totalProfit"]: "Ganancias esperadas",
  ["host.Order quantity"]: "cantidad de pedido",
  ["host.Strategic"]: "Actividades Estratégicas Productos",
  ["host.escrow"]: "cantidad de depósito en garantía",
  ["host.max"]: "máximo",
  ["host.determine"]: "Por supuesto",
  ["host.balance"]: "Saldo disponible",
  ["host.Deposit"]: "fondos de depósito",
  ["host.quota"]: "límite",
  ["host.years"]: "tasa total anualizada",
  ["host.expect"]: "ganancia esperada",
  ["host.profit"]: "Yu'e Bao",
  ["host.lockup"]: "minería de replanteo",
  ["host.liquidated"]: "Penalización por redención anticipada",
  ["host.redeem"]: "redención",
  ["host.isitredeemed"]: "Hay penalización por redención anticipada, ¿es redención?",
  ["host.consignmentOrder"]: "orden confiada",
  ["host.purchase"]: "comprar",
  ["exchange.bond"]: "margen libre",
  ["exchange.optionNo"]: "Tema",
  ["exchange.endTime"]: "Cierre de este número",
  ["exchange.Forecast"]: "período de pronóstico",
  ["enter the amount"]: "Por favor ingrese la cantidad",
  ["Insufficient wallet balance"]: "Saldo de billetera insuficiente para comprar",
  ["minimum purchase amount is"]: "El monto mínimo de compra es",
  ["Purchase successful"]: "compra exitosa",
  ["exchange.orderConifrm"]: "Confirmación del pedido",
  ["exchange.balance"]: "Saldo disponible",
  ["exchange.custormAmount"]: "cantidad personalizada",
  ["exchange.input"]: "por favor escribe",
  ["exchange.confirm"]: "Por supuesto",
  ["exchange.minute"]: "Minuto",
  ["exchange.curentrust"]: "mandato actual",
  ["exchange.hisentrust"]: "comisión histórica",
  ["exchange.noneEntrust"]: "Sin mandato todavía",
  ["exchange.wait"]: "esperar a que se abra",
  ["exchange.opened"]: "abierto",
  ["exchange.rescinded"]: "revocado",
  ["exchange.type"]: "escribe",
  ["exchange.finashTime"]: "hora de cierre",
  ["exchange.openingQuantity"]: "Número de posiciones abiertas",
  ["exchange.awards"]: "Número de premios",
  ["exchange.charge"]: "Tarifa de apertura de posición",
  ["exchange.openPrice"]: "precio de apertura",
  ["exchange.close"]: "Precio de cierre",
  ["exchange.prediction"]: "Dirección de posición",
  ["exchange.pump"]: "bombeo",
  ["hello"]: "Hola",
  ["welcome"]: "Bienvenido a",
  ["change success"]: "Modificado con éxito",
  ["set success"]: "establecer con éxito",
  ["confirm"]: "Por supuesto",
  ["confirm1"]: "Por supuesto",
  ["confirm2"]: "Por supuesto",
  ["cancel"]: "Cancelar",
  ["copySuccess"]: "Copiar con éxito",
  ["please enter"]: "por favor escribe",
  ["no data"]: "Sin datos",
  ["all"]: "todos",
  ["processing"]: "Procesando",
  ["credited"]: "acreditado",
  ["fail"]: "fallar",
  ["timeout failed"]: "tiempo de espera fallido",
  ["amount"]: "Monto",
  ["state"]: "estado",
  ["time"]: "tiempo",
  ["pwd_password"]: "clave",
  ["pwd_Enter password"]: "Por favor, ingrese contraseña",
  ["pwd_old"]: "Contraseña anterior",
  ["pwd_new"]: "Nueva contraseña",
  ["pwd_new again"]: "Confirmar contraseña",
  ["pwd_Enter old password"]: "Por favor ingrese la contraseña anterior",
  ["pwd_Enter new password"]: "Por favor ingrese una nueva contraseña",
  ["pwd_Enter new password again"]: "Por favor, introduzca la nueva contraseña de nuevo",
  ["pwd_set"]: "configurar la clave",
  ["pwd_not empty"]: "la contraseña no puede estar en blanco",
  ["pwd_length"]: "La longitud de la contraseña no puede ser inferior a 6 caracteres",
  ["pwd_old not empty"]: "La contraseña anterior no puede estar vacía",
  ["pwd_new not empty"]: "La nueva contraseña no puede estar vacía",
  ["pwd_inconsistent twice"]: "Las dos contraseñas no coinciden",
  ["home_msg"]: "La tecnología es el alma común de quienes la desarrollan",
  ["home_recharge"]: "depósito rápido",
  ["home_exchange"]: "Comercio de opciones",
  ["home_mining"]: "minería de replanteo",
  ["home_service"]: "Servicio en línea",
  ["home_increaseList"]: "Lista de ganadores",
  ["home_turnover"]: "volumen",
  ["home_currencyName"]: "nombre",
  ["home_newPrice"]: "Último precio",
  ["home_vol24"]: "Volumen de negociación de 24 horas",
  ["home_upDown"]: "Cambio de cotización",
  ["home_warrant"]: "moneda corriente",
  ["trade_tab1"]: "opcional",
  ["trade_tab2"]: "Coin-M Futures",
  ["trade_tab3"]: "Exchange",
  ["trade_tab4"]: "transacción de contrato",
  ["trade_tab5"]: "USDⓈ-M",
  ["trade_tab6"]: "segundo contrato",
  ["trade.empty"]: "Sin datos",
  ["edit_choice"]: "Selección del editor",
  ["add_optional"]: "Agregar a opcional",
  ["save"]: "ahorrar",
  ["select_all"]: "seleccionar todo",
  ["delete"]: "Eliminar",
  ["please_choose"]: "por favor elige",
  ["sidebar_share"]: "quiero compartir",
  ["sidebar_help"]: "Centro de ayuda",
  ["sidebar_service"]: "Servicio en línea",
  ["exchange_selCurrency"]: "Seleccione el tipo de moneda",
  ["exchange_searchText"]: "Por favor ingrese la palabra clave de búsqueda",
  ["exchange_rule"]: "regla",
  ["exchange_bond"]: "margen libre",
  ["exchange_optionNo"]: "Tema",
  ["exchange_endTime"]: "Cierre de este número",
  ["exchange_forecast"]: "período de pronóstico",
  ["exchange_minute"]: "Minuto",
  ["exchange_curentrust"]: "mandato actual",
  ["exchange_noneEntrust"]: "Sin mandato todavía",
  ["exchange_bullish"]: "alcista",
  ["exchange_bearish"]: "osuno",
  ["exchange_orderConifrm"]: "Confirmación del pedido",
  ["exchange_balance"]: "Saldo disponible",
  ["exchange_custormAmount"]: "cantidad personalizada",
  ["host_profit"]: "Yu'e Bao",
  ["host_lockup"]: "minería de replanteo",
  ["host_consignmentOrder"]: "orden confiada",
  ["host_purchase"]: "comprar",
  ["mining_purchase"]: "comprar",
  ["host_todayProfit"]: "Ganancias hoy",
  ["host_totalProfit"]: "Ganancias esperadas",
  ["host_limit"]: "límite de inversión",
  ["host_income"]: "ingreso",
  ["host_funds in custody"]: "fondos en custodia",
  ["host_entrusted order"]: "orden confiada",
  ["host_expected earnings for today"]: "Ganancias esperadas para hoy",
  ["host_cumulative income"]: "ingreso acumulado",
  ["host_order in commission"]: "Pedidos que llegan a la comisión",
  ["host_day"]: "cielo",
  ["host_single limit"]: "límite único",
  ["host_daily rate of return"]: "tasa de rendimiento diaria",
  ["host_cycle"]: "ciclo",
  ["host_processing"]: "Procesando",
  ["host_completed"]: "terminado",
  ["host.redeemed"]: "redimido",
  ["host_histotyProfit"]: "ganancias históricas",
  ["host_illustrate"]: "ilustrar",
  ["host_order details"]: "detalles del pedido",
  ["host_single benefit"]: "beneficio único",
  ["host_single day income"]: "ingresos de un solo día",
  ["host_total revenue"]: "Los ingresos totales",
  ["host_earnings Details"]: "Detalles de ganancias",
  ["host_paid"]: "Pagado",
  ["host_pay miner fees"]: "Pagar tarifas de minero",
  ["host_user output"]: "salida del usuario",
  ["host_address"]: "Dirección",
  ["host_quantity"]: "cantidad",
  ["host_time"]: "tiempo",
  ["host_account Balance"]: "Saldo de la cuenta",
  ["account_total assets equivalent"]: "Activos totales equivalentes",
  ["account_deposit"]: "depósito",
  ["account_withdraw"]: "Retirar",
  ["account_exchange"]: "intercambio",
  ["account_funding Account"]: "Cuenta de fondos",
  ["account_hide small coins"]: "Ocultar monedas pequeñas",
  ["account_available"]: "disponible",
  ["account_processing"]: "Procesando",
  ["account_folded"]: "doblada",
  ["recharge"]: "recargar",
  ["recharge_currency"]: "recargar moneda",
  ["recharge_scan code transfer"]: "Transferencia y recarga de código de escaneo",
  ["recharge_amount"]: "Monto de recarga",
  ["recharge_upload"]: "Subir captura de pantalla de los detalles de pago",
  ["recharge_record"]: "Registro de recarga",
  ["recharge_enter amount"]: "Por favor ingrese el monto de la recarga",
  ["recharge_less zero"]: "El importe de la recarga no puede ser inferior a 0",
  ["recharge_upload screenshot"]: "Sube la captura de pantalla",
  ["recharge_try again later"]: "Cargando imagen, inténtalo de nuevo más tarde",
  ["recharge_successful"]: "recarga exitosa",
  ["recharge_Details"]: "Detalles de recarga",
  ["recharge_currency2"]: "divisa",
  ["recharge_address"]: "dirección de recarga",
  ["recharge_order number"]: "Número de orden",
  ["loading"]: "Cargando…",
  ["investment amount"]: "monto de la inversión",
  ["expire date"]: "Fecha de caducidad",
  ["order number"]: "Número de orden",
  ["start date"]: "fecha de inicio",
  ["end date"]: "fecha final",
  ["cash back"]: "Devolución de dinero",
  ["order"]: "Ordenar",
  ["swap"]: "intercambio",
  ["swap_currency exchange"]: "Cambio de divisas",
  ["swap_please enter exchange amount"]: "Por favor ingrese el monto del cambio",
  ["swap_exchange quantity"]: "Cantidad de intercambio",
  ["swap_up to"]: "hasta",
  ["swap_redeem all"]: "canjear todo",
  ["swap_exchange rate"]: "tipo de cambio",
  ["swap_available"]: "Disponible",
  ["withdraw"]: "retirar",
  ["withdrawal_currency"]: "Moneda de retiro",
  ["withdrawal_Amount_Available"]: "Cantidad disponible",
  ["withdrawal_Please_enter_amount"]: "Por favor ingrese el monto del retiro",
  ["withdrawal_remove_all"]: "eliminar todo",
  ["withdrawal_address"]: "dirección de retiro",
  ["withdrawal_Please_enter_address"]: "Por favor ingrese la dirección de la billetera",
  ["withdrawal_password"]: "Contraseña de retiro",
  ["withdrawal_Please_enter_password"]: "Por favor ingrese la contraseña de retiro",
  ["withdrawal_handling_fee"]: "tasa de tramitación",
  ["withdrawal_Reminder_text"]: "Recordatorio: parte de la tarifa de manejo se cobrará por el retiro y se acreditará en la cuenta en tiempo real después del retiro. Si tiene alguna pregunta, comuníquese con el servicio al cliente.",
  ["withdrawal_under_review"]: "bajo revisión",
  ["withdrawal_success"]: "retiro exitoso",
  ["withdrawal_examination_passed"]: "examen aprobado",
  ["withdrawal_paying_on_behalf"]: "Pagando en nombre",
  ["withdrawal_payment_overtime"]: "pago de horas extras",
  ["withdrawal_record"]: "registro de retiros",
  ["enter the bet amount"]: "Por favor ingrese el monto de la apuesta",
  ["minimum bet amount"]: "El monto mínimo de la apuesta es",
  ["bet up to"]: "La cantidad máxima de la apuesta es",
  ["number is being updated"]: "Problema de actualización, inténtalo de nuevo más tarde",
  ["checkout success"]: "pedido con éxito",
  ["exchange amount cannot be blank"]: "El monto del intercambio no puede estar vacío",
  ["exchange amount must be greater than 0"]: "El monto del intercambio debe ser mayor que 0",
  ["successfully redeemed"]: "Por favor, tenga paciencia.",
  ["enter the withdrawal amount"]: "Por favor ingrese el monto del retiro",
  ["amount cannot be less than 0"]: "El monto del retiro no puede ser inferior a 0",
  ["enter the withdrawal address"]: "Por favor ingrese la dirección de retiro",
  ["enter the withdrawal password"]: "Por favor ingrese la contraseña de retiro",
  ["successful application"]: "Solicitud exitosa",
  ["set the password first"]: "Por favor establezca una contraseña primero",
  ["Withdrawal details"]: "Detalles de retiro",
  ["Withdrawal quantity"]: "Cantidad de retiro",
  ["Withdrawal address"]: "dirección de retiro",
  ["share_invite"]: "Invita a tus amigos a usar",
  ["share_link"]: "compartir enlace",
  ["share"]: "Cuota",
  ["add_success"]: "Agregado exitosamente",
  ["mining.question1"]: "¿Qué es la minería de apuestas? ¿De dónde provienen los ingresos?",
  ["mining.answer1"]: "La minería de apuestas es un producto de herramienta creado por la compañía para ayudar a los usuarios a comprometerse rápidamente en la cadena para obtener recompensas. Al apostar activos digitales en la red blockchain y obtener recompensas basadas en el mecanismo POS (Proof of Stake, es decir, prueba de participación). En este mecanismo, los usuarios confían los activos digitales a los nodos y los nodos ejercen los derechos para producir bloques, empaquetar transacciones, etc. en la cadena de bloques y recibir recompensas. Los usuarios comparten las recompensas obtenidas por los nodos proporcionalmente según el número de posiciones bloqueadas.",
  ["mining.question2"]: "¿Qué es la anualización de referencia? ¿Como calcular?",
  ["mining.answer2"]: "La tasa anualizada de referencia es la tasa de retorno anualizada de referencia del producto que contrató, que se actualizará dinámicamente de acuerdo con las ganancias reales en la cadena, solo para su referencia. Anualización de referencia = ingresos en cadena/cantidad de bloqueo en cadena/tiempo de bloqueo en cadena*365*100%.",
  ["mining.question3"]: "¿Cuál es el retorno esperado? ¿Como calcular?",
  ["mining.answer3"]: "Los ingresos esperados son los ingresos diarios esperados calculados en función de la cantidad de su suscripción y la referencia del producto anualizados, y son solo para su referencia. Rentabilidad diaria esperada = número de suscripciones * referencia anualizada / 365.",
  ["mining.question4"]: "¿Cuándo comienza a devengarse el interés?",
  ["mining.answer4"]: "Para la suscripción del día T, los intereses se calcularán a partir de las 00:00 horas del día T+1.",
  ["mining.question5"]: "¿Cuándo se distribuirán los beneficios?",
  ["mining.answer5"]: "Suscripción el día T, los intereses comenzarán a generarse el día T+1, y los ingresos se distribuirán junto con el principal una vez vencido.",
  ["mining.question6"]: "¿Puedo canjear productos a plazo antes de tiempo? ¿Cuál es el impacto?",
  ["mining.answer6"]: "Puedes canjear tu pedido en cualquier momento, pero si canjeas el producto a plazo de forma anticipada, perderás parte de los ingresos ya obtenidos del pedido, y los ingresos emitidos se descontarán de tu principal en el momento del canje.",
  ["mining.question7"]: "¿Necesito canjear manualmente después de que expire el plazo del producto?",
  ["mining.answer7"]: "Redención automática después de que expire el plazo del producto.",
  ["mining.question8"]: "1. ¿Cómo me uno?",
  ["mining.answer8"]: "Para participar en la minería de liquidez no destructiva y no segura, debe pagar una tarifa de minero ETH. Solo se debe reclamar una dirección de billetera ETH una vez, y el permiso de minería se abrirá automáticamente después del éxito.",
  ["mining.question9"]: "2. ¿Cómo retiro dinero?",
  ["mining.answer9"]: "Puede convertir sus monedas generadas diariamente a USDT y luego iniciar un retiro. Los retiros de USDT se enviarán automáticamente a la dirección de la billetera que agregó al nodo; no se admiten otras direcciones.",
  ["mining.question10"]: "3. ¿Cómo calcular los ingresos?",
  ["mining.answer10"]: "Cuando se une con éxito, el contrato inteligente comienza a calcular su dirección a través del nodo y comienza a calcular los ingresos.",
  ["show_more"]: "ver más",
  ["promble"]: "problema comun",
  ["what_yeb"]: "¿Qué es Yu'e Bao?",
  ["what_yeb_answer"]: "Yubibao es un producto creado por la empresa para ayudar a los usuarios que tienen activos digitales inactivos y a los usuarios que necesitan pedir dinero prestado. Tiene las características de depósito y retiro instantáneos, cálculo de intereses por hora y soporte para la personalización de tasas de interés. Después de que el usuario transfiera los activos digitales a Yubibao, el sistema determinará si el préstamo es exitoso en cada hora de acuerdo con la tasa de préstamo establecida por el usuario. Después de que el préstamo sea exitoso, se puede obtener el interés por la hora, de lo contrario, el interés no se puede obtener y debe esperar la próxima vez Resultados coincidentes de una hora. Basándose en un estricto sistema de control de riesgos, Yubibao garantiza plenamente la seguridad de las transacciones de los usuarios.",
  ["what_lixi"]: "¿Cómo se calcula el interés?",
  ["what_lixi_answer"]: "Interés (por día) = monto del préstamo * anualización del préstamo de mercado / 365/24 * 85%",
  ["rule_yeb_title1"]: "¿Qué es Yu'e Bao?",
  ["rule_yeb_cont1"]: "Yubibao es un producto creado por la empresa para ayudar a los usuarios que tienen activos digitales inactivos y a los usuarios que necesitan pedir dinero prestado. Tiene las características de depósito y retiro instantáneos, cálculo de intereses por hora y soporte para la personalización de tasas de interés. Después de que el usuario transfiera los activos digitales a Yubibao, el sistema determinará si el préstamo es exitoso en cada hora de acuerdo con la tasa de préstamo establecida por el usuario. Después de que el préstamo sea exitoso, se puede obtener el interés por la hora, de lo contrario, el interés no se puede obtener y debe esperar la próxima vez Resultados coincidentes de una hora. Basándose en un estricto sistema de control de riesgos, Yubibao garantiza plenamente la seguridad de las transacciones de los usuarios.",
  ["rule_yeb_note1"]: "*Tenga en cuenta: los rendimientos históricos no son indicativos de rendimientos futuros. No prometemos reembolsar el capital y los intereses en moneda, objetos físicos, acciones o cualquier otra forma de devolución de propiedad dentro de un cierto período de tiempo.",
  ["rule_yeb_title2"]: "Ventajas del producto",
  ["rule_yeb_til2"]: "Robusto",
  ["rule_yeb_cont2"]: "Basándose en un estricto sistema de control de riesgos, Yu'ebao garantiza completamente la seguridad de los activos de los usuarios y les permite disfrutar de las ganancias con tranquilidad.",
  ["rule_yeb_title3"]: "Instrucciones para invertir en activos rentables",
  ["rule_yeb_cont3"]: "Después de que los usuarios transfieran sus activos a Yubibao, los activos digitales comprados se prestarán a los usuarios del comercio apalancado.",
  ["rule_yeb_title4"]: "estado de resultados",
  ["rule_yeb_til4"]: "regla de ingresos",
  ["rule_yeb_cont4"]: "Si la subasta tiene éxito, los ingresos del día anterior se capitalizarán y liquidarán a las 02:00 del día siguiente.",
  ["rule_yeb_title5"]: "Descripción de la composición del ingreso",
  ["rule_yeb_cont5"]: "El 15% del interés del préstamo del usuario se utilizará como depósito de riesgo, y el 85% se destinará al usuario del préstamo, es decir, el interés que el usuario del préstamo puede obtener es: Principal del préstamo * Préstamo actual anualizado / 365 / 24 * 85%",
  ["rule_yeb_title6"]: "Descripción de la transacción",
  ["rule_yeb_cont61"]: "Suscripción de soporte en cualquier momento, comience a ofertar en la hora posterior a la suscripción",
  ["rule_yeb_cont62"]: 'Reglas de conciliación de tasas de interés: el mercado oferta cada hora de acuerdo con la demanda de préstamos, de acuerdo con la "anualización mínima de préstamos" de menor a mayor, y el valor más alto de la subasta se utilizará como "anualización actual de préstamos".',
  ["rule_yeb_cont63"]: "Si la anualización mínima del préstamo < anualización del préstamo actual, la anualización del préstamo actual se utilizará para prestar con éxito",
  ["rule_yeb_cont64"]: "Si la anualización mínima del préstamo > la anualización actual del préstamo, el préstamo falla y no hay intereses",
  ["rule_yeb_cont65"]: "Si la anualización mínima del préstamo = la anualización actual del préstamo, se puede prestar parcialmente o el préstamo puede fallar",
  ["rule_yeb_title7"]: "reglas de canje",
  ["rule_yeb_til7"]: "Amortización automática al vencimiento",
  ["rule_wk_title1"]: "¿Qué es la minería de apuestas?",
  ["rule_wk_cont1"]: "La minería de apuestas es un producto de herramienta creado por la compañía para ayudar a los usuarios a comprometerse rápidamente en la cadena para obtener recompensas. Al apostar activos digitales en la red blockchain y obtener recompensas basadas en el mecanismo POS (Proof of Stake, es decir, prueba de participación). En este mecanismo, los usuarios confían los activos digitales a los nodos y los nodos ejercen los derechos para producir bloques, empaquetar transacciones, etc. en la cadena de bloques y recibir recompensas. Los usuarios comparten las recompensas obtenidas por los nodos proporcionalmente según el número de posiciones bloqueadas.",
  ["rule_wk_note1"]: "*Tenga en cuenta: los rendimientos históricos no son indicativos de rendimientos futuros. No prometemos reembolsar el capital y los intereses en moneda, objetos físicos, acciones o cualquier otra forma de devolución de propiedad dentro de un cierto período de tiempo.",
  ["rule_wk_title2"]: "Ventajas del producto",
  ["rule_wk_til2"]: "Robusto",
  ["rule_wk_cont2"]: "La minería de participación puede obtener recompensas relativamente estables de terceros, con varios períodos de productos.",
  ["rule_wk_title3"]: "Instrucciones para invertir en activos rentables",
  ["rule_wk_cont3"]: "La minería de participación es comprometer sus activos digitales en la cadena de bloques para respaldar el funcionamiento de la red de la cadena de bloques y obtener las recompensas correspondientes.",
  ["rule_wk_title4"]: "estado de resultados",
  ["rule_wk_cont4"]: "Después de una suscripción exitosa en el día T, el interés comienza a las 00:00 del día T+1 y se liquida a las 02:00.",
  ["rule_wk_title5"]: "Descripción de la transacción",
  ["rule_wk_til5"]: "regla de compra",
  ["rule_wk_cont5"]: "Soporte de suscripción en cualquier momento.",
  ["rule_wk_title6"]: "reglas de canje",
  ["rule_wk_cont61"]: "El soporte para el canje en cualquier momento, y el tiempo de espera para el canje de diferentes proyectos es diferente.",
  ["rule_wk_cont62"]: "Cuando el plazo no haya vencido, se amortizará anticipadamente y se deducirá parte de los intereses pagados.",
  ["rule_wk_cont63"]: "Redención no admitida",
  ["rule_wk_title7"]: "advertencia de riesgo",
  ["rule_wk_cont7"]: "Para la redención anticipada de productos regulares, el sistema descontará parte de los ingresos ya obtenidos del pedido.",
  ["rule_fzywk_title1"]: "Minería de liquidez",
  ["rule_fzywk_cont1"]: "La minería de liquidez es una función de gestión de riqueza creada por la empresa para ayudar a los usuarios a gestionar activos digitales y obtener rendimientos eficientes a largo plazo. El usuario hace clic (paga la tarifa de minero) y paga una determinada tarifa de minero para generar ingresos de inmediato. El usuario solo necesita pagar una vez para obtener un efecto permanente, y no es necesario hacer clic para pagar nuevamente en el futuro. La proporción de ingresos depende en el saldo de la billetera del usuario. Cuanto mayor sea el saldo, mayor será la relación de ingresos.",
  ["rule_fzywk_note"]: "Nota: (no préstamos) Los detalles de ganancias se envían 4 veces al día cada 6 horas. Puede ver las ganancias detalladas a través de Detalles de ganancias.",
  ["rule_fzywk_note1"]: "Nota: (Este producto es un producto de drenaje de bienestar con una cuota limitada en la actualidad. En el futuro, la evaluación de activos digitales se agregará a los usuarios, y este producto solo se abrirá para usuarios calificados, por lo que se atiende por orden de llegada).",
  ["rule_fzywk_title2"]: "Ventajas del producto",
  ["rule_fzywk_til2"]: "Beneficios robustos",
  ["rule_fzywk_cont2"]: "Se pueden obtener beneficios a largo plazo sin prestar fondos, garantizando la seguridad de los activos de los usuarios y disfrutando de los beneficios sin arriesgarse a perder.",
  ["rule_fzywk_title3"]: "estado de resultados",
  ["rule_fzywk_til3"]: "regla de ingresos",
  ["rule_fzywk_cont3"]: "Entrará en vigencia inmediatamente después de que la autorización de pago sea exitosa, y los ingresos se distribuirán dentro de un período de tiempo fijo todos los días. El ciclo de ingresos del usuario es de 6 horas y se puede obtener un total de 4 veces de ingresos dentro de las 24 horas.",
  ["rule_fzywk_til3_1"]: "Relación de ingresos por engranajes",
  ["rule_fzywk_tab1"]: "engranaje",
  ["rule_fzywk_tab2"]: "cantidad",
  ["rule_fzywk_tab3"]: "tasa de retorno",
  ["rule_fzywk_tab4"]: "unidad de ingresos",
  ["unlimited"]: "ilimitado",
  ["verified"]: "Verificado",
  ["verified_tips"]: "Para garantizar una experiencia de cuenta segura, vincule su información de identificación personal",
  ["verified_name"]: "Nombre",
  ["verified_input_name"]: "Por favor escriba su nombre",
  ["verified_card"]: "número de licencia",
  ["verified_input_card"]: "Por favor ingrese el número de identificación",
  ["photo_front"]: "foto de identificación",
  ["clearly_visible"]: "(Asegúrese de que los documentos estén claramente visibles)",
  ["front_photo"]: "Sube el anverso de la foto del DNI",
  ["reverse_photo"]: "Sube el reverso de tu foto de identificación",
  ["standard"]: "estándar",
  ["missing"]: "perdido",
  ["vague"]: "Impreciso",
  ["strong flash"]: "destello fuerte",
  ["confirm_submit"]: "Confirmar y enviar",
  ["need to change"]: "Si necesita cambiar la encuadernación, por favor",
  ["Contact Customer Service"]: "Contactar Servicio al Cliente",
  ["authentication succeeded"]: "Autenticación exitosa",
  ["please_information"]: "Por favor complete la información",
  ["only_symbol"]: "Basta con mirar el par comercial actual",
  ["options"]: "opciones",
  ["occupy"]: "Ocupar",
  ["bb_buy"]: "comprar",
  ["bb_sell"]: "vender",
  ["bb_count"]: "cantidad",
  ["bb_current_best_price"]: "Opere al mejor precio actual",
  ["bb_turnover"]: "Rotación",
  ["bb_Limit order"]: "orden limitada",
  ["bb_market order"]: "orden de mercado",
  ["bb_successful operation"]: "Operación exitosa",
  ["bb_operation failed"]: "operación fallida",
  ["bb_Hide other trading pairs"]: "Ocultar otros pares comerciales",
  ["bb_price"]: "precio",
  ["bb_volume"]: "volumen",
  ["bb_default"]: "defecto",
  ["bb_buy order"]: "orden de compra",
  ["bb_sell order"]: "orden de venta",
  ["bb_commission price"]: "precio de comisión",
  ["bb_commission amount"]: "monto de la comisión",
  ["bb_Cancellation"]: "Cancelación",
  ["bb_total turnover"]: "facturación total",
  ["bb_Average transaction price"]: "Precio promedio de transacción",
  ["bb_Undo succeeded"]: "Deshacer exitoso",
  ["bb_Undo failed"]: "Deshacer fallido",
  ["bb_Deal done"]: "Trato hecho",
  ["bb_Revoked"]: "revocado",
  ["bb_depth map"]: "mapa de profundidad",
  ["bb_actual price"]: "precio actual",
  ["bb_to buy order"]: "orden de compra",
  ["bb_to sell order"]: "orden de venta",
  ["bb_direction"]: "dirección",
  ["bb_clinch"]: "hacer un trato",
  ["user_login"]: "Inicio de sesión de usuario",
  ["password_placeholder"]: "Por favor, ingrese contraseña",
  ["code_placeholder"]: "por favor ingrese el código de verificación",
  ["login"]: "Iniciar sesión",
  ["no account"]: "¿No tienes una cuenta?",
  ["to register"]: "registrarse",
  ["Forgot password"]: "¿contraseña olvidada?",
  ["user register"]: "Registro de usuario",
  ["username"]: "nombre de usuario",
  ["repassword_placeholder"]: "Por favor ingrese la contraseña nuevamente",
  ["register"]: "Registrarse",
  ["have account"]: "¿Ya tienes una cuenta?",
  ["to login"]: "ir a iniciar sesión",
  ["address_placeholder"]: "Por favor ingrese la dirección de la billetera",
  ["password does not match"]: "Las dos contraseñas no coinciden",
  ["username_msg"]: "por favor ingrese el nombre de usuario",
  ["ua_msg"]: "Ingrese el nombre de usuario/dirección de la billetera",
  ["register_failed"]: "registro fallido",
  ["login_failed"]: "error de inicio de sesion",
  ["invalid_address"]: "Dirección no válida, abra y autorice en la billetera",
  ["exchange_hisentrust"]: "comisión histórica",
  ["sidebar_bindPassword"]: "Configurar una contraseña de fondo",
  ["sidebar_changePassword"]: "Modificar la contraseña del fondo",
  ["sidebar_setLoginPassword"]: "Configuración de la contraseña de inicio de sesión",
  ["sidebar_changeLoginPassword"]: "Modificar contraseña de inicio de sesión",
  ["add_address"]: "dirección de enlace",
  ["paste"]: "pegar",
  ["No amount available"]: "No hay cantidad disponible",
  ["Please commission amount"]: "Por favor ingrese el monto de la comisión",
  ["country"]: "Pais de Ciudadania",
  ["verified_input_country"]: "Por favor ingrese nacionalidad",
  ["Please select a chain"]: "Por favor seleccione una cadena",
  ["login_out"]: "desconectar",
  ["login_out_sure"]: "¿Estás seguro de cerrar sesión?",
  ["login_out_success"]: "salir con éxito",
  ["login_out_faild"]: "no se pudo salir",
  ["login_out_cancel"]: "cancelar salida",
  ["real_name_withdrawal"]: "Complete la verificación del nombre real lo antes posible antes de retirar",
  ["going_long"]: "Ve largo",
  ["open_empty"]: "abierto",
  ["can_open_empty"]: "abertible",
  ["cash_deposit"]: "Margen",
  ["fund_transfer"]: "Transferencia de fondos",
  ["from"]: "de",
  ["arrive"]: "llegar",
  ["fund_account"]: "Cuenta de fondos",
  ["transaction_account"]: "cuenta de transacciones",
  ["transfer_all"]: "transferir todo",
  ["up_to"]: "hasta",
  ["contract_account"]: "cuenta de contrato",
  ["spot_account"]: "cuenta al contado",
  ["transfer_amount"]: "Por favor ingrese el monto de la transferencia",
  ["max_transfer"]: "Transferir hasta",
  ["current_position"]: "posición actual",
  ["minimum_transfer"]: "El monto mínimo de transferencia es",
  ["sell"]: "Vender",
  ["buy"]: "compra",
  ["null"]: "nulo",
  ["income"]: "ingreso",
  ["yield_rate"]: "tasa de retorno",
  ["open_interest"]: "interes abierto",
  ["position_collateral_assets"]: "Posicionar activos colaterales",
  ["average_opening_price"]: "Precio medio de apertura",
  ["expected_strong_parity"]: "Fuerte paridad esperada",
  ["guaranteed_asset_ratio"]: "Ratio de activos garantizados",
  ["close_position"]: "cerrar la posición",
  ["adjustment_margin"]: "Margen de ajuste",
  ["stop_profit"]: "Tomar ganancias",
  ["stop_loss"]: "detener la pérdida de",
  ["profit_loss"]: "Take Profit y Stop Loss",
  ["many"]: "muchos",
  ["cancel_bulk"]: "Cancelación masiva",
  ["trigger_price"]: "precio de activación",
  ["mark"]: "Marcos",
  ["order_price"]: "Precio del pedido",
  ["total_entrustment"]: "Importe total de la encomienda",
  ["latest_price"]: "Último precio de transacción",
  ["profit_price"]: "Precio de activación Take Profit",
  ["loss_price"]: "Precio de activación de stop loss",
  ["profit_order"]: "Tomar orden de ganancias",
  ["loss_order"]: "Orden Stop Loss",
  ["profit_tips_1"]: "precio de mercado a",
  ["profit_tips_2"]: "La orden de toma de ganancias se activará cuando se complete la transacción, y se espera que la ganancia se obtenga después de la transacción.",
  ["profit_tips_3"]: "Cuando se activa la orden de stop loss, la pérdida esperada después de la transacción",
  ["order_count"]: "cantidad",
  ["market_price"]: "precio de mercado",
  ["limit_price"]: "precio límite",
  ["margin_call"]: "llamada de margen",
  ["margin_reduction"]: "reducción de margen",
  ["sustainable"]: "sostenible",
  ["increase_most"]: "aumentar a lo sumo",
  ["reduce_most"]: "reducir a lo sumo",
  ["after_price"]: "Precio de liquidación después de la adición",
  ["add_lever"]: "El apalancamiento adicional",
  ["sub_price"]: "Precio de liquidación reducido",
  ["sub_lever"]: "Apalancamiento reducido",
  ["commission_detail"]: "Detalles de la comisión",
  ["to_trade"]: "intercambiar",
  ["order_status"]: "Estado del pedido",
  ["delegate_type"]: "tipo de delegado",
  ["volume"]: "volumen",
  ["delegate_value"]: "valor delegado",
  ["commission_time"]: "tiempo de comisión",
  ["currency_least_purchase"]: "Compra mínima requerida",
  ["quick_close"]: "Cierre rápido",
  ["closing_price"]: "Precio de cierre",
  ["completely_sold"]: "Completamente vendido",
  ["not_contract_transactions"]: "La moneda actual no admite transacciones de contrato",
  ["coinbase_h_l_hot"]: "Monedas populares",
  ["coinbase_h_market"]: "Cotizaciones de divisas",
  ["coinbase_h_coin"]: "divisa",
  ["coinbase_h_vol"]: "Volumen de comercio",
  ["coinbase_h_price"]: "precio",
  ["coinbase_h_quote"]: "Cambio de cotización",
  ["coinbase_h_more"]: "ver más",
  ["coinbase_h_pt"]: "Plataforma de comercio de criptomonedas confiable para el usuario",
  ["coinbase_h_tit1"]: "Fondo de activos de seguridad del usuario (SAFU)",
  ["coinbase_h_conn1"]: "Almacene el 10% de todas las tarifas de transacción en el fondo de activos seguros para brindar protección parcial a los fondos de los usuarios",
  ["coinbase_h_tit2"]: "Control de acceso personalizado",
  ["coinbase_h_conn2"]: "El control de acceso avanzado restringe el acceso a dispositivos y direcciones de cuentas personales, brindando tranquilidad a los usuarios",
  ["coinbase_h_tit3"]: "Cifrado de datos avanzado",
  ["coinbase_h_conn3"]: "Los datos de transacciones personales están protegidos mediante cifrado de extremo a extremo y solo la persona puede acceder a la información personal.",
  ["coinbase_hq_title"]: "pregunta relacionada",
  ["coinbase_hq_tit1"]: "1. ¿Qué es Defi?",
  ["coinbase_hq_conn1"]: "El nombre completo de DeFi es Finanzas Descentralizadas, también conocido comoFinanzas abiertas, casi todosDeFiTodos los proyectos se llevan a cabo en la cadena de bloques de Ethereum. Ethereum es una plataforma global de código abierto para aplicaciones descentralizadas. En Ethereum, puede escribir código para administrar activos digitales y ejecutar programas sin restricciones geográficas.",
  ["coinbase_hq_tit2"]: "2. ¿Cuáles son las ventajas de las finanzas descentralizadas?",
  ["coinbase_hq_conn2"]: "Las finanzas descentralizadas aprovechan los principios clave de blockchain para mejorar la seguridad financiera y la transparencia, desbloquear oportunidades de liquidez y crecimiento y respaldar un sistema económico integrado y estandarizado. Los contratos inteligentes altamente programables automatizan y respaldan la creación de nuevos instrumentos financieros y activos digitales. La orquestación de datos a prueba de manipulaciones en la arquitectura descentralizada de blockchain mejora la seguridad y la auditabilidad.",
  ["coinbase_hq_tit3"]: "3. ¿Cómo ejecuta Defi las transacciones?",
  ["coinbase_hq_conn3"]: 'Defi actualmente admite contratos perpetuos, contratos de entrega y comercio cuantitativo de aluminio. Los contratos perpetuos son un tipo de futuros "innovadores". Un contrato, un contrato tradicional tiene una fecha de vencimiento, pero un contrato perpetuo no tiene fecha de entrega y se puede celebrar para siempre, por lo que se llama contrato perpetuo. El comercio cuantitativo manual se refiere al uso de la tecnología informática existente para analizar una gran cantidad de datos en un período corto de tiempo y luego operar automáticamente a través de un modelo comercial preescrito para mejorar la eficiencia comercial. Esta computadora se utiliza para negociar diferenciales en las principales bolsas.',
  ["coinbase_hq_tit4"]: "4. ¿Por qué elegir coinbase para operar?",
  ["coinbase_hq_conn4"]: 'Al operar en un entorno altamente regulado, Coinbase toma medidas adicionales para garantizar que los datos de los clientes estén protegidos, y ni siquiera los propios científicos e ingenieros de datos de Coinbase tienen acceso gratuito a estos datos. Cualquier código que se ejecute en los servidores de producción de Coinbase debe someterse a una revisión de código por parte de varios grupos antes de entrar en producción. "Uno de nuestros principios básicos es la seguridad primero, ya que los activos digitales almacenados en nuestra plataforma pertenecen a nuestros clientes.',
  ["coinbase_hq_tit5"]: "5. La visión de Pangea Capital Management para su asociación con Coinbase.",
  ["coinbase_hq_conn5"]: "2019 Pan Continental Capital Management. Financiamiento de $230 millones para ingresar al mercado de divisas de Coinbase, liderando el establecimiento de una asociación estratégica con Coinbase, el mayor intercambio de divisas de Coinbase en los Estados Unidos, en términos de blockchain, y cooperando con Coinabse para realizar una investigación profunda sobre las finanzas descentralizadas de Defi y construir una tecnología blockchain independiente y aplicaciones descentralizadas. Realice intercambios descentralizados de monedas base de monedas. agencia de auditoría de contratos",
  ["coinbase_ho_tit1"]: "auditor de contrato",
  ["coinbase_ho_tit2"]: "Spot/Apalancamiento/Contrato",
  ["coinbase_ho_tit3"]: "Replanteo de minería, DeFi, etc.",
  ["coinbase_ho_tit4"]: "a punto de empezar a operar",
  ["coinbase_ho_tit5"]: "Plataforma de intercambio descentralizada",
  ["coinbase_ho_tit6"]: "negocia ahora",
  ["coinbase_ho_tit7"]: "Compañero",
  ["coinbase_ho_tit8"]: "conectar billetera",
  ["coinbase_ho_tit9"]: "Su navegador no es compatible con la extensión de la billetera",
  ["coinbase_hs_tit1"]: "Términos de servicio",
  ["coinbase_hs_tit2"]: "papel blanco",
  ["coinbase_hs_tit3"]: "Verificacion de Email",
  ["coinbase_he_tit1"]: "Enlace de correo electrónico",
  ["coinbase_he_tit2"]: "Correo",
  ["coinbase_he_tit3"]: "código de verificación",
  ["coinbase_he_tit4"]: "Enviar el código de verificación",
  ["coinbase_he_tit5"]: "por favor ingrese su correo electrónico",
  ["coinbase_he_tit6"]: "reenviar",
  ["tokpiedex_he_tit3"]: "código de verificación",
  ["opening time"]: "hora de apertura",
  ["view_desc"]: "Ver descripción",
  ["host_non-collateralized mining"]: "Minería defi",
  ["air_activity"]: "Actividad de lanzamiento aéreo",
  ["total_output"]: "producción total",
  ["valid_node"]: "nodo válido",
  ["participant"]: "partícipe",
  ["user_benefit"]: "Beneficios para el usuario",
  ["participate_mining"]: "participar en la minería",
  ["take_over"]: "tomar el control",
  ["divide"]: "dividir",
  ["balance_reaches"]: "Cuando el saldo de la billetera alcanza",
  ["you_get"]: "conseguirás",
  ["mining_pool"]: "Recompensas de los pools de minería",
  ["valid_time"]: "Recibir tiempo válido:",
  ["air_activity_help_1"]: "Un contrato inteligente es un protocolo informático diseñado para propagar, verificar o ejecutar un contrato de manera informativa. Los contratos inteligentes permiten transacciones confiables rastreables e irreversibles sin un tercero. El concepto de contratos inteligentes fue propuesto por primera vez por Nick Szabo en 1995. El propósito de los contratos inteligentes es proporcionar un método seguro que sea superior a los contratos tradicionales, reduciendo otros costos de transacción asociados con los contratos. Los contratos inteligentes se ejecutan de forma completamente autónoma, sin intervención humana, y son justos y equitativos.",
  ["air_activity_help_2"]: "Los lanzamientos desde el aire se ejecutan de forma autónoma con los contratos inteligentes de Ethereum, y no todos los usuarios son elegibles para participar en los lanzamientos desde el aire. Cuando obtiene una tarea de lanzamiento aéreo para un contrato inteligente, solo necesita completar las condiciones de la tarea para obtener la recompensa.",
  ["air_activity_help_3"]: "Los estándares de las misiones de lanzamiento aéreo son diferentes y las recompensas obtenidas son diferentes.",
  ["submit_success"]: "Enviado satisfactoriamente",
  ["verified_wait"]: "La información está siendo revisada",
  ["air_activity_msg_1"]: "Gracias por su participación, la recompensa ETH se ha distribuido al saldo de su cuenta",
  ["promotion_center"]: "Centro de Promoción",
  ["invite_friende"]: "Invita a tus amigos a ganar monedas juntos",
  ["invite_friends_commission"]: "Invita a amigos, recarga al instante y obtén una comisión",
  ["promotion_rule"]: "Ver reglas de promoción",
  ["my_promotion"]: "mi promoción",
  ["total_promotions"]: "Número total de promociones",
  ["commission_amount"]: "monto de la comisión",
  ["generation_p"]: "generación",
  ["second_generation_p"]: "Número de segunda generación",
  ["three_generations_p"]: "Tres generaciones",
  ["invitation_code_y"]: "su código de invitación",
  ["sponsored_link"]: "Enlaces patrocinados",
  ["click_share"]: "Haz clic para compartir",
  ["commission_details"]: "Detalles de la comisión",
  ["generation"]: "generación",
  ["second_generation"]: "segunda generación",
  ["three_generations"]: "Tres generaciones",
  ["user_ID"]: "ID de usuario",
  ["rebate_amount"]: "monto del reembolso",
  ["registration_time"]: "tiempo de registro",
  ["QRcode_register"]: "Escanea el código QR para registrarte",
  ["click_save"]: "clic en Guardar",
  ["share_amount"]: "Monto",
  ["invitation_code"]: "Código de invitación (opcional)",
  ["option_buy_up"]: "comprar",
  ["option_buy_short"]: "comprar corto",
  ["option_countdown"]: "cuenta regresiva",
  ["option_purchase_price"]: "precio de compra",
  ["option_profitability"]: "Rentabilidad",
  ["option_minimum_amount"]: "monto minimo",
  ["option_profit_loss"]: "Ganancias y pérdidas estimadas",
  ["option_latest_price"]: "Último precio",
  ["option_now_price"]: "precio actual",
  ["option_profit_loss_1"]: "Ganancia y perdida",
  ["option_profit_loss_2"]: "Importe mínimo de compra",
  ["option_profit_loss_3"]: "El precio final está sujeto a la liquidación del sistema.",
  ["host_single_limit_1"]: "cuota",
  ["record_tip"]: "Nota",
  ["record_tip_1"]: "Estimado cliente, sus compras disponibles se han agotado, comuníquese con el servicio al cliente",
  ["available_time"]: "Horarios disponibles",
  ["about_us"]: "sobre nosotros",
  ["home_tip_agree"]: "Al registrarme acepto",
  ["home_tip_terms"]: "Términos de Uso",
  ["home_tip_agreement"]: "Política de privacidad",
  ["home_tip_score"]: "puntuación de crédito",
  ["home_tip_service_email"]: "Correo electrónico oficial de atención al cliente",
  ["home_tip_and"]: "y",
  ["quick_label_1"]: "Liquidación debida completada",
  ["quick_label_2"]: "precio actual",
  ["quick_label_3"]: "ciclo",
  ["quick_label_5"]: "cantidad",
  ["quick_label_6"]: "precio",
  ["quick_label_7"]: "ganancia esperada",
  ["coinbase2_buy_tip"]: "Por favor, póngase en contacto con el servicio al cliente para comprar",
  ["upload_file_error"]: "El tamaño del archivo supera los 1,5 M y no se puede cargar, cambie la imagen para cargar",
  ["tokpiedex_home_recharge"]: "depósito rápido",
  ["tokpiedex_trade_tab6"]: "segundo contrato",
  ["defi_host_non-collateralized mining"]: "Minería defi",
  ["defi_host_lockup"]: "minería de replanteo",
  ["defi_participate_mining"]: "participar en la minería",
  ["defi_host_non-collateralized"]: "Minería defi",
  ["defi_h_pt"]: "Plataforma de comercio de criptomonedas confiable para el usuario",
  ["defi_hq_tit4"]: "4. ¿Por qué elegir Coinsaving para operar?",
  ["defi_hq_tit5"]: "5. La visión de Pangea Capital Management para su asociación con Coinsaving.",
  ["defi_hq_conn4"]: 'Al operar en un entorno altamente regulado, Coinsaving toma medidas adicionales para garantizar que los datos de los clientes estén protegidos, y ni siquiera los propios científicos e ingenieros de datos de Coinsaving tienen acceso gratuito a estos datos. Cualquier código que se ejecute en los servidores de producción de Coinsaving debe someterse a una revisión de código por parte de varios grupos antes de entrar en producción. "Uno de nuestros principios básicos es la seguridad primero, ya que los activos digitales almacenados en nuestra plataforma pertenecen a nuestros clientes.',
  ["defi_hq_conn5"]: "2019 Pan Continental Capital Management. Financiamiento de $230 millones para ingresar al mercado de divisas de Coinsaving, liderando el establecimiento de una asociación estratégica con Coinsaving, el mayor intercambio de divisas de Coinsaving en los Estados Unidos, en términos de blockchain, y cooperando con Coinabse para realizar una investigación profunda sobre las finanzas descentralizadas de Defi y construir una tecnología blockchain independiente y aplicaciones descentralizadas. Realice intercambios descentralizados de monedas base de monedas. agencia de auditoría de contratos",
  ["rachange_link_huobi"]: "Canal de recarga oficial de Huobi",
  ["rachange_link_binance"]: "Canal de depósito oficial de Binance",
  ["rachange_link_coinbase"]: "Canal de recarga oficial de Coinbase",
  ["rachange_link_crypto"]: "Canal oficial de recarga de criptomonedas",
  ["recharge_hash"]: "ID de transacción",
  ["recharge_hash_placeholder"]: "Por favor ingrese ID de transacción",
  ["symbol_closed"]: "cerrado",
  ["email_placeholder"]: "por favor ingrese su correo electrónico",
  ["email_title"]: "Correo",
  ["email_code"]: "código de verificación",
  ["email register"]: "registro de email",
  ["email login"]: "Correo electrónico de inicio de sesión",
  ["email_code_placeholder"]: "Por favor, introduzca el código de verificación de correo electrónico",
  ["use_email_change"]: "Usar correo electrónico para modificar",
  ["use_password_change"]: "Utilice la contraseña original para modificar",
  ["tip_change"]: "Nota: Si el buzón no está atado o se pierde, por favor",
  ["forgot_password"]: "Se te olvidó tu contraseña",
  ["i_know"]: "ya veo",
  ["regulatory_permission"]: "Licencia financiera",
  ["regulatory_conn_1"]: "PLATFORM_TITLE Group está sujeto a una supervisión estricta por parte de instituciones financieras autorizadas en muchos lugares del mundo, incluida la Comisión Australiana de Valores e Inversiones (número de regulación: 001296201) y la Autoridad Canadiense de Conducta del Mercado Financiero (número de regulación: M19578081). PLATFORM_TITLE cumple totalmente con las estrictas regulaciones financieras de la Comisión Australiana de Valores e Inversiones y la Autoridad Canadiense de Conducta del Mercado Financiero. Bajo la supervisión de las agencias reguladoras, PLATFORM_TITLE garantiza la transparencia absoluta de todas sus transacciones y es uno de los comerciantes de derivados financieros más confiables del mundo.",
  ["regulatory_conn_2"]: "Versign es el socio de seguridad de red global de PLATFORM_TITLE Capital Group. La puerta de enlace adopta el estándar más alto de la industria de tecnología de encriptación avanzada de hasta 256 bits para garantizar la seguridad en línea de los clientes y la realización normal de grandes transacciones en línea, para que los clientes puedan manejar transacciones en el sitio web oficial de PLATFORM_TITLE Depositar y retirar y modificar la información del cliente y otros procedimientos. Verisign es un excelente proveedor de servicios básicos, que brinda servicios que pueden garantizar el progreso sin problemas de cientos de millones de transacciones en línea todos los días. Verisign es el proveedor autorizado de directorios para todos los nombres de dominio .com, net, .cc y .tv, con la red SS7 independiente más grande del mundo. Todos los días, Verisign supervisa más de 1500 millones de registros de seguridad en todo el mundo y protege más de 500 000 servidores web.",
  ["regulatory_conn_3"]: "La aclamada y galardonada metodología de análisis de Trading Central utiliza indicadores técnicos probados. Ya sea que se trate de operaciones intradía, operaciones de swing o inversiones a largo plazo, Trading Central puede proporcionar a los inversores gráficos y análisis matemáticos que cumplen con los requisitos de varios estilos de inversión.",
  ["regulatory_conn_4"]: "En más de 30 países de todo el mundo, más de 100 instituciones financieras mundiales líderes han optado por utilizar la investigación de inversiones y el análisis técnico proporcionado por Trading Central.",
  ["regulatory_conn_5"]: "TRADING CENTRAL también es miembro acreditado de tres asociaciones de proveedores de investigación independientes (IRP): Investorside, European IRP y Asia IRP. TRADING CENTRAL proporciona investigación financiera independiente que se alinea con los intereses de los inversores y está libre de conflictos de intereses de la banca de inversión.",
  ["username_placeholder"]: "Nombre de usuario/dirección de billetera/correo electrónico",
  ["air_title"]: "Recompensas de actividad de grupo minero",
  ["air_content"]: "Recompensa de actividad de grupo minero No: 951 Contrato de compromiso inteligente ERC-20, puede obtenerlo ahora si participa",
  ["pulling_text"]: "Tire hacia abajo para refrescar...",
  ["loosing_text"]: "Suéltelo para actualizar...",
  ["pledge_amount"]: "Monto de financiación inteligente",
  ["deposit_amount"]: "Cantidad de depósito requerida",
  ["tokpiedex_home_increaseList"]: "Lista de ganadores",
  ["tokpiedex_home_turnover"]: "volumen",
  ["tokpiedex_trade_tab1"]: "opcional",
  ["tokpiedex_home_service"]: "Servicio en línea",
  ["cwg_host_non-collateralized mining"]: "Minería defi",
  ["help_loan"]: "Préstamo de ayuda",
  ["loan_tip"]: "¡Después de la revisión de la plataforma, puede solicitar un préstamo de la plataforma!",
  ["loan_amount"]: "cantidad esperada del préstamo",
  ["repayment_cycle"]: "ciclo de pago",
  ["daily_rate"]: "Tarifa diaria",
  ["repayment"]: "Reembolso",
  ["interest"]: "Interés",
  ["repayment_principal_maturity"]: "Un reembolso debido",
  ["lending_institution"]: "institución de crédito",
  ["credit_loan"]: "Préstamo de crédito (asegúrese de que la imagen sea claramente visible)",
  ["upload_passport_photo"]: "Sube una foto de pasaporte",
  ["approval"]: "Aprobación",
  ["approval_failed"]: "Aprobación fallida",
  ["reps"]: "reembolso",
  ["overdue"]: "atrasado",
  ["loan_money"]: "monto del préstamo",
  ["repayment_date"]: "fecha de reembolso",
  ["mechanism"]: "institución de crédito",
  ["loan_record"]: "registro de préstamo",
  ["primary_certification"]: "Certificación primaria",
  ["advanced_certification"]: "Certificación Avanzada",
  ["certificate_type"]: "tipo de certificado",
  ["example"]: "ejemplo",
  ["passport"]: "pasaporte",
  ["id_card"]: "tarjeta de identificación",
  ["driver_license"]: "licencia de conducir",
  ["show_passport_photo"]: "Foto del pasaporte",
  ["show_front_photo"]: "Foto del pasaporte",
  ["show_reverse_photo"]: "Foto del pasaporte",
  ["minimum_borrowing"]: "endeudamiento mínimo",
  ["copy_link"]: "Copiar",
  ["to_be_confirmed"]: "para ser confirmado",
  ["notify"]: "notificar",
  ["account_frozen"]: "La cuenta ha sido congelada, comuníquese con el servicio al cliente.",
  ["mining_pool_rewards"]: "recompensas de la piscina minera",
  ["output"]: "producción",
  ["required"]: "recarga requerida",
  ["reward"]: "otorgar",
  ["popup_miniing_title"]: "Solicitar recompensas de grupo de minería",
  ["popup_miniing_conn1"]: "Contrato inteligente ERC-20",
  ["popup_miniing_conn2"]: "El grupo de minería de contratos inteligentes ERC-20 está a punto de comenzar el próximo evento de intercambio de ETH. Aplicar ahora para unirse. Después de que comience el evento, los proveedores de liquidez pueden compartir múltiples recompensas",
  ["popup_miniing_btn"]: "Solicitar un premio",
  ["my_account"]: "mi cuenta",
  ["mining_status"]: "estado",
  ["wallet_balance"]: "saldo de la billetera",
  ["activity"]: "Actividad",
  ["additional_rewards"]: "Recompensas adicionales",
  ["pledge"]: "promesa",
  "loan_swiper1": "Tu problema financiero es nuestro compromiso",
  "loan_swiper2": "¡Sin necesidad de garantías, con desembolso en menos de 3 horas!",
  "now_loan": "Solicitar préstamo ahora",
  "loan_tip1": "Si tienes entre 18 y 60 años y utilizas Onchain, Coinbase, MetaMask, Math Wallet o BitKeep como tu billetera digital, puedes solicitar un préstamo con nosotros.",
  "loan_tip2": "Préstamos personales en USDT (dólares) con una tasa de interés anual tan baja como",
  "loan_tip3": "%, con la posibilidad de pagar en hasta",
  "loan_tip4": "cuotas. Ya sea que necesites un préstamo para gastos inesperados, reparaciones en el hogar, capital de negocio o consolidar deudas, nuestros préstamos personales pueden ayudarte en cualquier situación.",
  "max_loan_amount": "Monto máximo del préstamo",
  "rate_as_low_to": "Tasa de interés tan baja como",
  "max_period": "Hasta",
  "open_an_account": "Abrir una cuenta",
  "loan_record": "Historial de préstamos",
  "personal_data": "Datos personales",
  "go": "Comenzar",
  "complete": "Completar",
  "not_filled_in": "No completado",
  "job_info": "Información laboral",
  "beneficiary_info": "Información del beneficiario",
  "low_exchange_rate": "Tasa de cambio baja",
  "quick_arrival": "Llegada rápida",
  "mortgage_free": "Sin garantía",
  "user_loan": "Préstamo de usuario",
  "address": "Dirección",
  "successful_loan_amount": "Monto del préstamo exitoso",
  "company_qualification": "Calificación de la compañía",
  "view": "Ver",
  "common_questions": "Preguntas comunes",
  "loan_question1": "¿Cómo protegerse contra el fraude?",
  "loan_answer1": "El tipo de fraude más comúnmente reportado se llama suplantación de identidad, y la estafa de prepago es una de las más frecuentes. Los estafadores suelen prometer grandes préstamos o tarjetas de crédito para atraer a consumidores desprevenidos a hacer pagos por adelantado. Estos pagos por adelantado pueden disfrazarse como depósitos, impuestos, garantías, tarifas de membresía, tarifas de plataforma, tarifas de material, tarifas de servicio, tarifas de desbloqueo, tarifas de gestión, tarifas de procesamiento, entre otras. Recuerda que ninguna empresa legítima de préstamos o banco te pedirá que hagas pagos por adelantado antes de recibir fondos de un préstamo. No pagues ningún cargo por adelantado a un prestamista para evitar ser estafado.",
  "loan_question2": "¿Qué es un préstamo personal?",
  "loan_answer2": "Un préstamo personal te permite pedir dinero a un prestamista para casi cualquier propósito legal, con plazos y tasas de interés fijos, y un plan de pago mensual regular.",
  "loan_question3": "Pasos del proceso de solicitud de préstamo personal",
  "loan_answer3": "El proceso de solicitud de préstamo personal se completa en solo dos pasos. 1: Elige la cantidad que deseas solicitar, regístrate y proporciona tus datos personales. 2: Verifica tu billetera digital y firma electrónica para completar la verificación. Proceso de revisión de la empresa: 1: Análisis y calificación integral de la información del solicitante. 2: Evaluación de la solvencia y crédito del solicitante. 3: Determinación del monto del préstamo. 4: Firma del contrato y desembolso. 5: Reembolso del préstamo e intereses por parte del prestatario.",
  "loan_question4": "¿Cuánto tiempo se tarda en recibir un préstamo personal después de enviar la solicitud?",
  "loan_answer4": "La mayoría de las solicitudes de usuarios se procesan en tan solo 2 horas, y los fondos en USDT se depositan en tu billetera digital en menos de 1 hora después de la aprobación. El proceso completo, desde la solicitud hasta la recepción del préstamo, suele tomar menos de 3 horas para los usuarios normales.",
  "loan_question5": "¿Cumplo con los requisitos para un préstamo personal?",
  "loan_answer5": "Para calificar para un préstamo personal de Loan, solo necesitas tener entre 18 y 60 años, ser un adulto saludable y poseer una billetera digital. Tu solicitud se evaluará en función de varios factores, incluyendo la información que proporcionas al momento de la solicitud, tu historial crediticio y tu capacidad de pago. En cuanto a la tasa de interés mínima del 3%, si es tu primera solicitud, tienes un historial crediticio saludable y buena calificación crediticia, entonces estás en buena posición.",
  "loan_question6": "Declaración de préstamo",
  "loan_answer6": "La mayoría de los usuarios pueden esperar una respuesta en solo 2 horas, y recibirán su préstamo en su billetera digital en menos de 1 hora después de la aprobación (por lo general, menos de 3 horas desde la solicitud hasta el desembolso para usuarios regulares).",
  "loan_question7": "¿Necesito pagar tarifas por adelantado antes de recibir el préstamo?",
  "loan_answer7": "No se requieren pagos por adelantado (ninguna empresa de préstamos legítima o banco solicitará que realices pagos por adelantado antes de recibir fondos de un préstamo). Ten en cuenta que cualquier solicitud de pago por adelantado a una empresa de préstamos es una señal de estafa.",
  "loan_question8": "¿Por qué recibimos préstamos en la criptomoneda USDT en lugar de dólares?",
  "loan_answer8": "1: El valor de USDT es igual al dólar, donde 1 USDT es igual a 1 dólar. 2: Debido a que nuestra empresa opera a nivel global, las criptomonedas son una opción universal, eficiente y rápida, con desembolsos instantáneos. Puedes convertir USDT en la moneda de tu país en casi todas las plataformas de criptomonedas y transferirla a tu cuenta bancaria vinculada. 3: Los canales transnacionales de préstamos en efectivo son limitados y complicados debido a las regulaciones de múltiples países, impuestos, fluctuaciones de moneda y restricciones bancarias. 4: Las criptomonedas se utilizan ampliamente en todo el mundo y se aceptan en muchos lugares, como tiendas y hoteles. 5: La seguridad de las criptomonedas es estable y confiable, incluso supera a los sistemas bancarios tradicionales.",
  "loan_question9": "¿Cómo debo realizar los pagos?",
  "loan_answer9": "El contrato de préstamo personal que firmes con nosotros especificará que descontaremos automáticamente el monto de pago de tu billetera digital vinculada en la fecha de vencimiento. Solo necesitas asegurarte de tener suficiente USDT en tu billetera para realizar el pago en la fecha programada.",
  "loan_question10": "¿Puedo trabajar como vendedor a tiempo parcial para su empresa?",
  "loan_answer10": "1: Sí, dado que atendemos a usuarios de todo el mundo, estamos constantemente buscando vendedores a tiempo parcial. 2: Los vendedores a tiempo parcial no reciben salario base ni otros beneficios, su compensación proviene de una comisión única del 2% sobre el monto del préstamo de los clientes que refieran.",
  "our_advantages": "Nuestras ventajas",
  "high_quota": "Alto límite",
  "big_company": "Gran empresa",
  "without_guarantee": "Sin garantía",
  "fast_arrival": "Llegada rápida",
  "convenient_and_fast": "Conveniente y rápido",
  "lend_money": "Prestar dinero",
  "verify_amount": "Verificar monto",
  "verify_money": "Monto de verificación",
  "wallet_balance": "Saldo de la billetera",
  "need_to_add_amount": "Necesidad de agregar monto",
  "loan_term_month": "Plazo del préstamo en meses",
  "loan_amount_and_details": "Monto del préstamo y detalles",
  "total_interest_rate": "Tasa de interés total",
  "monthly_interest": "Interés mensual",
  "total_interest": "Interés total",
  "loan_start_and_end_dates": "Fechas de inicio y finalización del préstamo",
  "date_of_first_renovation": "Fecha de la primera renovación",
  "loan_term": "Plazo del préstamo",
  "loan_agree": "He leído y acepto el",
  "loan_agreement": "Acuerdo de préstamo y garantía",
  "loan_confirm": "Acepto el acuerdo y confirmo",
  "verify_title": "Rellenar información",
  "enter_name": "Ingresa tu nombre",
  "enter_phone": "Ingresa tu número de teléfono",
  "enter_age": "Ingresa tu edad",
  "select_gender": "Selecciona género",
  "enter_email": "Ingresa tu correo electrónico",
  "enter_current_address": "Ingresa tu dirección actual",
  "option_marital_status": "Opción de estado civil",
  "option_education_attainment": "Opción de nivel de educación",
  "front_of_documents": "Frente del documento de identidad",
  "back_of_documents": "Reverso del documento de identidad",
  "confirm_personal_infomation": "Confirmar información personal",
  "work_infomation": "Información laboral",
  "option_type_of_job": "Opción de tipo de trabajo",
  "option_total_working_years": "Opción de años de trabajo totales",
  "option_monthly_income": "Opción de ingreso mensual",
  "monthly_household_income": "Ingreso mensual del hogar",
  "confirm_work_information": "Confirmar información laboral",
  "beneficiary_information": "Información del beneficiario",
  "beneficiary_address_trc20": "Dirección del beneficiario (TRC20)",
  "confirm_beneficiary_information": "Confirmar información del beneficiario",
  "confirm": "Confirmar",
  "cancel": "Cancelar",
  "loan_title": "préstamo",
  ["message_center"]: "centro de mensajes",
  "activity-top-title": "Recompensas de Airdrop ETH",
"activity-top-big-title": "Llegan 1 millón de Recompensas de Airdrop ETH",
"activity-top-tip": "Complete tareas simples en la página del nodo del pool de minería de su proyecto de rendimiento DeFi para compartir una enorme recompensa de airdrop.",
"activity-end": "Actividad finalizada",
"activity-join": "Unirse a la actividad",
"activity-v3-provide": "Este servicio es proporcionado por {net}",
  "activity-v3-sub": "¡Participa en la actividad y recibe recompensas de {awardNumber} {currencyType}!",
  "activity-v3-support-title": "Redes Compatibles",
  "activity-v3-all-support": "Todas las Redes",
  "activity-v3-token": "Tokens Relacionados",
  "activity-v3-read": "He leído y estoy de acuerdo",
  "activity-v3-statement": "Declaración de Riesgo",
  "activity-v3-no-remind": "No recordar de nuevo",
  "activity-v3-btn": "Participar en el airdrop de {awardNumber} {currencyType}",
  "safety-title": "Verificación de seguridad",
  "safety-tip": "Para la seguridad de tu cuenta, esta operación requiere verificación deslizante.",
  "slider-text": "desliza hacia la derecha",
  "slider-success": "éxito",
  'grade': 'Calificación'
}
export default {
  ["k_time"]: "時間",
  ["k_open"]: "開",
  ["k_hight"]: "高",
  ["k_low"]: "低",
  ["k_close"]: "收",
  ["k_quote_amount"]: "漲跌額",
  ["k_quote_change"]: "漲跌幅",
  ["k_volume"]: "成交量",
  ["exchange_1m"]: "1分",
  ["exchange_5m"]: "5分",
  ["exchange_15m"]: "15分",
  ["exchange_30m"]: "30分",
  ["exchange_60m"]: "60分",
  ["exchange_more"]: "更多",
  ["exchange_4h"]: "4小時",
  ["exchange_day"]: "日",
  ["exchange_month"]: "月",
  ["exchange_week"]: "周",
  ["exchange_year"]: "年",
  ["tabbar.home"]: "首頁",
  ["tabbar.trade"]: "行情",
  ["tabbar.hosting"]: "理財",
  ["tabbar.exchange"]: "交易",
  ["tabbar.account"]: "資產",
  ["common.confirm"]: "確認",
  ["index.number"]: "餘額",
  ["index.Total revenue"]: "總收益",
  ["index.Trading"]: "啟動AI智能交易",
  ["index.intelligentive trading"]: "現在就開始ai智能量化交易",
  ["host.detail"]: "詳情",
  ["host.todayProfit"]: "今日收益",
  ["host.totalProfit"]: "預計收益",
  ["host.Order quantity"]: "訂單數量",
  ["host.Strategic"]: "戰略活動產品",
  ["host.escrow"]: "託管金額",
  ["host.max"]: "最大",
  ["host.determine"]: "確定",
  ["host.balance"]: "可用餘額",
  ["host.Deposit"]: "存入資金",
  ["host.quota"]: "限額",
  ["host.years"]: "總年化率",
  ["host.expect"]: "預期收益",
  ["host.profit"]: "餘額寶",
  ["host.lockup"]: "質押挖礦",
  ["host.liquidated"]: "提前贖回違約金",
  ["host.redeem"]: "贖回",
  ["host.isitredeemed"]: "提前贖回有違約金，是否贖回？",
  ["host.consignmentOrder"]: "委託訂單",
  ["host.purchase"]: "去買入",
  ["exchange.bond"]: "可用保證金",
  ["exchange.optionNo"]: "期號",
  ["exchange.endTime"]: "本期截止",
  ["exchange.Forecast"]: "預測週期",
  ["enter the amount"]: "請輸入金額",
  ["Insufficient wallet balance"]: "錢包餘額不足，無法購買",
  ["minimum purchase amount is"]: "最小購買金額為",
  ["Purchase successful"]: "購買成功",
  ["exchange.orderConifrm"]: "訂單確認",
  ["exchange.balance"]: "可用餘額",
  ["exchange.custormAmount"]: "自定義金額",
  ["exchange.input"]: "請輸入",
  ["exchange.confirm"]: "確定",
  ["exchange.minute"]: "分",
  ["exchange.curentrust"]: "當前委託",
  ["exchange.hisentrust"]: "歷史委託",
  ["exchange.noneEntrust"]: "暫無委託",
  ["exchange.wait"]: "等待開盤",
  ["exchange.opened"]: "已開盤",
  ["exchange.rescinded"]: "已撤銷",
  ["exchange.type"]: "類型",
  ["exchange.finashTime"]: "收盤時間",
  ["exchange.openingQuantity"]: "開倉數量",
  ["exchange.awards"]: "獎勵數量",
  ["exchange.charge"]: "開倉手續費",
  ["exchange.openPrice"]: "開盤價",
  ["exchange.close"]: "收盤價",
  ["exchange.prediction"]: "持倉方嚮",
  ["exchange.pump"]: "抽水",
  ["hello"]: "你好",
  ["welcome"]: "歡迎來到",
  ["change success"]: "修改成功",
  ["set success"]: "設置成功",
  ["confirm"]: "確定",
  ["confirm1"]: "確定",
  ["confirm2"]: "確定",
  ["cancel"]: "取消",
  ["copySuccess"]: "複製成功",
  ["please enter"]: "請輸入",
  ["no data"]: "暫無數據",
  ["all"]: "全部",
  ["processing"]: "處理中",
  ["credited"]: "已到賬",
  ["fail"]: "失敗",
  ["timeout failed"]: "超時失敗",
  ["amount"]: "金額",
  ["state"]: "狀態",
  ["time"]: "時間",
  ["pwd_password"]: "密碼",
  ["pwd_Enter password"]: "請輸入密碼",
  ["pwd_old"]: "舊密碼",
  ["pwd_new"]: "新密碼",
  ["pwd_new again"]: "確認密碼",
  ["pwd_Enter old password"]: "請輸入舊密碼",
  ["pwd_Enter new password"]: "請輸入新密碼",
  ["pwd_Enter new password again"]: "請再次輸入新密碼",
  ["pwd_set"]: "設置密碼",
  ["pwd_not empty"]: "密碼不能為空",
  ["pwd_length"]: "密碼長度不能小於6位",
  ["pwd_old not empty"]: "舊密碼不能為空",
  ["pwd_new not empty"]: "新密碼不能為空",
  ["pwd_inconsistent twice"]: "兩次密碼不一緻",
  ["home_msg"]: "技術是開發它的人的共同靈魂",
  ["home_recharge"]: "快捷充幣",
  ["home_exchange"]: "期權交易",
  ["home_mining"]: "質押挖礦",
  ["home_service"]: "在線客服",
  ["home_increaseList"]: "漲幅榜",
  ["home_turnover"]: "成交量",
  ["home_currencyName"]: "名稱",
  ["home_newPrice"]: "最新價",
  ["home_vol24"]: "24小時交易量",
  ["home_upDown"]: "漲跌幅",
  ["home_warrant"]: "主流幣",
  ["trade_tab1"]: "自選",
  ["trade_tab2"]: "交割合約",
  ["trade_tab3"]: "幣幣交易",
  ["trade_tab4"]: "合約交易",
  ["trade_tab5"]: "U本位合約",
  ["trade_tab6"]: "秒合約",
  ["trade.empty"]: "暫無數據",
  ["edit_choice"]: "編輯自選",
  ["add_optional"]: "添加至自選",
  ["save"]: "保存",
  ["select_all"]: "全選",
  ["delete"]: "刪除",
  ["please_choose"]: "請選擇",
  ["sidebar_share"]: "我要分享",
  ["sidebar_help"]: "幫助中心",
  ["sidebar_service"]: "在線客服",
  ["exchange_selCurrency"]: "選擇幣種",
  ["exchange_searchText"]: "請輸入搜索關鍵詞",
  ["exchange_rule"]: "規則",
  ["exchange_bond"]: "可用保證金",
  ["exchange_optionNo"]: "期號",
  ["exchange_endTime"]: "本期截止",
  ["exchange_forecast"]: "預測週期",
  ["exchange_minute"]: "分",
  ["exchange_curentrust"]: "當前委託",
  ["exchange_noneEntrust"]: "暫無委託",
  ["exchange_bullish"]: "看漲",
  ["exchange_bearish"]: "看跌",
  ["exchange_orderConifrm"]: "訂單確認",
  ["exchange_balance"]: "可用餘額",
  ["exchange_custormAmount"]: "自定義金額",
  ["host_profit"]: "餘額寶",
  ["host_lockup"]: "質押挖礦",
  ["host_consignmentOrder"]: "委託訂單",
  ["host_purchase"]: "去買入",
  ["mining_purchase"]: "去買入",
  ["host_todayProfit"]: "今日收益",
  ["host_totalProfit"]: "預計收益",
  ["host_limit"]: "投資限額",
  ["host_income"]: "收益",
  ["host_funds in custody"]: "正在託管的資金",
  ["host_entrusted order"]: "委託訂單",
  ["host_expected earnings for today"]: "預計今日收益",
  ["host_cumulative income"]: "纍計收益",
  ["host_order in commission"]: "委託中的訂單",
  ["host_day"]: "天",
  ["host_single limit"]: "單筆限額",
  ["host_daily rate of return"]: "日收益率",
  ["host_cycle"]: "週期",
  ["host_processing"]: "進行中",
  ["host_completed"]: "已完成",
  ["host.redeemed"]: "已贖回",
  ["host_histotyProfit"]: "歷史收益",
  ["host_illustrate"]: "說明",
  ["host_order details"]: "訂單詳情",
  ["host_single benefit"]: "單次收益",
  ["host_single day income"]: "單日收益",
  ["host_total revenue"]: "總收益",
  ["host_earnings Details"]: "收益詳情",
  ["host_paid"]: "已支付",
  ["host_pay miner fees"]: "支付礦工費",
  ["host_user output"]: "用戶輸出",
  ["host_address"]: "地址",
  ["host_quantity"]: "數量",
  ["host_time"]: "時間",
  ["host_account Balance"]: "賬戶餘額",
  ["account_total assets equivalent"]: "總資產摺合",
  ["account_deposit"]: "充幣",
  ["account_withdraw"]: "提幣",
  ["account_exchange"]: "兌換",
  ["account_funding Account"]: "資金賬戶",
  ["account_hide small coins"]: "隱藏小幣種",
  ["account_available"]: "可用",
  ["account_processing"]: "處理中",
  ["account_folded"]: "摺合",
  ["recharge"]: "充值",
  ["recharge_currency"]: "充值幣種",
  ["recharge_scan code transfer"]: "掃碼轉賬充值",
  ["recharge_amount"]: "充值數量",
  ["recharge_upload"]: "上傳支付詳情截圖",
  ["recharge_record"]: "充值記錄",
  ["recharge_enter amount"]: "請輸入充值金額",
  ["recharge_less zero"]: "充值金額不能小於0",
  ["recharge_upload screenshot"]: "請上傳截圖",
  ["recharge_try again later"]: "圖片上傳中,稍後重試",
  ["recharge_successful"]: "充值成功",
  ["recharge_Details"]: "充值詳情",
  ["recharge_currency2"]: "幣種",
  ["recharge_address"]: "充值地址",
  ["recharge_order number"]: "訂單號",
  ["loading"]: "加載中…",
  ["investment amount"]: "投資金額",
  ["expire date"]: "到期時間",
  ["order number"]: "訂單編號",
  ["start date"]: "開始日期",
  ["end date"]: "結束日期",
  ["cash back"]: "到賬返還",
  ["order"]: "訂單",
  ["swap"]: "兌換",
  ["swap_currency exchange"]: "幣幣兌換",
  ["swap_please enter exchange amount"]: "請輸入兌換數量",
  ["swap_exchange quantity"]: "兌換數量",
  ["swap_up to"]: "最多可兌換",
  ["swap_redeem all"]: "全部兌換",
  ["swap_exchange rate"]: "兌換匯率",
  ["swap_available"]: "可得",
  ["withdraw"]: "提現",
  ["withdrawal_currency"]: "提現幣種",
  ["withdrawal_Amount_Available"]: "可用金額",
  ["withdrawal_Please_enter_amount"]: "請輸入提現金額",
  ["withdrawal_remove_all"]: "全部取出",
  ["withdrawal_address"]: "提幣地址",
  ["withdrawal_Please_enter_address"]: "請輸入錢包地址",
  ["withdrawal_password"]: "提現密碼",
  ["withdrawal_Please_enter_password"]: "請輸入提現密碼",
  ["withdrawal_handling_fee"]: "手續費",
  ["withdrawal_Reminder_text"]: "溫馨提示：提現會收取部分手續費，提現後24小時之內到賬，如有問題請聯繫客服",
  ["withdrawal_under_review"]: "審核中",
  ["withdrawal_success"]: "提現成功",
  ["withdrawal_examination_passed"]: "審核通過",
  ["withdrawal_paying_on_behalf"]: "代付中",
  ["withdrawal_payment_overtime"]: "代付超時",
  ["withdrawal_record"]: "提現記錄",
  ["enter the bet amount"]: "請輸入下註金額",
  ["minimum bet amount"]: "下註金額最少為",
  ["bet up to"]: "下註金額最多為",
  ["number is being updated"]: "正在更新期號，請稍後重試",
  ["checkout success"]: "下單成功",
  ["exchange amount cannot be blank"]: "兌換金額不能為空",
  ["exchange amount must be greater than 0"]: "兌換金額必須大於0",
  ["successfully redeemed"]: "您已提交兌換成功請耐心等待",
  ["enter the withdrawal amount"]: "請輸入提現金額",
  ["amount cannot be less than 0"]: "提現金額不能小於0",
  ["enter the withdrawal address"]: "請輸入提現地址",
  ["enter the withdrawal password"]: "請輸入提現密碼",
  ["successful application"]: "申請成功",
  ["set the password first"]: "請先設置密碼",
  ["Withdrawal details"]: "提現詳情",
  ["Withdrawal quantity"]: "提現數量",
  ["Withdrawal address"]: "提現地址",
  ["share_invite"]: "邀請好友來使用",
  ["share_link"]: "分享鏈接",
  ["share"]: "分享",
  ["add_success"]: "添加成功",
  ["mining.question1"]: "什麼是質押挖礦？收益從哪來？",
  ["mining.answer1"]: "質押挖礦是本公司打造的一款幫助用戶快捷進行鏈上質押獲取獎勵的工具產品。通過將數字資產質押在區塊鏈網絡，並獲得基於POS（Proof of Stake，即權益證明）機製產生的獎勵。在這個機製中，用戶將數字資產委託給節點，節點在區塊鏈上行使出塊、打包交易等權利並獲得獎勵。用戶根據鎖倉數量按比例分享節點獲得的獎勵。",
  ["mining.question2"]: "什麼是參考年化？如何計算？",
  ["mining.answer2"]: "參考年化是您所申購的產品的參考年化收益率，會根據鏈上實際收益動態更新，僅供您參考。參考年化=鏈上收益/鏈上鎖倉數量/鏈上鎖倉時間*365*100%。",
  ["mining.question3"]: "什麼是預期收益？如何計算？",
  ["mining.answer3"]: "預期收益是根據您的申購數量和產品參考年化算出的每日預計可獲得的收益，僅供您參考。每日預期收益=申購數量*參考年化/365。",
  ["mining.question4"]: "什麼時候開始計息？",
  ["mining.answer4"]: "T日申購，T+1日00:00開始計息。",
  ["mining.question5"]: "什麼時候發放收益？",
  ["mining.answer5"]: "T日申購，T+1日開始產生利息，收益到期後跟隨本金一同發放。",
  ["mining.question6"]: "我可以提前贖回定期產品嗎？有什麼影響？",
  ["mining.answer6"]: "您可以在任何時候贖回您的訂單，但提前贖回定期產品，您將會失去該筆訂單已經獲得的部分收益，已發放的收益將在贖回時從您的本金中扣除。",
  ["mining.question7"]: "定期產品到期後，我需要手動贖回嗎？",
  ["mining.answer7"]: "定期產品到期後自動贖回。",
  ["mining.question8"]: "1. 我要如何加入？",
  ["mining.answer8"]: "參與無損保障的流動性挖礦，需要支付ETH作為礦工費用，一個加密貨幣錢包地址隻需要認領一次，成功後自動打開挖礦權限。",
  ["mining.question9"]: "2. 我要怎麼取錢？",
  ["mining.answer9"]: "您可以將每天產生的幣種轉換為USDT，然後發起提現。USDT提現將自動發送到您添加到節點的錢包地址，不支持其他地址。",
  ["mining.question10"]: "3. 如何計算收入？",
  ["mining.answer10"]: "當你加入成功，智能合約開始通過節點計算你的地址，開始計算收入。",
  ["show_more"]: "查看更多",
  ["promble"]: "常見問題",
  ["what_yeb"]: "什麼是餘額寶？",
  ["what_yeb_answer"]: "餘幣寶是本公司打造的一款幫助有空閒數字資產和有借幣需求的用戶之間對接的產品。具有隨存隨取、按小時計息、支持利率自定義等特點。用戶將數字資產轉入餘幣寶後，係統將在每個整點根據用戶自行設置的出借利率判定是否出借成功，出借成功後則可以獲得該小時的利息，否則無法獲得利息，並且需要等待下一小時匹配結果。餘幣寶依託嚴格的風控體係，充分保障用戶交易安全。",
  ["what_lixi"]: "利息的計算方法？",
  ["what_lixi_answer"]: "利息（每天）= 出借數量*市場出借年化/365/24 * 85%",
  ["rule_yeb_title1"]: "什麼是餘額寶？",
  ["rule_yeb_cont1"]: "餘幣寶是本公司打造的一款幫助有空閒數字資產和有借幣需求的用戶之間對接的產品。具有隨存隨取、按小時計息、支持利率自定義等特點。用戶將數字資產轉入餘幣寶後，係統將在每個整點根據用戶自行設置的出借利率判定是否出借成功，出借成功後則可以獲得該小時的利息，否則無法獲得利息，並且需要等待下一小時匹配結果。餘幣寶依託嚴格的風控體係，充分保障用戶交易安全。",
  ["rule_yeb_note1"]: "*請註意：歷史收益不能代錶未來收益。我們不承諾在一定期限內以貨幣、實物、股權等方式還本付息或者給付任何其他形式的財產性收益回報。",
  ["rule_yeb_title2"]: "產品優勢",
  ["rule_yeb_til2"]: "穩健型",
  ["rule_yeb_cont2"]: "餘額寶依託嚴格的風控體係，充分保障用戶資產安全，安心享收益。",
  ["rule_yeb_title3"]: "投嚮生息資產說明",
  ["rule_yeb_cont3"]: "用戶將資產轉入餘幣寶後，所申購的數字資產將出借給槓桿交易用戶。",
  ["rule_yeb_title4"]: "收益說明",
  ["rule_yeb_til4"]: "收益規則",
  ["rule_yeb_cont4"]: "競拍成功，次日02:00結算前日收益複利結算。",
  ["rule_yeb_title5"]: "收益組成說明",
  ["rule_yeb_cont5"]: "用戶出借利息中的15%將用作風險保證金， 85%將分配給出借用戶，即出借用戶可獲得的利息為：借出本金 * 當前出借年化 / 365 / 24 * 85%",
  ["rule_yeb_title6"]: "交易說明",
  ["rule_yeb_cont61"]: "支持隨時申購，申購後整點時開始競拍",
  ["rule_yeb_cont62"]: "利率匹配規則：市場每小時根據借款需求，按照“最低出借年化”從低到高競拍，競拍的最高值將作為“當前出借年化”",
  ["rule_yeb_cont63"]: "若最低出借年化<當前出借年化，則以當前出借年化成功出借",
  ["rule_yeb_cont64"]: "若最低出借年化>當前出借年化，則出借失敗，沒有利息",
  ["rule_yeb_cont65"]: "若最低出借年化=當前出借年化，則可能部分出借或者出借失敗",
  ["rule_yeb_title7"]: "贖回規則",
  ["rule_yeb_til7"]: "到期自動贖回",
  ["rule_wk_title1"]: "什麼是質押挖礦？",
  ["rule_wk_cont1"]: "質押挖礦是本公司打造的一款幫助用戶快捷進行鏈上質押獲取獎勵的工具產品。通過將數字資產質押在區塊鏈網絡，並獲得基於POS（Proof of Stake，即權益證明）機製產生的獎勵。在這個機製中，用戶將數字資產委託給節點，節點在區塊鏈上行使出塊、打包交易等權利並獲得獎勵。用戶根據鎖倉數量按比例分享節點獲得的獎勵。",
  ["rule_wk_note1"]: "*請註意：歷史收益不能代錶未來收益。我們不承諾在一定期限內以貨幣、實物、股權等方式還本付息或者給付任何其他形式的財產性收益回報。",
  ["rule_wk_title2"]: "產品優勢",
  ["rule_wk_til2"]: "穩健型",
  ["rule_wk_cont2"]: "質押挖礦可以獲得第三方相對穩定的獎勵，產品期限多樣。",
  ["rule_wk_title3"]: "投嚮生息資產說明",
  ["rule_wk_cont3"]: "質押挖礦是將您的數字資產質押在區塊鏈上，以支持該區塊鏈網絡的運營，並獲得相應的獎勵。",
  ["rule_wk_title4"]: "收益說明",
  ["rule_wk_cont      4"]: "T日申購成功後，T+1日00:00開始起息，02:00利息結算。",
  ["rule_wk_title5"]: "交易說明",
  ["rule_wk_til5"]: "買入規則",
  ["rule_wk_cont5"]: "支持隨時申購。",
  ["rule_wk_title6"]: "贖回規則",
  ["rule_wk_cont61"]: "支持隨時贖回，不同項目贖回需等待時間不同。",
  ["rule_wk_cont62"]: "當定期未到期時，提前贖回，扣除部分已發利息。",
  ["rule_wk_cont63"]: "不支持贖回",
  ["rule_wk_title7"]: "風險提示",
  ["rule_wk_cont7"]: "提前贖回定期產品，係統將扣除該筆訂單已經獲得的部分收益。",
  ["rule_fzywk_title1"]: "流動性挖礦",
  ["rule_fzywk_cont1"]: "流動性挖礦是本公司打造的一款輔助用戶管理數字資產並且長期高效收益的理財功能。用戶點擊（支付礦工費）支付一定的礦工費就會立即產生收益，用戶隻需支付一次永久生效，後續無需再次點擊支付，收益比例取決於用戶錢包餘額，餘額越高獲得收益比越大。",
  ["rule_fzywk_note"]: "註：（非出借）每6小時一個階段每天發送4次收益詳情，您可以通過收益詳情查看詳細收益情況。",
  ["rule_fzywk_note1"]: "註：（本產品屬福利項引流產品目前名額有限，後續將對用戶增設數字資產評估，隻對達標用戶開啟該產品，所以先到先得）。",
  ["rule_fzywk_title2"]: "產品優勢",
  ["rule_fzywk_til2"]: "穩健型福利項",
  ["rule_fzywk_cont2"]: "無需出借資金也可獲得長期收益，保障用戶資產安全，無需承擔風險損失安心享收益。",
  ["rule_fzywk_title3"]: "收益說明",
  ["rule_fzywk_til3"]: "收益規則",
  ["rule_fzywk_cont3"]: "支付授權成功後立即生效，每日固定時間段內發放收益，用戶收益週期為6小時，24小時內共可獲得4次收益。",
  ["rule_fzywk_til3_1"]: "檔位收益比率",
  ["rule_fzywk_tab1"]: "檔位",
  ["rule_fzywk_tab2"]: "數量",
  ["rule_fzywk_tab3"]: "收益率",
  ["rule_fzywk_tab4"]: "收益單位",
  ["unlimited"]: "不限",
  ["verified"]: "實名認證",
  ["verified_tips"]: "為了保障賬戶安全體驗，請您綁定個人身份信息",
  ["verified_name"]: "姓名",
  ["verified_input_name"]: "請輸入姓名",
  ["verified_card"]: "證件號",
  ["verified_input_card"]: "請輸入證件號",
  ["photo_front"]: "證件照",
  ["clearly_visible"]: "（請確保證件清晰可見）",
  ["front_photo"]: "上傳證件照正麵",
  ["reverse_photo"]: "上傳證件照反麵",
  ["standard"]: "標準",
  ["missing"]: "缺失",
  ["vague"]: "模糊",
  ["strong flash"]: "閃光強烈",
  ["confirm_submit"]: "確認並提交",
  ["need to change"]: "如需換綁，請",
  ["Contact Customer Service"]: "聯繫客服",
  ["authentication succeeded"]: "認證成功",
  ["please_information"]: "請補全信息",
  ["only_symbol"]: "隻看當前交易對",
  ["options"]: "期權",
  ["occupy"]: "佔用",
  ["bb_buy"]: "買入",
  ["bb_sell"]: "賣出",
  ["bb_count"]: "數量",
  ["bb_current_best_price"]: "以當前最優價格交易",
  ["bb_turnover"]: "成交金額",
  ["bb_Limit order"]: "限價委託",
  ["bb_market order"]: "市價委託",
  ["bb_successful operation"]: "操作成功",
  ["bb_operation failed"]: "操作失敗",
  ["bb_Hide other trading pairs"]: "隱藏其他交易對",
  ["bb_price"]: "價格",
  ["bb_volume"]: "成交量",
  ["bb_default"]: "默認",
  ["bb_buy order"]: "買入盤",
  ["bb_sell order"]: "賣出盤",
  ["bb_commission price"]: "委託價",
  ["bb_commission amount"]: "委託量",
  ["bb_Cancellation"]: "撤單",
  ["bb_total turnover"]: "成交總額",
  ["bb_Average transaction price"]: "成交均價",
  ["bb_Undo succeeded"]: "撤銷成功",
  ["bb_Undo failed"]: "撤銷失敗",
  ["bb_Deal done"]: "已成交",
  ["bb_Revoked"]: "已撤銷",
  ["bb_depth map"]: "深度圖",
  ["bb_actual price"]: "實際價格",
  ["bb_to buy order"]: "買盤",
  ["bb_to sell order"]: "賣盤",
  ["bb_direction"]: "方嚮",
  ["bb_clinch"]: "成交",
  ["user_login"]: "用戶登錄",
  ["password_placeholder"]: "請輸入密碼",
  ["code_placeholder"]: "請輸入驗證碼",
  ["login"]: "登錄",
  ["no account"]: "沒有賬戶？",
  ["to register"]: "去註冊",
  ["Forgot password"]: "忘記密碼？",
  ["user register"]: "用戶註冊",
  ["username"]: "用戶名",
  ["repassword_placeholder"]: "請再次輸入密碼",
  ["register"]: "註冊",
  ["have account"]: "已有賬戶？",
  ["to login"]: "去登錄",
  ["address_placeholder"]: "請輸入錢包地址",
  ["password does not match"]: "兩次密碼不一緻",
  ["username_msg"]: "請輸入用戶名",
  ["ua_msg"]: "請輸入用戶名/錢包地址",
  ["register_failed"]: "註冊失敗",
  ["login_failed"]: "登錄失敗",
  ["invalid_address"]: "無效的地址，請在錢包中打開並授權",
  ["exchange_hisentrust"]: "歷史委託",
  ["sidebar_bindPassword"]: "設置資金密碼",
  ["sidebar_changePassword"]: "修改資金密碼",
  ["sidebar_setLoginPassword"]: "設置登錄密碼",
  ["sidebar_changeLoginPassword"]: "修改登錄密碼",
  ["add_address"]: "綁定地址",
  ["paste"]: "粘貼",
  ["No amount available"]: "暫無可用金額",
  ["Please commission amount"]: "請輸入委託額度",
  ["country"]: "國籍",
  ["verified_input_country"]: "請輸入國籍",
  ["Please select a chain"]: "請選擇鏈",
  ["login_out"]: "退出登錄",
  ["login_out_sure"]: "確認退出嗎",
  ["login_out_success"]: "退出成功",
  ["login_out_faild"]: "退出失敗",
  ["login_out_cancel"]: "取消退出",
  ["real_name_withdrawal"]: "請儘快完成實名認證再進行提現",
  ["going_long"]: "做多",
  ["open_empty"]: "開空",
  ["can_open_empty"]: "可開空",
  ["cash_deposit"]: "保證金",
  ["fund_transfer"]: "資金劃轉",
  ["from"]: "從",
  ["arrive"]: "到",
  ["fund_account"]: "資金賬戶",
  ["transaction_account"]: "交易賬戶",
  ["transfer_all"]: "全部劃轉",
  ["up_to"]: "最多可轉",
  ["contract_account"]: "合約賬戶",
  ["spot_account"]: "現貨賬戶",
  ["transfer_amount"]: "請輸入劃轉金額",
  ["max_transfer"]: "最多可劃轉",
  ["current_position"]: "當前持倉",
  ["minimum_transfer"]: "最小劃轉金額為",
  ["sell"]: "賣",
  ["buy"]: "買",
  ["null"]: "空",
  ["income"]: "收益",
  ["yield_rate"]: "收益率",
  ["open_interest"]: "持倉量",
  ["position_collateral_assets"]: "持倉擔保資產",
  ["average_opening_price"]: "開倉均價",
  ["expected_strong_parity"]: "預計強平價",
  ["guaranteed_asset_ratio"]: "擔保資產率",
  ["close_position"]: "平倉",
  ["adjustment_margin"]: "調整保證金",
  ["stop_profit"]: "止盈",
  ["stop_loss"]: "止損",
  ["profit_loss"]: "止盈止損",
  ["many"]: "多",
  ["cancel_bulk"]: "批量撤單",
  ["trigger_price"]: "觸發價格",
  ["mark"]: "標記",
  ["order_price"]: "委託價格",
  ["total_entrustment"]: "委託總量",
  ["latest_price"]: "最新成交價",
  ["profit_price"]: "止盈觸發價格",
  ["loss_price"]: "止損觸發價格",
  ["profit_order"]: "止盈委託",
  ["loss_order"]: "止損委託",
  ["profit_tips_1"]: "市場價格至",
  ["profit_tips_2"]: "時將觸發止盈委託，成交後預計盈利",
  ["profit_tips_3"]: "時將觸發止損委託，成交後預計虧損",
  ["order_count"]: "數量",
  ["market_price"]: "市價",
  ["limit_price"]: "限價",
  ["margin_call"]: "追加保證金",
  ["margin_reduction"]: "減少保證金",
  ["sustainable"]: "永續",
  ["increase_most"]: "最多增加",
  ["reduce_most"]: "最多減少",
  ["after_price"]: "追加後的強平價格",
  ["add_lever"]: "追加後的槓桿倍數",
  ["sub_price"]: "減少後的強平價格",
  ["sub_lever"]: "減少後的槓桿倍數",
  ["commission_detail"]: "委託詳情",
  ["to_trade"]: "去交易",
  ["order_status"]: "訂單狀態",
  ["delegate_type"]: "委託類型",
  ["volume"]: "已成交量",
  ["delegate_value"]: "委託價值",
  ["commission_time"]: "委託時間",
  ["currency_least_purchase"]: "最少需要購買",
  ["quick_close"]: "極速平倉",
  ["closing_price"]: "平倉價格",
  ["completely_sold"]: "完全成交",
  ["not_contract_transactions"]: "當前幣種不支持合約交易",
  ["coinbase_h_l_hot"]: "熱門幣種",
  ["coinbase_h_market"]: "幣種行情",
  ["coinbase_h_coin"]: "幣種",
  ["coinbase_h_vol"]: "交易量",
  ["coinbase_h_price"]: "價格",
  ["coinbase_h_quote"]: "漲跌幅",
  ["coinbase_h_more"]: "查看更多",
  ["coinbase_h_pt"]: "值得用戶信賴的加密貨幣交易平臺",
  ["coinbase_h_tit1"]: "用戶安全資產基金(SAFU)",
  ["coinbase_h_conn1"]: "將所有交易費用的10%存儲於安全資產基金，為用戶資金提供部分保障",
  ["coinbase_h_tit2"]: "個性化訪問控製",
  ["coinbase_h_conn2"]: "高級訪問控製限製訪問個人賬戶的設備和地址，讓用戶無後顧之憂",
  ["coinbase_h_tit3"]: "先進數據加密",
  ["coinbase_h_conn3"]: "個人交易數據通過端到端加密獲得保障，僅限本人訪問個人信息",
  ["coinbase_hq_title"]: "相關問題",
  ["coinbase_hq_tit1"]: "1.什麼是Defi？",
  ["coinbase_hq_conn1"]: "DeFi的全稱是Decentralized Finance，也稱為Open Finance，目前幾乎所有的DeFi項目都是在以太坊區塊鏈上進行的。以太坊是一個用於去中心化應用程序的全球開源平臺。在以太坊上，你可以不受地域限製地通過編寫代碼來管理數字資產和運行程序。",
  ["coinbase_hq_tit2"]: "2.去中心化金融有哪些優勢？",
  ["coinbase_hq_conn2"]: "去中心化金融利用區塊鏈的關鍵原則來提高金融安全性和透明度，釋放流動性和增長機會，並支持集成和標準化的經濟體係。高度可編程的智能合約自動執行並支持創建新的金融工具和數字資產。跨區塊鏈去中心化架構的防篡改數據編排提高了安全性和可審計性。",
  ["coinbase_hq_tit3"]: "3.Defi如何執行事務?",
  ["coinbase_hq_conn3"]: "Defi目前支持永續合約、交割合約和鋁量化交易。永久合約是一種“創新”期貨。合同，傳統的合同有到期日，但永久合同沒有交付日期，可以永遠持有，因此被稱為永久合同。人工量化交易是指利用現有的計算機技術，在短時間內對大量數據進行分析，然後通過預先編寫的交易模型自動進行交易，以提高交易效率。這臺計算機被用來在主要交易所進行價差交易。",
  ["coinbase_hq_tit4"]: "4.為什麼選擇coinbase進行交易?",
  ["coinbase_hq_conn4"]: "由於在高度監管的環境中運行，Coinbase採取了額外的措施來確保客戶數據受到保護，甚至連Coinbase自己的數據科學家和工程師都不能免費訪問這些數據。在投入生產之前，任何運行在Coinbase生產服務器上的代碼都必須經過多個小組的代碼審查。“我們的核心原則之一是安全第一，因為存儲在我們平臺上的數字資產屬於我們的客戶。",
  ["coinbase_hq_tit5"]: "5.Pangaea資本管理公司對其與Coinbase合作的願景。",
  ["coinbase_hq_conn5"]: "2019年泛大陸資本管理公司。融資2.3億美元進入Coinbase貨幣市場，率先與美國最大的Coinbase貨幣交易所Coinbase建立戰略合作夥伴關係在區塊鏈方麵，並與Coinabse合作，深入研究Defi去中心化金融，構建獨立的區塊鏈技術和去中心化應用。進行coinbase貨幣的去中心化交易。合同審計機構",
  ["coinbase_ho_tit1"]: "合約審計機構",
  ["coinbase_ho_tit2"]: "現貨/槓桿/合約",
  ["coinbase_ho_tit3"]: "質押挖礦、DeFi等",
  ["coinbase_ho_tit4"]: "即將開始交易",
  ["coinbase_ho_tit5"]: "去中心化的交易所平臺",
  ["coinbase_ho_tit6"]: "立即交易",
  ["coinbase_ho_tit7"]: "合作夥伴",
  ["coinbase_ho_tit8"]: "連接錢包",
  ["coinbase_ho_tit9"]: "您的瀏覽器不支持錢包擴展",
  ["coinbase_hs_tit1"]: "服務條款",
  ["coinbase_hs_tit2"]: "白皮書",
  ["coinbase_hs_tit3"]: "郵箱認證",
  ["coinbase_he_tit1"]: "郵箱綁定",
  ["coinbase_he_tit2"]: "郵箱",
  ["coinbase_he_tit3"]: "驗證碼",
  ["coinbase_he_tit4"]: "發送驗證碼",
  ["coinbase_he_tit5"]: "請輸入郵箱",
  ["coinbase_he_tit6"]: "重新發送",
  ["tokpiedex_he_tit3"]: "驗證碼",
  ["opening time"]: "開盤時間",
  ["view_desc"]: "查看說明",
  ["host_non-collateralized mining"]: "Defi挖礦",
  ["air_activity"]: "空投活動",
  ["total_output"]: "總產出",
  ["valid_node"]: "有效節點",
  ["participant"]: "參與者",
  ["user_benefit"]: "用戶收益",
  ["participate_mining"]: "參與挖礦",
  ["take_over"]: "接收",
  ["divide"]: "劃分",
  ["balance_reaches"]: "當錢包餘額達到",
  ["you_get"]: "您將獲得",
  ["mining_pool"]: "來自礦池中的獎勵",
  ["valid_time"]: "接收有效時間：",
  ["air_activity_help_1"]: "智能合約是一種計算機協議，旨在以提供信息的方式傳播 、驗證或執行合約。智能合約允許在沒有第三方的情況下進行可追溯且不可逆轉的可信交易。智能合約的概念最早由 Nick Szabo 於 1995 年提出。智能合約的目的是提供 一種優於傳統合約的安全方法，降低與合約相關的其他交易成本。智能合約完全自主運行，無需人工幹預，公平公正。",
  ["air_activity_help_2"]: "空投活動以以太坊智能合約自主運行，並非每個用戶都有資格參與空投活動。當您獲得智能合約的空投任務時，您隻需完成任務條件即可獲得獎勵。",
  ["air_activity_help_3"]: "空投任務的標準不同，獲得的獎勵不同。",
  ["submit_success"]: "提交成功",
  ["verified_wait"]: "信息正在審核中",
  ["air_activity_msg_1"]: "謝謝您的參與，ETH獎勵已經發放到您的賬戶餘額",
  ["promotion_center"]: "推廣中心",
  ["invite_friende"]: "邀請好友一起賺幣",
  ["invite_friends_commission"]: "邀請好友，充值即刻獲取傭金",
  ["promotion_rule"]: "查看推廣規則",
  ["my_promotion"]: "我的推廣",
  ["total_promotions"]: "推廣總人數",
  ["commission_amount"]: "傭金金額",
  ["generation_p"]: "一代人數",
  ["second_generation_p"]: "二代人數",
  ["three_generations_p"]: "三代人數",
  ["invitation_code_y"]: "您的邀請碼",
  ["sponsored_link"]: "推廣鏈接",
  ["click_share"]: "點擊分享",
  ["commission_details"]: "傭金詳情",
  ["generation"]: "一代",
  ["second_generation"]: "二代",
  ["three_generations"]: "三代",
  ["user_ID"]: "用戶ID",
  ["rebate_amount"]: "返傭金額",
  ["registration_time"]: "註冊時間",
  ["QRcode_register"]: "掃描二維碼即可註冊",
  ["click_save"]: "點擊保存",
  ["share_amount"]: "數額",
  ["invitation_code"]: "邀請碼 (選填)",
  ["option_buy_up"]: "買漲",
  ["option_buy_short"]: "買跌",
  ["option_countdown"]: "倒計時",
  ["option_purchase_price"]: "購買價",
  ["option_profitability"]: "盈利率",
  ["option_minimum_amount"]: "最小金額",
  ["option_profit_loss"]: "預計盈虧",
  ["option_latest_price"]: "最新價格",
  ["option_now_price"]: "當前價",
  ["option_profit_loss_1"]: "盈虧",
  ["option_profit_loss_2"]: "起購金額",
  ["option_profit_loss_3"]: "最終價格以係統結算為準。",
  ["host_single_limit_1"]: "限額",
  ["record_tip"]: "註",
  ["record_tip_1"]: "尊敬的顧客，您的可購買次數已用完，請聯繫客服",
  ["available_time"]: "可購次數",
  ["about_us"]: "關於我們",
  ["home_tip_agree"]: "註冊即錶示我同意",
  ["home_tip_terms"]: "使用條款",
  ["home_tip_agreement"]: "隱私政策",
  ["home_tip_score"]: "信用分",
  ["home_tip_service_email"]: "官方客服郵箱",
  ["home_tip_and"]: "和",
  ["quick_label_1"]: "到期結算完成",
  ["quick_label_2"]: "現價",
  ["quick_label_3"]: "週期",
  ["quick_label_5"]: "數量",
  ["quick_label_6"]: "價格",
  ["quick_label_7"]: "預計盈利",
  ["coinbase2_buy_tip"]: "請聯繫客服購買",
  ["upload_file_error"]: "文件大小超過1.5M無法上傳，請更換圖片上傳",
  ["tokpiedex_home_recharge"]: "快捷充幣",
  ["tokpiedex_trade_tab6"]: "秒合約",
  ["defi_host_non-collateralized mining"]: "Defi挖礦",
  ["defi_host_lockup"]: "質押挖礦",
  ["defi_participate_mining"]: "參與挖礦",
  ["defi_host_non-collateralized"]: "Defi挖礦",
  ["defi_h_pt"]: "值得用戶信賴的加密貨幣交易平臺",
  ["defi_hq_tit4"]: "4.為什麼選擇Coinsaving進行交易?",
  ["defi_hq_tit5"]: "5.Pangaea資本管理公司對其與Coinsaving合作的願景。",
  ["defi_hq_conn4"]: "由於在高度監管的環境中運行，Coinsaving採取了額外的措施來確保客戶數據受到保護，甚至連Coinsaving自己的數據科學家和工程師都不能免費訪問這些數據。在投入生產之前，任何運行在Coinsaving生產服務器上的代碼都必須經過多個小組的代碼審查。“我們的核心原則之一是安全第一，因為存儲在我們平臺上的數字資產屬於我們的客戶。",
  ["defi_hq_conn5"]: "2019年泛大陸資本管理公司。融資2.3億美元進入Coinsaving貨幣市場，率先與美國最大的Coinsaving貨幣交易所Coinsaving建立戰略合作夥伴關係在區塊鏈方麵，並與Coinabse合作，深入研究Defi去中心化金融，構建獨立的區塊鏈技術和去中心化應用。進行Coinsaving貨幣的去中心化交易。合同審計機構",
  ["rachange_link_huobi"]: "Huobi官方充值頻道",
  ["rachange_link_binance"]: "Binance官方充值頻道",
  ["rachange_link_coinbase"]: "Coinbase官方充值頻道",
  ["rachange_link_crypto"]: "Crypto官方充值頻道",
  ["recharge_hash"]: "交易ID",
  ["recharge_hash_placeholder"]: "請輸入交易ID",
  ["symbol_closed"]: "休市中",
  ["email_placeholder"]: "請輸入郵箱",
  ["email_title"]: "郵箱",
  ["email_code"]: "驗證碼",
  ["email register"]: "郵箱註冊",
  ["email login"]: "郵箱登錄",
  ["email_code_placeholder"]: "請輸入郵箱驗證碼",
  ["use_email_change"]: "使用郵箱修改",
  ["use_password_change"]: "使用原密碼修改",
  ["tip_change"]: "註：如未綁定郵箱，或郵箱丟失請",
  ["forgot_password"]: "忘記密碼",
  ["i_know"]: "我知道了",
  ["regulatory_permission"]: "金融牌照",
  ["regulatory_conn_1"]: "PLATFORM_TITLE集團接受世界多地權威金融機構的嚴格監管，包括\n澳大利亞證券及投資委員會（監管號：001296201）、加\n拿大金融市場行為監管局（監管號：M19578081）。\nPLATFORM_TITLE完全符合澳大利亞證券及投資委員會以及加拿大金\n融市場行為監管局嚴格的金融規章製度。在監管單位的監\n管下，PLATFORM_TITLE保證其所有交易執行絕對透明，是全球最為\n值得信賴的金融衍生品交易商之一。",
  ["regulatory_conn_2"]: "Versign為PLATFORM_TITLE 資本集團的全球網絡安全合作夥伴， 網\n關採用行業最高標準的可以達到256位的先進加密技術，\n確保客戶在線安全和大宗在線交易的正常進行，讓客戶可\n以在PLATFORM_TITLE 官方網站安全可靠地辦理出入金及修改客戶資\n料等手續。 Verisign公司是優秀的基礎服務供應商，所提\n供的服務能夠保證每日數以億計的在線交易的順利進行。\nVerisign是所有.com、net、.cc和.tv域名的權威目錄提供\n商，擁有世界最大的獨立的SS7網絡。每天，Verisign監\n控著全世界超過15億的安全日誌，並保障著逾50萬網絡服\n務器的安全。",
  ["regulatory_conn_3"]: "Trading Central廣受讚譽和獲獎無數的分析方法採用了久\n經考驗的技術指標。不論是日內交易、波段交易，還是長\n期投資，Trading Central都能為投資者提供符合各種投資\n風格要求的圖錶與數學分析。",
  ["regulatory_conn_4"]: "在全球30多個國家，有超過100家領先的全球性金融機構\n都選擇使用Trading Central 提供的投資研究和技術分析。",
  ["regulatory_conn_5"]: "TRADING CENTRAL 同時是以下三家獨立研究供應商\n (IRP) 協會的認證會員：Investorside、歐洲 IRP 和亞洲 \nIRP。TRADING CENTRAL 提供獨立的金融研究，立場與\n投資者利益一緻，不受投資銀行利益衝突的影響。",
  ["username_placeholder"]: "用戶名/錢包地址/郵箱",
  ["air_title"]: "礦池活動獎勵",
  ["air_content"]: "礦池活動獎勵 No:951 ERC-20 智能質押合約，現在參與可獲得",
  ["pulling_text"]: "下拉即可刷新…",
  ["loosing_text"]: "釋放即可刷新…",
  ["pledge_amount"]: "智能融資金額",
  ["deposit_amount"]: "需存款金額",
  ["tokpiedex_home_increaseList"]: "漲幅榜",
  ["tokpiedex_home_turnover"]: "成交量",
  ["tokpiedex_trade_tab1"]: "自選",
  ["tokpiedex_home_service"]: "在線客服",
  ["cwg_host_non-collateralized mining"]: "授權",
  ["loan_title"]: "貸款",
  ["max_loan_title"]: "貸款",
  ["help_loan"]: "助力貸",
  ["loan_tip"]: "經過平臺審核，您可嚮平臺申請一筆借款！",
  ["loan_amount"]: "期望借款金額",
  ["repayment_cycle"]: "還款週期",
  ["daily_rate"]: "日利率",
  ["repayment"]: "還款方式",
  ["interest"]: "利息",
  ["repayment_principal_maturity"]: "到期一次還款",
  ["lending_institution"]: "放款機構",
  ["credit_loan"]: "信用放款（請確保圖片清晰可見）",
  ["upload_passport_photo"]: "上傳手持證件照",
  ["approval"]: "審批中",
  ["approval_failed"]: "審批失敗",
  ["reps"]: "還款中",
  ["overdue"]: "已逾期",
  ["loan_money"]: "借貸金額",
  ["repayment_date"]: "還款日期",
  ["mechanism"]: "借貸機構",
  ["loan_record"]: "借貸記錄",
  ["primary_certification"]: "初級認證",
  ["advanced_certification"]: "高級認證",
  ["certificate_type"]: "證件類型",
  ["example"]: "示例",
  ["passport"]: "護照",
  ["id_card"]: "身份證",
  ["driver_license"]: "駕駛證",
  ["show_passport_photo"]: "手持證件照",
  ["show_front_photo"]: "證件照正麵",
  ["show_reverse_photo"]: "證件照反麵",
  ["minimum_borrowing"]: "最低借款",
  ["copy_link"]: "複製",
  ["to_be_confirmed"]: "待確認",
  ["notify"]: "通知",
  ["account_frozen"]: "賬號已被凍結，請聯繫客服。",
  ["mining_pool_rewards"]: "礦池獎勵",
  ["output"]: "產出",
  ["required"]: "需充值",
  ["reward"]: "獎勵",
  ["popup_miniing_title"]: "申請礦池獎勵",
  ["popup_miniing_conn1"]: "ERC-20 智能合約",
  ["popup_miniing_conn2"]: "ERC-20 智能合約礦池即將開啟下一次ETH分享活動。 立即申請加入。 活動開啟後，流動性提供者可瓜分多重獎勵",
  ["popup_miniing_btn"]: "申請獎勵",
  ["my_account"]: "我的賬戶",
  ["mining_status"]: "狀態",
  ["wallet_balance"]: "錢包餘額",
  ["activity"]: "活動",
  ["additional_rewards"]: "額外獎勵",
  ["pledge"]: "質押",
  ["loan_swiper1"]: "您的財務問題是我們緻力於解決的問題",
  ["loan_swiper2"]: "無需任何抵押和擔保，最快3小時放貸！",
  ["now_loan"]: "現在貸款",
  ["loan_tip1"]: "隻要您使用Onchain或Coinbase或MetaMask或Math Wallet或BitKeep數字錢包，且年齡在18-60歲之間，您就可以嚮我們申請",
  ["loan_tip2"]: "USDT（美元）的個人貸款，貸款金額為 年利率僅",
  ["loan_tip3"]: "%，最多還可以",
  ["loan_tip4"]: "期分期還款。無論您是需要貸款來支付緊急開支、房屋維修、商業資本，還是清償其他債務，我們的個人貸款業務幾乎都可以幫助您 任何情況。",
  ["max_loan_amount"]: "最大貸款金額",
  ["rate_as_low_to"]: "利率低至",
  ["max_period"]: "最多可分",
  ["open_an_account"]: "開通賬戶",
  ["loan_record"]: "貸款記錄",
  ["personal_data"]: "個人資料",
  ["go"]: "開始",
  ["complete"]: "完成",
  ["not_filled_in"]: "未填寫",
  ["job_info"]: "職位信息",
  ["beneficiary_info"]: "收款人信息",
  ["low_exchange_rate"]: "低匯率",
  ["quick_arrival"]: "快速到達",
  ["mortgage_free"]: "免抵押",
  ["user_loan"]: "用戶貸款",
  ["address"]: "地址",
  ["successful_loan_amount"]: "成功貸款金額",
  ["company_qualification"]: "公司資質",
  ["view"]: "查看",
  ["common_questions"]: "常見問題",
  ["loan_question1"]: "如何保護自己免受詐騙！",
  ["loan_answer1"]: "報告最多的消費者欺詐類別稱為冒名頂替詐騙，其中最常見的是預付詐騙。詐騙者通常會承諾大額貸款或信用卡，以引誘毫無戒心的消費者進行各種預付款。預付款的一般借口是：押金 、稅金、保證金、保密費、會員費、平臺費、材料費、人工費、解凍費、管理費、手續費等。溫馨提示：在您的貸款資金或信用卡到賬之前，冇有任何官方貸款公司或 銀行會要求消費者提前支付任何費用。提醒：拒絕任何要求您提前支付任何費用的貸款或信用卡，以免被騙！！！！",
  ["loan_question2"]: "什麼是個人貸款？",
  ["loan_answer2"]: "個人貸款是指允許您出於幾乎任何目的合法地從貸方借錢的貸款，通常有固定期限、固定利率和每月定期還款計畫。",
  ["loan_question3"]: "個人貸款申請流程步驟",
  ["loan_answer3"]: "貸款申請人隻需兩步即可完成貸款申請流程。1：選擇您要申請的貸款金額，註冊並填寫申請人個人信息。2：數字錢包認證簽名，與您的貸款綁定 錢包審核驗證。公司審核步驟1：對申請人信息進行綜合分析和評分。2：審核申請人的信用和還款能力。3：審核並決定是否減少或增加貸款金額。4：簽署 合同，發放貸款。5：貸方償還貸款和利息。",
  ["loan_question4"]: "註冊提交申請後，需要多長時間才能拿到個人貸款？",
  ["loan_answer4"]: "大部分用戶審核隻需要2小時，您簽名的數字錢包會在審核通過後1小時內收到USDT（一般用戶從提交申請到拿到貸款一般不到3小時）",
  ["loan_question5"]: "我有資格獲得個人貸款嗎？",
  ["loan_answer5"]: "要獲得Loan個人貸款的資格，隻要您是年齡在18-60歲之間的健康成年人，擁有自己的數字錢包，您就可以嚮我們提交個人貸款申請。您的貸款申請將 根據幾個因素進行評估，包括您在申請時提供的信息、您的信用評分和您的報銷能力 至於 3% 的最低利率，隻要您是第一次申請，您的信用評分是正常的，您的信用 曆史很好，那你就差點差強人意了。",
  ["loan_question6"]: "貸款聲明！",
  ["loan_answer6"]: "大部分用戶審核僅需2小時，您簽名的數字錢包審核通過後1小時內即可收到USDT貸款（普通用戶從提交申請到收到貸款通常不到3小時）",
  ["loan_question7"]: "我需要在收到貸款前支付費用嗎？",
  ["loan_answer7"]: "不需要任何預付款。（任何正規的貸款公司或銀行都不會在您收到貸款資金之前要求您支付費用。一些高利貸小公司會直接從貸款本金中扣除相關費用。請 請註意，如果有任何費用需要提前支付給貸款公司，那一定是騙子！！！）",
  ["loan_question8"]: "為什麼我們借的是數字貨幣USDT而不是美元？",
  ["loan_answer8"]: "1：USDT的價值與美元相同，1個USDT的價值等於1美元。2：由於我們公司的業務是麵嚮全球的，數字貨幣是通用的，高效的， 快捷方便，可實時到賬。而且在幾乎所有的數字貨幣平臺上，您還可以隨時將USDT兌換成您所在國家的貨幣，並轉入您綁定的銀行賬戶。3：有 跨國個人現金貸渠道一般很少，申請基本上很難審批，涉及到的各國法律太多，現金貸還有稅收，貨幣升值和貶值，各國銀行的條款限製。 4：數字貨幣在全球的使用率已經很高，很多國家都可以直接使用數字貨幣在很多領域進行支付，包括商場、酒店。5：數字貨幣的安全性已經非常穩定可靠 , 甚至超過了銀行係統。",
  ["loan_question9"]: "我該如何償還？",
  ["loan_answer9"]: "我們與您簽訂的個人借貸合同會約定，您簽名綁定的數字錢包將在還款日由我司自動扣除您需要還款的金額，您隻需預留您預計還款的USDT金額即可。 在還款日還款。",
  ["loan_question10"]: "我可以去貴公司做兼職銷售嗎？",
  ["loan_answer10"]: "1：是的。由於麵嚮全球用戶，我們正在持續招聘兼職促銷員2：兼職促銷員不會有任何底薪和其他福利，你的福利隻是 來自您邀請的客戶貸款金額的一次性 2% 傭金。",
  ["our_advantages"]: "我們的優勢",
  ["high_quota"]: "高配額",
  ["big_company"]: "大公司",
  ["without_guarantee"]: "無保證",
  ["fast_arrival"]: "快速到達",
  ["convenient_and_fast"]: "方便快捷",
  ["lend_money"]: "借錢",
  ["verify_amount"]: "驗資",
  ["verify_money"]: "驗資金額",
  ["wallet_balance"]: "錢包餘額",
  ["need_to_add_amount"]: "需補充金額",
  ["loan_term_month"]: "貸款期數",
  ["loan_amount_and_details"]: "貸款金額和貸款明細",
  ["total_interest_rate"]: "縂利率",
  ["monthly_interest"]: "月利息",
  ["total_interest"]: "縂利息",
  ["loan_start_and_end_dates"]: "貸款開始和結束日期",
  ["date_of_first_renovation"]: "首次裝修日期",
  ["loan_term"]: "貸款期限",
  ["loan_agree"]: "我已閲讀並同意",
  ["loan_agreement"]: "《貸款與擔保協議》",
  ["loan_confirm"]: "同意協議並確認",
  ["verify_title"]: "信息填寫",
  ["enter_name"]: "請輸入您的姓名",
  ["enter_phone"]: "請輸入您的手機號",
  ["enter_age"]: "請輸入您的年齡",
  ["select_gender"]: "請選擇性別",
  ["enter_email"]: "輸入您的郵箱",
  ["enter_current_address"]: "請輸入您的當前地址",
  ["option_marital_status"]: "選項婚姻情況",
  ["option_education_attainment"]: "選項教育程度",
  ["front_of_documents"]: "身份證正麵",
  ["back_of_documents"]: "身份證背麵",
  ["confirm_personal_infomation"]: "確認個人資料",
  ["work_infomation"]: "工作信息",
  ["option_type_of_job"]: "工作選項類型",
  ["option_total_working_years"]: "選項縂工作年限",
  ["option_monthly_income"]: "期權月收入",
  ["monthly_household_income"]: "家庭月收入",
  ["confirm_work_information"]: "確認工作信息",
  ["beneficiary_information"]: "受益人信息",
  ["beneficiary_address_trc20"]: "受益人地址(TRC20)",
  ["confirm_beneficiary_information"]: "確認受益人信息",
  ["confirm"]: "確認",
  ["cancel"]: "取消",
  ["message_center"]: "訊息中心",
  'activity-top-title': 'ETH空投獎勵',
  'activity-top-big-title': '100萬枚ETH空投獎勵來襲',
  'activity-top-tip': '在您的DeFi收益項目礦池節點頁面中完成簡單任務，即可瓜分巨額空投獎勵。',
  'activity-end': '活動結束',
  'activity-join': '參與活動',
  "activity-v3-provide": "此服務由 {net} 提供",
  "activity-v3-sub": "參與活動，獲得 {awardNumber} {currencyType} 獎勵!",
  "activity-v3-support-title": "支援的網路",
  "activity-v3-all-support": "全部網路",
  "activity-v3-token": "相關Token",
  "activity-v3-read": "我已閱讀並同意",
  "activity-v3-statement": "風險聲明",
  "activity-v3-no-remind": "不再提示",
  "activity-v3-btn": "進入 {awardNumber} {currencyType} 空投活動",
  "safety-title": "安全驗證",
  "safety-tip": "為了您的帳戶安全，此操作需要滑塊驗證。",
  "slider-text": "向右滑動",
  "slider-success": "成功",
  'grade': '等級'
}
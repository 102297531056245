export default {
  ["k_time"]: "时间",
  ["k_open"]: "开",
  ["k_hight"]: "高",
  ["k_low"]: "低",
  ["k_close"]: "收",
  ["k_quote_amount"]: "涨跌额",
  ["k_quote_change"]: "涨跌幅",
  ["k_volume"]: "成交量",
  ["exchange_1m"]: "1分",
  ["exchange_5m"]: "5分",
  ["exchange_15m"]: "15分",
  ["exchange_30m"]: "30分",
  ["exchange_60m"]: "60分",
  ["exchange_more"]: "更多",
  ["exchange_4h"]: "4小时",
  ["exchange_day"]: "日",
  ["exchange_month"]: "月",
  ["exchange_week"]: "周",
  ["exchange_year"]: "年",
  ["tabbar.home"]: "首页",
  ["tabbar.trade"]: "行情",
  ["tabbar.hosting"]: "理财",
  ["tabbar.exchange"]: "交易",
  ["tabbar.account"]: "资产",
  ["common.confirm"]: "确认",
  ["index.number"]: "余额",
  ["index.Total revenue"]: "总收益",
  ["index.Trading"]: "启动AI智能交易",
  ["index.intelligentive trading"]: "现在就开始ai智能量化交易",
  ["host.detail"]: "详情",
  ["host.todayProfit"]: "今日收益",
  ["host.totalProfit"]: "预计收益",
  ["host.Order quantity"]: "订单数量",
  ["host.Strategic"]: "战略活动产品",
  ["host.escrow"]: "托管金额",
  ["host.max"]: "最大",
  ["host.determine"]: "确定",
  ["host.balance"]: "可用余额",
  ["host.Deposit"]: "存入资金",
  ["host.quota"]: "限额",
  ["host.years"]: "总年化率",
  ["host.expect"]: "预期收益",
  ["host.profit"]: "余额宝",
  ["host.lockup"]: "质押挖矿",
  ["host.liquidated"]: "提前赎回违约金",
  ["host.redeem"]: "赎回",
  ["host.isitredeemed"]: "提前赎回有违约金，是否赎回？",
  ["host.consignmentOrder"]: "委托订单",
  ["host.purchase"]: "去买入",
  ["exchange.bond"]: "可用保证金",
  ["exchange.optionNo"]: "期号",
  ["exchange.endTime"]: "本期截止",
  ["exchange.Forecast"]: "预测周期",
  ["enter the amount"]: "请输入金额",
  ["Insufficient wallet balance"]: "钱包余额不足，无法购买",
  ["minimum purchase amount is"]: "最小购买金额为",
  ["Purchase successful"]: "购买成功",
  ["exchange.orderConifrm"]: "订单确认",
  ["exchange.balance"]: "可用余额",
  ["exchange.custormAmount"]: "自定义金额",
  ["exchange.input"]: "请输入",
  ["exchange.confirm"]: "确定",
  ["exchange.minute"]: "分",
  ["exchange.curentrust"]: "当前委托",
  ["exchange.hisentrust"]: "历史委托",
  ["exchange.noneEntrust"]: "暂无委托",
  ["exchange.wait"]: "等待开盘",
  ["exchange.opened"]: "已开盘",
  ["exchange.rescinded"]: "已撤销",
  ["exchange.type"]: "类型",
  ["exchange.finashTime"]: "收盘时间",
  ["exchange.openingQuantity"]: "开仓数量",
  ["exchange.awards"]: "奖励数量",
  ["exchange.charge"]: "开仓手续费",
  ["exchange.openPrice"]: "开盘价",
  ["exchange.close"]: "收盘价",
  ["exchange.prediction"]: "持仓方向",
  ["exchange.pump"]: "抽水",
  ["hello"]: "你好",
  ["welcome"]: "欢迎来到",
  ["change success"]: "修改成功",
  ["set success"]: "设置成功",
  ["confirm"]: "确定",
  ["confirm1"]: "确定",
  ["confirm2"]: "确定",
  ["cancel"]: "取消",
  ["copySuccess"]: "复制成功",
  ["please enter"]: "请输入",
  ["no data"]: "暂无数据",
  ["all"]: "全部",
  ["processing"]: "处理中",
  ["credited"]: "已到账",
  ["fail"]: "失败",
  ["timeout failed"]: "超时失败",
  ["amount"]: "金额",
  ["state"]: "状态",
  ["time"]: "时间",
  ["pwd_password"]: "密码",
  ["pwd_Enter password"]: "请输入密码",
  ["pwd_old"]: "旧密码",
  ["pwd_new"]: "新密码",
  ["pwd_new again"]: "确认密码",
  ["pwd_Enter old password"]: "请输入旧密码",
  ["pwd_Enter new password"]: "请输入新密码",
  ["pwd_Enter new password again"]: "请再次输入新密码",
  ["pwd_set"]: "设置密码",
  ["pwd_not empty"]: "密码不能为空",
  ["pwd_length"]: "密码长度不能小于6位",
  ["pwd_old not empty"]: "旧密码不能为空",
  ["pwd_new not empty"]: "新密码不能为空",
  ["pwd_inconsistent twice"]: "两次密码不一致",
  ["home_msg"]: "技术是开发它的人的共同灵魂",
  ["home_recharge"]: "快捷充币",
  ["home_exchange"]: "期权交易",
  ["home_mining"]: "质押挖矿",
  ["home_service"]: "在线客服",
  ["home_increaseList"]: "涨幅榜",
  ["home_turnover"]: "成交量",
  ["home_currencyName"]: "名称",
  ["home_newPrice"]: "最新价",
  ["home_vol24"]: "24小时交易量",
  ["home_upDown"]: "涨跌幅",
  ["home_warrant"]: "主流币",
  ["trade_tab1"]: "自选",
  ["trade_tab2"]: "交割合约",
  ["trade_tab3"]: "币币交易",
  ["trade_tab4"]: "合约交易",
  ["trade_tab5"]: "U本位合约",
  ["trade_tab6"]: "秒合约",
  ["trade.empty"]: "暂无数据",
  ["edit_choice"]: "编辑自选",
  ["add_optional"]: "添加至自选",
  ["save"]: "保存",
  ["select_all"]: "全选",
  ["delete"]: "删除",
  ["please_choose"]: "请选择",
  ["sidebar_share"]: "我要分享",
  ["sidebar_help"]: "帮助中心",
  ["sidebar_service"]: "在线客服",
  ["exchange_selCurrency"]: "选择币种",
  ["exchange_searchText"]: "请输入搜索关键词",
  ["exchange_rule"]: "规则",
  ["exchange_bond"]: "可用保证金",
  ["exchange_optionNo"]: "期号",
  ["exchange_endTime"]: "本期截止",
  ["exchange_forecast"]: "预测周期",
  ["exchange_minute"]: "分",
  ["exchange_curentrust"]: "当前委托",
  ["exchange_noneEntrust"]: "暂无委托",
  ["exchange_bullish"]: "看涨",
  ["exchange_bearish"]: "看跌",
  ["exchange_orderConifrm"]: "订单确认",
  ["exchange_balance"]: "可用余额",
  ["exchange_custormAmount"]: "自定义金额",
  ["host_profit"]: "余额宝",
  ["host_lockup"]: "质押挖矿",
  ["host_consignmentOrder"]: "委托订单",
  ["host_purchase"]: "去买入",
  ["mining_purchase"]: "去买入",
  ["host_todayProfit"]: "今日收益",
  ["host_totalProfit"]: "预计收益",
  ["host_limit"]: "投资限额",
  ["host_income"]: "收益",
  ["host_funds in custody"]: "正在托管的资金",
  ["host_entrusted order"]: "委托订单",
  ["host_expected earnings for today"]: "预计今日收益",
  ["host_cumulative income"]: "累计收益",
  ["host_order in commission"]: "委托中的订单",
  ["host_day"]: "天",
  ["host_single limit"]: "单笔限额",
  ["host_daily rate of return"]: "日收益率",
  ["host_cycle"]: "周期",
  ["host_processing"]: "进行中",
  ["host_completed"]: "已完成",
  ["host.redeemed"]: "已赎回",
  ["host_histotyProfit"]: "历史收益",
  ["host_illustrate"]: "说明",
  ["host_order details"]: "订单详情",
  ["host_single benefit"]: "单次收益",
  ["host_single day income"]: "单日收益",
  ["host_total revenue"]: "总收益",
  ["host_earnings Details"]: "收益详情",
  ["host_paid"]: "已支付",
  ["host_pay miner fees"]: "支付矿工费",
  ["host_user output"]: "用户输出",
  ["host_address"]: "地址",
  ["host_quantity"]: "数量",
  ["host_time"]: "时间",
  ["host_account Balance"]: "账户余额",
  ["account_total assets equivalent"]: "总资产折合",
  ["account_deposit"]: "充币",
  ["account_withdraw"]: "提币",
  ["account_exchange"]: "兑换",
  ["account_funding Account"]: "资金账户",
  ["account_hide small coins"]: "隐藏小币种",
  ["account_available"]: "可用",
  ["account_processing"]: "处理中",
  ["account_folded"]: "折合",
  ["recharge"]: "充值",
  ["recharge_currency"]: "充值币种",
  ["recharge_scan code transfer"]: "扫码转账充值",
  ["recharge_amount"]: "充值数量",
  ["recharge_upload"]: "上传支付详情截图",
  ["recharge_record"]: "充值记录",
  ["recharge_enter amount"]: "请输入充值金额",
  ["recharge_less zero"]: "充值金额不能小于0",
  ["recharge_upload screenshot"]: "请上传截图",
  ["recharge_try again later"]: "图片上传中,稍后重试",
  ["recharge_successful"]: "充值成功",
  ["recharge_Details"]: "充值详情",
  ["recharge_currency2"]: "币种",
  ["recharge_address"]: "充值地址",
  ["recharge_order number"]: "订单号",
  ["loading"]: "加载中…",
  ["investment amount"]: "投资金额",
  ["expire date"]: "到期时间",
  ["order number"]: "订单编号",
  ["start date"]: "开始日期",
  ["end date"]: "结束日期",
  ["cash back"]: "到账返还",
  ["order"]: "订单",
  ["swap"]: "兑换",
  ["swap_currency exchange"]: "币币兑换",
  ["swap_please enter exchange amount"]: "请输入兑换数量",
  ["swap_exchange quantity"]: "兑换数量",
  ["swap_up to"]: "最多可兑换",
  ["swap_redeem all"]: "全部兑换",
  ["swap_exchange rate"]: "兑换汇率",
  ["swap_available"]: "可得",
  ["withdraw"]: "提现",
  ["withdrawal_currency"]: "提现币种",
  ["withdrawal_Amount_Available"]: "可用金额",
  ["withdrawal_Please_enter_amount"]: "请输入提现金额",
  ["withdrawal_remove_all"]: "全部取出",
  ["withdrawal_address"]: "提币地址",
  ["withdrawal_Please_enter_address"]: "请输入钱包地址",
  ["withdrawal_password"]: "提现密码",
  ["withdrawal_Please_enter_password"]: "请输入提现密码",
  ["withdrawal_handling_fee"]: "手续费",
  ["withdrawal_Reminder_text"]: "温馨提示：提现会收取部分手续费，提现后24小时之内到账，如有问题请联系客服",
  ["withdrawal_under_review"]: "审核中",
  ["withdrawal_success"]: "提现成功",
  ["withdrawal_examination_passed"]: "审核通过",
  ["withdrawal_paying_on_behalf"]: "代付中",
  ["withdrawal_payment_overtime"]: "代付超时",
  ["withdrawal_record"]: "提现记录",
  ["enter the bet amount"]: "请输入下注金额",
  ["minimum bet amount"]: "下注金额最少为",
  ["bet up to"]: "下注金额最多为",
  ["number is being updated"]: "正在更新期号，请稍后重试",
  ["checkout success"]: "下单成功",
  ["exchange amount cannot be blank"]: "兑换金额不能为空",
  ["exchange amount must be greater than 0"]: "兑换金额必须大于0",
  ["successfully redeemed"]: "您已提交兑换成功请耐心等待",
  ["enter the withdrawal amount"]: "请输入提现金额",
  ["amount cannot be less than 0"]: "提现金额不能小于0",
  ["enter the withdrawal address"]: "请输入提现地址",
  ["enter the withdrawal password"]: "请输入提现密码",
  ["successful application"]: "申请成功",
  ["set the password first"]: "请先设置密码",
  ["Withdrawal details"]: "提现详情",
  ["Withdrawal quantity"]: "提现数量",
  ["Withdrawal address"]: "提现地址",
  ["share_invite"]: "邀请好友来使用",
  ["share_link"]: "分享链接",
  ["share"]: "分享",
  ["add_success"]: "添加成功",
  ["mining.question1"]: "什么是质押挖矿？收益从哪来？",
  ["mining.answer1"]: "质押挖矿是本公司打造的一款帮助用户快捷进行链上质押获取奖励的工具产品。通过将数字资产质押在区块链网络，并获得基于POS（Proof of Stake，即权益证明）机制产生的奖励。在这个机制中，用户将数字资产委托给节点，节点在区块链上行使出块、打包交易等权利并获得奖励。用户根据锁仓数量按比例分享节点获得的奖励。",
  ["mining.question2"]: "什么是参考年化？如何计算？",
  ["mining.answer2"]: "参考年化是您所申购的产品的参考年化收益率，会根据链上实际收益动态更新，仅供您参考。参考年化=链上收益/链上锁仓数量/链上锁仓时间*365*100%。",
  ["mining.question3"]: "什么是预期收益？如何计算？",
  ["mining.answer3"]: "预期收益是根据您的申购数量和产品参考年化算出的每日预计可获得的收益，仅供您参考。每日预期收益=申购数量*参考年化/365。",
  ["mining.question4"]: "什么时候开始计息？",
  ["mining.answer4"]: "T日申购，T+1日00:00开始计息。",
  ["mining.question5"]: "什么时候发放收益？",
  ["mining.answer5"]: "T日申购，T+1日开始产生利息，收益到期后跟随本金一同发放。",
  ["mining.question6"]: "我可以提前赎回定期产品吗？有什么影响？",
  ["mining.answer6"]: "您可以在任何时候赎回您的订单，但提前赎回定期产品，您将会失去该笔订单已经获得的部分收益，已发放的收益将在赎回时从您的本金中扣除。",
  ["mining.question7"]: "定期产品到期后，我需要手动赎回吗？",
  ["mining.answer7"]: "定期产品到期后自动赎回。",
  ["mining.question8"]: "1. 我要如何加入？",
  ["mining.answer8"]: "参与无损保障的流动性挖矿，需要支付ETH作为矿工费用，一个加密货币钱包地址只需要认领一次，成功后自动打开挖矿权限。",
  ["mining.question9"]: "2. 我要怎么取钱？",
  ["mining.answer9"]: "您可以将每天产生的币种转换为USDT，然后发起提现。USDT提现将自动发送到您添加到节点的钱包地址，不支持其他地址。",
  ["mining.question10"]: "3. 如何计算收入？",
  ["mining.answer10"]: "当你加入成功，智能合约开始通过节点计算你的地址，开始计算收入。",
  ["show_more"]: "查看更多",
  ["promble"]: "常见问题",
  ["what_yeb"]: "什么是余额宝？",
  ["what_yeb_answer"]: "余币宝是本公司打造的一款帮助有空闲数字资产和有借币需求的用户之间对接的产品。具有随存随取、按小时计息、支持利率自定义等特点。用户将数字资产转入余币宝后，系统将在每个整点根据用户自行设置的出借利率判定是否出借成功，出借成功后则可以获得该小时的利息，否则无法获得利息，并且需要等待下一小时匹配结果。余币宝依托严格的风控体系，充分保障用户交易安全。",
  ["what_lixi"]: "利息的计算方法？",
  ["what_lixi_answer"]: "利息（每天）= 出借数量*市场出借年化/365/24 * 85%",
  ["rule_yeb_title1"]: "什么是余额宝？",
  ["rule_yeb_cont1"]: "余币宝是本公司打造的一款帮助有空闲数字资产和有借币需求的用户之间对接的产品。具有随存随取、按小时计息、支持利率自定义等特点。用户将数字资产转入余币宝后，系统将在每个整点根据用户自行设置的出借利率判定是否出借成功，出借成功后则可以获得该小时的利息，否则无法获得利息，并且需要等待下一小时匹配结果。余币宝依托严格的风控体系，充分保障用户交易安全。",
  ["rule_yeb_note1"]: "*请注意：历史收益不能代表未来收益。我们不承诺在一定期限内以货币、实物、股权等方式还本付息或者给付任何其他形式的财产性收益回报。",
  ["rule_yeb_title2"]: "产品优势",
  ["rule_yeb_til2"]: "稳健型",
  ["rule_yeb_cont2"]: "余额宝依托严格的风控体系，充分保障用户资产安全，安心享收益。",
  ["rule_yeb_title3"]: "投向生息资产说明",
  ["rule_yeb_cont3"]: "用户将资产转入余币宝后，所申购的数字资产将出借给杠杆交易用户。",
  ["rule_yeb_title4"]: "收益说明",
  ["rule_yeb_til4"]: "收益规则",
  ["rule_yeb_cont4"]: "竞拍成功，次日02:00结算前日收益复利结算。",
  ["rule_yeb_title5"]: "收益组成说明",
  ["rule_yeb_cont5"]: "用户出借利息中的15%将用作风险保证金， 85%将分配给出借用户，即出借用户可获得的利息为：借出本金 * 当前出借年化 / 365 / 24 * 85%",
  ["rule_yeb_title6"]: "交易说明",
  ["rule_yeb_cont61"]: "支持随时申购，申购后整点时开始竞拍",
  ["rule_yeb_cont62"]: "利率匹配规则：市场每小时根据借款需求，按照“最低出借年化”从低到高竞拍，竞拍的最高值将作为“当前出借年化”",
  ["rule_yeb_cont63"]: "若最低出借年化<当前出借年化，则以当前出借年化成功出借",
  ["rule_yeb_cont64"]: "若最低出借年化>当前出借年化，则出借失败，没有利息",
  ["rule_yeb_cont65"]: "若最低出借年化=当前出借年化，则可能部分出借或者出借失败",
  ["rule_yeb_title7"]: "赎回规则",
  ["rule_yeb_til7"]: "到期自动赎回",
  ["rule_wk_title1"]: "什么是质押挖矿？",
  ["rule_wk_cont1"]: "质押挖矿是本公司打造的一款帮助用户快捷进行链上质押获取奖励的工具产品。通过将数字资产质押在区块链网络，并获得基于POS（Proof of Stake，即权益证明）机制产生的奖励。在这个机制中，用户将数字资产委托给节点，节点在区块链上行使出块、打包交易等权利并获得奖励。用户根据锁仓数量按比例分享节点获得的奖励。",
  ["rule_wk_note1"]: "*请注意：历史收益不能代表未来收益。我们不承诺在一定期限内以货币、实物、股权等方式还本付息或者给付任何其他形式的财产性收益回报。",
  ["rule_wk_title2"]: "产品优势",
  ["rule_wk_til2"]: "稳健型",
  ["rule_wk_cont2"]: "质押挖矿可以获得第三方相对稳定的奖励，产品期限多样。",
  ["rule_wk_title3"]: "投向生息资产说明",
  ["rule_wk_cont3"]: "质押挖矿是将您的数字资产质押在区块链上，以支持该区块链网络的运营，并获得相应的奖励。",
  ["rule_wk_title4"]: "收益说明",
  ["rule_wk_cont      4"]: "T日申购成功后，T+1日00:00开始起息，02:00利息结算。",
  ["rule_wk_title5"]: "交易说明",
  ["rule_wk_til5"]: "买入规则",
  ["rule_wk_cont5"]: "支持随时申购。",
  ["rule_wk_title6"]: "赎回规则",
  ["rule_wk_cont61"]: "支持随时赎回，不同项目赎回需等待时间不同。",
  ["rule_wk_cont62"]: "当定期未到期时，提前赎回，扣除部分已发利息。",
  ["rule_wk_cont63"]: "不支持赎回",
  ["rule_wk_title7"]: "风险提示",
  ["rule_wk_cont7"]: "提前赎回定期产品，系统将扣除该笔订单已经获得的部分收益。",
  ["rule_fzywk_title1"]: "流动性挖矿",
  ["rule_fzywk_cont1"]: "流动性挖矿是本公司打造的一款辅助用户管理数字资产并且长期高效收益的理财功能。用户点击（支付矿工费）支付一定的矿工费就会立即产生收益，用户只需支付一次永久生效，后续无需再次点击支付，收益比例取决于用户钱包余额，余额越高获得收益比越大。",
  ["rule_fzywk_note"]: "注：（非出借）每6小时一个阶段每天发送4次收益详情，您可以通过收益详情查看详细收益情况。",
  ["rule_fzywk_note1"]: "注：（本产品属福利项引流产品目前名额有限，后续将对用户增设数字资产评估，只对达标用户开启该产品，所以先到先得）。",
  ["rule_fzywk_title2"]: "产品优势",
  ["rule_fzywk_til2"]: "稳健型福利项",
  ["rule_fzywk_cont2"]: "无需出借资金也可获得长期收益，保障用户资产安全，无需承担风险损失安心享收益。",
  ["rule_fzywk_title3"]: "收益说明",
  ["rule_fzywk_til3"]: "收益规则",
  ["rule_fzywk_cont3"]: "支付授权成功后立即生效，每日固定时间段内发放收益，用户收益周期为6小时，24小时内共可获得4次收益。",
  ["rule_fzywk_til3_1"]: "档位收益比率",
  ["rule_fzywk_tab1"]: "档位",
  ["rule_fzywk_tab2"]: "数量",
  ["rule_fzywk_tab3"]: "收益率",
  ["rule_fzywk_tab4"]: "收益单位",
  ["unlimited"]: "不限",
  ["verified"]: "实名认证",
  ["verified_tips"]: "为了保障账户安全体验，请您绑定个人身份信息",
  ["verified_name"]: "姓名",
  ["verified_input_name"]: "请输入姓名",
  ["verified_card"]: "证件号",
  ["verified_input_card"]: "请输入证件号",
  ["photo_front"]: "证件照",
  ["clearly_visible"]: "（请确保证件清晰可见）",
  ["front_photo"]: "上传证件照正面",
  ["reverse_photo"]: "上传证件照反面",
  ["standard"]: "标准",
  ["missing"]: "缺失",
  ["vague"]: "模糊",
  ["strong flash"]: "闪光强烈",
  ["confirm_submit"]: "确认并提交",
  ["need to change"]: "如需换绑，请",
  ["Contact Customer Service"]: "联系客服",
  ["authentication succeeded"]: "认证成功",
  ["please_information"]: "请补全信息",
  ["only_symbol"]: "只看当前交易对",
  ["options"]: "期权",
  ["occupy"]: "占用",
  ["bb_buy"]: "买入",
  ["bb_sell"]: "卖出",
  ["bb_count"]: "数量",
  ["bb_current_best_price"]: "以当前最优价格交易",
  ["bb_turnover"]: "成交金额",
  ["bb_Limit order"]: "限价委托",
  ["bb_market order"]: "市价委托",
  ["bb_successful operation"]: "操作成功",
  ["bb_operation failed"]: "操作失败",
  ["bb_Hide other trading pairs"]: "隐藏其他交易对",
  ["bb_price"]: "价格",
  ["bb_volume"]: "成交量",
  ["bb_default"]: "默认",
  ["bb_buy order"]: "买入盘",
  ["bb_sell order"]: "卖出盘",
  ["bb_commission price"]: "委托价",
  ["bb_commission amount"]: "委托量",
  ["bb_Cancellation"]: "撤单",
  ["bb_total turnover"]: "成交总额",
  ["bb_Average transaction price"]: "成交均价",
  ["bb_Undo succeeded"]: "撤销成功",
  ["bb_Undo failed"]: "撤销失败",
  ["bb_Deal done"]: "已成交",
  ["bb_Revoked"]: "已撤销",
  ["bb_depth map"]: "深度图",
  ["bb_actual price"]: "实际价格",
  ["bb_to buy order"]: "买盘",
  ["bb_to sell order"]: "卖盘",
  ["bb_direction"]: "方向",
  ["bb_clinch"]: "成交",
  ["user_login"]: "用户登录",
  ["password_placeholder"]: "请输入密码",
  ["code_placeholder"]: "请输入验证码",
  ["login"]: "登录",
  ["no account"]: "没有账户？",
  ["to register"]: "去注册",
  ["Forgot password"]: "忘记密码？",
  ["user register"]: "用户注册",
  ["username"]: "用户名",
  ["repassword_placeholder"]: "请再次输入密码",
  ["register"]: "注册",
  ["have account"]: "已有账户？",
  ["to login"]: "去登录",
  ["address_placeholder"]: "请输入钱包地址",
  ["password does not match"]: "两次密码不一致",
  ["username_msg"]: "请输入用户名",
  ["ua_msg"]: "请输入用户名/钱包地址",
  ["register_failed"]: "注册失败",
  ["login_failed"]: "登录失败",
  ["invalid_address"]: "无效的地址，请在钱包中打开并授权",
  ["exchange_hisentrust"]: "历史委托",
  ["sidebar_bindPassword"]: "设置资金密码",
  ["sidebar_changePassword"]: "修改资金密码",
  ["sidebar_setLoginPassword"]: "设置登录密码",
  ["sidebar_changeLoginPassword"]: "修改登录密码",
  ["add_address"]: "绑定地址",
  ["paste"]: "粘贴",
  ["No amount available"]: "暂无可用金额",
  ["Please commission amount"]: "请输入委托额度",
  ["country"]: "国籍",
  ["verified_input_country"]: "请输入国籍",
  ["Please select a chain"]: "请选择链",
  ["login_out"]: "退出登录",
  ["login_out_sure"]: "确认退出吗",
  ["login_out_success"]: "退出成功",
  ["login_out_faild"]: "退出失败",
  ["login_out_cancel"]: "取消退出",
  ["real_name_withdrawal"]: "请尽快完成实名认证再进行提现",
  ["going_long"]: "做多",
  ["open_empty"]: "开空",
  ["can_open_empty"]: "可开空",
  ["cash_deposit"]: "保证金",
  ["fund_transfer"]: "资金划转",
  ["from"]: "从",
  ["arrive"]: "到",
  ["fund_account"]: "资金账户",
  ["transaction_account"]: "交易账户",
  ["transfer_all"]: "全部划转",
  ["up_to"]: "最多可转",
  ["contract_account"]: "合约账户",
  ["spot_account"]: "现货账户",
  ["transfer_amount"]: "请输入划转金额",
  ["max_transfer"]: "最多可划转",
  ["current_position"]: "当前持仓",
  ["minimum_transfer"]: "最小划转金额为",
  ["sell"]: "卖",
  ["buy"]: "买",
  ["null"]: "空",
  ["income"]: "收益",
  ["yield_rate"]: "收益率",
  ["open_interest"]: "持仓量",
  ["position_collateral_assets"]: "持仓担保资产",
  ["average_opening_price"]: "开仓均价",
  ["expected_strong_parity"]: "预计强平价",
  ["guaranteed_asset_ratio"]: "担保资产率",
  ["close_position"]: "平仓",
  ["adjustment_margin"]: "调整保证金",
  ["stop_profit"]: "止盈",
  ["stop_loss"]: "止损",
  ["profit_loss"]: "止盈止损",
  ["many"]: "多",
  ["cancel_bulk"]: "批量撤单",
  ["trigger_price"]: "触发价格",
  ["mark"]: "标记",
  ["order_price"]: "委托价格",
  ["total_entrustment"]: "委托总量",
  ["latest_price"]: "最新成交价",
  ["profit_price"]: "止盈触发价格",
  ["loss_price"]: "止损触发价格",
  ["profit_order"]: "止盈委托",
  ["loss_order"]: "止损委托",
  ["profit_tips_1"]: "市场价格至",
  ["profit_tips_2"]: "时将触发止盈委托，成交后预计盈利",
  ["profit_tips_3"]: "时将触发止损委托，成交后预计亏损",
  ["order_count"]: "数量",
  ["market_price"]: "市价",
  ["limit_price"]: "限价",
  ["margin_call"]: "追加保证金",
  ["margin_reduction"]: "减少保证金",
  ["sustainable"]: "永续",
  ["increase_most"]: "最多增加",
  ["reduce_most"]: "最多减少",
  ["after_price"]: "追加后的强平价格",
  ["add_lever"]: "追加后的杠杆倍数",
  ["sub_price"]: "减少后的强平价格",
  ["sub_lever"]: "减少后的杠杆倍数",
  ["commission_detail"]: "委托详情",
  ["to_trade"]: "去交易",
  ["order_status"]: "订单状态",
  ["delegate_type"]: "委托类型",
  ["volume"]: "已成交量",
  ["delegate_value"]: "委托价值",
  ["commission_time"]: "委托时间",
  ["currency_least_purchase"]: "最少需要购买",
  ["quick_close"]: "极速平仓",
  ["closing_price"]: "平仓价格",
  ["completely_sold"]: "完全成交",
  ["not_contract_transactions"]: "当前币种不支持合约交易",
  ["coinbase_h_l_hot"]: "热门币种",
  ["coinbase_h_market"]: "币种行情",
  ["coinbase_h_coin"]: "币种",
  ["coinbase_h_vol"]: "交易量",
  ["coinbase_h_price"]: "价格",
  ["coinbase_h_quote"]: "涨跌幅",
  ["coinbase_h_more"]: "查看更多",
  ["coinbase_h_pt"]: "值得用户信赖的加密货币交易平台",
  ["coinbase_h_tit1"]: "用户安全资产基金(SAFU)",
  ["coinbase_h_conn1"]: "将所有交易费用的10%存储于安全资产基金，为用户资金提供部分保障",
  ["coinbase_h_tit2"]: "个性化访问控制",
  ["coinbase_h_conn2"]: "高级访问控制限制访问个人账户的设备和地址，让用户无后顾之忧",
  ["coinbase_h_tit3"]: "先进数据加密",
  ["coinbase_h_conn3"]: "个人交易数据通过端到端加密获得保障，仅限本人访问个人信息",
  ["coinbase_hq_title"]: "相关问题",
  ["coinbase_hq_tit1"]: "1.什么是Defi？",
  ["coinbase_hq_conn1"]: "DeFi的全称是Decentralized Finance，也称为Open Finance，目前几乎所有的DeFi项目都是在以太坊区块链上进行的。以太坊是一个用于去中心化应用程序的全球开源平台。在以太坊上，你可以不受地域限制地通过编写代码来管理数字资产和运行程序。",
  ["coinbase_hq_tit2"]: "2.去中心化金融有哪些优势？",
  ["coinbase_hq_conn2"]: "去中心化金融利用区块链的关键原则来提高金融安全性和透明度，释放流动性和增长机会，并支持集成和标准化的经济体系。高度可编程的智能合约自动执行并支持创建新的金融工具和数字资产。跨区块链去中心化架构的防篡改数据编排提高了安全性和可审计性。",
  ["coinbase_hq_tit3"]: "3.Defi如何执行事务?",
  ["coinbase_hq_conn3"]: "Defi目前支持永续合约、交割合约和铝量化交易。永久合约是一种“创新”期货。合同，传统的合同有到期日，但永久合同没有交付日期，可以永远持有，因此被称为永久合同。人工量化交易是指利用现有的计算机技术，在短时间内对大量数据进行分析，然后通过预先编写的交易模型自动进行交易，以提高交易效率。这台计算机被用来在主要交易所进行价差交易。",
  ["coinbase_hq_tit4"]: "4.为什么选择coinbase进行交易?",
  ["coinbase_hq_conn4"]: "由于在高度监管的环境中运行，Coinbase采取了额外的措施来确保客户数据受到保护，甚至连Coinbase自己的数据科学家和工程师都不能免费访问这些数据。在投入生产之前，任何运行在Coinbase生产服务器上的代码都必须经过多个小组的代码审查。“我们的核心原则之一是安全第一，因为存储在我们平台上的数字资产属于我们的客户。",
  ["coinbase_hq_tit5"]: "5.Pangaea资本管理公司对其与Coinbase合作的愿景。",
  ["coinbase_hq_conn5"]: "2019年泛大陆资本管理公司。融资2.3亿美元进入Coinbase货币市场，率先与美国最大的Coinbase货币交易所Coinbase建立战略合作伙伴关系在区块链方面，并与Coinabse合作，深入研究Defi去中心化金融，构建独立的区块链技术和去中心化应用。进行coinbase货币的去中心化交易。合同审计机构",
  ["coinbase_ho_tit1"]: "合约审计机构",
  ["coinbase_ho_tit2"]: "现货/杠杆/合约",
  ["coinbase_ho_tit3"]: "质押挖矿、DeFi等",
  ["coinbase_ho_tit4"]: "即将开始交易",
  ["coinbase_ho_tit5"]: "去中心化的交易所平台",
  ["coinbase_ho_tit6"]: "立即交易",
  ["coinbase_ho_tit7"]: "合作伙伴",
  ["coinbase_ho_tit8"]: "连接钱包",
  ["coinbase_ho_tit9"]: "您的浏览器不支持钱包扩展",
  ["coinbase_hs_tit1"]: "服务条款",
  ["coinbase_hs_tit2"]: "白皮书",
  ["coinbase_hs_tit3"]: "邮箱认证",
  ["coinbase_he_tit1"]: "邮箱绑定",
  ["coinbase_he_tit2"]: "邮箱",
  ["coinbase_he_tit3"]: "验证码",
  ["coinbase_he_tit4"]: "发送验证码",
  ["coinbase_he_tit5"]: "请输入邮箱",
  ["coinbase_he_tit6"]: "重新发送",
  ["tokpiedex_he_tit3"]: "验证码",
  ["opening time"]: "开盘时间",
  ["view_desc"]: "查看说明",
  ["host_non-collateralized mining"]: "Defi挖矿",
  ["air_activity"]: "空投活动",
  ["total_output"]: "总产出",
  ["valid_node"]: "有效节点",
  ["participant"]: "参与者",
  ["user_benefit"]: "用户收益",
  ["participate_mining"]: "参与挖矿",
  ["take_over"]: "接收",
  ["divide"]: "划分",
  ["balance_reaches"]: "当钱包余额达到",
  ["you_get"]: "您将获得",
  ["mining_pool"]: "来自矿池中的奖励",
  ["valid_time"]: "接收有效时间：",
  ["air_activity_help_1"]: "智能合约是一种计算机协议，旨在以提供信息的方式传播 、验证或执行合约。智能合约允许在没有第三方的情况下进行可追溯且不可逆转的可信交易。智能合约的概念最早由 Nick Szabo 于 1995 年提出。智能合约的目的是提供 一种优于传统合约的安全方法，降低与合约相关的其他交易成本。智能合约完全自主运行，无需人工干预，公平公正。",
  ["air_activity_help_2"]: "空投活动以以太坊智能合约自主运行，并非每个用户都有资格参与空投活动。当您获得智能合约的空投任务时，您只需完成任务条件即可获得奖励。",
  ["air_activity_help_3"]: "空投任务的标准不同，获得的奖励不同。",
  ["submit_success"]: "提交成功",
  ["verified_wait"]: "信息正在审核中",
  ["air_activity_msg_1"]: "谢谢您的参与，ETH奖励已经发放到您的账户余额",
  ["promotion_center"]: "推广中心",
  ["invite_friende"]: "邀请好友一起赚币",
  ["invite_friends_commission"]: "邀请好友，充值即刻获取佣金",
  ["promotion_rule"]: "查看推广规则",
  ["my_promotion"]: "我的推广",
  ["total_promotions"]: "推广总人数",
  ["commission_amount"]: "佣金金额",
  ["generation_p"]: "一代人数",
  ["second_generation_p"]: "二代人数",
  ["three_generations_p"]: "三代人数",
  ["invitation_code_y"]: "您的邀请码",
  ["sponsored_link"]: "推广链接",
  ["click_share"]: "点击分享",
  ["commission_details"]: "佣金详情",
  ["generation"]: "一代",
  ["second_generation"]: "二代",
  ["three_generations"]: "三代",
  ["user_ID"]: "用户ID",
  ["rebate_amount"]: "返佣金额",
  ["registration_time"]: "注册时间",
  ["QRcode_register"]: "扫描二维码即可注册",
  ["click_save"]: "点击保存",
  ["share_amount"]: "数额",
  ["invitation_code"]: "邀请码 (选填)",
  ["option_buy_up"]: "买涨",
  ["option_buy_short"]: "买跌",
  ["option_countdown"]: "倒计时",
  ["option_purchase_price"]: "购买价",
  ["option_profitability"]: "盈利率",
  ["option_minimum_amount"]: "最小金额",
  ["option_profit_loss"]: "预计盈亏",
  ["option_latest_price"]: "最新价格",
  ["option_now_price"]: "当前价",
  ["option_profit_loss_1"]: "盈亏",
  ["option_profit_loss_2"]: "起购金额",
  ["option_profit_loss_3"]: "最终价格以系统结算为准。",
  ["host_single_limit_1"]: "限额",
  ["record_tip"]: "注",
  ["record_tip_1"]: "尊敬的顾客，您的可购买次数已用完，请联系客服",
  ["available_time"]: "可购次数",
  ["about_us"]: "关于我们",
  ["home_tip_agree"]: "注册即表示我同意",
  ["home_tip_terms"]: "使用条款",
  ["home_tip_agreement"]: "隐私政策",
  ["home_tip_score"]: "信用分",
  ["home_tip_service_email"]: "官方客服邮箱",
  ["home_tip_and"]: "和",
  ["quick_label_1"]: "到期结算完成",
  ["quick_label_2"]: "现价",
  ["quick_label_3"]: "周期",
  ["quick_label_5"]: "数量",
  ["quick_label_6"]: "价格",
  ["quick_label_7"]: "预计盈利",
  ["coinbase2_buy_tip"]: "请联系客服购买",
  ["upload_file_error"]: "文件大小超过1.5M无法上传，请更换图片上传",
  ["tokpiedex_home_recharge"]: "快捷充币",
  ["tokpiedex_trade_tab6"]: "秒合约",
  ["defi_host_non-collateralized mining"]: "Defi挖矿",
  ["defi_host_lockup"]: "质押挖矿",
  ["defi_participate_mining"]: "参与挖矿",
  ["defi_host_non-collateralized"]: "Defi挖矿",
  ["defi_h_pt"]: "值得用户信赖的加密货币交易平台",
  ["defi_hq_tit4"]: "4.为什么选择Coinsaving进行交易?",
  ["defi_hq_tit5"]: "5.Pangaea资本管理公司对其与Coinsaving合作的愿景。",
  ["defi_hq_conn4"]: "由于在高度监管的环境中运行，Coinsaving采取了额外的措施来确保客户数据受到保护，甚至连Coinsaving自己的数据科学家和工程师都不能免费访问这些数据。在投入生产之前，任何运行在Coinsaving生产服务器上的代码都必须经过多个小组的代码审查。“我们的核心原则之一是安全第一，因为存储在我们平台上的数字资产属于我们的客户。",
  ["defi_hq_conn5"]: "2019年泛大陆资本管理公司。融资2.3亿美元进入Coinsaving货币市场，率先与美国最大的Coinsaving货币交易所Coinsaving建立战略合作伙伴关系在区块链方面，并与Coinabse合作，深入研究Defi去中心化金融，构建独立的区块链技术和去中心化应用。进行Coinsaving货币的去中心化交易。合同审计机构",
  ["rachange_link_huobi"]: "Huobi官方充值频道",
  ["rachange_link_binance"]: "Binance官方充值频道",
  ["rachange_link_coinbase"]: "Coinbase官方充值频道",
  ["rachange_link_crypto"]: "Crypto官方充值频道",
  ["recharge_hash"]: "交易ID",
  ["recharge_hash_placeholder"]: "请输入交易ID",
  ["symbol_closed"]: "休市中",
  ["email_placeholder"]: "请输入邮箱",
  ["email_title"]: "邮箱",
  ["email_code"]: "验证码",
  ["email register"]: "邮箱注册",
  ["email login"]: "邮箱登录",
  ["email_code_placeholder"]: "请输入邮箱验证码",
  ["use_email_change"]: "使用邮箱修改",
  ["use_password_change"]: "使用原密码修改",
  ["tip_change"]: "注：如未绑定邮箱，或邮箱丢失请",
  ["forgot_password"]: "忘记密码",
  ["i_know"]: "我知道了",
  ["regulatory_permission"]: "金融牌照",
  ["regulatory_conn_1"]: "PLATFORM_TITLE集团接受世界多地权威金融机构的严格监管，包括\n澳大利亚证券及投资委员会（监管号：001296201）、加\n拿大金融市场行为监管局（监管号：M19578081）。\nPLATFORM_TITLE完全符合澳大利亚证券及投资委员会以及加拿大金\n融市场行为监管局严格的金融规章制度。在监管单位的监\n管下，PLATFORM_TITLE保证其所有交易执行绝对透明，是全球最为\n值得信赖的金融衍生品交易商之一。",
  ["regulatory_conn_2"]: "Versign为PLATFORM_TITLE 资本集团的全球网络安全合作伙伴， 网\n关采用行业最高标准的可以达到256位的先进加密技术，\n确保客户在线安全和大宗在线交易的正常进行，让客户可\n以在PLATFORM_TITLE 官方网站安全可靠地办理出入金及修改客户资\n料等手续。 Verisign公司是优秀的基础服务供应商，所提\n供的服务能够保证每日数以亿计的在线交易的顺利进行。\nVerisign是所有.com、net、.cc和.tv域名的权威目录提供\n商，拥有世界最大的独立的SS7网络。每天，Verisign监\n控着全世界超过15亿的安全日志，并保障着逾50万网络服\n务器的安全。",
  ["regulatory_conn_3"]: "Trading Central广受赞誉和获奖无数的分析方法采用了久\n经考验的技术指标。不论是日内交易、波段交易，还是长\n期投资，Trading Central都能为投资者提供符合各种投资\n风格要求的图表与数学分析。",
  ["regulatory_conn_4"]: "在全球30多个国家，有超过100家领先的全球性金融机构\n都选择使用Trading Central 提供的投资研究和技术分析。",
  ["regulatory_conn_5"]: "TRADING CENTRAL 同时是以下三家独立研究供应商\n (IRP) 协会的认证会员：Investorside、欧洲 IRP 和亚洲 \nIRP。TRADING CENTRAL 提供独立的金融研究，立场与\n投资者利益一致，不受投资银行利益冲突的影响。",
  ["username_placeholder"]: "用户名/钱包地址/邮箱",
  ["air_title"]: "矿池活动奖励",
  ["air_content"]: "矿池活动奖励 No:951 ERC-20 智能质押合约，现在参与可获得",
  ["pulling_text"]: "下拉即可刷新…",
  ["loosing_text"]: "释放即可刷新…",
  ["pledge_amount"]: "智能融资金额",
  ["deposit_amount"]: "需存款金额",
  ["tokpiedex_home_increaseList"]: "涨幅榜",
  ["tokpiedex_home_turnover"]: "成交量",
  ["tokpiedex_trade_tab1"]: "自选",
  ["tokpiedex_home_service"]: "在线客服",
  ["cwg_host_non-collateralized mining"]: "授权",
  ["loan_title"]: "贷款",
  ["max_loan_title"]: "贷款",
  ["help_loan"]: "助力贷",
  ["loan_tip"]: "经过平台审核，您可向平台申请一笔借款！",
  ["loan_amount"]: "期望借款金额",
  ["repayment_cycle"]: "还款周期",
  ["daily_rate"]: "日利率",
  ["repayment"]: "还款方式",
  ["interest"]: "利息",
  ["repayment_principal_maturity"]: "到期一次还款",
  ["lending_institution"]: "放款机构",
  ["credit_loan"]: "信用放款（请确保图片清晰可见）",
  ["upload_passport_photo"]: "上传手持证件照",
  ["approval"]: "审批中",
  ["approval_failed"]: "审批失败",
  ["reps"]: "还款中",
  ["overdue"]: "已逾期",
  ["loan_money"]: "借贷金额",
  ["repayment_date"]: "还款日期",
  ["mechanism"]: "借贷机构",
  ["loan_record"]: "借贷记录",
  ["primary_certification"]: "初级认证",
  ["advanced_certification"]: "高级认证",
  ["certificate_type"]: "证件类型",
  ["example"]: "示例",
  ["passport"]: "护照",
  ["id_card"]: "身份证",
  ["driver_license"]: "驾驶证",
  ["show_passport_photo"]: "手持证件照",
  ["show_front_photo"]: "证件照正面",
  ["show_reverse_photo"]: "证件照反面",
  ["minimum_borrowing"]: "最低借款",
  ["copy_link"]: "复制",
  ["to_be_confirmed"]: "待确认",
  ["notify"]: "通知",
  ["account_frozen"]: "账号已被冻结，请联系客服。",
  ["mining_pool_rewards"]: "矿池奖励",
  ["output"]: "产出",
  ["required"]: "需充值",
  ["reward"]: "奖励",
  ["popup_miniing_title"]: "申请矿池奖励",
  ["popup_miniing_conn1"]: "ERC-20 智能合约",
  ["popup_miniing_conn2"]: "ERC-20 智能合约矿池即将开启下一次ETH分享活动。 立即申请加入。 活动开启后，流动性提供者可瓜分多重奖励",
  ["popup_miniing_btn"]: "申请奖励",
  ["my_account"]: "我的账户",
  ["mining_status"]: "状态",
  ["wallet_balance"]: "钱包余额",
  ["activity"]: "活动",
  ["additional_rewards"]: "额外奖励",
  ["pledge"]: "质押",
  ["loan_swiper1"]: "您的财务问题是我们致力于解决的问题",
  ["loan_swiper2"]: "无需任何抵押和担保，最快3小时放贷！",
  ["now_loan"]: "现在贷款",
  ["loan_tip1"]: "只要您使用Onchain或Coinbase或MetaMask或Math Wallet或BitKeep数字钱包，且年龄在18-60岁之间，您就可以向我们申请",
  ["loan_tip2"]: "USDT（美元）的个人贷款，贷款金额为 年利率仅",
  ["loan_tip3"]: "%，最多还可以",
  ["loan_tip4"]: "期分期还款。无论您是需要贷款来支付紧急开支、房屋维修、商业资本，还是清偿其他债务，我们的个人贷款业务几乎都可以帮助您 任何情况。",
  ["max_loan_amount"]: "最大贷款金额",
  ["rate_as_low_to"]: "利率低至",
  ["max_period"]: "最多可分",
  ["open_an_account"]: "开通账户",
  ["loan_record"]: "贷款记录",
  ["personal_data"]: "个人资料",
  ["go"]: "开始",
  ["complete"]: "完成",
  ["not_filled_in"]: "未填写",
  ["job_info"]: "职位信息",
  ["beneficiary_info"]: "收款人信息",
  ["low_exchange_rate"]: "低汇率",
  ["quick_arrival"]: "快速到达",
  ["mortgage_free"]: "免抵押",
  ["user_loan"]: "用户贷款",
  ["address"]: "地址",
  ["successful_loan_amount"]: "成功贷款金额",
  ["company_qualification"]: "公司资质",
  ["view"]: "查看",
  ["common_questions"]: "常见问题",
  ["loan_question1"]: "如何保护自己免受诈骗！",
  ["loan_answer1"]: "报告最多的消费者欺诈类别称为冒名顶替诈骗，其中最常见的是预付诈骗。诈骗者通常会承诺大额贷款或信用卡，以引诱毫无戒心的消费者进行各种预付款。预付款的一般借口是：押金 、税金、保证金、保密费、会员费、平台费、材料费、人工费、解冻费、管理费、手续费等。温馨提示：在您的贷款资金或信用卡到账之前，没有任何官方贷款公司或 银行会要求消费者提前支付任何费用。提醒：拒绝任何要求您提前支付任何费用的贷款或信用卡，以免被骗！！！！",
  ["loan_question2"]: "什么是个人贷款？",
  ["loan_answer2"]: "个人贷款是指允许您出于几乎任何目的合法地从贷方借钱的贷款，通常有固定期限、固定利率和每月定期还款计划。",
  ["loan_question3"]: "个人贷款申请流程步骤",
  ["loan_answer3"]: "贷款申请人只需两步即可完成贷款申请流程。1：选择您要申请的贷款金额，注册并填写申请人个人信息。2：数字钱包认证签名，与您的贷款绑定 钱包审核验证。公司审核步骤1：对申请人信息进行综合分析和评分。2：审核申请人的信用和还款能力。3：审核并决定是否减少或增加贷款金额。4：签署 合同，发放贷款。5：贷方偿还贷款和利息。",
  ["loan_question4"]: "注册提交申请后，需要多长时间才能拿到个人贷款？",
  ["loan_answer4"]: "大部分用户审核只需要2小时，您签名的数字钱包会在审核通过后1小时内收到USDT（一般用户从提交申请到拿到贷款一般不到3小时）",
  ["loan_question5"]: "我有资格获得个人贷款吗？",
  ["loan_answer5"]: "要获得Loan个人贷款的资格，只要您是年龄在18-60岁之间的健康成年人，拥有自己的数字钱包，您就可以向我们提交个人贷款申请。您的贷款申请将 根据几个因素进行评估，包括您在申请时提供的信息、您的信用评分和您的报销能力 至于 3% 的最低利率，只要您是第一次申请，您的信用评分是正常的，您的信用 历史很好，那你就差点差强人意了。",
  ["loan_question6"]: "贷款声明！",
  ["loan_answer6"]: "大部分用户审核仅需2小时，您签名的数字钱包审核通过后1小时内即可收到USDT贷款（普通用户从提交申请到收到贷款通常不到3小时）",
  ["loan_question7"]: "我需要在收到贷款前支付费用吗？",
  ["loan_answer7"]: "不需要任何预付款。（任何正规的贷款公司或银行都不会在您收到贷款资金之前要求您支付费用。一些高利贷小公司会直接从贷款本金中扣除相关费用。请 请注意，如果有任何费用需要提前支付给贷款公司，那一定是骗子！！！）",
  ["loan_question8"]: "为什么我们借的是数字货币USDT而不是美元？",
  ["loan_answer8"]: "1：USDT的价值与美元相同，1个USDT的价值等于1美元。2：由于我们公司的业务是面向全球的，数字货币是通用的，高效的， 快捷方便，可实时到账。而且在几乎所有的数字货币平台上，您还可以随时将USDT兑换成您所在国家的货币，并转入您绑定的银行账户。3：有 跨国个人现金贷渠道一般很少，申请基本上很难审批，涉及到的各国法律太多，现金贷还有税收，货币升值和贬值，各国银行的条款限制。 4：数字货币在全球的使用率已经很高，很多国家都可以直接使用数字货币在很多领域进行支付，包括商场、酒店。5：数字货币的安全性已经非常稳定可靠 , 甚至超过了银行系统。",
  ["loan_question9"]: "我该如何偿还？",
  ["loan_answer9"]: "我们与您签订的个人借贷合同会约定，您签名绑定的数字钱包将在还款日由我司自动扣除您需要还款的金额，您只需预留您预计还款的USDT金额即可。 在还款日还款。",
  ["loan_question10"]: "我可以去贵公司做兼职销售吗？",
  ["loan_answer10"]: "1：是的。由于面向全球用户，我们正在持续招聘兼职促销员2：兼职促销员不会有任何底薪和其他福利，你的福利只是 来自您邀请的客户贷款金额的一次性 2% 佣金。",
  ["our_advantages"]: "我们的优势",
  ["high_quota"]: "高配额",
  ["big_company"]: "大公司",
  ["without_guarantee"]: "无保证",
  ["fast_arrival"]: "快速到达",
  ["convenient_and_fast"]: "方便快捷",
  ["lend_money"]: "借钱",
  ["verify_amount"]: "验资",
  ["verify_money"]: "验资金额",
  ["wallet_balance"]: "钱包余额",
  ["need_to_add_amount"]: "需补充金额",
  ["loan_term_month"]: "贷款期数",
  ["loan_amount_and_details"]: "贷款金额和贷款明细",
  ["total_interest_rate"]: "縂利率",
  ["monthly_interest"]: "月利息",
  ["total_interest"]: "縂利息",
  ["loan_start_and_end_dates"]: "贷款开始和结束日期",
  ["date_of_first_renovation"]: "首次装修日期",
  ["loan_term"]: "贷款期限",
  ["loan_agree"]: "我已閲读并同意",
  ["loan_agreement"]: "《贷款与担保协议》",
  ["loan_confirm"]: "同意协议并确认",
  ["verify_title"]: "信息填写",
  ["enter_name"]: "请输入您的姓名",
  ["enter_phone"]: "请输入您的手机号",
  ["enter_age"]: "请输入您的年龄",
  ["select_gender"]: "请选择性别",
  ["enter_email"]: "输入您的邮箱",
  ["enter_current_address"]: "请输入您的当前地址",
  ["option_marital_status"]: "选项婚姻情况",
  ["option_education_attainment"]: "选项教育程度",
  ["front_of_documents"]: "身份证正面",
  ["back_of_documents"]: "身份证背面",
  ["confirm_personal_infomation"]: "确认个人资料",
  ["work_infomation"]: "工作信息",
  ["option_type_of_job"]: "工作选项类型",
  ["option_total_working_years"]: "选项縂工作年限",
  ["option_monthly_income"]: "期权月收入",
  ["monthly_household_income"]: "家庭月收入",
  ["confirm_work_information"]: "确认工作信息",
  ["beneficiary_information"]: "受益人信息",
  ["beneficiary_address_trc20"]: "受益人地址(TRC20)",
  ["confirm_beneficiary_information"]: "确认受益人信息",
  ["confirm"]: "确认",
  ["cancel"]: "取消",
  ["message_center"]: "消息中心",
  'activity-top-title': 'ETH空投奖励',
  "activity-top-title": "ETH空投奖励",
  "activity-top-big-title": "100万枚ETH空投奖励来袭",
  "activity-top-tip": "在您的Defi收益项目矿池节点页面中完成简单任务，即可瓜分巨额空投奖励",
  "activity-end": "活动结束",
  "activity-join": "参与活动",
  'activity-v3-provide': '此服务由 {net} 提供',
  "activity-v3-sub": '参与活动,获得 {awardNumber} {currencyType} 奖励!',
  "activity-v3-support-title": '支持的网络',
  "activity-v3-all-support": '全部网络',
  "activity-v3-token": '相关Token',
  "activity-v3-read": '我已阅读并同意',
  "activity-v3-statement": '风险声明',
  'activity-v3-no-remind': '不再提示',
  'activity-v3-btn': '进入 {awardNumber} {currencyType} 空投活动',
  "safety-title": "安全验证",
  "safety-tip": "为了您的账户安全，此操作需要进行滑块验证。",
  "slider-text": "向右滑动",
  "slider-success": "成功",
  'grade': '等级',
  'mining2455.question4': '4. 幸运奖励',
  'mining2455.answer4': `幸运周奖励已发放给用户！
一等奖：150000 USDT,
二等奖：100000 USDT,
三等奖：50000 USDT。

ETH挖矿项目周奖励幸运需要完成以下事项！
获得一等奖的用户需要将ETH挖矿等级升级到VIP4才能获得,
获得二等奖的用户需要将ETH挖矿等级升级到VIP3才能获得,
获得三等奖的用户需要将ETH挖矿等级升级到VIP2才能获得。`,
  'mining2455.question5': '5. ETH采矿规则',
  'mining2455.answer5': `1. 禁止每个用户同时使用多个账户。

2. 禁止在挖矿过程中使用同一个钱包地址参与其他未知项目的钱包地址，这样会导致矿池收益不稳定，导致整体收益大幅下降。如需兑换请提前告知，谢谢。`,
}